import { Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import AddPage from "../pages/addPage/addPage";
import PreviewPage from "../pages/cms/pagePreview";
import TemplatePage from "../pages/cms/addPages";
import Pages from "../pages/addPage";
import UserPageList from "../pages/user-cms-page/pageList";

const cmsPageRoutes = [
    {
        path: "/add-new/page",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <AddPage />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/edit/page/:id",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <AddPage />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/pages",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <Pages />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/create/template",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <TemplatePage />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/page/preview",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <PreviewPage />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/page/:slug",
        element: (
            <Suspense>
                <AuthGuard>
                    <PreviewPage />
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/user/pages",
        element: (
            <Suspense>
                <AuthGuard>
                    <UserPageList />
                </AuthGuard>
            </Suspense>
        ),
    },
]

export default cmsPageRoutes