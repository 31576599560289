import React, { useState } from 'react';
import { Avatar, AvatarGroup, Popover, Typography, Box, ListItem, List, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faSmile, faAngry, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import './ReactionGroup.scss';

const ReactionGroup = ({ reactions, customClass = '' }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [extraReactions, setExtraReactions] = useState([]);

  const getIconAndClass = (reaction) => {
    switch (reaction) {
      case 'love':
        return { icon: faHeart, className: 'love-btn' };
      case 'smile':
        return { icon: faSmile, className: 'smile-btn' };
      case 'angry':
        return { icon: faAngry, className: 'angry-btn' };
      case 'like':
        return { icon: faThumbsUp, className: 'like-btn' };
      default:
        return { icon: null, className: '' };
    }
  };


  const handleExtraClick = (event, reactions) => {
    setAnchorEl(event.currentTarget);
    setExtraReactions(reactions);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExtraReactions([]);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <AvatarGroup max={4} className="custom-avatar-group"
        componentsProps={{
          additionalAvatar: {
            onClick: (event) =>
              handleExtraClick(event, reactions.slice(3)), // Pass extra reactions
          },
        }}>
        {reactions.map((reaction, index) => {
          const { icon, className } = getIconAndClass(reaction);
          return (
            <Avatar key={index} className={`${className} ${customClass}`}>
              <FontAwesomeIcon icon={icon} />
            </Avatar>
          );
        })}
      </AvatarGroup>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List className='popup-list'>
          {extraReactions.map((reaction, index) => {
            const { icon, className } = getIconAndClass(reaction);
            return (
              <>
                <ListItem key={index} className='popup-list-item'>
                  <Avatar
                    className={`${className} ${customClass} popup-avatar`}
                  >
                    <FontAwesomeIcon icon={icon} />
                  </Avatar>
                  <Typography>{reaction}</Typography>
                </ListItem>
                {extraReactions.length - 1 !== index && <Divider />}

              </>
            );
          })}
        </List>
      </Popover>
    </>
  );
};

export default ReactionGroup;
