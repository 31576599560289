import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Grid,
  Avatar,
  Paper,
  Button,
  Stack,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  styled,
  Dialog,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import TablePagination from "@mui/material/TablePagination";
import { CSVLink } from "react-csv";
import {
  faCircleInfo,
  faSearch,
  faFileCsv,
  faXmark,
  faFloppyDisk
} from "@fortawesome/free-solid-svg-icons";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import Accordion from "react-bootstrap/Accordion";
import { columns_types_changelog_header } from "../../helpers/theaderColumns/types/typeChangelog";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import NestedTableRow from "../../components/common/draggable/NestedTableRow";
import "../../../src/styles/menu.scss"
import AddEditItemModal from "../../components/common/menu/AddEditItemModal";
import Select from "react-select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { menuTabAction } from "../../redux/slices/menu/menu";
import { menuItemsList } from "../../helpers/constants/menu/menuListing";
import { exportXlsx } from "../../helpers/Exports/FileExport";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import CloseIcon from "@mui/icons-material/Close";

const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="pl-3 py-2"
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box sx={{ p: 3, paddingTop: 1 }}>{children}</Box>}
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    // minHeight: "40vh",
    // maxHeight: "40vh"
  }
}));

const columns_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },
];

const columns_advace_atttributes_headers = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "other-color",
  },
  {
    key: 2,
    id: "view",
    disablePadding: true,
    label: "View",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 3,
    id: "deny",
    disablePadding: true,
    label: "Deny",
    minWidth: 50,
    className: "other-color",
  },

  {
    key: 5,
    id: "add",
    disablePadding: true,
    label: "Add",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 6,
    id: "edit",
    disablePadding: true,
    label: "Edit",
    minWidth: 50,
    className: "other-color",
  },
  {
    key: 7,
    id: "delete",
    disablePadding: true,
    label: "Delete",
    minWidth: 50,
    className: "other-color",
  },
];

const InternalMenuOptionLists = [
  { label: "Types", value: "Types" },
  { label: "Categories", value: "Categories" },
  { label: "Attributes", value: "Attributes" }
]

const AddNewMenus = () => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getAllChangeLogs, setGetAllChangeLogs] = useState();
  const [search, setSearch] = useState("");
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);
  const [anchorElForEnable, setAnchorElForEnable] = useState(null);
  const [anchorElForSearch, setAnchorElForSearch] = useState(null);
  const [anchorElForFolders, setAnchorElForFolders] = useState(null);
  const [istype, setIstype] = useState(false);
  const [anchorElMenu, setAnchorElForMenu] = useState(null);
  const [attributeID, setAttributeID] = useState();
  const [addSubMenu, setAddSubMenu] = useState(false)
  const [getAllIcons, setGetAllIcons] = useState([]);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [tabValue, setTabValue] = useState(0);
  const [errorTab, setErrorTab] = useState(-1);
  const [isErrorOnMenuContentTab, setIsErrorOnMenuContentTab] = useState(false);

  const [getAllGroupsAndUsers, setGetAllGroupsAndUsers] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const [isAdvancePermissionForMenuItem, setIsAdvancePermissionForMenuItem] = useState(false);

  const [isAdvancePermission, setIsAdvancePermission] = useState(false);

  const [showItemModal, setShowItemModal] = useState(false);
  const [showPositionModal, setShowPositionModal] = useState(false);

  const [menuTitle, setMenuTitle] = useState("")
  const [menuStatus, setMenuStatus] = useState(true)

  const [menuItemTitle, setMenuItemTitle] = useState("")
  const [menuItemType, setMenuItemType] = useState("external")
  const [menuItemInternalValue, setMenuItemInternalValue] = useState({
    label: "",
    value: ""
  })
  const [menuItemLink, setMenuItemLink] = useState("")
  const [menuItemVisible, setMenuItemVisible] = useState(true)
  const [menuItemGetAllGroupsAndUsers, setMenuItemGetAllGroupsAndUsers] = useState([]);
  const [menuItemPermissions, setMenuItemPermissions] = useState([])
  const [menuItemIsAdvancePermission, setMenuItemIsAdvancePermission] = useState(false)
  const [menuItemTypeIcon, setMenuItemTypeIcon] = useState("");
  const [lastId, setLastId] = useState(0);
  const [menuItemTypeColor, setMenuItemTypeColor] = useState("#959595")
  const [menuItemTypeBGColor, setMenuItemTypeBGColor] = useState("#660C60")

  const [menuErrors, setMenuErrors] = useState({})
  const [menuItemErrors, setMenuItemErrors] = useState({})

  const [menuItems, setMenuItems] = useState([])
  const [fetchAllUsers, setFetchAllUsers] = useState([])
  const [allMenuItems, setAllMenuItems] = useState([])

  const [allMenuList, setAllMenuList] = useState([])
  const [menuFor, setMenuFor] = useState(true)

  const handleOnClose = () => {
    setShowItemModal(false);
    setMenuItemTitle("")
    setMenuItemLink("")
    setMenuItemType("external")
    setMenuItemInternalValue({
      label: "",
      value: ""
    })
    setMenuItemVisible(true)
    setMenuItemTypeIcon()
    setMenuItemTypeColor("#959595")
    setMenuItemTypeBGColor("#660C60")
    const allUserOption = fetchAllUsers.find(
      (user) => user.value === "all_users"
    );
    setMenuItemPermissions([allUserOption])
    setAddSubMenu(false)
    setShowPositionModal(false)

  }

  const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

  const {
    open,
  } = useSelector((state) => state?.drawerMenu);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const ITEM_HEIGHT = 48;

  const openInfoEnable = Boolean(anchorElForEnable);
  const openInfoSearch = Boolean(anchorElForSearch);
  const openInfoFolder = Boolean(anchorElForFolders);

  const openMenuFoEdit = Boolean(anchorElMenu);

  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  let debounceTimer;

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    } else if (newPage < page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    }
  };

  const getMaxId = (items) => {
    let maxId = 0;
    console.log("menu items::", items)
    items.forEach(item => {
      if (item.id > maxId) {
        maxId = item.id;
      }

      // If there are children, recursively check their ids
      if (item.children && item.children.length > 0) {
        console.log("line 283")
        const childMaxId = getMaxId(item.children);
        if (childMaxId > maxId) {
          maxId = childMaxId;
        }
      }
    });

    return maxId;
  };

  const mapMenuItems = (menu, index) => {
    return {
      order: menu?.id,
      menu_order: index + 1,
      title: menu?.menuItemTitle,
      item_type: menu?.menuItemType,
      link: menu?.menuItemType === "external" ? menu?.menuItemLink : menu?.menuItemInternalValue?.value,
      status: menu?.menuItemVisible === true ? "enable" : "disable",
      icon_id: menu?.menuItemTypeIcon,
      icon_colour: menu?.menuItemTypeColor,
      background_colour: menu?.menuItemTypeBGColor,
      permission: menu?.menuItemPermissions?.length > 0 && menu?.menuItemPermissions?.map((menuPermission) => {
        return {
          user_group_id: menuPermission?.value,
          type: menuPermission?.type,
          can_view: menuPermission?.view === true ? 1 : 0,
          can_deny: menuPermission?.deny === true ? 1 : 0,
          label: menuPermission?.label
        }
      }),
      id: menu?.menu_id ? menu?.menu_id : "",
      advance_permission: menu?.menuItemIsAdvancePermission === true ? 1 : 0,
      sub_menu: menu?.children?.length > 0 ? menu?.children?.map(mapMenuItems) : [] // Recursive mapping for children
    };
  };

  const findMenuItemById = (menuItems, id) => {
    for (let menu of menuItems) {
      if (menu.id === id) {
        return menu;
      }
      if (menu.children?.length > 0) {
        const childResult = findMenuItemById(menu.children, id);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  };

  const buildMenuItems = (menu, allowed) => {
    return {
      id: menu?.id,
      menuItemTitle: menu?.title,
      menuItemType: menu?.item_type,
      menuItemLink: menu?.item_type === "external" ? menu?.link : {
        label: "",
        value: ""
      },
      menuItemInternalValue: menu?.item_type === "internal" ? {
        label: menu?.link,
        value: menu?.link
      } : {
        label: "",
        value: ""
      },
      menuItemVisible: menu?.status === "enable" ? true : false,
      menuItemTypeIcon: menu?.icon_id,
      menuItemTypeColor: menu?.icon_colour,
      menuItemTypeBGColor: menu?.background_colour,
      menuItemPermissions: menu?.permission?.map((permission) => {
        return {
          label: permission?.label,
          value: permission?.user_group_id,
          view: permission?.can_view === 1 ? true : false,
          deny: permission?.can_deny === 1 ? true : false,
          type: permission?.type
        };
      }),
      menu_id: menu?.id ? menu?.id : "",
      addChildMenu: menu?.item_type === "external" ? allowed : false,

      children: menu?.sub_menu?.length > 0
        ? menu?.sub_menu.map((subMenu) => buildMenuItems(subMenu, false))
        : [] // Recursively handle sub-menus
    };
  };

  const header_csv = [
    { label: "No", key: "no" },
    { label: "User", key: "user" },
    { label: "Date", key: "date" },
    { label: "Area", key: "area" },
    { label: "Details", key: "details" },
  ];

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        tabValue == index
          ? `selected-tab-attribute ${isErrorOnMenuContentTab == true && `error-on-class`
          }`
          : `non-selected-tab-attribute ${isErrorOnMenuContentTab == true && `error-on-class`
          }`,
    };
  }

  function flattenMenu(menuItems) {
    console.log("flattenedMenu menuItems:::", menuItems)
    const result = [];
    menuItems.forEach(item => {
      result.push({ menu_id: item.menu_id, menuItemTitle: item.menuItemTitle });
      if (item.children && item.children.length > 0) {
        result.push(...flattenMenu(item.children));
      }
    });
    const data = result.map((menu, index) => {
      return {
        label: menu.menuItemTitle,
        value: menu.menu_id
      }
    })
    setAllMenuItems(data)
    return result;
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validateFields = () => {
    const errors = {};
    if (!menuItemTitle.trim()) {
      errors.title = "Menu Item Title is required.";
    }
    if (menuItemType === "external") {
      if (menuItemLink === "") {
        errors.link = "Menu Item Link is required.";
      }
    }
    if (menuItemType === "internal") {
      if (menuItemInternalValue?.value === "") {
        errors.link = "Menu Item Internal option is required.";
      }
    }

    if (menuItemPermissions?.length < 1) {
      errors.permissions = "Select atleast one user/group."
    }
    if (menuItemTypeIcon === "") {
      errors.icon = "Menu icon is required."
    }
    if (!menuItemTypeColor.trim()) {
      errors.iconcolor = "Menu icon color is required."
    }
    if (!menuItemTypeBGColor.trim()) {
      errors.iconBgColor = "Menu icon background color is required."
    }
    return errors;
  };

  const validateMenuFields = () => {
    const errors = {}

    if (!menuTitle.trim()) {
      errors.menuTitle = "Menu title is required."
    }

    if (menuItems?.length < 1) {
      errors.menuItems = "Add atleast one menu item."
    }
    if (permissions?.length < 1) {
      errors.permissions = "Select atleast one user/group."
    }

    return errors
  }

  const addMenuItems = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      console.log("line 437")
      setMenuItemErrors(validationErrors); // Display errors if validation fails
    } else {
      setMenuItemErrors({});
      console.log("Form submitted successfully");
      const nextId = lastId + 1
      let menuItem = {
        id: nextId,
        menuItemTitle: menuItemTitle,
        menuItemType: menuItemType,
        menuItemLink: menuItemType === "external" ? menuItemLink : { label: "", value: "" },
        menuItemInternalValue: menuItemType === "internal" ? menuItemInternalValue : { label: "", value: "" },
        menuItemVisible: menuItemVisible,
        menuItemPermissions: menuItemPermissions,
        menuItemIsAdvancePermission: menuItemIsAdvancePermission,
        menuItemTypeIcon: menuItemTypeIcon,
        menuItemTypeColor: menuItemTypeColor,
        menuItemTypeBGColor: menuItemTypeBGColor,
        addChildMenu: true,
      }
      const updatedMenuItems = [...menuItems, menuItem];
      setMenuItems([...menuItems, menuItem])
      setLastId(nextId);
      const flattenedMenu = flattenMenu(updatedMenuItems);
      console.log("flattenedMenu:::", flattenedMenu);
      setMenuErrors((prevErrors) => ({ ...prevErrors, menuItems: "" }));
      handleOnClose()
    }
  }

  const addSubMenuItems = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setMenuItemErrors(validationErrors); // Display errors if validation fails
    } else {
      setMenuItemErrors({});
      const nextId = lastId + 1;
      let menuItem = {
        id: nextId,
        menuItemTitle: menuItemTitle,
        menuItemType: menuItemType,
        menuItemLink: menuItemType === "external" ? menuItemLink : { label: "", value: "" },
        menuItemInternalValue: menuItemType === "internal" ? menuItemInternalValue : { label: "", value: "" },
        menuItemVisible: menuItemVisible,
        menuItemPermissions: menuItemPermissions,
        menuItemIsAdvancePermission: menuItemIsAdvancePermission,
        menuItemTypeIcon: menuItemTypeIcon,
        menuItemTypeColor: menuItemTypeColor,
        menuItemTypeBGColor: menuItemTypeBGColor,
        addChildMenu: false,
        children: [] // Initialize empty children array for future nested items
      };

      // Recursive function to add submenu item at any nested level
      const addSubMenuToMenuItems = (items) => {
        return items.map(menu => {
          if (menu.id === attributeID?.id) {
            // If the parent doesn't have 'children', initialize it as an empty array
            const updatedChildren = menu.children ? [...menu.children, menuItem] : [menuItem];
            return {
              ...menu,
              children: updatedChildren // Add the new submenu item to 'children'
            };
          } else if (menu.children) {
            // If the menu has children, recursively search within the children
            return {
              ...menu,
              children: addSubMenuToMenuItems(menu.children)
            };
          }
          return menu;
        });
      };

      // Update the menuItems state with the new submenu added recursively
      const updatedMenuItems = addSubMenuToMenuItems(menuItems);

      setMenuItems(updatedMenuItems);
      setLastId(nextId);
      const flattenedMenu = flattenMenu(updatedMenuItems);
      console.log("flattenedMenu:::", flattenedMenu);
      setMenuErrors((prevErrors) => ({ ...prevErrors, menuItems: "" }));
      handleOnClose();
      setAttributeID();
      setAddSubMenu(false);
    }
  };

  const openMenuItemsForEdit = () => {
    const fetchMenuItem = findMenuItemById(menuItems, attributeID?.id);
    if (fetchMenuItem) {
      setMenuItemTitle(fetchMenuItem?.menuItemTitle);
      setMenuItemType(fetchMenuItem?.menuItemType)
      if (fetchMenuItem?.menuItemType === "external") {
        setMenuItemLink(fetchMenuItem?.menuItemLink);
      } else if (fetchMenuItem?.menuItemType === "internal") {
        setMenuItemInternalValue(fetchMenuItem?.menuItemInternalValue)
      }
      setMenuItemVisible(fetchMenuItem?.menuItemVisible);
      setMenuItemPermissions(fetchMenuItem?.menuItemPermissions);
      setMenuItemIsAdvancePermission(fetchMenuItem?.menuItemIsAdvancePermission);
      setMenuItemTypeIcon(fetchMenuItem?.menuItemTypeIcon);
      setMenuItemTypeColor(fetchMenuItem?.menuItemTypeColor);
      habdleAddMenuItem();
      handleClose();
    }
  };

  const updateMenuItemById = (menuItems, id, updatedData) => {
    return menuItems.map((menu) => {
      if (menu.id === id) {
        return { ...menu, ...updatedData };
      }
      if (menu.children?.length > 0) {
        return {
          ...menu,
          children: updateMenuItemById(menu.children, id, updatedData)
        };
      }
      return menu;
    });
  };

  const updateMenuItem = () => {
    const validationErrors = validateFields();
    if (Object.keys(validationErrors).length > 0) {
      setMenuItemErrors(validationErrors); // Display errors if validation fails
    } else {
      setMenuItemErrors({});

      // Prepare the updated data
      const updatedData = {
        menuItemTitle: menuItemTitle,
        menuItemType: menuItemType,
        menuItemLink: menuItemType === "external" ? menuItemLink : { label: "", value: "" },
        menuItemInternalValue: menuItemType === "internal" ? menuItemInternalValue : { label: "", value: "" },
        menuItemVisible: menuItemVisible,
        menuItemPermissions: menuItemPermissions,
        menuItemIsAdvancePermission: menuItemIsAdvancePermission,
        menuItemTypeIcon: menuItemTypeIcon,
        menuItemTypeColor: menuItemTypeColor,
        menuItemTypeBGColor: menuItemTypeBGColor
      };

      // Update the menu item recursively
      const updatedMenuItems = updateMenuItemById(menuItems, attributeID?.id, updatedData);

      // Update the state with the modified array
      setMenuItems(updatedMenuItems);
      const flattenedMenu = flattenMenu(updatedMenuItems);
      console.log("flattenedMenu:::", flattenedMenu);
      setMenuErrors((prevErrors) => ({ ...prevErrors, menuItems: "" }));
      handleOnClose();
      handleClose();
      setAttributeID();
    }
  };

  const removeMenuItemById = (menuItems, id) => {
    return menuItems.filter((menu) => {
      if (menu.id === id) {
        return false; // Remove the item with the given ID
      }
      if (menu.children?.length > 0) {
        menu.children = removeMenuItemById(menu.children, id); // Recursively remove from children
      }
      return true;
    });
  };

  const removeMenuItem = () => {
    // Remove the menu item recursively
    const updatedMenuItems = removeMenuItemById(menuItems, attributeID?.id);

    // Update the state with the modified array
    setMenuItems(updatedMenuItems);
    const flattenedMenu = flattenMenu(updatedMenuItems);
    console.log("flattenedMenu:::", flattenedMenu);
    handleClose();
    setAttributeID();
  };

  const handleChangeForTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClick = (event, row) => {
    console.log("row id::", row)
    setAnchorElForMenu(event.currentTarget);
    setAttributeID(row);
  };

  const handleClickForAttId = (event, row) => {
    console.log("row id::", row)
    // setAnchorElForMenu(event.currentTarget);
    setAttributeID(row);
  };

  const handleToggle = (value, key) => {
    setPermissions((prevPermissions) =>
      prevPermissions?.map((permission) => {
        if (permission?.value === value) {
          if (key === 'deny') {
            // If deny is toggled, set all other permissions to false
            return {
              ...permission,
              deny: !permission.deny,
              view: false,
              edit: false,
              delete: false,
              add: false,
            };
          } else {
            // If any other permission is toggled, set deny to false
            return {
              ...permission,
              [key]: !permission[key],
              deny: false,
            };
          }
        }
        return permission;
      })
    );

    setGetAllGroupsAndUsers((prevPermissions) =>
      prevPermissions.map((permission) => {
        if (permission?.value === value) {
          if (key === 'deny') {
            // If deny is toggled, set all other permissions to false
            return {
              ...permission,
              deny: !permission.deny,
              view: false,
              edit: false,
              delete: false,
              add: false,
            };
          } else {
            // If any other permission is toggled, set deny to false
            return {
              ...permission,
              [key]: !permission[key],
              deny: false,
            };
          }
        }
        return permission;
      })
    );
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
    setShowPositionModal(false)
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    console.log("source::", source)
    console.log("destination::", destination)

    // If no destination, check if it's dragged outside and make it a new parent
    if (!destination) {
      //make parent if not in root
      if (source.droppableId !== "rowsData") {
        setMenuItems((prevMenuItems) => {
          const sourceParentId = parseInt(source.droppableId.split("-")[1], 10);
          let draggedItem = null;

          const removeDraggedItem = (items, parentId, index) => {
            return items.map((item) => {
              if (item.id === parentId) {
                const updatedChildren = [...item.children];
                draggedItem = updatedChildren.splice(index, 1)[0]; // Remove the dragged item
                return { ...item, children: updatedChildren };
              }
              if (item.children && item.children.length > 0) {
                return { ...item, children: removeDraggedItem(item.children, parentId, index) };
              }
              return item;
            });
          };

          const updatedItems = removeDraggedItem(prevMenuItems, sourceParentId, source.index);

          // Add the dragged item as a new parent at the top level
          const newMenuItems = [...updatedItems, { ...draggedItem, children: [] }];

          // Flatten the menu after updating
          const flattenedMenu = flattenMenu(newMenuItems);
          console.log("flattenedMenu:::", flattenedMenu);

          return newMenuItems; // Return the updated menu items
        });
        // const flattenedMenu = flattenMenu(menuItems);
        // console.log("flattenedMenu:::", flattenedMenu);
      }
      return;
    }

    const reorderItems = (list, startIndex, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      return result;
    };

    // Helper function to recursively find and reorder the nested children
    const reorderNestedItems = (items, sourceId, destinationId, sourceIndex, destinationIndex) => {
      return items.map((item) => {
        // If the source or destination parent matches the current item, reorder children
        if (item.id === sourceId || item.id === destinationId) {
          return {
            ...item,
            children: reorderItems(item.children, sourceIndex, destinationIndex),
          };
        }

        // If the item has children, continue searching recursively
        if (item.children && item.children.length > 0) {
          return {
            ...item,
            children: reorderNestedItems(item.children, sourceId, destinationId, sourceIndex, destinationIndex),
          };
        }

        // Return the item as-is if it doesn't match
        return item;
      });
    };

    setMenuItems((prevMenuItems) => {
      let updatedMenuItems = [];
      // Handle reordering at the top-level (parent menu items)
      if (source.droppableId === "rowsData" && destination.droppableId === "rowsData") {
        return reorderItems(prevMenuItems, source.index, destination.index);
      }

      // Extract the parent ID from droppableId (e.g., "children-1" => 1)
      const sourceParentId = parseInt(source.droppableId.split("-")[1], 10);
      const destinationParentId = parseInt(destination.droppableId.split("-")[1], 10);


      // Handle reordering within children of the parent or nested items
      updatedMenuItems = reorderNestedItems(
        prevMenuItems,
        sourceParentId,
        destinationParentId,
        source.index,
        destination.index
      );
      const flattenedMenu = flattenMenu(updatedMenuItems);
      console.log("flattenedMenu:::", flattenedMenu);
      return updatedMenuItems;
    });

  };

  const handleChangeLogCsvDownload = async () => {
    try {
      setLoading(true);

      const result = await exportXlsx(`menu/changelog/${id}?search=${search}&pagination=no`, 'menu_change_log');
      if (!result) {
        Toast.error("Failed to download file");
      }

    } catch (error) {
      console.error("Error while downloading csv changelog::", error);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  }

  const onSubmit = async () => {
    const validationErrors = validateMenuFields();
    if (Object.keys(validationErrors).length > 0) {
      setMenuErrors(validationErrors); // Display errors if validation fails
    } else {
      const menuItemData = menuItems?.map(mapMenuItems);
      let permissionData;
      const formData = new FormData();
      if (isAdvancePermission === true) {
        permissionData = permissions?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: data?.view === true ? 1 : 0,
            can_deny: data?.deny === true ? 1 : 0,
            can_edit: data?.edit === true ? 1 : 0,
            can_delete: data?.delete === true ? 1 : 0,
            // can_resource: data?.redacted === true ? 1 : 0,
            can_add: data?.add === true ? 1 : 0,
            label: data?.label,
          };
        });
      } else {
        permissionData = permissions?.map((data) => {
          return {
            user_group_id: data?.value,
            type: data?.type,
            can_view: data?.view === true ? 1 : 0,
            can_deny: data?.deny === true ? 1 : 0,
            can_edit: 0,
            can_delete: 0,
            // can_resource: 0,
            can_add: 0,
            label: data?.label,
          };
        });
      }

      formData.append("title", menuTitle)
      formData.append("status", menuStatus === true ? "enable" : "disable")
      formData.append("menu_items", JSON?.stringify(menuItemData))
      formData.append("permission", JSON?.stringify(permissionData))
      formData.append("advance_permission", isAdvancePermission === true ? 1 : 0)
      formData.append("menu_type", menuFor === true ? "user" : "admin")

      if (id) {
        try {
          setLoading(true);
          const response = await api.post(
            `/admin/menu/update/${id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success == true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/menu`);
            dispatch(menuTabAction.handleSelectedTab(0));
            dispatch(drawerMenuAction?.handleFetchNewMenus(true))
            navigate("/menu");
          } else if (response?.success == false) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(response?.message);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status == 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status == 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.name);
            const errorData = Object.values(
              err?.response?.data?.errors
            ).forEach((value) => Toast.error(value));
          }
          if (err?.response?.status == 429) {
            Toast.error("Too many attemps wait for 2 min.");
          }
        }
      } else {
        try {
          setLoading(true);
          const response = await api.post(
            "/admin/menu/store",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${userToken}`,
              },
            }
          );
          if (response?.success == true) {
            setLoading(false);
            setIsSuccessMessage(true);
            setSuccessMessage(response?.message);
            Toast.success(response?.message);
            localStorage.setItem("last-redirect-page", `/menu`);
            dispatch(menuTabAction.handleSelectedTab(0));
            dispatch(drawerMenuAction?.handleFetchNewMenus(true))

            navigate("/menu");
          } else if (response?.success == false) {
            setLoading(false);
            // dispatch(typeTabAction.handleSelectedTab(0));
            setIsWarningMessage(true);
            setWarningMessage(response?.message);
            Toast.error(response?.message);
          }
        } catch (err) {
          console.log("error in ctreate news api::", err);
          setLoading(false);

          if (err?.response?.status == 401) {
            setLoading(false);
            logoutHelper(dispatch, navigate, userToken);
          }
          if (err?.response?.status == 422) {
            setLoading(false);
            setIsWarningMessage(true);
            setWarningMessage(err?.response?.data?.errors?.name);
            const errorData = Object.values(
              err?.response?.data?.errors
            ).forEach((value) => Toast.error(value));
          }
          if (err?.response?.status == 429) {
            Toast.error("Too many attemps wait for 2 min.");
          }
        }
      }
    }

  };

  const fetchMenuDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/menu/edit/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        setMenuTitle(response?.response?.title)
        setMenuStatus(response?.response?.status === "enable" ? true : false)
        const menuItems = response?.response?.menu_items?.map((menu) => buildMenuItems(menu, true));
        const menuPermissions = response?.response?.permission?.map((permission) => {
          return {
            label: permission?.label,
            value: permission?.user_group_id,
            view: permission?.can_view === 1 ? true : false,
            deny: permission?.can_deny === 1 ? true : false,
            edit: permission?.can_edit === 1 ? true : false,
            delete: permission?.can_delete === 1 ? true : false,
            add: permission?.can_add === 1 ? true : false,
            type: permission?.type
          }
        })
        setMenuItems(menuItems)
        const flattenedMenu = flattenMenu(menuItems);
        console.log("flattenedMenu:::", flattenedMenu);
        setPermissions(menuPermissions)
        setMenuFor(response?.response?.menu_type === "user" ? true : false)
        setIsAdvancePermission(response?.response?.advance_permission === 0 ? false : true)
        const maxId = getMaxId(menuItems);
        setLastId(maxId);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  }

  const fetchAllChangeLogs = async (page) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/menu/changelog/${parseInt(
          id
        )}}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        setGetAllChangeLogs(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
    }
  };

  const fetchAllChangeLogsForExportCSV = async (page, pagination) => {
    try {
      // setLoading(true);
      const response = await api.get(
        `admin/type/master/changelog/${parseInt(
          id
        )}}?per_page=${rowsPerPage}&page=${page}&search=${search}&pagination=${pagination}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        const data_csv = response?.response?.data?.map(
          (csvData, index) => {
            const descriptionText = processedHTML(
              csvData?.description
            ).innerText;

            return {
              no: index + 1,
              user: csvData?.changed_by,
              date: csvData?.changed_at,
              area: csvData?.area,
              details: descriptionText,
            };
          }
        );
        setGetChangelogCsvData(data_csv);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
    }
  };

  const habdleAddMenuItem = () => {
    console.log('inide handle menu item');
    setShowItemModal(true);
    if (!id) {
      const allUserOption = fetchAllUsers.find(
        (user) => user.value === "all_users"
      );
      setMenuItemPermissions([allUserOption])
    }
  }

  const fetchElementList = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/type-post/attribute/news`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        const users = response?.response?.users;
        const groups = response?.response?.groups;

        const userMenuLists = menuItemsList?.filter((data) => data?.menuFor === "user")
        const adminMenuLists = menuItemsList?.filter((data) => data?.menuFor === "admin")

        const finalGroups = groups?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });
        const finalUsers = users?.map((data) => {
          return {
            label: data?.label,
            value: data?.value,
            view: true,
            deny: false,
            edit: false,
            delete: false,
            redacted: false,
            type: data?.type,
          };
        });

        setFetchAllUsers(finalUsers)

        const finalUserMenuList = userMenuLists?.map((data) => {
          return {
            label: data?.url,
            value: data?.url,
          }
        })

        const finalAdminMenuList = adminMenuLists?.map((data) => {
          return {
            label: data?.url,
            value: data?.url,
          }
        })

        const allGroupsAndUsersData = [
          { label: "Users", options: finalUsers },
          { label: "Groups", options: finalGroups },
        ];

        const allUserAndAdminMenuListData = [
          { label: "User Menu", options: finalUserMenuList },
          { label: "Admin Menu", options: finalAdminMenuList },
        ]
        // Find the "all_user" option
        const allUserOption = finalUsers.find(
          (user) => user.value == "all_users"
        );
        setAllMenuList(allUserAndAdminMenuListData)
        setGetAllGroupsAndUsers(allGroupsAndUsersData);
        setMenuItemGetAllGroupsAndUsers(allGroupsAndUsersData)
        // Preselect "all_user"
        if (allUserOption && !id) {
          setPermissions([allUserOption]);
          setMenuItemPermissions([allUserOption])
        }
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/attribute/add-new`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/attribute/add-new`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/attribute/add-new`);

        navigate("/");
      }
      if (err?.response?.status === 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchAllIcons = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/icon-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setGetAllIcons(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  useEffect(() => {
    if (id) {
      fetchElementList()
      fetchAllIcons()
      fetchMenuDetails()
      fetchAllChangeLogs(page)
    } else {
      fetchElementList()
      fetchAllIcons()
    }
  }, [parseInt(id)]);


  useEffect(() => {
    if (search.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setPage(0);
        fetchAllChangeLogs(page);
        fetchAllChangeLogsForExportCSV(page, "no");
      }, debounceTimeout);
    } else if (search === "") {
      setPage(0);
      fetchAllChangeLogs(page);
      fetchAllChangeLogsForExportCSV(page, "no");
    } else {
      clearTimeout(debounceTimer);
    }
    return () => clearTimeout(debounceTimer);
  }, [search]);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"
            }`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}

          {/* Breadcrumbs */}
          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={
                  <NavigateNextIcon fontSize="small" />
                }
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                >
                  Admin
                </Link>
                <Typography
                  key="3"
                  component="h3"
                  color="inherit"
                  className="cursor-pointer"
                  onClick={() => {
                    localStorage.setItem(
                      "last-redirect-page",
                      `/menu`
                    );

                    navigate("/menu");
                  }}
                >
                  Menus
                </Typography>
                <Typography
                  key="3"
                  component="h3"
                  color="text.primary"
                >
                  {id ? "Edit" : "Add"} Menu
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider
            className="mt-4"
            style={{ background: "#EEEEEE" }}
          />

          <Row className="mt-1">
            <Col lg="9" md="7">
              <Stack
                direction="row"
                className="gap-30"
              >
                <Accordion
                  defaultActiveKey={[
                    "0",
                    "1",
                    "2",
                  ]}
                  alwaysOpen
                  className="w-100"
                >
                  <Accordion.Item
                    eventKey="0"
                    className="bg-white card-wrapper w-100"
                  >
                    <Accordion.Header className="bg-white fw-700 primary-text">
                      Content
                    </Accordion.Header>
                    <Accordion.Body className="add-menu-content-p-0">
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                        }}
                        className="box-attribute"
                      >
                        <Tabs
                          value={tabValue}
                          onChange={handleChangeForTab}
                          aria-label="basic tabs example"
                          // variant={isTablet && "scrollable"}
                          variant="scrollable"
                          className={isErrorOnMenuContentTab === true ? "tabs" : ""}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: (isErrorOnMenuContentTab === true && tabValue == 0) ? "red" : "#660C60",
                            },
                          }}
                        >
                          <Tab
                            icon={<FontAwesomeIcon icon={faList} />}
                            label="Menu Content"
                            iconPosition="start"
                            {...a11yProps1(0)}
                          />
                        </Tabs>
                      </Box>
                      <CustomTabPanel
                        value={tabValue}
                        index={0}
                        errorTab={errorTab}
                      >
                        <Row>
                          <Col lg="12">
                            <Typography className="fw-400 dark-text">
                              Title
                              <span className="required">
                                *
                              </span>
                            </Typography>
                            <TextField
                              className="input-field mt-2"
                              fullWidth
                              id="outlined-basic username"
                              variant="outlined"
                              size="small"
                              name="title"
                              placeholder="Enter title here..."
                              value={
                                menuTitle
                              }
                              onChange={(
                                event
                              ) => {
                                setMenuTitle(event?.target?.value)
                                if (event.target.value.trim()) {
                                  setMenuErrors((prevErrors) => ({ ...prevErrors, menuTitle: "" }));
                                }
                              }}

                              helperText={
                                menuErrors.menuTitle
                              }
                              error={
                                Boolean(
                                  menuErrors.menuTitle
                                )
                              }
                            />
                          </Col>
                          <Col lg="12">
                            <Box
                              aria-labelledby="tableTitle"
                              stickyHeader
                            >
                              <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="rowsData" type={"blockList"}>
                                  {(provided) => (
                                    <Box
                                      style={{ width: "100%", top: 0, left: 0 }}
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {menuItems?.map((rows, index) => (
                                        <NestedTableRow
                                          key={rows.id}
                                          rows={rows}
                                          index={index}
                                          handleClick={handleClick}
                                          openMenuFoEdit={openMenuFoEdit}
                                          handleClose={handleClose}
                                          handleClickForAttId={handleClickForAttId}
                                        />
                                      ))}
                                      {provided.placeholder}
                                    </Box>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </Box>
                          </Col>
                          {menuErrors?.menuItems && (
                            <Col>
                              <p
                                style={{ color: "#d32f2f" }}
                                className="error-msg"
                              >
                                {menuErrors?.menuItems}
                              </p>
                            </Col>
                          )}
                        </Row>
                        <div className="mt-4 d-flex flex-wrap justify-content-end">
                          <Button
                            variant="outlined"
                            startIcon={
                              <FontAwesomeIcon icon={faPlus} />
                            }
                            className="add-option-btn"
                            onClick={habdleAddMenuItem}
                          >
                            Add Menu Item
                          </Button>
                        </div>
                      </CustomTabPanel>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="2"
                    className="bg-white card-wrapper w-100 mt-4"
                  >
                    <Accordion.Header className="bg-white fw-700 primary-text">
                      Access and Permissions
                    </Accordion.Header>
                    <Accordion.Body className="content-card-body">
                      <Row>
                        <Col lg="12" className="">
                          <Typography className="fw-400 fs-14 dark-text">
                            Select Users
                          </Typography>
                          <Select
                            closeMenuOnSelect={false}
                            options={getAllGroupsAndUsers}
                            menuPlacement="bottom"
                            name="users"
                            value={permissions}
                            placeholder="Select users..."
                            isMulti
                            menuPosition="fixed"
                            onChange={(selectedOption) => {
                              const users = selectedOption.map(
                                (option) => option
                              );
                              setPermissions(users);
                              if (permissions?.length < 1) {
                                setMenuErrors((prevErrors) => ({ ...prevErrors, permissions: "" }));
                              }
                            }}
                            className={`muilt-select-field mt-2 `}
                            classNamePrefix="select"
                            styles={{
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#660c605c"
                                  : provided.backgroundColor,
                                color: state.isSelected
                                  ? "var(--dark-color)"
                                  : provided.color,
                              }),
                              multiValueLabel: (provided, state) => {
                                return {
                                  ...provided,
                                  color:
                                    state.data?.type === "group"
                                      ? "rgb(0, 135, 90)"
                                      : "rgb(0, 82, 204)",
                                };
                              },
                            }}
                          />
                        </Col>
                        {menuErrors?.permissions && (
                          <p
                            style={{ color: "#d32f2f" }}
                            className="error-msg"
                          >
                            {menuErrors?.permissions}
                          </p>
                        )}
                        <Col lg="12" className="repeatable-col">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isAdvancePermission === true}
                                name="allowEdit"
                                onChange={() =>
                                  setIsAdvancePermission(
                                    !isAdvancePermission
                                  )
                                }
                              />
                            }
                            label="Show Advanced Permissions"
                            className="font-inter fs-14"
                          />
                        </Col>
                        <Col lg="12" className="repeatable-col">
                          <TableContainer className="z-0">
                            <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              stickyHeader
                            >
                              <TableHeaderForSorting
                                columns={
                                  isAdvancePermission === false
                                    ? columns_atttributes_headers
                                    : columns_advace_atttributes_headers
                                }
                              />
                              <TableBody>
                                {permissions?.length > 0 && (
                                  <>
                                    {permissions?.map(
                                      (permission, index) => (
                                        <>
                                          <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={permission.value}
                                            className="cursor-pointer tbl-row-attribute-row"
                                          >
                                            <TableCell
                                              align="left"
                                              className={` tbl-border-left tbl-cell-attribute-2`}
                                              style={{
                                                width: "100%",
                                                top: 0,
                                                left: 0,
                                              }}
                                            >
                                              <Typography className="fs-14">
                                                {permission?.label}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className={`tbl-cell-attribute-2`}
                                              style={{
                                                width: "100%",
                                                top: 0,
                                                left: 0,
                                              }}
                                            >
                                              <AntSwitch
                                                name="view"
                                                checked={
                                                  permission.view
                                                }
                                                onChange={() =>
                                                  handleToggle(
                                                    permission.value,
                                                    "view"
                                                  )
                                                }
                                                inputProps={{
                                                  "aria-label":
                                                    "ant design",
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell
                                              align="right"
                                              className={`tbl-cell-attribute-2`}
                                              style={{
                                                width: "100%",
                                                top: 0,
                                                left: 0,
                                              }}
                                            >
                                              <AntSwitch
                                                name="deny"
                                                checked={
                                                  permission.deny
                                                }
                                                onChange={() =>
                                                  handleToggle(
                                                    permission.value,
                                                    "deny"
                                                  )
                                                }
                                                inputProps={{
                                                  "aria-label":
                                                    "ant design",
                                                }}
                                              />
                                            </TableCell>

                                            {isAdvancePermission && (
                                              <>
                                                <TableCell
                                                  align="right"
                                                  className={`tbl-cell-attribute-2`}
                                                  style={{
                                                    width: "100%",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  <AntSwitch
                                                    name="add"
                                                    checked={
                                                      permission.add
                                                    }
                                                    onChange={() =>
                                                      handleToggle(
                                                        permission.value,
                                                        "add"
                                                      )
                                                    }
                                                    inputProps={{
                                                      "aria-label":
                                                        "ant design",
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  align="right"
                                                  className={`tbl-cell-attribute-2`}
                                                  style={{
                                                    width: "100%",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  <AntSwitch
                                                    name="edit"
                                                    checked={
                                                      permission.edit
                                                    }
                                                    onChange={() =>
                                                      handleToggle(
                                                        permission.value,
                                                        "edit"
                                                      )
                                                    }
                                                    inputProps={{
                                                      "aria-label":
                                                        "ant design",
                                                    }}
                                                  />
                                                </TableCell>
                                                <TableCell
                                                  align="right"
                                                  className={`tbl-border-right tbl-cell-attribute-2`}
                                                  style={{
                                                    width: "100%",
                                                    top: 0,
                                                    left: 0,
                                                  }}
                                                >
                                                  <AntSwitch
                                                    name="delete"
                                                    checked={
                                                      permission.delete
                                                    }
                                                    onChange={() =>
                                                      handleToggle(
                                                        permission.value,
                                                        "delete"
                                                      )
                                                    }
                                                    inputProps={{
                                                      "aria-label":
                                                        "ant design",
                                                    }}
                                                  />
                                                </TableCell>
                                              </>
                                            )}
                                          </TableRow>
                                          <TableRow className="extra-row"></TableRow>
                                        </>
                                      )
                                    )}
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </Accordion.Body>
                  </Accordion.Item>

                  {id && (
                    <Accordion.Item
                      eventKey="2"
                      className="bg-white card-wrapper mt-4"
                    >
                      <Accordion.Header className="bg-white fw-700 primary-text">
                        Changelog
                      </Accordion.Header>
                      <Accordion.Body className="p-0 changelog-body">
                        <Grid
                          container
                          spacing={1}
                          className="changelog-div"
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            lg={9}
                            xl={9}
                          >
                            <Box className="">
                              <InputGroup className="">
                                <TextField
                                  className="search-changelog-input"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="attributeTitle"
                                  value={
                                    search
                                  }
                                  placeholder="search changelog"
                                  onChange={(
                                    e
                                  ) => {
                                    setIstype(
                                      true
                                    );

                                    setSearch(
                                      e
                                        .target
                                        .value
                                    );
                                    if (
                                      e
                                        .target
                                        .value ===
                                      ""
                                    ) {
                                      fetchAllChangeLogs(
                                        page
                                      );
                                      fetchAllChangeLogsForExportCSV(
                                        page,
                                        "no"
                                      );
                                    }
                                  }}
                                  onKeyDown={(
                                    e
                                  ) => {
                                    if (
                                      e.key ===
                                      "Enter"
                                    ) {
                                      e.preventDefault();
                                      fetchAllChangeLogs(
                                        page
                                      );
                                      fetchAllChangeLogsForExportCSV(
                                        page,
                                        "no"
                                      );
                                    }
                                  }}
                                />
                                <Button
                                  className="search-changelog"
                                  onClick={() => {
                                    handleChangeLogCsvDownload()
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={
                                      faSearch
                                    }
                                    className="search-changelog-icon"
                                  />
                                </Button>
                              </InputGroup>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            lg={3}
                            xl={3}
                          >
                            <div className="d-flex flex-wrap w-100 h-100 align-items-center">
                              <CSVLink
                                data={
                                  getChangelogCsvData
                                }
                                headers={
                                  header_csv
                                }
                                filename={`changelog ${menuTitle}.csv`}
                                target="_blank"
                                className="csv-btn-a"
                              >
                                <Button
                                  variant="outlined"
                                  startIcon={
                                    <FontAwesomeIcon
                                      icon={
                                        faFileCsv
                                      }
                                    />
                                  }
                                  className="export-csv-btn d-flex justify-content-center"
                                >
                                  Export
                                  as
                                  CSV
                                </Button>
                              </CSVLink>
                            </div>
                          </Grid>
                        </Grid>
                        <Paper
                          sx={{
                            width: "100%",
                          }}
                          className="changelog-table-div mt-4"
                        >
                          <TableContainer className="z-0">
                            <Table
                              sx={{
                                minWidth: 750,
                              }}
                              aria-labelledby="tableTitle"
                              stickyHeader
                            >
                              <TableHeaderForSorting
                                columns={
                                  columns_types_changelog_header
                                }
                              />
                              <TableBody>
                                {getAllChangeLogs?.data?.map(
                                  (
                                    data
                                  ) => (
                                    <>
                                      <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={
                                          -1
                                        }
                                        className=" tbl-changelog mt-2"
                                      >
                                        <TableCell
                                          align="left"
                                          className="tbl-border-left tbl-cell-5"
                                        >
                                          <Box>
                                            <Stack
                                              direction="row"
                                              alignContent="center"
                                              alignItems="center"
                                              spacing={
                                                1
                                              }
                                              className="z-n1"
                                            >
                                              <Avatar
                                                alt={
                                                  data?.changed_by
                                                }
                                                src="/static/images/avatar/1.jpg"
                                                className="author-avtar"
                                              />
                                              <Typography className="fw-400 fs-14 ">
                                                {
                                                  data?.changed_by
                                                }
                                              </Typography>
                                            </Stack>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          className="text-nowrap"
                                        >
                                          {
                                            data?.changed_at
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          className="text-nowrap"
                                        >
                                          {
                                            data?.area
                                          }
                                        </TableCell>
                                        <TableCell
                                          align="left"
                                          className="tbl-border-right"
                                          dangerouslySetInnerHTML={{
                                            __html: data?.description
                                              ? processedHTML(
                                                data?.description
                                              ).innerHTML
                                              : "-",
                                          }}
                                        >
                                        </TableCell>
                                      </TableRow>
                                      <TableRow className="extra-row"></TableRow>
                                    </>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Paper>
                        {total_changelog >
                          rowsPerPage && (
                            <TablePagination
                              component="div"
                              count={
                                total_changelog
                              }
                              page={
                                page
                              }
                              onPageChange={
                                handleChangePage
                              }
                              rowsPerPage={
                                rowsPerPage
                              }
                              onRowsPerPageChange={
                                handleChangeRowsPerPage
                              }
                              labelDisplayedRows={({
                                from,
                                to,
                                count,
                              }) => {
                                const currentPage =
                                  page +
                                  1;
                                const start =
                                  currentPage *
                                  rowsPerPage -
                                  rowsPerPage +
                                  1;
                                const end =
                                  Math.min(
                                    currentPage *
                                    rowsPerPage,
                                    count
                                  );
                                return (
                                  <>
                                    {`Showing ${start} to ${end} of `}
                                    <span>
                                      {
                                        count
                                      }
                                    </span>
                                    {
                                      " records"
                                    }
                                  </>
                                );
                              }}
                            />
                          )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                </Accordion>
              </Stack>
            </Col>
            <Col lg="3" md="5">
              <Stack
                direction="row"
                justifyContent="space-between"
                gap={2}
                flexWrap="wrap"
                className="publish-div"
              >
                <Card className="bg-white card-wrapper w-100">
                  <Card.Header className="bg-white fw-700 primary-text">
                    Publish
                  </Card.Header>
                  <Card.Body className="p-15 card-publish">
                    <Button
                      className="btn primary-btn w-100"
                      type="submit"
                      onClick={onSubmit}
                    >
                      <FontAwesomeIcon
                        icon={
                          faBullhorn
                        }
                      />
                      Publish
                    </Button>
                    <div>
                      <div className="mt-3 switch-items-1">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          gap={2}
                          flexWrap="wrap"
                        >
                          <div className="d-flex flex-wrap align-items-center gap-3">
                            <Typography
                              className="fw-400 dark-text font-inter "
                              whiteSpace="nowrap"
                            >
                              Enable Menu
                            </Typography>
                            <BootstrapTooltip
                              title="You can enable/disable menu."
                              placement="bottom"
                            >
                              <FontAwesomeIcon
                                icon={
                                  faCircleInfo
                                }
                                className="mandatory-icon fw-900"
                                aria-owns={
                                  openInfoEnable
                                    ? "mouse-over-popover"
                                    : undefined
                                }
                                aria-haspopup="true"
                              />
                            </BootstrapTooltip>
                          </div>

                          <Stack
                            direction="row"
                            spacing={
                              1
                            }
                            alignItems="center"
                            className="h-100"
                          >
                            <AntSwitch
                              name="typeStatus"
                              checked={
                                menuStatus
                              }
                              onChange={(
                                event
                              ) => {
                                setMenuStatus(!menuStatus)
                              }}
                              inputProps={{
                                "aria-label":
                                  "ant design",
                              }}
                            />
                          </Stack>
                        </Stack>
                      </div>
                      <Divider variant="fullWidth" className="mt-2" />
                      <div className="mt-3 switch-items-1">
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          gap={2}
                          flexWrap="wrap"
                        >
                          <Typography
                            className="fw-400 dark-text font-inter "
                            whiteSpace="nowrap"
                          >
                            Admin
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={
                              1
                            }
                            alignItems="center"
                            className="h-100"
                          >
                            <AntSwitch
                              name="typeStatus"
                              checked={
                                menuFor
                              }
                              onChange={(
                                event
                              ) => {
                                setMenuFor(!menuFor)
                              }}
                              inputProps={{
                                "aria-label":
                                  "ant design",
                              }}
                            />
                          </Stack>

                          <Typography
                            className="fw-400 dark-text font-inter "
                            whiteSpace="nowrap"
                          >
                            User
                          </Typography>
                        </Stack>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Stack>
            </Col>
          </Row>


          {showItemModal &&
            <AddEditItemModal
              onClose={handleOnClose}
              loading={loading}
              setLoading={setLoading}
              userToken={userToken}
              navigate={navigate}
              getAllGroupsAndUsers={menuItemGetAllGroupsAndUsers}
              setGetAllGroupsAndUsers={setMenuItemGetAllGroupsAndUsers}
              permissions={permissions}
              setPermissions={setPermissions}
              isAdvancePermission={isAdvancePermissionForMenuItem}
              setIsAdvancePermission={setIsAdvancePermissionForMenuItem}
              getAllIcons={getAllIcons}
              setGetAllIcons={setGetAllIcons}
              menuItemTypeIcon={menuItemTypeIcon}
              setMenuItemTypeIcon={setMenuItemTypeIcon}
              menuItemTitle={menuItemTitle}
              setMenuItemTitle={setMenuItemTitle}
              menuItemLink={menuItemLink}
              setMenuItemLink={setMenuItemLink}
              menuItemVisible={menuItemVisible}
              setMenuItemVisible={setMenuItemVisible}
              menuItemTypeColor={menuItemTypeColor}
              setMenuItemTypeColor={setMenuItemTypeColor}
              menuItemTypeBGColor={menuItemTypeBGColor}
              setMenuItemTypeBGColor={setMenuItemTypeBGColor}
              menuItems={menuItems}
              setMenuItems={setMenuItems}
              menuItemPermissions={menuItemPermissions}
              setMenuItemPermissions={setMenuItemPermissions}
              menuItemIsAdvancePermission={menuItemIsAdvancePermission}
              setMenuItemIsAdvancePermission={setMenuItemIsAdvancePermission}
              menuItemErrors={menuItemErrors}
              setMenuItemErrors={setMenuItemErrors}
              validateFields={validateFields}
              addMenuItems={addMenuItems}
              updateMenuItem={updateMenuItem}
              attributeID={attributeID}
              addSubMenuItems={addSubMenuItems}
              addSubMenu={addSubMenu}
              allMenuList={allMenuList}
              setMenuItemType={setMenuItemType}
              menuItemType={menuItemType}
              InternalMenuOptionLists={InternalMenuOptionLists}
              setMenuItemInternalValue={setMenuItemInternalValue}
              menuItemInternalValue={menuItemInternalValue}
            />
          }

          <BootstrapDialog
            onClose={handleOnClose}
            aria-labelledby="customized-dialog-title"
            open={showPositionModal}
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle
              sx={{ m: 0, p: 2 }}
              id="customized-dialog-title"
              className="highlight-menu-model-title-div"
            >
              <Typography sx={{ fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: 400 }} className="text-center menu-modal-title">
                Change Position
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleOnClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
                className="alert-close-btn menu-close-btn"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers className="dialog-content">
              <Box>
                {/* Tabs Header */}
                <Row>
                  <Col lg="12" className="">
                    <Typography className="fw-400 dark-text">
                      Select menu position below
                    </Typography>
                    <Select
                      closeMenuOnSelect={true}
                      options={allMenuItems}
                      menuPlacement="bottom"
                      name="users"
                      // value={menuItemInternalValue}
                      placeholder="Select..."
                      menuPosition="fixed"
                      onChange={(selectedOption) => {
                        // setMenuItemInternalValue(selectedOption);
                        // if (selectedOption) {
                        //   setMenuItemErrors((prevErrors) => ({ ...prevErrors, link: "" }));
                        // }
                      }}
                      className={`muilt-select-field mt-2 `}
                      classNamePrefix="select"
                      styles={{
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#660c605c"
                            : provided.backgroundColor,
                          color: state.isSelected
                            ? "var(--dark-color)"
                            : provided.color,
                        }),
                        multiValueLabel: (provided, state) => {
                          return {
                            ...provided,
                            color:
                              state.data?.type === "group"
                                ? "rgb(0, 135, 90)"
                                : "rgb(0, 82, 204)",
                          };
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Box>
            </DialogContent>
            <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
              <Button
                className="btn delete-btn-model"
                onClick={handleOnClose}
              >
                <FontAwesomeIcon icon={faXmark} />
                Cancel
              </Button>
              <Button
                className="btn primary-btn"
                onClick={() => {
                  // if (attributeID === undefined) {
                  //   addMenuItems()
                  // }
                  // else if (attributeID !== undefined && addSubMenu === true) {
                  //   addSubMenuItems()
                  // }
                  // else {
                  //   updateMenuItem()
                  // }
                }}
              >
                <FontAwesomeIcon icon={faFloppyDisk} />
                Save Changes
              </Button>
            </DialogActions>
          </BootstrapDialog >

          <Menu
            anchorEl={anchorElMenu}
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            open={openMenuFoEdit}
            anchorOrigin={"right"}
            onClose={() => {
              handleClose()
              setAttributeID()
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
                zIndex: "1",
              },
            }}
          >
            {/* {(attributeID?.addChildMenu === true && attributeID?.menuItemType === "external") && ( */}
            <MenuItem
              onClick={() => {
                setAddSubMenu(true)
                handleClose()
                habdleAddMenuItem()
              }}
            >
              Add Sub Menu
            </MenuItem>
            {/* )} */}

            <MenuItem
              onClick={() => {
                openMenuItemsForEdit()
              }}
            >
              Edit Item
            </MenuItem>
            <MenuItem
              onClick={() => {
                removeMenuItem()
              }}
            >
              Remove Item
            </MenuItem>
            {/* <MenuItem
              onClick={() => {
                // removeMenuItem()
                handleClose()
                setShowPositionModal(true)
              }}
            >
              Change Item Position
            </MenuItem> */}
          </Menu>
        </Container>
      </div>

      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default AddNewMenus;
