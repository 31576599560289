import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Image, Stack } from "react-bootstrap";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Popper, Select, Typography } from "@mui/material";
import { json, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import "../../styles/userDashboard.scss";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import {
  Box,
  Grid,
  Button,
  Tabs,
  Tab,
  Paper,
  CardHeader,
  Avatar,
  IconButton,
  Divider,
  TextField,
  Chip,
  Card,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  Popover
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faSearch,
  faPlus,
  faList,
  faInfo,
  faCircleInfo,
  faArrowAltCircleDown,
  faArrowRight,
  faBriefcase,
  faClipboard,
  faEdit,
  faTrash,
  faPhone,
  faEnvelopeOpen,
  faFolder,
  faFilter,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faGoogle,
  faGoogleDrive,
  faRocketchat,
  faSlack,
  faTelegram,
  faTwitter,
  faWindows,
} from "@fortawesome/free-brands-svg-icons";
import PropTypes from "prop-types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardMedia from "@mui/material/CardMedia";
import testImg from "../../assets/images/test-post-img.jpg";
import CardContent from "@mui/material/CardContent";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { useParams } from "react-router-dom";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import CardActions from "@mui/material/CardActions";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../styles/userTypePageNew.scss";
import { Toast } from "../../utils/toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { faXmark, faFloppyDisk, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import DummyUser from "../../assets/images/dummy-user.jpg";

import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import StarBorder from '@mui/icons-material/StarBorder';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import TablePagination from '@mui/material/TablePagination';
import Filter from "../../components/common/userTypePage/filter";
import { filterSliceAction } from "../../redux/slices/userTypePage/filterSlice";
import PeopleListing from "../../components/common/userTypePage/peopleListing";
import { ChevronLeftRounded, ChevronRightRounded, KeyboardArrowDown, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PaginationCustom from "../../components/common/pagination/paginationCustom";
import TypeListing from "../../components/common/userTypePage/typeListing";


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const UserTypePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { type } = useParams();
  const [loading, setLoading] = useState(false);
  const [getAllTypePosts, setGetAllTypePosts] = useState([]);
  const [apiRes, setAPIRes] = useState();
  const [search, setSearch] = useState("");
  const [istype, setIstype] = useState(false);

  const [page, setPage] = useState(1);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [postId, setPostId] = useState();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [getAllCategories, setGetAllCategories] = useState([])
  const [getAllGroups, setGetAllGroups] = useState([])
  // const [selectedCategories, setSelectedCategories] = useState({})
  // const [selectedGroups, setSelectedGroups] = useState([])

  const [openAddFolder, setOpenAddFolder] = useState(false)
  const [newFolderName, setNewFolderName] = useState()

  const [openFolderIds, setOpenFolderIds] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);

  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  const debounceTimeoutForCatFilter = 1200; // Adjust the debounce timeout as needed

  const { selectedGroups, selectedCategory, isArchive, sortPeople, sortType, peoplePagination, selectedShowValue, filterToggle } = useSelector((state) => state.filter);

  const maxVisibleGroups = 5;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddPopup, setOpenAddPopup] = useState(false);

  let debounceTimer;
  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
    menuFor,
    adminAccessible
  } = useSelector((state) => state?.drawerMenu);

  const total_types = parseInt(apiRes?.meta?.total);
  const recordsPerPage = 12;
  const totalPages = Math.ceil(total_types / recordsPerPage);
  const totalPagesForFolder = Math.ceil(total_types / 9)

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const userToken = localStorage.getItem("accessToken");

  const typeName = type ?? localStorage.getItem("type-selected-name-user");
  const typeID = localStorage.getItem("type-selected-id-user");
  const typeSlug = localStorage.getItem("type-selected-slug-user");

  function PreviousItem(props) {
    return (
      <Button
        {...props}
        startIcon={<ArrowBackIcon />}
        className="text-capitalize fs-12-pagination pagination-btns"
      >
        {typeName === "People" ? "Newer People" : "Newer Posts"}
      </Button>
    );
  }

  function NextItem(props) {
    return (
      <Button
        {...props}
        endIcon={<ArrowForwardIcon />}
        className="text-capitalize fs-12-pagination pagination-btns"
      >

        {typeName === "People" ? "Previous People" : "Previous Posts"}
      </Button>
    );
  }

  const transformCategoryData = (categories) => {
    return categories.map(category => ({
      label: category.title,
      slug: category.slug,
      id: category.id,
      options: category.categoryterm.map(term => ({
        label: term.name,
        value: term.id,
        categoryId: category.id
      }))
    }));
  };

  const handleCategoryChange = (categoryLabel, selectedOption) => {
    // setSelectedCategories(prevState => ({
    //   ...prevState,
    //   [categoryLabel]: selectedOption
    // }));
  };

  const [selectedArchive, setSelecetedArchive] = useState({
    'archive': {
      "label": "No",
      "value": 0
    }
  })

  const handleArchiveChange = (label, selectedOption) => {
    if (selectedOption?.value !== selectedArchive?.archive?.value) {
      setSelecetedArchive({ [label]: selectedOption });
    }
  }

  const transformSelectedCategories = (selectedCategories) => {
    const payload = {};

    for (const category in selectedCategories) {
      if (selectedCategories.hasOwnProperty(category)) {
        const terms = selectedCategories[category];
        const categoryId = terms.length > 0 ? terms[0].categoryId : null;

        let termValues = terms.map(term => term.value).join(',');

        if (categoryId !== null && termValues !== "") {
          payload[category] = {
            category_id: categoryId,
            term_values: termValues
          };
        }
      }
    }

    return payload;
  };

  const calculateTotalPosts = (folder) => {
    let totalPosts = folder.posts_count;
    if (folder.child_sub_folder.length > 0) {
      folder.child_sub_folder.forEach((subFolder) => {
        totalPosts += calculateTotalPosts(subFolder);
      });
    }
    return totalPosts;
  };

  const FolderList = ({ folders, type }) => {


    // Automatically select the first folder on component mount or when folders change
    useEffect(() => {
      const storedFolderId = localStorage.getItem("selected-folder-item");

      let openFolders = [];

      // Recursive function to traverse the folder structure
      const findFolderAndParents = (foldersList, folderId) => {
        for (let folder of foldersList) {
          if (folder.id === folderId) {
            // Add the folder ID if it's not already in the array
            if (!openFolders.includes(folder.id)) {
              openFolders.unshift(folder.id);
            }

            if (folder.parent_id) {
              findFolderAndParents(folders, folder.parent_id);
            }
            return folder;
          }
          if (folder.child_sub_folder?.length > 0) {
            const foundFolder = findFolderAndParents(folder.child_sub_folder, folderId);
            if (foundFolder) {
              // Add the folder ID if it's not already in the array
              if (!openFolders.includes(folder.id)) {
                openFolders.unshift(folder.id);
              }
              return foundFolder;
            }
          }
        }
        return null;
      };

      if (storedFolderId) {
        const selectedFolderId = parseInt(storedFolderId);
        findFolderAndParents(folders, selectedFolderId);
      }

      if (folders?.length > 0 && selectedFolderId === null) {
        const firstFolderId = openFolders.length > 0 ? openFolders[openFolders.length - 1] : folders[0]?.id;
        setSelectedFolderId(firstFolderId);
        setOpenFolderIds(openFolders.length > 0 ? openFolders : [firstFolderId]);

        // Save the open folder hierarchy
        localStorage.setItem("selected-folder-item", firstFolderId);
        if (type) {
          localStorage.setItem("selected-folder-type", JSON.stringify(type));
        }
      }

    }, [folders, selectedFolderId, type]);

    const handleClick = (folder) => {
      if (folder?.is_parent === 1) {
        setSelectedFolderId(folder?.id);
        localStorage.setItem("selected-folder-item", folder?.id)
        if (openFolderIds?.length < 1) {
          localStorage.setItem("selected-folder-item", folder?.id)
          if (openFolderIds.includes(folder?.id)) {
            setOpenFolderIds([])
            setOpenFolderIds(openFolderIds.filter((folderId) => folderId !== folder?.id));
          } else {
            setOpenFolderIds([])

            setOpenFolderIds([...openFolderIds, folder?.id]);
          }
        } else if (openFolderIds.includes(folder?.id)) {
          setOpenFolderIds(openFolderIds.filter((folderId) => folderId !== folder?.id));
          setOpenFolderIds([])

        }
        else {
          setOpenFolderIds([])

        }

      } else if (folder?.is_parent !== 1) {
        setSelectedFolderId(folder?.id);
        localStorage.setItem("selected-folder-item", folder?.id)
        if (openFolderIds.includes(folder?.id)) {
          setOpenFolderIds(openFolderIds.filter((folderId) => folderId !== folder?.id));
        } else {
          setOpenFolderIds([...openFolderIds, folder?.id]);
        }
      }
    };

    return (
      <List
        className="list-item-menu-folders"
        // component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {folders.map((folder) => (
          <FolderItem
            key={folder.id}
            folder={folder}
            openFolderIds={openFolderIds}
            selectedFolderId={selectedFolderId}
            handleClick={handleClick}
          />
        ))}
      </List>
    );
  };

  const FolderItem = ({ folder, parentId = null, level = 0, openFolderIds, selectedFolderId, handleClick }) => {
    const isOpen = openFolderIds.includes(folder.id);
    const totalPosts = calculateTotalPosts(folder);

    return (
      <>
        <ListItem disablePadding sx={{ display: 'block', pl: level === 0 ? 2 : 2, mt: 1 }}>
          <ListItemButton
            onClick={() => handleClick(folder)}
            sx={{
              pl: 2 * level,
              minHeight: 48,
              justifyContent: 'center',
            }}
            className={
              selectedFolderId === folder.id
                ? 'selected-menu-item w-48px'
                : 'non-selected-menu-item w-48px'
            }
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 2,
                ml: 2,
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon icon={faFolder} className="menu-icon" />
            </ListItemIcon>
            <ListItemText sx={{ opacity: 1 }} >
              <Box className="d-flex align-items-center gap-4">
                <Typography className="folder-name-text">{folder?.folder_name}</Typography>
                <Typography className="folder-name-text">({totalPosts})</Typography>
              </Box>
            </ListItemText>
            {folder.child_sub_folder.length > 0 ? (isOpen ? <ExpandMore /> : <ExpandLess />) : null}
          </ListItemButton>
        </ListItem>
        {folder.child_sub_folder.length > 0 && (
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List disablePadding>
              {folder.child_sub_folder.map((subFolder) => (
                <FolderItem
                  key={subFolder.id}
                  folder={subFolder}
                  parentId={folder.id}
                  level={level + 1}
                  openFolderIds={openFolderIds}
                  selectedFolderId={selectedFolderId}
                  handleClick={handleClick}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  };


  const handleChangePage = (newPage) => {
    // if (newPage > page) {
    //   setPage(newPage);
    //   fetchAllPostList(newPage);
    // } else if (newPage < page) {
    //   setPage(newPage);
    //   fetchAllPostList(newPage);
    // }
    dispatch(filterSliceAction.setPeoplePagination({
      ...peoplePagination,
      page: newPage
    }));
    fetchAllPostList(newPage);
    console.log("handleChangePage::", newPage);
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
  };

  const handleCloseAddFolderModel = () => {
    setOpenAddFolder(false);
  };

  const fetchAllPostList = async (page) => {
    try {
      setLoading(true);
      let groups
      if (selectedGroups?.length > 0 && type === "people") {
        const viewManagers = selectedGroups?.map((user) => user);
        const usersStringManager = viewManagers.join(",");

        groups = usersStringManager;
      } else {
        groups = "";
      }
      console.log("selectedFolderId:::", selectedFolderId)
      let data = {
        search: search,
        page: page,
        category_data: selectedCategory,
        group_data: selectedGroups,
        folder_id: selectedFolderId,
      };

      if (type !== "people") {
        // data.is_archive = selectedArchive?.archive?.value;
        data.is_archive = isArchive;
        if (apiRes?.type?.is_folder === "enable") {
          const firstNonNull = Object.entries(sortType).find(([key, value]) => value !== null);
          if (firstNonNull) {
            data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
          }
        }
      } else {
        //addition data payload for people
        const firstNonNull = Object.entries(sortPeople).find(([key, value]) => value !== null);
        if (firstNonNull) {
          data.order_by = firstNonNull[0] + '_' + firstNonNull[1];
        }
      }
      data.per_page = peoplePagination.per_page;
      if (!page) {
        data.page = peoplePagination.page;
      }

      const response = await api.post(`user/post/post-list/${type}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userToken}`,
        },
      });

      setAPIRes(response?.response);
      setGetAllTypePosts(response?.response?.data);

      dispatch(filterSliceAction.setPeoplePagination({
        ...peoplePagination,
        page: response?.response?.meta?.current_page,
        per_page: response?.response?.meta?.per_page,
        meta_data: response?.response?.meta
      }));

      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        localStorage.setItem("last-redirect-page", `/type/${type}`);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const deleteTypePostAPI = async (id) => {
    try {

      setLoading(true);
      const response = await api.delete(`user/post/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (getAllTypePosts?.length < 2) {
          setPage(page - 1)
          fetchAllPostList(page - 1)
        } else {
          setPage(page)
          fetchAllPostList(page)
        }

        handleCloseDeleteAlertModel()
        Toast.success(response?.message);
        setLoading(false);

      } else if (response?.success == false) {

        Toast.warning(response?.message);
        setLoading(false);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllCategoryData = async () => {
    try {
      setLoading(true);
      const response = await api.get(`user/people-filter-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        const categories = response?.response?.data?.category_filter
        const groups = response?.response?.data?.group_filter
        const allCategories = transformCategoryData(categories);
        const finalGroups = groups?.map((group) => {
          return {
            label: group?.title,
            value: group?.id
          }
        })
        setGetAllCategories(allCategories);
        setGetAllGroups(finalGroups)
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  }

  const addNewFolderAPI = async () => {
    if (!newFolderName) {
      return Toast.error("Please enter folder name")
    }
    let data = {
      folder_name: newFolderName,
      parent_id: selectedFolderId
    }

    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(
        `/user/post/store-folder/${typeID}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        Toast.success(response?.message);
        setLoading(false);
        handleCloseAddFolderModel()
        setNewFolderName()
        fetchAllPostList()
      } else {
        setLoading(false);
        Toast.error(response?.message || response?.response)
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status == 401) {
        setLoading(false);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message)
    }
  }

  const [openViewToggle, setOpenViewToggle] = useState(false);
  const anchorFilterListRef = useRef(null);
  // const [selectedShowValue, setSelectedShowValue] = useState('Lists');
  // const [filterToggle, setFilterToggle] = useState(true);
  const [filterList, setFilterList] = useState([]);

  const handleMenuItemClick = (event, value) => {
    dispatch(filterSliceAction?.setSelectedShowValue(value))
    // setSelectedShowValue(value);
    setOpenViewToggle(false);
  };

  const handleToggle = () => {
    setOpenViewToggle((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorFilterListRef.current && anchorFilterListRef.current.contains(event.target)) {
      return;
    }

    setOpenViewToggle(false);
  };

  const handleAddClick = (event) => {
    setOpenAddPopup(true)
    setAnchorEl(event.currentTarget);
  };

  const handleCloseForAdd = () => {
    setAnchorEl(null);
    setOpenAddPopup(false)
  };

  const handleFilterToggle = () => {
    dispatch(filterSliceAction?.setFilterToggle(!filterToggle))
    // setFilterToggle(!filterToggle);
  }

  const handleChangeRowsPerPage = (event) => {
    console.log("handleChangeRowsPerPage::", event.target.value);
    console.log({
      ...peoplePagination,
      per_page: event.target.value,
      page: 1
    });
    dispatch(filterSliceAction.setPeoplePagination({
      ...peoplePagination,
      per_page: event.target.value,
      page: 1
    }));
    // fetchAllPostList();
  };
  useEffect(() => {
    // if (selectedFolderId !== null) {
    console.log("useEffect call api from selected folder else block");
    console.log("fetchall 639")
    fetchAllPostList(1);
    setPage(1);
    fetchAllCategoryData()
    // }
  }, [selectedFolderId])

  const isFirstRender = useRef(true);


  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    console.log("useEffect search");
    if (search.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        console.log("fetchall 656")
        fetchAllPostList(1);
      }, debounceTimeout);
    }
    else if (search === "" && istype == true) {
      console.log("fetchall 661")
      fetchAllPostList(1);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     return;
  //   }
  //   console.log("useEffect category");

  //   if (selectedCategory && Object.getOwnPropertyNames(selectedCategory)?.length > 0) {
  //     clearTimeout(debounceTimer);
  //     debounceTimer = setTimeout(() => {
  //       console.log("fetchall 680")
  //       fetchAllPostList(1);
  //     }, debounceTimeoutForCatFilter);
  //     return () => clearTimeout(debounceTimer);
  //   }
  // }, [selectedCategory]);

  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }

    if ((selectedGroups && Object.getOwnPropertyNames(selectedGroups)?.length > 0) || (selectedCategory && Object.getOwnPropertyNames(selectedCategory)?.length > 0) || Object.entries(sortPeople).find(([key, value]) => value !== null) || Object.entries(sortType).find(([key, value]) => value !== null) || peoplePagination.per_page) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fetchAllPostList(1);
      }, debounceTimeoutForCatFilter);
      return () => clearTimeout(debounceTimer);
    }
  }, [selectedGroups, selectedCategory, sortPeople, sortType, peoplePagination.per_page]);


  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    fetchAllPostList(1);
  }, [isArchive]);

  useEffect(() => {
    setLoading(true);
    setGetAllTypePosts([]);
    setAPIRes();
    setSearch("");
    setIstype(false);
    setTimeout(() => {
      setLoading(false);
    }, 3000)
  }, [type]);


  useEffect(() => {
    if (isFirstRender.current) {
      return;
    }
    console.log("useEffect typename");

    // setSelectedCategories([])
    dispatch(filterSliceAction.setSelectedCategory([]))
    dispatch(filterSliceAction.setSelectedGroups([]))
    // setSelectedGroups([])
  }, [typeName])

  useEffect(() => {
    // This effect will run only after the first render
    console.log("useEffect chage first render status");

    isFirstRender.current = false;
  }, []);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <Box className="main-div-2-types">
            {typeName?.toLowerCase() !== "people" ? (
              <Typography variant="h6" className="title">{typeName}</Typography>
            ) : (
              <Typography variant="h6" className="title">People Directory</Typography>
            )}
            {apiRes?.type?.is_folder === "enable" && (
              <>
                <Button
                  className="btn primary-btn"
                  onClick={handleAddClick}
                >
                  Add New
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
                <Popover
                  id={openAddPopup ? 'simple-popover' : undefined}
                  open={openAddPopup}
                  anchorEl={anchorEl}
                  onClose={handleCloseForAdd}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  className="add-new-popover"
                >
                  <Box>
                    <List component="nav" className="p-0" aria-label="main mailbox folders">
                      <ListItem
                        disablePadding
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          onClick={() => {
                            handleCloseForAdd();
                            // setOpenLinksAddModel(true);
                            if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                              localStorage.setItem("last-redirect-page", `/type/add/${apiRes?.type?.slug}`)
                              localStorage.setItem("type-selected-name", apiRes?.type?.name);
                              localStorage.setItem("type-selected-id", apiRes?.type?.id);
                              localStorage.setItem("type-selected-slug", apiRes?.type?.slug);
                              navigate(`/type/add/${apiRes?.type?.slug}`);
                            }
                            else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
                              localStorage.setItem("last-redirect-page-admin-user", `/create-content`)
                              localStorage.setItem("type-selected-name-user", apiRes?.type?.name);
                              localStorage.setItem("type-selected-id-user", apiRes?.type?.id);
                              localStorage.setItem("type-selected-slug-user", apiRes?.type?.slug);
                              navigate(`/create-content`);
                            }
                            else {
                              localStorage.setItem("last-redirect-page-user", `/create-content`)
                              localStorage.setItem("type-selected-name-user", apiRes?.type?.name);
                              localStorage.setItem("type-selected-id-user", apiRes?.type?.id);
                              localStorage.setItem("type-selected-slug-user", apiRes?.type?.slug);
                              navigate(`/create-content`);
                            }
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}>
                            <FontAwesomeIcon icon={apiRes?.type?.icons?.icon_value} className="menu-icon" />
                          </ListItemIcon>
                          <ListItemText primary={typeName} />
                        </ListItemButton>
                      </ListItem>

                      <Divider className="divider" />
                      <ListItem
                        disablePadding
                        sx={{ display: 'block' }}
                      >
                        <ListItemButton
                          onClick={() => {
                            handleCloseForAdd();
                            // setOpenLabelAddModel(true);
                            setOpenAddFolder(true)
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: 0, mr: 2, justifyContent: "center" }}>
                            <FontAwesomeIcon icon={faFolder} className="menu-icon" />
                          </ListItemIcon>
                          <ListItemText primary="Folder" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                </Popover></>
            )}

          </Box>

          <Divider className="mt-3" style={{ background: "#EEEEEE" }} />

          <Box className="d-flex justify-content-end align-items-center mt-3">
            <Box className="filter-listing d-flex gap-2">
              <Button varient="text" className="filter-btn"
                endIcon={<FontAwesomeIcon
                  icon={faFilter}
                  className="hide-option-btn-svg"
                />}
                onClick={handleFilterToggle}
              >
                {filterToggle ? 'Show' : 'Hide'} Filters
              </Button>
              {typeName?.toLowerCase() === "people" && (

                <Box>
                  <Typography className="ps-2 fw-medium" varient="h3" component="span">Show As: </Typography>
                  <Button ref={anchorFilterListRef}
                    variant="text"
                    className="filter-btn pl-0 justify-content-start"
                    endIcon={<FontAwesomeIcon
                      icon={faChevronDown}
                      className="hide-option-btn-svg"
                    />}
                    onClick={handleToggle}
                  >
                    {selectedShowValue}
                  </Button>
                  <Popper
                    sx={{ zIndex: 1 }}
                    open={openViewToggle}
                    anchorEl={anchorFilterListRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                              {['Lists', 'Cards'].map((option, index) => (
                                <MenuItem
                                  key={option}
                                  disabled={index === 2}
                                  selected={option === selectedShowValue}
                                  onClick={(event) => handleMenuItemClick(event, option)}
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </Box>

              )}
            </Box>
          </Box>

          {/* <Box className="">
            <TextField
              className="bg-white mt-5 mb-4"
              fullWidth
              id="outlined-basic username"
              variant="outlined"
              size="small"
              name="attributeTitle"
              value={search}
              placeholder="Keyword Filter"
              onChange={(e) => {
                setIstype(true)
                setSearch(e.target.value);
                if (e.target.value === "") {
                  fetchAllPostList(page);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  fetchAllPostList(page);
                }
              }}
            />
          </Box> */}
          {/* {isCollapsed == true && (getAllGroups?.length > 0 || getAllCategories?.length > 0) && (
            <>
              <Box className="mt-3">
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                  {getAllGroups?.length > 0 && typeName === "People" && (
                    <>
                      <Grid item xs={6} lg={3} md={6} sm={12} xl={3}>
                        <Col lg="12" className="">
                          <Typography className="fw-400 fs-14 dark-text ">
                            Select Groups
                          </Typography>
                          <Select
                            closeMenuOnSelect={false}
                            options={getAllGroups}
                            menuPlacement="auto"
                            name="groupManagers"
                            value={getAllGroups?.filter((option) =>
                              selectedGroups?.includes(
                                option?.value
                              )
                            )}
                            placeholder={`Select Groups...`}
                            menuPosition="fixed"
                            isMulti
                            onChange={(selectedOption) => {
                              const users = selectedOption.map(
                                (option) => option.value
                              );
                              setSelectedGroups(users)
                            }}
                            className={`muilt-select-field mt-1 `}
                            classNamePrefix="select"
                            styles={{
                              option: (provided, state) => {
                                return {
                                  ...provided,
                                  backgroundColor: state.isSelected
                                    ? "#660c605c"
                                    : provided.backgroundColor,
                                  color: state.isSelected
                                    ? "var(--dark-color)"
                                    : provided.color,
                                };
                              },
                            }} />
                        </Col>
                      </Grid>
                    </>
                  )}
                  {getAllCategories?.length > 0 && (
                    <>
                      {getAllCategories?.map((category) => (
                        <Grid item xs={6} lg={3} md={6} sm={12} xl={3}>
                          <Col lg="12" className="">
                            <Typography className="fw-400 fs-14 dark-text ">
                              {category?.label}
                            </Typography>
                            <Select
                              closeMenuOnSelect={false}
                              options={category?.options}
                              menuPlacement="auto"
                              name="groupManagers"
                              value={selectedCategories[category.slug] || []}
                              placeholder={`Select Terms...`}
                              menuPosition="fixed"
                              isMulti
                              onChange={(selectedOption) => handleCategoryChange(category.slug, selectedOption)}
                              className={`muilt-select-field mt-1 `}
                              classNamePrefix="select"
                              styles={{
                                option: (provided, state) => {
                                  return {
                                    ...provided,
                                    backgroundColor: state.isSelected
                                      ? "#660c605c"
                                      : provided.backgroundColor,
                                    color: state.isSelected
                                      ? "var(--dark-color)"
                                      : provided.color,
                                  };
                                },
                              }} />
                          </Col>
                        </Grid>
                      ))}
                    </>
                  )}
                  {typeName !== 'People' && (
                    <Grid item xs={6} lg={3} md={6} sm={12} xl={3}>
                      <Col lg="12" className="">
                        <Typography className="fw-400 fs-14 dark-text ">
                          Include Archive
                        </Typography>
                        <Select
                          closeMenuOnSelect={true}
                          options={[{
                            label: 'No',
                            value: 0
                          }, {
                            label: 'Yes',
                            value: 1
                          }]}
                          menuPlacement="auto"
                          name="acrchiveFilter"
                          value={selectedArchive['archive']}
                          placeholder={`Select Terms...`}
                          menuPosition="fixed"
                          onChange={(selectedOption) => handleArchiveChange('archive', selectedOption)}
                          className={`muilt-select-field mt-1 `}
                          classNamePrefix="select"
                          styles={{
                            option: (provided, state) => {
                              return {
                                ...provided,
                                backgroundColor: state.isSelected
                                  ? "#660c605c"
                                  : 'transparent',
                                color: state.isSelected
                                  ? "var(--dark-color)"
                                  : provided.color,
                              };
                            },
                          }} />
                      </Col>
                    </Grid>
                  )}
                </Grid>
              </Box>

            </>
          )} */}

          {/* <Divider className={`${isCollapsed == true ? "mt-4" : ""}`}>
            <Button
              onClick={() => setIsCollapsed((prevState) => !prevState)}
              className="bg-white hide-option-btn d-flex gap-2"
            >
              {isCollapsed == false
                ? "Show Advanced Filters"
                : "Hide Advanced Filters"}

              <FontAwesomeIcon
                icon={isCollapsed == false ? faChevronDown : faChevronUp}
                className="hide-option-btn-svg"
              />
            </Button>
          </Divider> */}
          <Grid container spacing={2}>
            {!filterToggle && (
              <Grid item xs={12} md={3} lg={3} xl={3} sm={12} className="mt-4 mb-4 p-0">
                <Filter type={typeName?.toLowerCase()} categories={getAllCategories} groups={getAllGroups} />
              </Grid>
            )}
            <Grid item xs className="mb-4">

              {apiRes?.type?.is_folder === "disable" ? (
                <>
                  <TypeListing
                    folderEnable={apiRes?.type?.is_folder === "enable"}
                    selectedShowValue={selectedShowValue}
                    getAllTypes={getAllTypePosts}
                    filterToggle={!filterToggle}
                    typeName={typeName}
                    deleteTypePostAPI={deleteTypePostAPI}
                  />

                  {getAllTypePosts?.length < 1 && (
                    <div className="no-data mt-5">
                      <div>
                        <Image
                          src={NoDataFoundImg}
                          className="text-center mx-auto no-data-img mt-4"
                        />
                      </div>
                      <Typography component="h5" variant="h5">
                        No data found
                      </Typography>
                    </div>
                  )}
                </>
              ) : apiRes?.type?.is_folder === "enable" ? (
                <>
                  <TypeListing
                    folderEnable={apiRes?.type?.is_folder === "enable"}
                    selectedShowValue={selectedShowValue}
                    getAllTypes={getAllTypePosts}
                    filterToggle={!filterToggle}
                    typeName={typeName}
                    deleteTypePostAPI={deleteTypePostAPI}
                  />

                  {getAllTypePosts?.length < 1 && (
                    <div className="no-data mt-5">
                      <div>
                        <Image
                          src={NoDataFoundImg}
                          className="text-center mx-auto no-data-img mt-4"
                        />
                      </div>
                      <Typography component="h5" variant="h5">
                        No data found
                      </Typography>
                    </div>
                  )}

                  {/* <Grid container spacing={2}>
                    <Grid item xs={12} md={3} lg={3} xl={3} sm={12} className="mt-4 mb-4">
                      <FolderList folders={apiRes?.post_folder} type={apiRes?.type} />
                    </Grid>
                    <Grid item xs={12} md={9} lg={9} xl={9} sm={12} className="mt-4 mb-4">
                      <Grid container spacing={2}>
                        {getAllTypePosts?.map((post, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} key={index} >
                            <Card className="news-card-wrapper" sx={{ maxWidth: 420 }}>
                              <CardMedia
                                src={post?.image}
                                sx={{ height: 140 }}
                                className="cursor-pointer"
                                component="img"
                                alt={post?.title}
                                onClick={() => {
                                  if (loggedUser?.user_type === "user") {
                                    localStorage.setItem("last-redirect-page-user", `/user-type/view/${typeName}/${post?.id}`);
                                    navigate(`/user-type/view/${typeName}/${post?.id}`);
                                  }
                                  else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                    localStorage.setItem("last-redirect-page-admin-user", `/user-type/view/${typeName}/${post?.id}`)
                                    navigate(`/user-type/view/${typeName}/${post?.id}`);
                                  }

                                }}
                              />
                              <CardContent>
                                <Stack direction="vertical" className="gap-10 h-100">
                                  <Stack
                                    direction="horizontal"
                                    className="gap-10 news-date justify-content-between"
                                  >
                                    <div className="d-flex align-items-center gap-2">
                                      <Avatar
                                        alt={post?.created_by}
                                        className="author-avtar-creator"
                                        src={post?.profile}
                                      >
                                        {post?.created_by?.charAt(0)}
                                      </Avatar>
                                      <span>{post?.created_by}</span>
                                    </div>
                                    <div>
                                      {post?.published_date === "" && (
                                        <BootstrapTooltip
                                          title={`${post?.title} is not publish yet.`}
                                          placement="right-end"
                                        >
                                          <FontAwesomeIcon
                                            icon={faClipboard}
                                            style={{ color: "#660C60" }}
                                          />
                                        </BootstrapTooltip>
                                      )}
                                    </div>
                                  </Stack>
                                  <Box className="main-content-data-box">
                                    <Stack
                                      direction="horizontal"
                                      className="d-flex flex-wrap gap-3"
                                    >
                                      <h4
                                        className="news-title dark-text fw-400 cursor-pointer text-break"
                                        style={{ height: "28px" }}
                                        onClick={() => {
                                          if (loggedUser?.user_type === "user") {
                                            localStorage.setItem("last-redirect-page-user", `/user-type/view/${typeName}/${post?.id}`);
                                            navigate(`/user-type/view/${typeName}/${post?.id}`);
                                          }
                                          else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                            localStorage.setItem("last-redirect-page-admin-user", `/user-type/view/${typeName}/${post?.id}`)
                                            navigate(`/user-type/view/${typeName}/${post?.id}`);
                                          }

                                        }}
                                      >
                                        {" "}
                                        {post?.title}
                                      </h4>
                                      {post?.is_archived == 1 && (
                                        <Chip
                                          label="Archived"
                                          className="chip-label-div-user"
                                        />
                                      )}
                                    </Stack>
                                    <p
                                      className="news-para dark-text fw-400 opacity-50 text-break"
                                      style={{ height: "46px" }}
                                    >
                                      {post?.content}
                                    </p>
                                    <Stack
                                      direction="horizontal"
                                      justifyContent="space-between"
                                      spacing={2}
                                      alignItems="baseline"
                                      className="d-flex flex-wrap justify-content-between mt-auto gap-2 mt-3 mb-1"
                                    >
                                      <Button
                                        className="bg-white btn-view-article"
                                        variant="outlined"
                                        size="large"
                                        onClick={() => {
                                          if (loggedUser?.user_type === "user") {
                                            localStorage.setItem("last-redirect-page-user", `/user-type/view/${typeName}/${post?.id}`);
                                            navigate(`/user-type/view/${typeName}/${post?.id}`);
                                          }
                                          else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                            localStorage.setItem("last-redirect-page-admin-user", `/user-type/view/${typeName}/${post?.id}`)
                                            navigate(`/user-type/view/${typeName}/${post?.id}`);
                                          }

                                        }}
                                      >
                                        <FontAwesomeIcon icon={faArrowRight} />
                                      </Button>
                                      {post?.is_edit == 1 && (
                                        <Button
                                          className="btn-light btn-view-article"
                                          variant="outlined"
                                          size="large"
                                          onClick={() => {
                                            if (loggedUser?.user_type === "user") {
                                              localStorage.setItem("last-redirect-page-user", `/user-type/create-content/edit/${post?.id}`);
                                              navigate(`/user-type/create-content/edit/${post?.id}`);
                                            }
                                            else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                              localStorage.setItem("last-redirect-page-admin-user", `/user-type/create-content/edit/${post?.id}`)
                                              navigate(`/user-type/create-content/edit/${post?.id}`);
                                            }

                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                      )}
                                      {post?.is_delete == 1 && (
                                        <Button
                                          className="btn-light btn-delete-article"
                                          variant="outlined"
                                          size="large"
                                          onClick={() => {
                                            setPostId(post)
                                            setOpenDeleteAlert(true);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                      )}
                                    </Stack>
                                  </Box>
                                </Stack>
                              </CardContent>
                              <CardActions
                                sx={{ borderTop: "1px solid #EEEEEE", padding: "15px" }}
                              >
                                <div className="d-flex flex-wrap gap-2 mt-auto">
                                  {post?.tags?.map((tags) => (
                                    <Chip
                                      label={tags}
                                      className="chip-label-div text-break"
                                    />
                                  ))}
                                </div>
                              </CardActions>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                      <Divider className="mt-4 mb-4" style={{ background: "#EEEEEE" }} />
                      <Row>
                        {total_types > 9 && (
                          <>
                            <Pagination
                              count={totalPagesForFolder}
                              page={page}
                              className="type-pagination"
                              onChange={handleChangePage}
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{ previous: PreviousItem, next: NextItem }}

                                  {...item}
                                />
                              )}

                            />
                          </>
                        )}
                      </Row>
                      {getAllTypePosts?.length < 1 && (
                        <div className="no-data mt-5">
                          <div>
                            <Image
                              src={NoDataFoundImg}
                              className="text-center mx-auto no-data-img mt-4"
                            />
                          </div>
                          <Typography component="h5" variant="h5">
                            No data found
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid> */}
                </>
              ) : typeName?.toLowerCase() === "people" ? (
                <>
                  <PeopleListing selectedShowValue={selectedShowValue} getAllPeoples={getAllTypePosts} filterToggle={!filterToggle} />

                  {getAllTypePosts?.length < 1 && (
                    <div className="no-data mt-5">
                      <div>
                        <Image
                          src={NoDataFoundImg}
                          className="text-center mx-auto no-data-img mt-4"
                        />
                      </div>
                      <Typography component="h5" variant="h5">
                        No data found
                      </Typography>
                    </div>
                  )}
                </>
              ) : ""}
            </Grid>
          </Grid>
          {getAllTypePosts?.length > 0 && (
            <>
              <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
              <PaginationCustom
                per_page={peoplePagination?.per_page}
                per_page_options={[12, 24, 36, 48, 60]}
                from={peoplePagination?.meta_data?.from}
                to={peoplePagination?.meta_data?.to}
                last_page={peoplePagination?.meta_data?.last_page}
                total={peoplePagination?.meta_data?.total}
                page={peoplePagination?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )
          }



        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete post?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => deleteTypePostAPI(postId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openAddFolder}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Add Folder
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAddFolderModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            <Box>
              <Typography className="fs-14 mt-2">Folder Name</Typography>
              <TextField
                className="input-field mt-2"
                fullWidth
                id="outlined-basic short summary"
                variant="outlined"
                placeholder={`write folder name`}
                value={newFolderName}
                onChange={(event) => setNewFolderName(event?.target?.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseAddFolderModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={addNewFolderAPI}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Add Folder
            </Button>
          </DialogActions>
        </Dialog>

        <Loader isLoading={loading} />
        <Toaster />
      </div >
    </>
  );
};

export default UserTypePage;
