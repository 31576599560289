import React from 'react'
import "../pagination/pagination.scss";
import { Box, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';

function PaginationCustom(props) {
  const { per_page, per_page_options, from, to, last_page, total, page, handleChangePage, handleChangeRowsPerPage } = props;
  return (
    <Box className="pagination">
      {/* Rows Per Page */}
      <Box className="rows-per-page">
        <Typography className="label fs-12-muted">Rows per page:</Typography>
        <Select
          value={per_page}
          onChange={handleChangeRowsPerPage}
          className="select-rows-per-page fs-12-muted"
          variant="standard"
        >
          {per_page_options?.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* Pagination Information */}
      <Typography className="pagination-info fs-12-muted">
        Showing {from || 0} to {to || 0} of {total || 0}
      </Typography>

      {/* Pagination Buttons */}
      <Box className="pagination-buttons">
        <IconButton aria-label="previous page" onClick={() => handleChangePage(page - 1)}
          disabled={page === 1}
          sx={{
            border: page === 1 ? 'none' : '1px solid #660C60',
            color: page === 1 ? '#959595 !important' : '#660C60 !important',
          }}
        >
          <ChevronLeftRounded />
        </IconButton>

        <IconButton aria-label="next page" onClick={() => handleChangePage(page + 1)}
          disabled={page === last_page && last_page}
          sx={{
            border: page === last_page ? 'none' : '1px solid #660C60',
            color: page === last_page ? '#959595 !important' : '#660C60 !important',

          }}
        >
          <ChevronRightRounded />
        </IconButton>
      </Box>
    </Box>
  )
}

export default PaginationCustom