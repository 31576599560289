export const columns_insight_header = [
    {
        key: 1,
        disablePadding: true,
        id: "name",
        label: "Name",
        minWidth: 250,
        className: "tbl-head-class-2",
        showInfoIcon: true,
    },
    { key: 2, disablePadding: true, id: "used", label: "Type", minWidth: 250 },
    {
        key: 3,
        id: "author",
        disablePadding: true,
        label: "Insights Generated",
        minWidth: 70,
        className: "other-color",
    },
    {
        key: 4,
        id: "statu",
        disablePadding: true,
        label: "Creator",
        minWidth: 50,
        className: "other-color",
    },
    {
        key: 5,
        id: "status",
        disablePadding: true,
        label: "Satus",
        minWidth: 50,
        className: "other-color",
    },
];
