import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    html: '',
    css: '',
    js: '',
    components: '',
    styles: '',
    selectedMediaFileForUpdate: {},
    pageTitle: '',
    pageSlug: '',
    pageURL: '',
    pageStatus: true,
    permissions: [],
    isAdvancePermission: false,
    selectedPageTypeTab: 0,
    pageProcessing: false,
    getAllGroupsAndUsers: [],
    getAllChangeLogs: [],
    menu: [
        {
            type: "",
            position: ""
        }
    ],
    link: {
        selectLink: []
    },
    youtube:
        [{
            title: "",
            url: "",
        }],
    types: [],
    people: [],
    feed: []
};

const page = createSlice({
    name: "pageDetails",
    initialState: initialData,
    reducers: {
        setTemplateData: (state, action) => {
            const { htmlData, cssData, jsData, componentData, styleData } = action.payload;
            state.html = htmlData;
            state.css = cssData;
            state.js = jsData;
            state.components = componentData
            state.styles = styleData
        },
        setPageTitle: (state, action) => {
            state.pageTitle = action?.payload
        },
        setPageSlug: (state, action) => {
            state.pageSlug = action?.payload
        },
        setPageURL: (state, action) => {
            state.pageURL = action?.payload
        },
        setPermissions: (state, action) => {
            state.permissions = action?.payload
        },
        setIsAdvancePermission: (state, action) => {
            state.isAdvancePermission = action?.payload
        },
        setPageStatus: (state, action) => {
            state.pageStatus = action?.payload
        },
        setGetAllGroupsAndUsers: (state, action) => {
            state.getAllGroupsAndUsers = action?.payload
        },
        clearAll: (state, action) => {
            state.pageTitle = ''
            state.permissions = []
            state.isAdvancePermission = false
            state.pageStatus = true
            state.html = ''
            state.css = ''
            state.js = ''
            state.components = ''
            state.styles = ''
            state.pageSlug = ''
            state.pageURL = ''
            state.menu = {}
            state.getAllChangeLogs = []
            state.getAllGroupsAndUsers = []
        },
        handleSelectedTab: (state, action) => {
            state.selectedPageTypeTab = action?.payload;
        },
        setPageProcessing: (state, action) => {
            state.pageProcessing = action?.payload
        },
        setGetAllChangeLogs: (state, action) => {
            state.getAllChangeLogs = action?.payload
        },
        setMenuID: (state, action) => {
            state.menu = action?.payload
        },
        setLinkData: (state, action) => {
            state.link = action?.payload
        },
        setYoutubeData: (state, action) => {
            state.youtube = action?.payload
        },
        setTypesData: (state, action) => {
            state.types = action?.payload
        },
        setPeopleData: (state, action) => {
            state.people = action?.payload
        },
        setFeedData: (state, action) => {
            state.feed = action?.payload
        }
    }
})

export const pageAction = page.actions;
export default page.reducer;