import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedInsightTab: 0,
};

const insightTab = createSlice({
    name: "insightTabDetails",
    initialState: initialData,
    reducers: {
        handleSelectedTab(state, action) {
            state.selectedInsightTab = action?.payload
        },
    }
})

export const insightTabAction = insightTab.actions;
export default insightTab.reducer;