import React from 'react'
import DummyUser from "../../../assets/images/dummy-user.jpg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, IconButton, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper } from "@mui/material";
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { filterSliceAction } from '../../../redux/slices/userTypePage/filterSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

function PeopleList(props) {
  const { peoples, handleClickActionIcon } = props;
  const dispatch = useDispatch();
  const { sortPeople } = useSelector((state) => state.filter);

  const handleSort = (key) => {
    let sortType = null;
    if (sortPeople[key] === null) {
      sortType = 'asc';
    } else if (sortPeople[key] === 'asc') {
      sortType = 'desc';
    } else if (sortPeople[key] === 'desc') {
      sortType = null;
    }
    const data = {
      name: null,
      job_title: null,
      email: null,
      phone: null,
      [key]: sortType
    };
    dispatch(filterSliceAction.setSortPeople(data));
  }

  return (
    <TableContainer className="mt-3 people-list-container" component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead className='people-list-header'>
          <TableRow>
            <TableCell className='people-list-header-data cursor-pointer'
              onClick={
                () => {
                  handleSort('name');
                }
              }
            >Name {(sortPeople?.name && sortPeople?.name === 'asc') ? <ExpandLess className="sort-icon" /> : ((sortPeople?.name && sortPeople?.name === 'desc') ? <ExpandMore className="sort-icon" /> : <ExpandLess className="sort-icon text-muted opacity-50" />)}</TableCell>
            <TableCell className='people-list-header-data' align="left">Job Title</TableCell>
            <TableCell className='people-list-header-data' align="left">Email</TableCell>
            <TableCell className='people-list-header-data' align="left">Phone</TableCell>
            <TableCell className='people-list-header-data' align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableRow style={{ height: '10px', backgroundColor: 'transparent' }} />
        <TableBody className='people-list-body'>
          {peoples.map((people) => (
            <TableRow className='people-list-body-row' key={people.id}>
              <TableCell className='d-flex gap-2 align-items-center' component="td" scope="row">
                <Avatar height={32} width={32} src={people?.image ?? DummyUser} alt={people?.name} />
                <Typography variant='h5' className='fs-14-600'>
                  {people?.name}
                </Typography>
              </TableCell>
              <TableCell className='fs-12-muted' align="left">{people.job_title ?? '-'}</TableCell>
              <TableCell className='fs-12-muted' align="left">{people.email ?? '-'}</TableCell>
              <TableCell className='fs-12-muted' align="left">{people.phone ?? '-'}</TableCell>
              <TableCell align="right">
                <IconButton
                  sx={{ height: 12, width: 3 }}
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={(e) => handleClickActionIcon(e, people)}
                >
                  <MoreVertIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PeopleList