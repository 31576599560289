import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import PeopleCard from './peopleCard';
import { useSelector } from 'react-redux';
import PeopleList from './peopleList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import ActionMenu from '../../ui/ActionMenu';

function PeopleListing(props) {
  const navigate = useNavigate();

  const { getAllPeoples, selectedShowValue, filterToggle } = props;
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const { adminAccessible } = useSelector((state) => state?.drawerMenu);

  const [anchorElAction, setAnchorElForAction] = useState(null);
  const [actionClickpost, setActionClickpost] = useState(null);
  const openMenuForActionClick = Boolean(anchorElAction);
  const [menuItems, SetMenuItems] = useState([{
    label: 'View',
    icon: <FontAwesomeIcon icon={faXmark} />,
    onClick: () => handlePostRedirectView(actionClickpost),
  }]);

  const handleClickActionIcon = (event, post) => {
    const menuItemsArr = [
      {
        label: 'View',
        icon: <FontAwesomeIcon icon={faEye} />,
        onClick: () => handlePostRedirectView(post),
      },
      ...(post?.is_edit === 1
        ? [
          {
            label: 'Edit',
            icon: <FontAwesomeIcon icon={faPen} />,
            onClick: () => handlePostRedirectEdit(post),
            showDivider: true,
            className: "edit-item", // Custom class for the menu item
            iconClassName: "edit-icon", // Custom class for the icon
            labelClassName: "edit-label",
          },
        ]
        : []),
      ...(post?.is_delete === 1
        ? [
          {
            label: 'Delete',
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: () => handlePostDelete(post),
            showDivider: true,
            className: "action-delete-item", // Custom class for the menu item
            iconClassName: "action-delete-icon", // Custom class for the icon
            labelClassName: "action-delete-label",
          },
        ]
        : []),];
    SetMenuItems(menuItemsArr);
    setAnchorElForAction(event.currentTarget);
    setActionClickpost(post);
  };

  const handleCloseActionIcon = () => {
    setAnchorElForAction(null);
    setActionClickpost(null);
    SetMenuItems([]);
  };

  const handlePostRedirectView = (post) => {
    if (loggedUser?.user_type === "user") {
      localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${post?.id}`);
      navigate(`/user-type/people/profile/view/${post?.id}`);
    }
    else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
      localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${post?.id}`)
      navigate(`/user-type/people/profile/view/${post?.id}`);
    }
  }

  const handlePostRedirectEdit = (post) => {
    if (loggedUser?.user_type === "user") {
      localStorage.setItem("last-redirect-page-user", `/user-type/create-content/edit/${post?.id}`);
      navigate(`/user-type/create-content/edit/${post?.id}`);
    }
    else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
      localStorage.setItem("last-redirect-page-admin-user", `/user-type/create-content/edit/${post?.id}`)
      navigate(`/user-type/create-content/edit/${post?.id}`);
    }
  }

  const handlePostDelete = () => {
    handleCloseActionIcon();
  }

  return (
    <>
      {selectedShowValue === 'Cards' && (
        <Row className={`mt-0 gutter ${filterToggle ? 'filtered-layout' : 'default-layout'}`}>
          {getAllPeoples?.map((people) => (
            <>
              <Col lg={filterToggle ? 4 : 3} md={filterToggle ? 4 : 6} sm={12}>
                <PeopleCard people={people} adminAccessible={adminAccessible} loggedUser={loggedUser} handleClickActionIcon={handleClickActionIcon} />
              </Col>

            </>
          ))}
        </Row>
      )}
      {selectedShowValue === 'Lists' && getAllPeoples?.length > 0 && (
        <PeopleList peoples={getAllPeoples} adminAccessible={adminAccessible} loggedUser={loggedUser} handleClickActionIcon={handleClickActionIcon} />
      )}

      {openMenuForActionClick &&
        <ActionMenu
          anchorEl={anchorElAction}
          open={openMenuForActionClick}
          onClose={handleCloseActionIcon}
          menuItems={menuItems}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      }
    </>
  )
}

export default PeopleListing