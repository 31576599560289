import axios from "axios";

// Construct the baseURL correctly based on the environment
// const apiURL = `${window?.location?.protocol}//${window?.location?.hostname}/api`;
const devMode = process.env.REACT_APP_MODE
let apiURL;

if (devMode !== "DEV") {
  apiURL = `${window?.location?.protocol}//${window?.location?.hostname}/api/v1`;
} else {
  apiURL = `${window?.location?.protocol}//${window?.location?.hostname}:8000/api/v1`;
}

// const api = axios.create({
//   baseURL: process.env.REACT_APP_MODE !== "DEV" ? apiURL : process.env.REACT_APP_API_URL, // Your API base URL
// });
const api = axios.create({
  baseURL: apiURL  // Your API base URL
});
// Request interceptor
api.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
