import React from "react";
import ReactDOM from "react-dom/client";
import ReactDOMServer from "react-dom/server";
import api from "../commonFunctionForApi";
import _ from 'lodash';

const formatDateToReadable = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    // Determine the suffix for the day
    const suffix =
        day === 1 || day === 21 || day === 31
            ? "st"
            : day === 2 || day === 22
                ? "nd"
                : day === 3 || day === 23
                    ? "rd"
                    : "th";

    const month = date.toLocaleString("default", { month: "long" }); // e.g., January
    const year = date.getFullYear();

    return `${day}${suffix} ${month} ${year}`;
};


const maxVisibleCategory = 1

export const RenderFeedBlock = ({ feedData, title, showUpdateBox, loggedUser }) => {
    return (
        <div className="px-3 py-2">
            {title && (
                <h3 className="text-center">{title}</h3>
            )}
            {showUpdateBox === "YES" && (
                // <div class="serach-box w-100 MuiBox-root css-0 mt-2">
                //     <div class="input-group">
                //         <input placeholder="Post an update" aria-label="Recipient's username"
                //             aria-describedby="basic-addon2" class="search-input w-100 form-control" />
                //         <button type="button"
                //             class="search-btn-dash-2 btn btn-primary"><svg aria-hidden="true" focusable="false" data-prefix="fab"
                //                 data-icon="telegram" class="svg-inline--fa fa-telegram " role="img" xmlns="http://www.w3.org/2000/svg"
                //                 viewBox="0 0 496 512">
                //                 <path fill="currentColor"
                //                     d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z">
                //                 </path>
                //             </svg>
                //         </button>
                //     </div>
                // </div>
                <div class="update-type-post-card card">
                    <div class="MuiCardContent-root update-type-post-card-content css-46bh2p-MuiCardContent-root">
                        <div class="MuiBox-root css-0">
                            <div class="MuiBox-root css-0"><span style={{ fontSize: "20px" }}>Welcome to platform, <b>{loggedUser?.full_name} </b></span>
                            </div>
                            <div class="mt-3 d-flex justify-content-center align-items-center gap-2 MuiBox-root css-0">
                                <div class="MuiBox-root css-0">
                                    {loggedUser?.thumbnail !== "" ? (
                                        <div class="MuiAvatar-root MuiAvatar-circular update-avtar-creator css-1wlk0hk-MuiAvatar-root" aria-label="recipe">
                                            <img src={loggedUser?.thumbnail} class="MuiAvatar-img feed-update-post-user-img css-1pqm26d-MuiAvatar-img" />
                                        </div>
                                    ) : (
                                        <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator-post css-17o22dy-MuiAvatar-root" aria-label="recipe">
                                            {loggedUser?.full_name?.charAt(0)}
                                        </div>
                                    )}
                                </div>
                                <div class="w-100 MuiBox-root css-0 update-box-search-div">
                                    <input placeholder="Post an update" aria-label="Recipient's username" aria-describedby="basic-addon2" class="search-input w-100 form-control update-box-search" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {feedData?.length > 0 ? (
                <>
                    {feedData?.map((feed) => (
                        <>
                            {feed?.type?.name === "Update" ? (
                                <>
                                    {/* <div data-post-id={feed?.id} className="mt-4 card feed-card">
                                        <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                            <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                                {feed?.profile !== "" ? (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">
                                                            <img src={feed?.profile} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">{feed?.created_by?.charAt(0)}
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                            <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                                <span className="MuiTypography-body2  MuiCardHeader-title">
                                                    <div className="MuiBox-root feed-top-header css-69i1ev">
                                                        <span><b>{feed?.created_by}</b> posted a <b>{feed?.type?.name}</b></span>
                                                        <span className="MuiTypography-caption">{feed?.published_date}</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" role="img"
                                            style={{
                                                backgroundImage: `url(${feed?.image})`,
                                            }}>
                                        </div>
                                        <div className="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                            <div className="main-content-div-1">
                                                <div className="main-div-posted">
                                                    <div className="icon-bd-div">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                                                            className="svg-inline--fa fa-clock " role="img" xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512">
                                                            <path fill="currentColor"
                                                                d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                    <p className="MuiTypography-body1 fs-14 opacity-50"> Posted {feed?.published_date}</p>
                                                </div>
                                            </div>
                                            <div className="MuiBox-root css-0">
                                                <div
                                                    className="MuiTypography-h5 MuiTypography-gutterBottom content-title text-break">
                                                    {feed?.title}</div>
                                                <p
                                                    className="MuiTypography-body2 fs-14 content-desc text-break">
                                                    {feed?.content}
                                                </p>
                                            </div>
                                            <button type="button" className="btn primary-btn mt-4 btn btn-primary">View Article
                                                <svg aria-hidden="true"
                                                    focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right "
                                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="currentColor"
                                                        d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z">
                                                    </path>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="MuiCardActions-root MuiCardActions-spacing bg-white card-action-2 css-c7tfsh-MuiCardActions-root"></div>
                                    </div> */}
                                    <div data-post-id={feed?.id} className="mt-4 card feed-card">
                                        <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                            <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                                {feed?.created_by?.thumbnail !== "" ? (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">
                                                            <img src={feed?.created_by?.thumbnail} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">{feed?.created_by?.name?.charAt(0)}
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                            <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                                <span className="MuiTypography-body2  MuiCardHeader-title">
                                                    <div className="MuiBox-root feed-top-header css-69i1ev">
                                                        <span className="post-posted-label"><b>{feed?.created_by?.name}</b> posted a <b>{feed?.type?.name}</b></span>
                                                        <div class="MuiBox-root css-0"><span
                                                            class=" MuiTypography-caption post-date-label css-1sn4lm3-MuiTypography-root">{formatDateToReadable(feed?.published_date_time)}</span><button
                                                                class="MuiIconButton-root MuiIconButton-sizeMedium edit-post-icon-btn css-78trlr-MuiButtonBase-root-MuiIconButton-root"
                                                                tabindex="0" type="button" aria-label="more" id="long-button" aria-haspopup="true"><svg
                                                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                                    focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreVertIcon">
                                                                    <path
                                                                        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2">
                                                                    </path>
                                                                </svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="MuiCardContent-root content-div-body-2 css-46bh2p-MuiCardContent-root">
                                            <div className="MuiBox-root css-0">
                                                <h3
                                                    className="MuiTypography-h3 content-title-update text-break">
                                                    <span>{feed?.content}</span>
                                                </h3>

                                            </div>
                                            {feed?.multi_image?.length > 0 && (
                                                feed?.multi_image?.length === 1 ? (
                                                    <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" role="img"
                                                        style={{
                                                            backgroundImage: `url(${feed?.multi_image[0]?.file_url})`,
                                                        }}>
                                                    </div>
                                                ) : (
                                                    <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-spacing-md-3 mt-1 update-multi-grid-container css-1xgjpwf-MuiGrid-root">
                                                        {feed?.multi_image.map((file, index) => (
                                                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-4 MuiGrid-grid-md-3 css-1vm8tgf-MuiGrid-root update-post-grid-item">
                                                                <div className="upload-img-wrapper" style={{ height: "162px" }}>
                                                                    <img src={file?.file_url} alt={file?.alt_text || `Image ${index + 1}`} className="" style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "3px" }} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : feed?.type?.name === "Insight" ? (
                                <>
                                    <div data-post-id={feed?.id} className="mt-4 card feed-card">
                                        <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                            <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                                {feed?.created_by?.thumbnail !== "" ? (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">
                                                            <img src={feed?.created_by?.thumbnail} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">{feed?.created_by?.name?.charAt(0)}
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                            <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                                <span className="MuiTypography-body2  MuiCardHeader-title">
                                                    <div className="MuiBox-root feed-top-header css-69i1ev">
                                                        <span className="post-posted-label"><b>{feed?.created_by?.name}</b> posted a <b>{feed?.type?.name}</b></span>
                                                        <div class="MuiBox-root css-0"><span
                                                            class=" MuiTypography-caption post-date-label css-1sn4lm3-MuiTypography-root">{formatDateToReadable(feed?.published_date_time)}</span><button
                                                                class="MuiIconButton-root MuiIconButton-sizeMedium edit-post-icon-btn css-78trlr-MuiButtonBase-root-MuiIconButton-root"
                                                                tabindex="0" type="button" aria-label="more" id="long-button" aria-haspopup="true"><svg
                                                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                                    focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreVertIcon">
                                                                    <path
                                                                        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2">
                                                                    </path>
                                                                </svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="MuiCardContent-root content-div-body-2 css-46bh2p-MuiCardContent-root">
                                            <div class="d-flex gap-3 align-items-center MuiBox-root css-0">
                                                <div class="insight-img-box MuiBox-root css-0">
                                                    <img
                                                        src={feed?.celebrate_user?.thumbnail}
                                                        alt={feed?.celebrate_user?.name} class="cursor-pointer rounded-circle" style={{ width: "100%", height: "100%" }} /></div>
                                                <div class="MuiBox-root css-0">
                                                    <p
                                                        class=" MuiTypography-body1 insight-content-name text-uppercase css-ahj2mt-MuiTypography-root">
                                                        {feed?.content}</p>
                                                    <p
                                                        class="MuiTypography-body1 insight-content-user-name text-capitalize mt-1 css-ahj2mt-MuiTypography-root">
                                                        {feed?.celebrate_user?.name} {feed?.celebrate_user?.last_name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                // <div data-post-id={feed?.id} className="mt-4 card feed-card">
                                //     <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                //         <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                //             {feed?.created_by?.thumbnail !== "" ? (
                                //                 <>
                                //                     <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                //                         aria-label="recipe">
                                //                         <img src={feed?.created_by?.thumbnail} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                //                     </div>
                                //                 </>
                                //             ) : (
                                //                 <>
                                //                     <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                //                         aria-label="recipe">{feed?.created_by?.name?.charAt(0)}
                                //                     </div>
                                //                 </>
                                //             )}

                                //         </div>
                                //         <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                //             <span className="MuiTypography-body2  MuiCardHeader-title">
                                //                 <div className="MuiBox-root feed-top-header css-69i1ev">
                                //                     <span><b>{feed?.created_by}</b> posted a <b>{feed?.type?.name}</b></span>
                                //                     <span className="MuiTypography-caption">{feed?.published_date}</span>
                                //                 </div>
                                //             </span>
                                //         </div>
                                //     </div>

                                //     <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" role="img"
                                //         style={{
                                //             backgroundImage: `url(${feed?.image})`,
                                //         }}>
                                //     </div>
                                //     <div className="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                //         <div className="main-content-div-1">
                                //             <div className="main-div-posted">
                                //                 <div className="icon-bd-div">
                                //                     <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                                //                         className="svg-inline--fa fa-clock " role="img" xmlns="http://www.w3.org/2000/svg"
                                //                         viewBox="0 0 512 512">
                                //                         <path fill="currentColor"
                                //                             d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                //                         </path>
                                //                     </svg>
                                //                 </div>
                                //                 <p className="MuiTypography-body1 fs-14 opacity-50"> Posted {feed?.published_date}</p>
                                //             </div>
                                //         </div>
                                //         <div className="MuiBox-root css-0">
                                //             <div
                                //                 className="MuiTypography-h5 MuiTypography-gutterBottom content-title text-break">
                                //                 {feed?.title}</div>
                                //             <p
                                //                 className="MuiTypography-body2 fs-14 content-desc text-break">
                                //                 {feed?.content}
                                //             </p>
                                //         </div>
                                //         <button type="button" className="btn primary-btn mt-4 btn btn-primary">View Article
                                //             <svg aria-hidden="true"
                                //                 focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right "
                                //                 role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                //                 <path fill="currentColor"
                                //                     d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z">
                                //                 </path>
                                //             </svg>
                                //         </button>
                                //     </div>
                                //     <div className="MuiCardActions-root MuiCardActions-spacing bg-white card-action-2 css-c7tfsh-MuiCardActions-root"></div>
                                // </div>
                                <div data-post-id={feed?.id} className="mt-4 card feed-card">
                                    <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                        <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                            {feed?.created_by?.thumbnail !== "" ? (
                                                <>
                                                    <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                        aria-label="recipe">
                                                        <img src={feed?.created_by?.thumbnail} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                        aria-label="recipe">{feed?.created_by?.name?.charAt(0)}
                                                    </div>
                                                </>
                                            )}

                                        </div>
                                        <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                            <span className="MuiTypography-body2  MuiCardHeader-title">
                                                <div className="MuiBox-root feed-top-header css-69i1ev">
                                                    <span className="post-posted-label"><b>{feed?.created_by?.name}</b> posted a <b>{feed?.type?.name}</b></span>
                                                    <div class="MuiBox-root css-0"><span
                                                        class=" MuiTypography-caption post-date-label css-1sn4lm3-MuiTypography-root">{formatDateToReadable(feed?.published_date_time)}</span><button
                                                            class="MuiIconButton-root MuiIconButton-sizeMedium edit-post-icon-btn css-78trlr-MuiButtonBase-root-MuiIconButton-root"
                                                            tabindex="0" type="button" aria-label="more" id="long-button" aria-haspopup="true"><svg
                                                                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                                focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreVertIcon">
                                                                <path
                                                                    d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2">
                                                                </path>
                                                            </svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>

                                    {/* <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" role="img"
                                        style={{
                                            backgroundImage: `url(${feed?.image})`,
                                        }}>
                                    </div>
                                    <div className="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                        <div className="main-content-div-1">
                                            <div className="main-div-posted">
                                                <div className="icon-bd-div">
                                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                                                        className="svg-inline--fa fa-clock " role="img" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512">
                                                        <path fill="currentColor"
                                                            d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <p className="MuiTypography-body1 fs-14 opacity-50"> Posted {feed?.published_date}</p>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-0">
                                            <div
                                                className="MuiTypography-h5 MuiTypography-gutterBottom content-title text-break">
                                                {feed?.title}</div>
                                            <p
                                                className="MuiTypography-body2 fs-14 content-desc text-break">
                                                {feed?.content}
                                            </p>
                                        </div>
                                        <button type="button" className="btn primary-btn mt-4 btn btn-primary">View Article
                                            <svg aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right "
                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path fill="currentColor"
                                                    d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div> */}

                                    <div class="d-flex card-content-box MuiBox-root css-0">
                                        <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root post-img" role="img"
                                            style={{
                                                backgroundImage: `url(${feed?.image})`,
                                            }}>
                                        </div>
                                        <div class="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                            <div class="main-content-div-1">
                                                <div class="main-div-posted"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                                                    class="svg-inline--fa fa-clock posted-date-icon" role="img" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512">
                                                    <path fill="currentColor"
                                                        d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                                    </path>
                                                </svg>
                                                    <p
                                                        class="MuiTypography-root MuiTypography-body1 posted-date-text opacity-50  css-ahj2mt-MuiTypography-root">
                                                        {formatDateToReadable(feed?.published_date_time)}</p>
                                                </div>
                                                {feed?.category?.length > 0 && (
                                                    <div class="main-div-workspace">
                                                        <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                            data-icon="briefcase" class="svg-inline--fa fa-briefcase posted-date-icon" role="img"
                                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path fill="currentColor"
                                                                d="M184 48l144 0c4.4 0 8 3.6 8 8l0 40L176 96l0-40c0-4.4 3.6-8 8-8zm-56 8l0 40L64 96C28.7 96 0 124.7 0 160l0 96 192 0 128 0 192 0 0-96c0-35.3-28.7-64-64-64l-64 0 0-40c0-30.9-25.1-56-56-56L184 0c-30.9 0-56 25.1-56 56zM512 288l-192 0 0 32c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-32L0 288 0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-128z">
                                                            </path>
                                                        </svg>
                                                        {feed?.category
                                                            ?.slice(0, maxVisibleCategory)
                                                            .map((cate, index) => (
                                                                <p
                                                                    class="MuiTypography-root MuiTypography-body1 posted-date-text opacity-50 css-ahj2mt-MuiTypography-root">
                                                                    {cate}
                                                                </p>
                                                            ))}
                                                        {feed?.category?.length > maxVisibleCategory && (
                                                            <div class="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-filledDefault categoty-label-chip cursor-pointer css-gavykb-MuiChip-root"
                                                                data-mui-internal-clone-element="true">
                                                                <span
                                                                    class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">+{feed?.category.length - maxVisibleCategory
                                                                    } others
                                                                </span>
                                                            </div>
                                                        )}

                                                    </div>
                                                )}

                                            </div>
                                            <div class="post-content-data MuiBox-root css-0">
                                                <div
                                                    class="MuiTypography-h5 MuiTypography-gutterBottom content-title text-break css-h93ljk-MuiTypography-root">
                                                    {feed?.title}</div>
                                                <p
                                                    class=" MuiTypography-body2 fs-14 content-desc text-break css-r40f8v-MuiTypography-root">
                                                    {feed?.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
                </>
            ) : (
                <h1>No Feed Available</h1>
            )}
        </div>
    )
}

const FeedBlock = (editor, options) => {

    const { userToken, typesData, categoryData, loggedUser } = options; // Pass menu items dynamically to CustomMenu

    const feedBlock = ReactDOMServer.renderToString(
        <RenderFeedBlock />
    )

    // Create a Map to store React roots
    const reactRoots = new Map();

    const typeOptions = typesData?.map((data) => ({
        id: data?.id, // Create a unique ID for each menu type
        label: data?.name, // Use the title as the name
    }));

    const categoryOptions = categoryData?.map((data) => ({
        id: data?.id, // Create a unique ID for each menu type
        label: data?.title, // Use the title as the name
    }));

    const fetchTypePost = async (typeID, catID) => {
        try {
            const response = await api.post(
                `admin/page/component/feed-list`,
                { category_id: catID, type_id: typeID },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            const data = response?.response?.data
            return data

        } catch (err) {
            console.error("Error fetching menu details:", err);
            return []; // Return empty array on error
        }
    }

    editor.TraitManager.addType('multi-select-types', {
        createInput({ trait }) {
            // console.log("traits for multi::", trait);
            const el = document.createElement('div');
            el.innerHTML = `
            <select multiple style="width: 100%; padding: 5px;">
                ${trait?.attributes?.options
                    ?.map(opt => `<option value="${opt?.id}">${opt?.label}</option>`)
                    ?.join('')}
            </select>
        `;
            return el;
        },

        onEvent({ elInput, component, trait }) {
            // console.log("trait event::", trait);

            // Locate the <select> element inside the container
            const selectElement = elInput.querySelector('select');
            if (!selectElement) {
                console.error("Select element not found within elInput.");
                return;
            }

            // Collect all selected values
            const selectedValues = Array.from(selectElement.selectedOptions).map(opt => opt.value);
            // console.log("Selected Values:", selectedValues);

            // Get previously selected values from the component
            const previousValues = component.get(trait?.attributes?.name) || [];
            // console.log("Previous Values:", previousValues);

            // Create a new array for updated values with toggle logic
            const updatedValues = [...previousValues];

            selectedValues.forEach(value => {
                if (updatedValues.includes(value)) {
                    // If the value is already in the list, remove it (toggle off)
                    const index = updatedValues.indexOf(value);
                    updatedValues.splice(index, 1);
                } else {
                    // If the value is not in the list, add it (toggle on)
                    updatedValues.push(value);
                }
            });

            // Update the component's attribute with the final toggled values
            component.set(trait?.attributes?.name, updatedValues);

            // Persist attributes to ensure UI reflects the current state
            component.addAttributes({ [trait?.attributes?.name]: updatedValues });

            // console.log("Updated Values:", updatedValues);
            console.log("Component Attributes:", component.getAttributes());
        },

        onUpdate({ elInput, component, trait }) {
            // console.log("onUpdate called");

            // Locate the <select> element inside the container
            const selectElement = elInput.querySelector('select');
            const currentValues = component.get(trait?.attributes?.name) || [];
            // console.log("currentValues::", currentValues);

            // Synchronize the <select> element with the current values
            if (selectElement) {
                Array.from(selectElement.options).forEach(opt => {
                    opt.selected = currentValues.includes(opt.value);
                });
            }
        },
    });

    editor.TraitManager.addType('multi-select-category', {
        createInput({ trait }) {
            // console.log("traits for multi::", trait);
            const el = document.createElement('div');
            el.innerHTML = `
            <select multiple style="width: 100%; padding: 5px;">
                ${trait?.attributes?.options
                    ?.map(opt => `<option value="${opt?.id}">${opt?.label}</option>`)
                    ?.join('')}
            </select>
        `;
            return el;
        },

        onEvent({ elInput, component, trait }) {
            // console.log("trait event::", trait);

            // Locate the <select> element inside the container
            const selectElement = elInput.querySelector('select');
            if (!selectElement) {
                console.error("Select element not found within elInput.");
                return;
            }

            // Collect all selected values
            const selectedValues = Array.from(selectElement.selectedOptions).map(opt => opt.value);
            // console.log("Selected Values:", selectedValues);

            // Get previously selected values from the component
            const previousValues = component.get(trait?.attributes?.name) || [];
            // console.log("Previous Values:", previousValues);

            // Create a new array for updated values with toggle logic
            const updatedValues = [...previousValues];

            selectedValues.forEach(value => {
                if (updatedValues.includes(value)) {
                    // If the value is already in the list, remove it (toggle off)
                    const index = updatedValues.indexOf(value);
                    updatedValues.splice(index, 1);
                } else {
                    // If the value is not in the list, add it (toggle on)
                    updatedValues.push(value);
                }
            });

            // Update the component's attribute with the final toggled values
            component.set(trait?.attributes?.name, updatedValues);

            // Persist attributes to ensure UI reflects the current state
            component.addAttributes({ [trait?.attributes?.name]: updatedValues });

            // console.log("Updated Values:", updatedValues);
            console.log("Component Attributes:", component.getAttributes());
        },

        onUpdate({ elInput, component, trait }) {
            // console.log("onUpdate called");

            // Locate the <select> element inside the container
            const selectElement = elInput.querySelector('select');
            const currentValues = component.get(trait?.attributes?.name) || [];
            // console.log("currentValues::", currentValues);

            // Synchronize the <select> element with the current values
            if (selectElement) {
                Array.from(selectElement.options).forEach(opt => {
                    opt.selected = currentValues.includes(opt.value);
                });
            }
        },
    });

    editor.BlockManager.add("cust-feed-block", {
        label: "Custom Feed Block",
        category: "Components",
        content: {
            type: "custom-feed-block-component", // Custom type for React
            components: feedBlock,
            className: "feed-blk-className",
            tagName: "div", // Wrap the menu in a div
        },
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 64C0 46.3 14.3 32 32 32c229.8 0 416 186.2 416 416c0 17.7-14.3 32-32 32s-32-14.3-32-32C384 253.6 226.4 96 32 96C14.3 96 0 81.7 0 64zM0 416a64 64 0 1 1 128 0A64 64 0 1 1 0 416zM32 160c159.1 0 288 128.9 288 288c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-123.7-100.3-224-224-224c-17.7 0-32-14.3-32-32s14.3-32 32-32z"/></svg>',
    });

    editor.DomComponents.addType("custom-feed-block-component", {
        isComponent: (el) => el?.tagName === "DIV" && el?.dataset?.type === "custom-feed-block-component",
        model: {
            defaults: {
                tagName: "div",
                droppable: false, // Prevent other components from being dropped inside
                copyable: true, // Enable copy
                resizable: {
                    // Add resizable options
                    tl: 0, // Top-left
                    tr: 0, // Top-right
                    bl: 0, // Bottom-left
                    br: 1, // Bottom-right (enabled)
                    tc: 0, // Top-center
                    bc: 1, // Bottom-center
                    cl: 0, // Center-left
                    cr: 1, // Center-right
                    keyWidth: 'width',
                    keyHeight: 'height',
                },
                attributes: {
                    title: "",
                    feedType: "", // Ensure this is initialized
                    category: "",
                    showUpdateBox: "NO"
                },
                traits: [
                    {
                        type: "text",
                        label: "Title",
                        name: "title",
                        placeholder: "Enter title here",
                    },
                    {
                        type: "multi-select-types",
                        label: "Type",
                        name: "feedType",
                        options: typeOptions,
                    },
                    {
                        type: "multi-select-category",
                        label: "Category",
                        name: "category",
                        options: categoryOptions,
                    },
                ],
                classes: ["custom-feed-block"], // Add the class here
            },
            init() {
                this.on("change:title", this.handleUpdate);
                this.on("change:feedType", this.handleUpdate);
                this.on("change:category", this.handleUpdate);

                this.on("change:title change:feedType change:category", this.handleUpdate);

                // Debounce the `handleUpdate` to prevent multiple calls within a short time
                this.debouncedUpdate = _.debounce(this.handleUpdate.bind(this), 300);

                // Initial check for type '5'
                const feedType = this.getAttributes().feedType || [];
                const typeArray = Array.isArray(feedType) ? feedType : [feedType];

                this.updateTraitsBasedOnType(typeArray);

                this.on("change:attributes", () => {
                    this.handleUpdate(this.getAttributes().title)
                });

            },
            handleUpdate() {
                // Use the debounced version to avoid multiple calls
                this.debouncedFetchData();
            },
            debouncedFetchData: _.debounce(async function () {
                const type = this.getAttributes().feedType;
                const category = this.getAttributes().category;
                const title = this.getAttributes().title;
                const showUpdateBox = this.getAttributes().showUpdateBox

                const typeArray = Array.isArray(type) ? type : [type];

                // Manage traits dynamically
                this.updateTraitsBasedOnType(typeArray);

                try {
                    const data = await fetchTypePost(type, category);

                    const container = this.view.el;

                    // Ensure container exists
                    if (!container) {
                        console.error("No container found for rendering.");
                        return;
                    }

                    // Manage React root for rendering
                    if (!reactRoots.has(container)) {
                        const root = ReactDOM.createRoot(container);
                        reactRoots.set(container, root);
                    }

                    const root = reactRoots.get(container);
                    root.render(
                        <RenderFeedBlock feedData={data} title={title} showUpdateBox={showUpdateBox} loggedUser={loggedUser} />
                    );
                } catch (err) {
                    console.error("Error fetching data or rendering:", err);
                }
            }, 300),

            updateTraitsBasedOnType(typeArray) {
                const updateNameTrait = {
                    type: "checkbox",
                    label: "Show Update Box",
                    name: "showUpdateBox",
                    valueTrue: 'YES', // Value to assign when is checked, default: `true`
                    valueFalse: 'NO',
                };

                const existingTraits = this.get("traits");
                const traitNames = existingTraits.map((trait) => trait.name);

                if (typeArray.includes("5")) {
                    console.log("ID '5' found in typeArray");
                    if (!traitNames.includes("updateName")) {
                        console.log("ID '5' found in typeArray");
                        this.set("traits", [...existingTraits, updateNameTrait]);
                    }
                } else {
                    console.log("ID '5' not found in typeArray");
                    const updatedTraits = existingTraits.filter(
                        (trait) => trait?.attributes?.name !== "showUpdateBox"
                    );
                    console.log("Updated Traits after removal:", updatedTraits);
                    this.set("traits", updatedTraits);
                }
            },
            removed() {
                console.log('Local hook: model.removed');
                if (this.reactRoot) {
                    this.reactRoot.unmount();
                }
            },

        },
        view: {
            onRender() {
                const menuRoot = this.el;

                // Ensure menuRoot exists
                if (!menuRoot) {
                    console.error("menuRoot is not available for rendering the menu.");
                    return;
                }

                // Cleanup existing React root if it exists
                if (this.reactRoot) {
                    this.reactRoot.unmount();
                }

                // Create a new React root
                this.reactRoot = ReactDOM.createRoot(menuRoot);
                const attributes = this.model.getAttributes();
                const { category, feedType, title, showUpdateBox } = attributes

                fetchTypePost(feedType, category)
                    .then((data) => {
                        // Render the component with updated data
                        this.reactRoot.render(
                            <RenderFeedBlock feedData={data} title={title} showUpdateBox={showUpdateBox} loggedUser={loggedUser} />

                        );
                    })
                    .catch((err) => {
                        console.error("Error fetching data for menu items:", err);
                        this.reactRoot.render(
                            <RenderFeedBlock title={title} showUpdateBox={showUpdateBox} loggedUser={loggedUser} />
                        );
                    });

                // Inject CSS into iframe if it exists
                const iframe = document.querySelector("iframe");
                if (iframe) {
                    const iframeDocument =
                        iframe.contentDocument || iframe.contentWindow.document;

                    // Check if the style tag already exists to avoid duplication
                    if (!iframeDocument.querySelector("#custom-css")) {
                        const style = iframeDocument.createElement("style");
                        style.id = "custom-css";
                        style.innerHTML = `
                                        //global style
                            *{
                                font-family: "Inter" !important;
                                //   background-color: #fff;
                            }
                            [data-gjs-type="cell"] {
                                position: relative !important;
                            }
                            .mt-4 {
                                margin-top: 1.5rem !important;
                            }
                            //type component
                            .news-card-wrapper {
                                padding: 0 !important;
                                background-color: #fff;
                                color: rgba(0, 0, 0, 0.87);
                                transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                border-radius: 4px;
                                box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                overflow: hidden;
                                max-width: 420px;
                                height: "100%";
                            }
                            .news-card-wrapper img {
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                width: 100%;
                                object-fit: cover;
                                height: 140px;
                                cursor: pointer;
                            }
                            .card-content-post {
                                padding: 16px
                            }
                            .gaps-for-stack {
                                gap: 10px;
                                height: 100% !important;
                                display: flex;
                                flex-direction: column;
                            }
                            .stack-for-stack {
                                gap: 10px;
                                justify-content: space-between !important;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                align-self: stretch;
                            }
                            .div-for-avatar {
                                gap: .5rem !important;
                                align-items: center !important;
                                display: flex !important;
                            }
                            .author-avtar-creator {
                                position: relative;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                font-size: 1.25rem;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                                height: auto !important;
                                width: 32px !important;
                                min-height: 32px;
                                font-size: 14px !important;
                            }
                            .avatar-span-class {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 16.94px;
                                letter-spacing: 0px;
                                color: #000000;
                            }
                            .main-content-data-box {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                            }
                            .stack-for-data-title {
                                gap: 1rem !important;
                                flex-wrap: wrap !important;
                                display: flex !important;
                                flex-direction: row;
                                align-items: center;
                                align-self: stretch;
                            }
                            .news-title {
                                font-size: 20px;
                                line-height: 28px;
                                letter-spacing: 0px;
                                overflow: hidden;
                                cursor: pointer;
                                font-weight: 400;
                                color: #333333;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                            }
                            .news-para {
                                font-size: 14px;
                                line-height: 23px;
                                letter-spacing: 0px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                font-weight: 400;
                                color: #333333;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                                opacity: .5 !important;
                            }
                            .actions-div {
                                justify-content: space-between !important;
                                flex-wrap: wrap !important;
                                display: flex !important;
                                flex-direction: row;
                                align-items: center;
                                align-self: stretch;
                            }
                            .btn-view-article {
                                cursor: pointer;
                                display: -ms-inline-flexbox;
                                display: inline-flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                position: relative;
                                box-sizing: border-box;
                                -webkit-tap-highlight-color: transparent;
                                outline: 0;
                                margin: 0;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                vertical-align: middle;
                                -webkit-text-decoration: none;
                                text-decoration: none;
                                font-weight: 500;
                                line-height: 1.75;
                                letter-spacing: 0.02857em;
                                min-width: 64px;
                                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                background: #F9F9F9 !important;
                                color: #660C60 !important;
                                border: none !important;
                                text-transform: math-auto !important;
                                border-radius: 3px !important;
                                font-size: 14px !important;
                                padding: 10px !important;
                                --bs-bg-opacity: 1;
                            }
                            .btn-view-article svg {
                                overflow: visible;
                                box-sizing: content-box;
                                font-size: 12px !important;
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -0.125em;
                            }
                            .btn-view-article span {
                                overflow: hidden;
                                pointer-events: none;
                                position: absolute;
                                z-index: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                border-radius: inherit;
                            }
                            .card-action-bottom {
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                border-top: 1px solid #EEEEEE;
                                padding: 15px;
                            }
                            .card-action-bottom div {
                                gap: .5rem !important;
                                margin-top: auto !important;
                                flex-wrap: wrap !important;
                                display: flex !important;
                            }
                            .col-lg-3 {
                                height: 100%;
                            }
                            .col-md-6 {
                                height: 100%;
                            }
                            .col-sm-12 {
                                height: 100%;
                            }
                            //people block
                            .custom-people-block {
                                padding: 10px;
                            }
                            //feed block
                            .custom-feed-block {
                                padding: 10px 15px 10px 15px;
                            }
                            .feed-card {
                                border: 1px solid #eeeeee !important;
                                border-radius: 6px;
                                box-shadow: none;
                                background-color: #ffffff !important;
                                height: 100% !important;
                                width: 100% !important;
                                color: #212529 !important;
                            }
                            .card-header-content {
                                padding: 10px !important;
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #EEEEEE !important;
                            }
                            .feed-card-avatar-header {
                                display: flex;
                                flex: 0 0 auto;
                                margin-right: 16px;
                            }
                            .author-avtar-creator {
                                height: auto !important;
                                width: 32px !important;
                                min-height: 32px;
                                font-size: 14px !important;
                                position: relative;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                            }
                            .feed-card-header {
                                flex: 1 1 auto;
                            }
                            .MuiTypography-body2 {
                                margin: 0;
                                font-weight: 400;
                                font-size: 0.875rem;
                                line-height: 1.43;
                                letter-spacing: 0.01071em;
                                display: block;
                            }
                            .feed-top-header {
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                            }
                            .MuiTypography-caption {
                                margin: 0;
                                font-weight: 400;
                                font-size: 0.75rem;
                                line-height: 1.66;
                                letter-spacing: 0.03333em;
                            }
                            .feed-card-img {
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                -webkit-background-position: center;
                                background-position: center;
                                height: 222px;
                                cursor: pointer;
                            }
                            .content-div-body {
                                padding: 15px 15px 15px 15px !important;
                                width: 70% !important;
                                background-color: #F9F9F9 !important;
                            }
                            .main-content-div-1 {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 40px;
                                margin-bottom: 20px;
                            }
                            .main-div-posted {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 15px;
                            }
                            .posted-date-icon {
                                font-size: 12px;
                                font-weight: 400;
                                color: #660C60;
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .posted-date-text {
                                font-size: 12px !important;
                                font-weight: 500 !important;
                                color: #000000;
                                opacity: 50% !important;
                                margin-top: auto !important;
                                margin-bottom: auto !important;
                            }
                            .main-div-workspace {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 15px;
                            }
                            .categoty-label-chip {
                                height: auto !important;
                                background-color: #F9F9F9 !important;
                                border-radius: 0 !important;
                                font-weight: 400 !important;
                                max-width: 100%;
                                font-size: 0.8125rem;
                                display: inline-flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                                color: rgba(0, 0, 0, 0.87);
                                white-space: nowrap;
                                transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                cursor: pointer;
                                outline: 0;
                                text-decoration: none;
                                border: 0;
                                padding: 0;
                                vertical-align: middle;
                                box-sizing: border-box;
                            }
                            .categoty-label-chip span {
                                font-weight: 500 !important;
                                font-size: 9px !important;
                                line-height: 10px !important;
                                color: #959595;
                                /* opacity: 65%; */
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 3px !important;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .post-content-data {
                                margin-top: 96px;
                            }
                            .icon-bd-div {
                                border: 1px solid #eeeeee;
                                height: 35px;
                                width: 35px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                background-color: #f9f9f9;
                            }
                            .icon-bd-div svg {
                                color: #660c60 !important;
                                font-size: 12px !important;
                                font-weight: 400 !important;
                                line-height: 12px !important;
                                overflow: visible;
                                box-sizing: content-box;
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .fs-14 {
                                font-size: 14px !important;
                                font-weight: 400 !important;
                                line-height: 24px !important;
                                font-family: "Inter" !important;
                                color: #333333 !important;
                                margin: 0 !important;
                            }
                            .opacity-50 {
                                opacity: .5 !important;
                            }
                            .content-title {
                                font-weight: 600 !important;
                                font-size: 16px !important;
                                line-height: 20px !important;
                                color: #000000 !important;
                                margin: 0;
                                letter-spacing: 0em;
                                margin-bottom: 0.35em;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                            }
                            .content-desc {
                                font-size: 14px !important;
                                font-weight: 400 !important;
                                line-height: 24px !important;
                                font-family: "Inter" !important;
                                color: #333333 !important;
                            }
                            .primary-btn {
                                background-color: #660c60 !important;
                                color: #ffffff !important;
                                border: 1px solid #660c60;
                                border-radius: 3px !important;
                                text-transform: capitalize !important;
                                box-shadow: none !important;
                                padding: 10px 15px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 15px;
                                letter-spacing: 0px;
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                justify-content: center;
                                font-family: "Inter";
                            }
                            .svg-inline--fa {
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .author-avtar-creator img {
                                height: 32px;
                                width: 32px;
                                text-align: center;
                                object-fit: cover;
                                color: transparent;
                                text-indent: 10000px;
                            }
                            .content-title-update {
                                font-size: 16px !important;
                                font-weight: 600 !important;
                                text-align: start;
                                color: #333333 !important;
                                line-height: 20px !important;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                            }
                            .search-input {
                                border: 1px solid #eeeeee;
                                border-radius: 100px !important;
                                background-color: #f9f9f9;
                                height: auto;
                                padding: 10px 54px 10px 15px;
                            }
                            .serach-box .search-btn-dash-2 {
                                position: absolute;
                                margin: 5px !important;
                                bottom: 0px;
                                right: 0px;
                                background-color: #660c60 !important;
                                border-color: #f9f9f9;
                                width: 38px !important;
                                height: 38px;
                                border-radius: 100px !important;
                                position: absolute !important;
                                right: 0;
                                margin: 0px 4px 0px 4px;
                                top: -2px;
                            }
                            .p-3{
                                padding: 1rem!important;
                            }
                            .update-post-grid-container{
                                width: calc(100% + 16px);
                                margin-left: -16px;
                                box-sizing: border-box;
                                display: -ms-flexbox;
                                display: flex;
                                -ms-flex-wrap: wrap;
                                flex-wrap: wrap;
                                -ms-flex-direction: row;
                                flex-direction: row;
                            }
                             @media(min-width: 600px) {
                                .update-post-grid-item{
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                            }
                            @media(min-width: 900px) {
                                .update-post-grid-container{
                                    width: calc(100% + 24px);
                                    margin-left: -24px;
                                }
                                .update-post-grid-item{
                                    padding-left: 15px;
                                    padding-top: 15px;
                                    -ms-flex-preferred-size: 25%;
                                    flex-basis: 25%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 25%;
                                }
                            }
                            @media(min-width: 1200px) {
                                .update-post-grid-item{
                                    -ms-flex-preferred-size: 25%;
                                    flex-basis: 25%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 25%;
                                }
                            }
                            @media(min-width: 1536px) {
                                .update-post-grid-item{
                                    -ms-flex-preferred-size: 25%;
                                    flex-basis: 25%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 25%;
                                }
                            }
                            .update-type-post-card {
                                height: 154px;
                                padding: 30px;
                                margin: 0 0 46px 0;
                                border: 1px solid #eeeeee;
                                border-radius: 6px;
                                box-shadow: none;
                                background-color: #ffffff;
                            }
                            .update-type-post-card-content{
                                padding: 0 !important;
                            }
                            .update-avtar-creator {
                                height: 40px !important;
                                width: 40px !important;
                                position: relative;
                                display: flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                font-size: 1.25rem;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                            }
                            .search-input {
                                border: 1px solid #eeeeee;
                                border-radius: 6px !important;
                                background-color: #f9f9f9;
                                height: auto;
                                padding: 10px 54px 10px 15px;
                                display: block;
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: var(--bs-body-color);
                                appearance: none;
                                background-clip: padding-box;
                                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                            }
                           .post-date-label {
                                font-size: 12px !important; 
                                font-weight: 500 !important;
                                color: #959595 !important;
                            }
                            .edit-post-icon-btn {
                                display: inline-flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                                position: relative;
                                box-sizing: border-box;
                                -webkit-tap-highlight-color: transparent;
                                background-color: transparent;
                                outline: 0;
                                border: 0;
                                margin: 0;
                                cursor: pointer;
                                user-select: none;
                                vertical-align: middle;
                                -moz-appearance: none;
                                -webkit-appearance: none;
                                -webkit-text-decoration: none;
                                text-decoration: none;
                                text-align: center;
                                border-radius: 50%;
                                overflow: visible;
                                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                color: #333333 !important;
                                font-size: 12px !important;
                                font-weight: 400 !important;
                                padding: 0 8px 0 8px !important;
                            }
                            .edit-post-icon-btn svg{
                                user-select: none;
                                width: 1em;
                                height: 1em;
                                display: inline-block;
                                fill: currentColor;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                font-size: 1.5rem;
                            }
                            .content-div-body-2 {
                                padding: 15px 15px 15px 15px !important;
                                width: 100% !important;
                                background-color: #fff !important;
                            }
                            .update-content-img{
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                height: 222px;
                            }
                            .author-avtar-creator-post {
                                height: 24px !important;
                                width: 24px !important;
                                font-size: 14px !important;
                                position: relative;
                                display: flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                                flex-shrink: 0;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                            }
                            .feed-update-post-user-img{
                                width: 100%;
                                height: 100%;
                                text-align: center;
                                object-fit: cover;
                                color: transparent;
                                text-indent: 10000px;
                            }
                            .feed-card-header-avatar{
                                display: flex;
                                flex: 0 0 auto;
                                margin-right: 16px;
                            }
                            .feed-card-header-content{
                                flex: 1 1 auto;
                            }
                            .feed-card-box-1{
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                -webkit-box-align: center;
                                align-items: center;
                            }
                            .feed-card-box-2{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .post-posted-label{
                                font-size: 12px !important;
                            }
                            .update-multi-grid-container{
                                box-sizing: border-box;
                                display: flex;
                                flex-wrap: wrap;
                                flex-direction: row;
                            }
                            @media(min-width: 900px){
                                .update-multi-grid-container{
                                    width: calc(100% + 24px) !important;
                                    margin-left: -24px !important;
                                }
                            }
                             @media(min-width: 0px){
                                .update-multi-grid-container{
                                    width: calc(100% + 16px) !important;
                                    margin-left: -16px !important;
                                }
                            }
                            .card-content-box {
                                padding: 20px;
                            }
                            .post-img{
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                height: 222px;
                                width: 30% !important;
                            }
                            .insight-img-box {
                                width: 80px;
                                height: 80px;
                            }
                            .insight-content-name {
                                font-size: 12px !important;
                                font-weight: 700 !important;
                                line-height: 14.52px !important;
                                color: #660C60 !important;
                            }
                            .insight-content-user-name {
                                font-size: 16px !important;
                                font-weight: 600 !important;
                                line-height: 20px !important;
                                color: #333333 !important;
                            }
                            //menu block
                            .custom-menu {
                                padding: 16px;
                                background-color: #f9f9f9;
                                border: 1px solid #ddd;
                                border-radius: 8px;
                                max-width: 320px;
                            }
                            .custom-menu-list {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                            }
                            .custom-menu-item {
                                margin-bottom: 8px;
                            }
                            .custom-menu-link {
                                text-decoration: none;
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                color: #333;
                                font-size: 16px;
                            }
                            .custom-menu-icon {
                                font-size: 18px;
                                color: #660C60;
                                height: 18px;
                            }
                            .custom-menu-name {
                                font-weight: 400;
                            }
                            .MuiPaper-root {
                                width: auto;
                                transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                                overflow-x: hidden;
                                height: 100vh;
                                padding-top: 125px;
                                padding-bottom: 30px;
                                padding: 20px;
                                background-color: #fff;
                                color: rgba(0, 0, 0, 0.87);
                                box-shadow: none;
                                overflow-y: auto;
                                display: flex;
                                flex-direction: column;
                                position: absolute;
                                top: 0;
                                outline: 0;
                                left: 0;
                                border: 1px solid rgba(0, 0, 0, 0.12);
                                white-space: nowrap;
                            }
                            .MuiList-root {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                width: 100%;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                white-space: nowrap;
                            }
                            .MuiList-root-vertical {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                gap: 0;
                                width: 100%;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                white-space: nowrap;
                            }
                            .MuiList-root-horizontal {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                position: relative;
                                display: flex;
                                flex-direction: row;
                                gap: 10px;
                                width: 100%;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                white-space: nowrap;
                            }
                            .MuiListItem-root {
                                -webkit-box-pack: start;
                                justify-content: flex-start;
                                -webkit-box-align: center;
                                align-items: center;
                                position: relative;
                                text-decoration: none;
                                width: 100%;
                                box-sizing: border-box;
                                text-align: left;
                                display: block;
                                padding-top: 8px;
                                padding-bottom: 8px;
                                padding-left: 16px;
                                padding-right: 16px;
                            }
                            .MuiButtonBase-root {
                                -webkit-tap-highlight-color: transparent;
                                background-color: transparent;
                                outline: 0;
                                border: 0;
                                margin: 0;
                                border-radius: 0;
                                padding: 0;
                                cursor: pointer;
                                user-select: none;
                                vertical-align: middle;
                                -webkit-text-decoration: none;
                                color: inherit;
                                display: flex;
                                -webkit-box-flex: 1;
                                align-items: center;
                                position: relative;
                                text-decoration: none;
                                min-width: 0;
                                box-sizing: border-box;
                                text-align: left;
                                padding-top: 8px;
                                padding-bottom: 8px;
                                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                min-height: 48px;
                                -webkit-box-pack: initial;
                                justify-content: space-between;
                                align-items: center;
                                padding-left: 20px;
                                padding-right: 20px;
                            }
                            .MuiListItemIcon-root {
                                color: rgba(0, 0, 0, 0.54);
                                flex-shrink: 0;
                                display: inline-flex;
                                min-width: 0;
                                margin-right: 16px;
                                -webkit-box-pack: center;
                                justify-content: center;
                            }
                            .MuiListItemIcon-root svg {
                                overflow: visible;
                                box-sizing: content-box;
                                font-size: 15px;
                                color: #959595;
                                font-weight: 400;
                                line-height: 15px;
                            }
                            .svg-inline--fa {
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .menu-icon {
                                font-size: 15px;
                                color: #959595;
                                font-weight: 400;
                                line-height: 15px;
                            }
                            .MuiListItemText-root {
                                flex: 1 1 auto;
                                min-width: 0;
                                margin-top: 4px;
                                margin-bottom: 4px;
                                opacity: 1;
                            }
                            .MuiTypography-root {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 15px;
                                opacity: 50%;
                            }
                            .menu-name span {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 15px;
                                opacity: 50%;
                            }
                            .selected-menu-item .menu-name span {
                                color: white !important;
                                opacity: inherit;
                            }
                            .selected-menu-item {
                                background-color: #660C60 !important;
                                border-radius: 6px;
                                color: white !important;
                            }
                            .selected-menu-item .menu-icon {
                                color: white !important;
                            }
                            .vertical-menu {
                                display: flex;
                                flex-direction: column;
                            }
                            .horizontal-menu {
                                display: flex;
                                flex-direction: row;
                                gap: 20px;
                            }
                            .horizontal-item {
                                display: inline-block;
                            }
                            .list-item-box {
                                height: 540px !important;
                                overflow-y: auto;
                            }
                            .menu-divider{
                                margin: 0;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                border-width: 0;
                                border-style: solid;
                                border-color: rgba(0, 0, 0, 0.12);
                                border-bottom-width: thin;
                            }
                            .content-btn {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 1rem!important;
                                margin-top: 1rem!important;
                                width: 100%!important;
                            }
                            .menu-create-content-box{
                                width: 100%;
                            }
                            .create-content-btn {
                                background-color: #660C60 !important;
                                color: white !important;
                                border-color: white !important;
                                border-radius: 3px !important;
                                text-transform: capitalize !important;
                                box-shadow: none !important;
                                padding: 10px 15px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 15px;
                                letter-spacing: 0px;
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                justify-content: center;
                                font-family: "Inter";
                                width: 100%;
                            }
                            .menu-user-info-div{
                                margin-bottom: 1rem !important;
                                margin-top: 1rem !important;
                                display: flex !important;
                                justify-content: space-between !important;
                                align-items: center !important;
                            }
                            .menu-user-name-avatar-box{
                                display: flex !important;
                                align-items: center !important;
                                gap: .5rem !important;
                            }
                            .author-avtar {
                                height: 32px !important;
                                width: 32px !important;
                                position: relative;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                                font-size: 14px;
                            }
                            .logged-user-name {
                                font-size: 14px !important;
                                font-weight: 500 !important;
                                line-height: 18px !important;
                                margin-bottom: 0 !important;
                                color: #333333 !important;
                                opacity: unset !important;
                            }
                            .setting-btn {
                                width: auto !important;
                                background-color: #fff !important;
                                color: #959595 !important;
                                border-color: white !important;
                                border-radius: 3px !important;
                                text-transform: capitalize !important;
                                box-shadow: none !important;
                                padding: 10px 15px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 15px;
                                letter-spacing: 0px;
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                justify-content: center;
                                font-family: "Inter";
                            }
                            .setting-icon {
                                color: #959595 !important;
                            }
                            //link block
                            .link-list-container {
                                box-sizing: border-box;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-flex-wrap: wrap;
                                -ms-flex-wrap: wrap;
                                flex-wrap: wrap;
                                -ms-flex-direction: row;
                                flex-direction: row;
                                width: calc(100% + 16px);
                                margin-left: -16px;
                            }
                            .link-list-item-grid {
                                padding-left: 16px;
                                padding-top: 16px;
                                box-sizing: border-box;
                                margin: 0;
                                -ms-flex-direction: row;
                                flex-direction: row;
                                -ms-flex-preferred-size: 100%;
                                flex-basis: 100%;
                                -webkit-box-flex: 0;
                                -ms-flex-positive: 0;
                                flex-grow: 0;
                                max-width: 100%;

                                @media(min-width: 1536px) {
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                                @media(min-width: 1200px) {
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                                @media(min-width: 900px) {
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                                @media(min-width: 600px) {
                                    -ms-flex-preferred-size: 50%;
                                    flex-basis: 50%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 50%;
                                }
                            }
                            .link-icon-box {
                                cursor: pointer;
                                position: relative;
                                overflow: hidden;
                                display: flex;
                                gap: 12px;
                                border-radius: 6px;
                                padding: 15px;
                                padding-top: 1rem !important;
                                padding-bottom: 1rem !important;
                            }
                            .icon-content {
                                display: flex;
                                gap: 12px;
                                overflow: hidden;
                            }
                            .icon-box {
                                display: block;
                                box-sizing: border-box;
                            }
                            .icon-value {
                                overflow: visible;
                                box-sizing: content-box;
                                display: inline-block;
                                height: 1em;
                                vertical-align: -.125em;
                            }
                            .link-label-text {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                height: 22px;
                            }
                            .link-label-box {
                                margin: 0;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1.5;
                                letter-spacing: 0.00938em;
                            }
                            
                        `;
                        iframeDocument.head.appendChild(style);
                    }
                }
            },
        },
    });
}

export default FeedBlock