import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedGroups: [],
  selectedCategory: [],
  isArchive: false,
  sortPeople: {
    name: null,
    job_title: null,
    email: null,
    phone: null,
  },
  sortType: {
    name: null,
    updated_at: null,
    created_by: null,
  },
  peoplePagination: {
    page: 1,
    per_page: 12,
    meta_data: null
  },
  selectedShowValue: "Lists",
  filterToggle: true,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setSelectedGroups(state, action) {
      state.selectedGroups = action.payload;
    },
    setSelectedCategory(state, action) {
      state.selectedCategory = action.payload;
    },
    setIsArchive(state, action) {
      state.isArchive = action.payload;
    },
    setSortPeople(state, action) {
      state.sortPeople = action.payload;
    },
    setPeoplePagination(state, action) {
      state.peoplePagination = action.payload;
    },
    setSortType(state, action) {
      state.sortType = action.payload;
    },
    setSelectedShowValue(state, action) {
      state.selectedShowValue = action?.payload
    },
    setFilterToggle(state, action) {
      state.filterToggle = action?.payload
    }
  },
});

export const filterSliceAction =
  filterSlice.actions;

export default filterSlice.reducer;
