import React from "react";
import { Menu, MenuItem } from "@mui/material";
import "./ActionMenu.scss";

function ActionMenu({
  anchorEl,
  open,
  onClose,
  menuItems,
  anchorOrigin = { vertical: "bottom", horizontal: "right" },
  transformOrigin = { vertical: "top", horizontal: "right" },
  topBarOffset = 10,
  regularOffset = 10,
  isInTopBar = false,
  className = "",
}) {
  // Adjust offsets dynamically based on position
  const dynamicAnchorOrigin = isInTopBar
    ? { vertical: "bottom", horizontal: "right" } // Popover opens 10px below the top bar
    : { vertical: "bottom", horizontal: "right" };

  const dynamicTransformOrigin = isInTopBar
    ? { vertical: -topBarOffset, horizontal: "right" } // Offset from the bottom of the top bar
    : { vertical: -regularOffset, horizontal: "right" }; // Regular offset from target element

  return (
    <Menu
      className={`action-menu ${className}`}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        "aria-labelledby": "action-menu-button",
      }}
      anchorOrigin={dynamicAnchorOrigin}
      transformOrigin={dynamicTransformOrigin}
    >
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          className={`action-menu-item ${item?.className || ""}`}
          onClick={item?.onClick}
          disabled={item?.disabled || false}
        >
          {item?.icon && <span className={`menu-item-icon ${item?.iconClassName || ""}`}>{item?.icon}</span>}
          <span className={`menu-item-label ${item?.labelClassName || ""}`}>{item?.label}</span>
        </MenuItem>
      ))}
    </Menu>
  );
}

export default ActionMenu;
