import api from "./commonFunctionForApi"
import { Toast } from "../../utils/toast"

const CheckDomain = async (navigate) => {
    let domainName = `${window?.location?.hostname}`
    let redirect_domainName = `${window?.location?.protocol}//${window?.location?.hostname}`;
    const data = {
        domain: domainName,
    }
    console.log("domainName::", domainName)
    try {
        const response = await api.post(
            `domain-check`,
            data,
            // {
            //     headers: {
            //         'Referrer-Policy': 'no-referrer',
            //         'Referer': domainName
            //     }
            // }
        );
        console.log("response for deomain::", response)

        if (response?.success == false) {
            console.log("dta not foo")
            Toast.error(response?.message)
            // navigate("/sign-in")
            // window.location.href = "https://www.google.com";
        } else if (response?.success == false) {
            navigate("/")
        }

    } catch (err) {
        console.log("err`or in fetch news api::", err);
        if (err?.response?.status == 429) {
            Toast.error("Too many attemps wait for 2 min.")
        }
    }
}

export default CheckDomain
