import React from 'react'
import { Stack } from "react-bootstrap";
import { Avatar, Box, CardActions, CardMedia, IconButton, Tooltip, Typography } from "@mui/material";
import {
  Card,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, } from '@fortawesome/free-solid-svg-icons';
import ReactionGroup from '../../ui/ReactionGroup';
import { onlyDateFormat } from '../../../helpers/DateTimeFormat/dateTimeFormat';

function TypeCard(props) {
  const { post, openMoreTag, handleClickActionIcon, handlePostRedirectView } = props;

  return (
    <>
      <Card className="type-card-wrapper" sx={{ maxWidth: 420 }}>
        <Box position="relative">
          <CardMedia
            src={post?.image}
            className="type-card-image"
            component="img"
            alt={post?.title}
            onClick={() => {
              handlePostRedirectView(post);
            }}
          />

          <IconButton className='type-card-action-btn' aria-label="settings"
            onClick={(e) => handleClickActionIcon(e, post)}>
            <MoreVertIcon />
          </IconButton>
        </Box>
        <Box className="d-flex flex-column justify-content-between">
          <CardContent className="type-card-content">
            <Stack direction="vertical" className="">
              {/* Created by detail section */}
              <Stack
                direction="horizontal"
              >
                <Avatar
                  alt={post?.created_by?.name}
                  className="type-card-content-avatar"
                  src={post?.created_by?.thumbnail}

                >
                  {post?.created_by[0]}
                </Avatar>
                <Typography className="type-card-username-text" variant="h5" component="div">{`${post?.created_by?.name} ${post?.created_by?.last_name}`}</Typography>
                <Typography className="fs-12-muted type-card-username-text" variant="h5" component="div">•</Typography>
                <Typography className="fs-12-muted type-card-username-text" variant="h5" component="div">{post?.created_at ? onlyDateFormat(post?.created_at, false, false, true) : post?.created_at_format ?? ''}</Typography>
              </Stack>
              <Stack
                direction="horizontal"
              >
                <Tooltip title={post?.title?.length > 50 ? post?.title : ''} placement="top">
                  <Typography className="type-card-title-text" variant="h4" component="div">
                    {post?.title?.length > 50 ? post?.title.substr(0, 50) + '...' : post?.title}
                  </Typography>
                </Tooltip>
              </Stack>
            </Stack>

          </CardContent>
          <CardActions>
            <Stack direction='vertical'>
              {/* Tags */}
              <Stack direction='horizontal' className={`${post?.tags?.length > 0 ? '' : 'invisible'} type-card-tag-stack`}>
                <FontAwesomeIcon className='fs-12-muted type-card-username-text' icon={faBriefcase} />
                <Typography className="fs-12-muted type-card-username-text" variant="h5" component="div">{post?.tags[0]}</Typography>
                {post?.tags?.length > 1 && (
                  <span className="type-card-more-tag-badge cursor-pointer"
                    onClick={(event) => openMoreTag(event, post?.tags)}
                  >
                    +{(post?.tags?.length - 1)} Others
                  </span>
                )}
              </Stack>
              {/* Like and comment stack */}
              <Stack direction='horizontal' className='justify-content-between type-card-like-comment-stack'>
                <Box className="d-flex gap-1 align-items-center">
                  <ReactionGroup reactions={post?.reaction} />
                  <Box className="type-card-like-count">{post?.reaction_count}</Box>
                </Box>
                <Typography className="fs-12-muted type-card-username-text" variant="h5" component="div">{post?.comments_count} Comments</Typography>

              </Stack>
            </Stack>
          </CardActions>
        </Box>
      </Card>

    </>
  )
}

export default TypeCard