import { Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import AddInsight from "../pages/insight/addInsight";
import InsightList from "../pages/insight";

const insightRoutes = [
    {
        path: "/add-insight",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <AddInsight />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/insight",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <InsightList />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
    {
        path: "/edit-insight/:id",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <AddInsight />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
]

export default insightRoutes;