import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Image } from "react-bootstrap";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faHome, faHouse, faPlus } from "@fortawesome/free-solid-svg-icons";
import "../../styles/types.scss";
import { useNavigate } from "react-router-dom";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toast } from "../../utils/toast";
import {
  faList,
  faPlay,
  faPause,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Toaster } from "react-hot-toast";
import { columns_menu_header } from "../../helpers/theaderColumns/menu/menu";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { menuTabAction } from "../../redux/slices/menu/menu";
import StatusIcon from "../../components/common/StatusIcon";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const MenusList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const openMenuFoEdit = Boolean(anchorElMenu);
  const [loading, setLoading] = useState(false);
  const [getAllTypes, setGetAllTypes] = useState([]);
  const [getAllMenus, setGetAllMenus] = useState([])
  const { selectedTypeTab } = useSelector((state) => state?.typeTab);
  const { selectedMenuTab } = useSelector((state) => state?.menuTab);

  const [value, setValue] = useState(selectedMenuTab);
  const [menuId, setmenuId] = useState();
  const [dataOrder, setDataOrder] = useState("asc");

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const userToken = localStorage.getItem("accessToken");

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  const ITEM_HEIGHT = 48;

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setmenuId(row);
  };
  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleChange = (event, newValue) => {
    // dispatch(typeTabAction.handleSelectedTab(newValue));
    dispatch(menuTabAction.handleSelectedTab(newValue))
    setValue(newValue);
    let value =
      newValue === 0
        ? "all"
        : newValue === 1
          ? "system"
          : newValue === 2
            ? "enable"
            : "disable";
    // fetchAllTypes(value, dataOrder);
    fetchAllMenus(value, dataOrder, 10)

  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);
    fetchAllMenus(value === 0 ? "all" : value === 1 ? "system" : value === 2 ? "enable" : "disable", order, 10)
  };

  const fetchAllTypes = async (type, order) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/menu/${type}${order ? `?order=${order}` : ""}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setGetAllTypes(response?.data);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch menu api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const fetchAllMenus = async (type, order, perPage) => {
    const data = {
      order: order,
      per_page: perPage
    }
    try {
      setLoading(true);
      const response = await api.post(
        `admin/menu/${type}`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      setGetAllMenus(response?.data);
      setLoading(false);
    } catch (err) {
      console.log("err`or in fetch menu api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  }

  const changeMenuStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/menu/change/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
        setmenuId();
        fetchAllMenus(value === 0 ? "all" : value === 1 ? "system" : value === 2 ? "enable" : "disable", dataOrder, 10)
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const RestoreType = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(
        `admin/type/master/restore `,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
        fetchAllTypes(
          value == 0
            ? "enable"
            : value == 1
              ? "disable"
              : value == 3
                ? "deleted"
                : "system",
          dataOrder
        );
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const deleteTypeAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/type/master/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);

        fetchAllTypes(
          value == 0
            ? "enable"
            : value == 1
              ? "disable"
              : value == 3
                ? "deleted"
                : "system",
          dataOrder
        );
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteTypeAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(
        `admin/type/master/forcedelete `,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);

        fetchAllTypes(
          value == 0
            ? "enable"
            : value == 1
              ? "disable"
              : value == 3
                ? "deleted"
                : "system",
          dataOrder
        );
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.");
      }
      setLoading(false);
    }
  };

  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps4(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps5(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    fetchAllMenus(selectedMenuTab === 0 ? "all" : selectedMenuTab === 1 ? "system" : selectedMenuTab === 2 ? "enable" : "disable", dataOrder, 10)
    setValue(selectedMenuTab);
  }, []);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"
            }`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}
          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={
                    <NavigateNextIcon fontSize="small" />
                  }
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography
                    key="3"
                    component="h3"
                    color="text.primary"
                  >
                    Menus
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <Button
              className="btn primary-btn"
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/menu/add-new`
                );

                navigate("/menu/add-new");
              }}
            >
              Add New
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Divider
            className="mt-4"
            style={{ background: "#EEEEEE" }}
          />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                // variant={isTablet && "scrollable"}
                variant="scrollable"
                className={"tabs"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >

                <Tab
                  label="All Menus"
                  icon={<FontAwesomeIcon icon={faHome} />}
                  iconPosition="start"
                  {...a11yProps2(0)}
                />
                <Tab
                  label="System Menus"
                  icon={<FontAwesomeIcon icon={faGears} />}
                  iconPosition="start"
                  {...a11yProps3(1)}
                />
                <Tab
                  label="Active Menus"
                  {...a11yProps4(2)}
                  icon={<FontAwesomeIcon icon={faPlay} />}
                  iconPosition="start"
                />

                <Tab
                  label="Inactive Menus"
                  {...a11yProps5(3)}
                  icon={<FontAwesomeIcon icon={faPause} />}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper
                sx={{ width: "100%" }}
                className="type-list-paper"
              >
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    {getAllMenus?.length > 0 && (
                      <>
                        <TableHeaderForSorting
                          columns={
                            columns_menu_header
                          }
                          handleChangeDataOrdr={
                            handleChangeDataOrdr
                          }
                          dataOrder={dataOrder}
                        />
                        <TableBody>
                          {getAllMenus?.map(
                            (row, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index + 1}
                                className="cursor-pointer"
                              >
                                <TableCell
                                  align="left"
                                  className={`tbl-body-cell tbl-border-left ${row?.status ===
                                    "deleted" &&
                                    "text-muted"
                                    }`}
                                >
                                  <div
                                    className="d-flex flex-warp gap-3"
                                    style={{
                                      fontSize:
                                        "14px",
                                    }}
                                  >
                                    <p
                                      className={`${row?.status ===
                                        "deleted" &&
                                        "text-muted fw-700"
                                        }`}
                                    >
                                      {
                                        row?.title
                                      }
                                    </p>
                                    {row?.system_type ==
                                      1 && (
                                        <Chip
                                          label="System Type"
                                          className="chip-label-div"
                                        />
                                      )}
                                  </div>
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className="tbl-body-cell-3 "
                                >
                                  {
                                    row?.date_created
                                  }
                                </TableCell>

                                <TableCell
                                  align="left"
                                  className="tbl-body-cell"
                                >
                                  <Box>
                                    <Stack
                                      direction="row"
                                      alignContent="center"
                                      alignItems="center"
                                      spacing={
                                        1
                                      }
                                      className="z-n1"
                                    >
                                      {row
                                        ?.author
                                        ?.thumbnail !==
                                        "" ? (
                                        <Avatar
                                          alt="Admin"
                                          src={
                                            row
                                              ?.author
                                              ?.thumbnail
                                          }
                                          className="author-avtar-creator"
                                        />
                                      ) : (
                                        <Avatar
                                          alt="Admin"
                                          src="/static/images/avatar/1.jpg"
                                          className="author-avtar-creator"
                                        >
                                          {row?.author?.name?.charAt(
                                            0
                                          )}
                                        </Avatar>
                                      )}

                                      <Typography className="fw-400 fs-14">
                                        {
                                          row
                                            ?.author
                                            ?.name
                                        }
                                      </Typography>
                                    </Stack>
                                  </Box>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  className="tbl-body-cell-2 tbl-border-right"
                                >
                                  <div>
                                    <Stack
                                      direction="row"
                                      alignContent="center"
                                      alignItems="center"
                                      spacing={
                                        1
                                      }
                                      className="gap-1"
                                    >
                                      <StatusIcon status={row?.status} />

                                      <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={
                                          openMenuFoEdit
                                            ? "long-menu"
                                            : undefined
                                        }
                                        aria-expanded={
                                          openMenuFoEdit
                                            ? "true"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                        onClick={(
                                          e
                                        ) =>
                                          handleClick(
                                            e,
                                            row
                                          )
                                        }
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    </Stack>
                                  </div>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
              {getAllMenus?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {menuId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/menu/edit/${menuId?.id}`
                );

                navigate(`/menu/edit/${menuId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {menuId?.status !== "enable" &&
            menuId?.status !== "deleted" && (
              <MenuItem
                onClick={() =>
                  changeMenuStatus(menuId?.id, "enable")
                }
              >
                Active
              </MenuItem>
            )}
          {menuId?.status !== "disable" &&
            menuId?.status !== "deleted" && (
              <MenuItem
                onClick={() =>
                  changeMenuStatus(menuId?.id, "disable")
                }
              >
                Inactive
              </MenuItem>
            )}
        </Menu>
      </div>

      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default MenusList;
