import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Stack,
    Breadcrumbs,
    Link,
    Divider,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    Avatar,
    TableBody,
    Menu,
    MenuItem,
    Tabs,
    Tab,
    Button,
    IconButton,
    Typography,
    Chip,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemIcon,
    CircularProgress,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFloppyDisk,
    faList,
    faPause,
    faPlay,
    faPlus,
    faTrash,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { columns_user_header } from "../../helpers/theaderColumns/users/users";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Image } from "react-bootstrap";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import { userTabAction } from "../../redux/slices/users/users";
import { CSVLink } from "react-csv";
import StatusIcon from "../../components/common/StatusIcon";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { exportXlsx } from "../../helpers/Exports/FileExport";
import { columns_pages_header } from "../../helpers/theaderColumns/pages/pages";
import { pageAction } from "../../redux/slices/page/page";

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="tab-box">{children}</Box>}
        </div>
    );
}

const UserPageList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { selectedPageTypeTab } = useSelector((state) => state?.page);

    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [value, setValue] = useState(selectedPageTypeTab);
    const [dataOrder, setDataOrder] = useState("asc");
    const [loading, setLoading] = useState(false);
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);

    const [allPages, setAllPages] = useState([])

    const ITEM_HEIGHT = 48;

    const [categoryId, setCategoryId] = useState();

    const [anchorElMenu, setAnchorElForMenu] = useState(null);

    const openMenuFoEdit = Boolean(anchorElMenu);

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");

    //states for paginations
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [nextPage, setNextPage] = useState(false);
    const [isDataFetching, setIsDataFetching] = useState(false);

    const handleClick = (event, row) => {
        setAnchorElForMenu(event.currentTarget);
        setCategoryId(row);
    };

    const handleCloseWarningMessage = () => {
        setIsWarningMessage(false);

        setWarningMessage("");
    };

    const handleCloseSuccessMessage = () => {
        setIsSuccessMessage(false);
        setIsSuccessMessage("");
    };

    const handleClose = () => {
        setAnchorElForMenu(null);
    };

    const handleCloseDeleteAlertModel = () => {
        setOpenDeleteAlert(false);
    };

    const handleCloseConfirmDeleteAlertModel = () => {
        setOpenConfirmDeleteAlert(false);
    };

    const handleChangeDataOrdr = () => {
        let order = dataOrder === "asc" ? "desc" : "asc";
        setDataOrder(order);

        setPage(1);
        setAllPages([]);

        fetchAllPages(
            value == 0
                ? "all"
                : value == 1
                    ? "enable"
                    : value == 2
                        ? "disable"
                        : "deleted",
            order, 1
        );
    };

    const handleChange = (event, newValue) => {
        dispatch(pageAction.handleSelectedTab(newValue));
        setValue(newValue);
        let value =
            newValue == 0
                ? "all"
                : newValue == 1
                    ? "enable"
                    : newValue == 2
                        ? "disable"
                        : "deleted";
        setPage(1);
        setAllPages([]);
        fetchAllPages(value, dataOrder, 1);
    };

    const {
        open,
        selectedItem,
        nameCollapsable,
        drawerVariant,
        typeCollapsable,
        usersCollapsable,
    } = useSelector((state) => state?.drawerMenu);

    function a11yProps1(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps2(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps3(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps4(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                value == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    const fetchAllPages = async (type, order, page) => {
        try {
            if (page == 1) {
                setLoading(true);
            }
            const userToken = localStorage.getItem("accessToken");
            const response = await api.get(
                `user/page/page-list/${type}${order ? `?order=${order}` : ""}&page=${page ?? 1}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const newPages = response?.response?.data;
            // setAllUsers(response?.response);
            setAllPages((prevPosts) => {
                const uniquePosts = newPages.filter(
                    (newPost) =>
                        !prevPosts.some((post) => post.id === newPost.id)
                );
                return [...prevPosts, ...uniquePosts];
            });

            setTotalPages(response?.response?.meta.last_page);
            if (page + 1 <= response?.response?.meta.last_page) {
                setNextPage(true);
            }
        } catch (err) {
            console.log("error in fetch posts api::", err);
            if (err?.response?.status === 401) {
                localStorage.setItem("last-redirect-page", `/users`);
                logoutHelper(dispatch, navigate, userToken);
            }

            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        } finally {
            setIsDataFetching(false); // Reset fetching state after the API call
            setLoading(false);
        }
    };

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    const handleScroll = async () => {
        const scrollableHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.innerHeight + window.scrollY;
        const remainingScrollThreshold = scrollableHeight * 0.3;

        if (
            scrollableHeight - scrollPosition < remainingScrollThreshold &&
            !isDataFetching &&
            page < totalPages
        ) {
            setIsDataFetching(true);
            const nextPageNum = page + 1;
            setPage(nextPageNum);
            await fetchAllPages(value == 0
                ? "all"
                : value == 1
                    ? "enable"
                    : value == 2
                        ? "disable"
                        : "deleted", dataOrder, nextPageNum);
        }
    };

    // const changePageStatus = async (id, status) => {
    //     try {
    //         let data = {
    //             status: status,
    //         };
    //         setLoading(true);
    //         const response = await api.post(`admin/page/change-status-page/${id} `, data, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${userToken}`,
    //             },
    //         });
    //         if (response?.success == true) {
    //             if (value === 0) {
    //                 setAllPages((prevUsers) =>
    //                     prevUsers.map((user) =>
    //                         user.id === id ? { ...user, status: status } : user
    //                     )
    //                 );
    //             } else {
    //                 setAllPages((prevUsers) =>
    //                     prevUsers.filter((user) => user.id !== id)
    //                 );
    //             }
    //             // fetchAllPages(
    //             //     value == 0
    //             //         ? "all"
    //             //         : value == 1
    //             //             ? "enable"
    //             //             : value == 2
    //             //                 ? "disable"
    //             //                 : "deleted",
    //             //     dataOrder, 1
    //             // );
    //             setLoading(false);
    //             setIsSuccessMessage(true);
    //             setSuccessMessage(response?.message);
    //             Toast.success(response?.message);
    //             setLoading(false);
    //             setAnchorElForMenu(null);
    //         } else if (response?.success == false) {
    //             setIsWarningMessage(true);
    //             setWarningMessage(response?.message);
    //             Toast.warning(response?.message);
    //             setLoading(false);
    //             setAnchorElForMenu(null);
    //         }
    //     } catch (err) {
    //         if (err?.response?.status == 401) {
    //             setLoading(false);
    //             logoutHelper(dispatch, navigate, userToken);
    //         }
    //         if (err?.response?.status == 429) {
    //             Toast.error("Too many attemps wait for 2 min.")
    //         }
    //         setLoading(false);
    //     }
    // };

    // const RestorePage = async (id) => {
    //     try {
    //         let data = {
    //             id: id,
    //         };
    //         setLoading(true);
    //         const response = await api.get(`admin/page/restore-page/${id} `, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${userToken}`,
    //             },
    //         });
    //         if (response?.success == true) {
    //             if (value === 0) {
    //                 setAllPages((prevPosts) =>
    //                     prevPosts.map((post) =>
    //                         post.id === id ? { ...post, status: response?.response?.status } : post
    //                     )
    //                 );
    //             } else {
    //                 setAllPages((prevUsers) =>
    //                     prevUsers.filter((user) => user.id !== id)
    //                 );
    //             }
    //             // fetchAllPages(
    //             //     value == 0
    //             //         ? "all"
    //             //         : value == 1
    //             //             ? "enable"
    //             //             : value == 2
    //             //                 ? "disable"
    //             //                 : "deleted",
    //             //     dataOrder, 1
    //             // );
    //             setLoading(false);
    //             setIsSuccessMessage(true);
    //             setSuccessMessage(response?.message);
    //             Toast.success(response?.message);
    //             setLoading(false);
    //             setAnchorElForMenu(null);
    //         } else if (response?.success == false) {
    //             setIsWarningMessage(true);
    //             setWarningMessage(response?.message);
    //             Toast.warning(response?.message);
    //             setLoading(false);
    //             handleClose();
    //         }
    //     } catch (err) {
    //         if (err?.response?.status == 401) {
    //             setLoading(false);
    //             logoutHelper(dispatch, navigate, userToken);
    //         }
    //         if (err?.response?.status == 429) {
    //             Toast.error("Too many attemps wait for 2 min.")
    //         }
    //         setLoading(false);
    //     }
    // };

    // const deletePageAPI = async (id) => {
    //     try {
    //         let data = {
    //             id: id,
    //         };
    //         setLoading(true);
    //         const response = await api.delete(`admin/page/delete-page/${id} `, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${userToken}`,
    //             },
    //         });
    //         if (response?.success == true) {
    //             if (value === 0) {
    //                 setAllPages((prevUsers) =>
    //                     prevUsers.map((user) =>
    //                         user.id === id ? { ...user, status: 'deleted' } : user
    //                     )
    //                 );
    //             } else {
    //                 setAllPages((prevUsers) =>
    //                     prevUsers.filter((user) => user.id !== id)
    //                 );
    //             }
    //             // fetchAllPages(
    //             //     value == 0
    //             //         ? "all"
    //             //         : value == 1
    //             //             ? "enable"
    //             //             : value == 2
    //             //                 ? "disable"
    //             //                 : "deleted",
    //             //     dataOrder, 1
    //             // );
    //             setLoading(false);
    //             setIsSuccessMessage(true);
    //             setSuccessMessage(response?.message);
    //             Toast.success(response?.message);
    //             handleCloseDeleteAlertModel();
    //             setLoading(false);
    //             setAnchorElForMenu(null);
    //         } else if (response?.success == false) {
    //             setIsWarningMessage(true);
    //             setWarningMessage(response?.message);
    //             Toast.warning(response?.message);
    //             handleCloseDeleteAlertModel();

    //             setLoading(false);
    //             setAnchorElForMenu(null);
    //         }
    //     } catch (err) {
    //         if (err?.response?.status == 401) {
    //             setLoading(false);
    //             logoutHelper(dispatch, navigate, userToken);
    //         }
    //         if (err?.response?.status == 429) {
    //             Toast.error("Too many attemps wait for 2 min.")
    //         }
    //         setLoading(false);
    //     }
    // };

    // const permanantlyDeleteUserAPI = async (id) => {
    //     try {
    //         let data = {
    //             id: id,
    //         };
    //         setLoading(true);
    //         const response = await api.delete(`admin/page/force-delete-page/${id} `, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${userToken}`,
    //             },
    //         });
    //         if (response?.success == true) {
    //             setIsSuccessMessage(true);
    //             setSuccessMessage(response?.message);
    //             Toast.success(response?.message);
    //             handleCloseConfirmDeleteAlertModel();
    //             setLoading(false);
    //             setAnchorElForMenu(null);

    //             setAllPages([]);
    //             setPage(1);

    //             fetchAllPages(
    //                 value == 0
    //                     ? "all"
    //                     : value == 1
    //                         ? "enable"
    //                         : value == 2
    //                             ? "disable"
    //                             : "deleted",
    //                 dataOrder, 1
    //             );
    //         } else if (response?.success == false) {
    //             setIsWarningMessage(true);
    //             setWarningMessage(response?.message);
    //             Toast.warning(response?.message);
    //             handleCloseConfirmDeleteAlertModel();

    //             setLoading(false);
    //             setAnchorElForMenu(null);
    //         }
    //     } catch (err) {
    //         if (err?.response?.status == 401) {
    //             setLoading(false);
    //             logoutHelper(dispatch, navigate, userToken);
    //         }
    //         if (err?.response?.status == 429) {
    //             Toast.error("Too many attemps wait for 2 min.")
    //         }
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        const debouncedHandleScroll = debounce(handleScroll, 200);

        window.addEventListener("scroll", debouncedHandleScroll);
        return () =>
            window.removeEventListener("scroll", debouncedHandleScroll);
    }, [isDataFetching, page, totalPages]);

    useEffect(() => {
        dispatch(pageAction?.setPageProcessing(false))

        fetchAllPages(
            selectedPageTypeTab == 0
                ? "all"
                : selectedPageTypeTab == 1
                    ? "enable"
                    : selectedPageTypeTab == 2
                        ? "disable"
                        : "deleted",
            dataOrder, 1
        );
        setValue(selectedPageTypeTab);
    }, []); //run only once after page load

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    {isSuccessMessage == true && (
                        <SuccessAlert
                            message={successMessage}
                            onClose={handleCloseSuccessMessage}
                        />
                    )}

                    {isWarningMessage == true && (
                        <WarningAlert
                            message={warningMessage}
                            onClose={handleCloseWarningMessage}
                        />
                    )}
                    <div className="main-div-2-types">
                        <Box className="">
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    <Link
                                        underline="hover"
                                        key="1"
                                        color="inherit"
                                        href="/"
                                        component="h3"
                                        className="cursor-pointer"
                                    >
                                        User
                                    </Link>
                                    <Typography key="3" component="h3" color="text.primary">
                                        Pages
                                    </Typography>
                                </Breadcrumbs>
                            </Stack>
                        </Box>
                        <div className="d-flex flex-wrap gap-3">
                            {/* <Button
                                className="btn primary-btn"
                                onClick={() => {
                                    localStorage.setItem("last-redirect-page", `/add-new/page`);
                                    dispatch(pageAction?.clearAll())
                                    navigate("/add-new/page");
                                }}
                            >
                                Add New
                                <FontAwesomeIcon icon={faPlus} />
                            </Button> */}
                        </div>
                    </div>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Box
                            sx={{ borderBottom: 1, borderColor: "divider" }}
                            className="box-attribute"
                        >
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                                // variant={isTablet && "scrollable"}
                                variant="scrollable"
                                className={"tabs"}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#660C60",
                                    },
                                }}
                            >
                                <Tab
                                    icon={<FontAwesomeIcon icon={faList} />}
                                    label="All Pages"
                                    iconPosition="start"
                                    {...a11yProps1(0)}
                                />

                                {/* <Tab
                                    label="Active Pages"
                                    icon={<FontAwesomeIcon icon={faPlay} />}
                                    iconPosition="start"
                                    {...a11yProps2(1)}
                                />
                                <Tab
                                    label="Inactive Pages"
                                    {...a11yProps3(2)}
                                    icon={<FontAwesomeIcon icon={faPause} />}
                                    iconPosition="start"
                                />
                                <Tab
                                    label="Deleted Pages"
                                    {...a11yProps4(3)}
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                    iconPosition="start"
                                /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={value}>
                            <Paper sx={{ width: "100%" }} className="type-list-paper">
                                <TableContainer>
                                    <Table
                                        sx={{ minWidth: 750 }}
                                        aria-labelledby="tableTitle"
                                        stickyHeader
                                    >
                                        {allPages?.length > 0 && (
                                            <>
                                                <TableHeaderForSorting
                                                    columns={columns_pages_header}
                                                    // handleChangeDataOrdr={handleChangeDataOrdr}
                                                    dataOrder={dataOrder}
                                                />
                                                <TableBody>
                                                    {allPages?.map((row, index) => (
                                                        <TableRow
                                                            hover
                                                            role="checkbox"
                                                            tabIndex={-1}
                                                            key={index + 1}
                                                            className="cursor-pointer"
                                                        >
                                                            <TableCell
                                                                align="left"
                                                                className={`tbl-body-cell tbl-border-left ${row?.status === "deleted" && "text-muted"
                                                                    }`}
                                                            >
                                                                <div
                                                                    className="d-flex flex-warp gap-3 align-items-center"
                                                                    style={{ fontSize: "14px" }}
                                                                >

                                                                    <p
                                                                        className={`${row?.status === "deleted" &&
                                                                            "text-muted fw-700"
                                                                            }`}
                                                                    >
                                                                        {row?.title}
                                                                    </p>
                                                                </div>
                                                            </TableCell>

                                                            <TableCell
                                                                align="left"
                                                                className="tbl-body-cell-3 "
                                                            >
                                                                {
                                                                    row?.date_created
                                                                }
                                                            </TableCell>
                                                            <TableCell
                                                                align="left"
                                                                className="tbl-body-cell-3 "
                                                            >
                                                                <Box>
                                                                    <Stack
                                                                        direction="row"
                                                                        alignContent="center"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                        className="z-n1"
                                                                    >
                                                                        <Avatar
                                                                            src={row?.created_by?.thumbnail}
                                                                            className="author-avtar "
                                                                            sx={{ fontSize: "14px" }}
                                                                        >
                                                                            {row?.created_by?.full_name?.charAt(0)}
                                                                        </Avatar>
                                                                        <Typography className="fw-400 fs-14">
                                                                            {row?.created_by?.full_name}
                                                                        </Typography>
                                                                    </Stack>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell
                                                                align="center"
                                                                className="tbl-body-cell-2 tbl-border-right"
                                                            >
                                                                <div>
                                                                    <Stack
                                                                        direction="row"
                                                                        alignContent="center"
                                                                        alignItems="center"
                                                                        spacing={1}
                                                                        className="gap-1"
                                                                    >
                                                                        <StatusIcon status={row?.status} />
                                                                        <IconButton
                                                                            aria-label="more"
                                                                            id="long-button"
                                                                            aria-haspopup="true"
                                                                            onClick={(e) => handleClick(e, row)}
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                    </Stack>
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </>
                                        )}
                                    </Table>
                                </TableContainer>
                                {isDataFetching && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: 2
                                        }}
                                    >
                                        <CircularProgress sx={{ color: "#660C60" }} />
                                    </Box>
                                )}
                            </Paper>
                            {allPages?.length < 1 && (
                                <div className="no-data mt-5">
                                    <div>
                                        <Image
                                            src={NoDataFoundImg}
                                            className="text-center mx-auto no-data-img mt-4"
                                        />
                                    </div>
                                    <Typography component="h5" variant="h5">
                                        No data found
                                    </Typography>
                                </div>
                            )}
                        </CustomTabPanel>
                    </Box>
                </Container>

                {/* <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openDeleteAlert}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                        className="dialog-title"
                    >
                        Are you sure?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDeleteAlertModel}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        className="alert-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="">
                        {" "}
                        Are you sure want to delete page?
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                        <Button
                            className="btn delete-btn-model"
                            onClick={handleCloseDeleteAlertModel}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            Cancel
                        </Button>
                        <Button
                            className="btn primary-btn"
                            onClick={() => deletePageAPI(categoryId?.id)}
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} />
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog> */}

                {/* <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openConfirmDeleteAlert}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                        className="dialog-title"
                    >
                        Are you sure?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseConfirmDeleteAlertModel}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        className="alert-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="">
                        {" "}
                        Are you sure want to permanantly delete page? Once you permanatly
                        delete you can not restore it again.
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                        <Button
                            className="btn delete-btn-model"
                            onClick={handleCloseConfirmDeleteAlertModel}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            Cancel
                        </Button>
                        <Button
                            className="btn primary-btn"
                            onClick={() => permanantlyDeleteUserAPI(categoryId?.id)}
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} />
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog> */}

                <Menu
                    anchorEl={anchorElMenu}
                    id="long-menu"
                    MenuListProps={{
                        "aria-labelledby": "long-button",
                    }}
                    open={openMenuFoEdit}
                    anchorOrigin={"right"}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                            zIndex: "1",
                        },
                    }}
                >
                    {/* {categoryId?.status !== "deleted" && (
                        <MenuItem
                            onClick={() => {
                                localStorage.setItem(
                                    "last-redirect-page",
                                    `/edit/page/${categoryId?.id}`
                                );
                                navigate(`/edit/page/${categoryId?.id}`);
                            }}
                        >
                            Edit
                        </MenuItem>
                    )} */}

                    {/* {categoryId?.status !== "deleted" && ( */}
                    <MenuItem
                        onClick={() => {
                            localStorage.setItem(
                                "last-redirect-page",
                                `/page/${categoryId?.slug}`
                            );
                            navigate(`/page/${categoryId?.slug}`);
                        }}
                    >
                        View
                    </MenuItem>
                    {/* )} */}

                    {/* {categoryId?.status !== "enable" &&
                        categoryId?.status !== "deleted" && (
                            <MenuItem
                                onClick={() => {
                                    changePageStatus(categoryId?.id, "enable");
                                }}
                            >
                                Active
                            </MenuItem>
                        )}
                    {categoryId?.status !== "disable" &&
                        categoryId?.status !== "deleted" && (
                            <MenuItem
                                onClick={() => {
                                    changePageStatus(categoryId?.id, "disable");
                                }}
                            >
                                Inactive
                            </MenuItem>
                        )}
                    {categoryId?.status !== "deleted" && (
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setOpenDeleteAlert(true);
                            }}
                        >
                            Delete
                        </MenuItem>
                    )}
                    {categoryId?.status === "deleted" && (
                        <MenuItem
                            onClick={() => {
                                RestorePage(categoryId?.id);
                            }}
                        >
                            Restore
                        </MenuItem>
                    )}
                    {categoryId?.status === "deleted" && (
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                setOpenConfirmDeleteAlert(true);
                            }}
                        >
                            Permanantly Delete
                        </MenuItem>
                    )} */}
                </Menu>
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default UserPageList