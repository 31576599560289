import React, { useState } from 'react'
import { Row, Col } from "react-bootstrap";
import { useSelector } from 'react-redux';
import TypeCard from './typeCard';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, Popover, Typography } from "@mui/material";
import {
  Divider
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFloppyDisk, faPen, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import ActionMenu from '../../ui/ActionMenu';
import TypeList from './typeList';


function TypeListing(props) {
  const { getAllTypes, selectedShowValue, filterToggle, folderEnable, typeName, deleteTypePostAPI } = props;
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const { open, adminAccessible } = useSelector((state) => state?.drawerMenu);

  //States for tags list handle
  const [anchorElForMoreTag, setanchorElForMoreTag] = useState(null);
  const [moreTagPopoverContent, setMoreTagPopoverContent] = useState([]);
  const openPostDetailPopover = Boolean(anchorElForMoreTag);
  const postdetailPopoverid = open ? 'popover-list' : undefined;

  const handleMoreTagPopoverOpen = (event, remainingValues) => {
    setanchorElForMoreTag(event.currentTarget);
    setMoreTagPopoverContent(remainingValues?.slice(1));
  }

  const handleMoreTagPopoverClose = () => {
    setanchorElForMoreTag(null);
    setMoreTagPopoverContent([]);
  };

  //states for action menu handle
  const [anchorElAction, setAnchorElForAction] = useState(null);
  const [actionClickpost, setActionClickpost] = useState(null);
  const openMenuForActionClick = Boolean(anchorElAction);
  const [menuItems, SetMenuItems] = useState([{
    label: 'View',
    icon: <FontAwesomeIcon icon={faXmark} />,
    onClick: () => handlePostRedirectView(actionClickpost),
  }]);

  const handleClickActionIcon = (event, post) => {
    const menuItemsArr = [
      {
        label: 'View',
        icon: <FontAwesomeIcon icon={faEye} />,
        onClick: () => handlePostRedirectView(post),
      },
      ...(post?.is_edit === 1
        ? [
          {
            label: 'Edit',
            icon: <FontAwesomeIcon icon={faPen} />,
            onClick: () => handlePostRedirectEdit(post),
            showDivider: true,
            className: "edit-item", // Custom class for the menu item
            iconClassName: "edit-icon", // Custom class for the icon
            labelClassName: "edit-label",
          },
        ]
        : []),
      ...(post?.is_delete === 1
        ? [
          {
            label: 'Delete',
            icon: <FontAwesomeIcon icon={faTrash} />,
            onClick: () => handlePostDelete(post),
            showDivider: true,
            className: "action-delete-item", // Custom class for the menu item
            iconClassName: "action-delete-icon", // Custom class for the icon
            labelClassName: "action-delete-label",
          },
        ]
        : []),];
    SetMenuItems(menuItemsArr);
    setAnchorElForAction(event.currentTarget);
    setActionClickpost(post);
  };

  const handleCloseActionIcon = () => {
    setAnchorElForAction(null);
    setActionClickpost(null);
    SetMenuItems([]);

  };

  const handlePostRedirectView = (post) => {
    if (loggedUser?.user_type === "user") {
      localStorage.setItem("last-redirect-page-user", `/user-type/view/${typeName}/${post?.id}`);
      navigate(`/user-type/view/${typeName}/${post?.id}`);
    }
    else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
      localStorage.setItem("last-redirect-page-admin-user", `/user-type/view/${typeName}/${post?.id}`)
      navigate(`/user-type/view/${typeName}/${post?.id}`);
    }
  }

  const handlePostRedirectEdit = (post) => {
    if (loggedUser?.user_type === "user") {
      localStorage.setItem("last-redirect-page-user", `/user-type/create-content/edit/${post?.id}`);
      navigate(`/user-type/create-content/edit/${post?.id}`);
    }
    else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
      localStorage.setItem("last-redirect-page-admin-user", `/user-type/create-content/edit/${post?.id}`)
      navigate(`/user-type/create-content/edit/${post?.id}`);
    }
  }

  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deletePostId, setDeletePostId] = useState();
  const handlePostDelete = (post) => {
    setDeletePostId(post)
    setOpenDeleteAlert(true);
    handleCloseActionIcon();
  }

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
  };

  const handleDeletePostClick = (deletePostId) => {
    deleteTypePostAPI(deletePostId?.id);
    setOpenDeleteAlert(false);
    setDeletePostId(null)
  }

  return (
    <>
      {folderEnable ? (
        <TypeList folderAndPosts={getAllTypes} adminAccessible={adminAccessible} loggedUser={loggedUser} handleClickActionIcon={handleClickActionIcon} />
      ) : (
        <Row className={`mt-0 gutter ${filterToggle ? 'filtered-layout' : 'default-layout'}`}>
          {getAllTypes?.map((post) => (
            <>
              <Col lg={filterToggle ? 4 : 3} md={filterToggle ? 4 : 6} sm={12}>
                <TypeCard
                  post={post}
                  openMoreTag={handleMoreTagPopoverOpen}
                  handleClickActionIcon={handleClickActionIcon}
                  handlePostRedirectView={handlePostRedirectView}
                />
              </Col>

            </>
          ))}
        </Row>
      )}

      {moreTagPopoverContent &&
        <Popover
          id={postdetailPopoverid}
          open={openPostDetailPopover}
          anchorEl={anchorElForMoreTag}
          onClose={handleMoreTagPopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List sx={{
            p: 1,
            maxHeight: 200,
            overflow: 'auto',
          }}>
            {moreTagPopoverContent.map((value, idx) => (
              <>
                <ListItem key={idx}>
                  <Typography variant="body2">{value}</Typography>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </Popover>
      }

      {openMenuForActionClick &&
        <ActionMenu
          anchorEl={anchorElAction}
          open={openMenuForActionClick}
          onClose={handleCloseActionIcon}
          menuItems={menuItems}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        />
      }

      {openDeleteAlert && (
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete post?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => { handleDeletePostClick(deletePostId); }}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default TypeListing