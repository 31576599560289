import React from 'react'
import { Image, Stack } from "react-bootstrap";
import { CardHeader, IconButton, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  Card,
} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import DummyUser from "../../../assets/images/dummy-user.jpg";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function PeopleCard(props) {
  const { people, loggedUser, adminAccessible, handleClickActionIcon } = props;
  const navigate = useNavigate();

  const handleRedirect = (id) => {
    if (loggedUser?.user_type === "user") {
      localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${id}`);
      navigate(`/user-type/people/profile/view/${id}`);
    }
    else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
      localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${id}`)
      navigate(`/user-type/people/profile/view/${id}`);
    }
  }
  return (
    <Card className="people-card-wrapper">
      <CardHeader
        className="people-card-header"
        avatar={
          people?.image ? (
            <Image src={people?.image} className="mt-2 user-img text-center cursor-pointer" alt={people?.name} onClick={() => {
              handleRedirect(people?.id);
            }} />
          ) : (
            <Image src={DummyUser} className="mt-2 user-img text-center cursor-pointer" alt={people?.name} onClick={() => {
              handleRedirect(people?.id);
            }} />
          )}

        action={
          <IconButton sx={{ height: 12, width: 3 }} aria-label="settings" onClick={(e) => handleClickActionIcon(e, people)}>
            <MoreVertIcon />
          </IconButton>
        }

      />
      <CardContent className='pt-0 cursor-pointer'
        onClick={() => {
          handleRedirect(people?.id);
        }}
      >
        <Stack direction="vertical" className="gap-2">
          <Typography className="fs-15-bold" sx={{ textTransform: 'capitalize' }} variant="h5" component="div">
            {people?.name}
          </Typography>
          <Typography className="fs-12-muted">{people?.job_title ?? ''}</Typography>
          <Divider />
          <Typography className="fs-12-muted">{people?.phone}</Typography>
          <Typography className="fs-12-muted">{people?.email}</Typography>
        </Stack>
      </CardContent>
    </Card >
  )
}

export default PeopleCard