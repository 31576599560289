import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExpandMore, ExpandLess, StarBorder, Category } from '@mui/icons-material'
import { Box, Checkbox, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  filterSliceAction
} from "../../../redux/slices/userTypePage/filterSlice.js";



function Filter(props) {
  const { type, categories, groups } = props
  const dispatch = useDispatch();
  const { selectedGroups, selectedCategory, isArchive } = useSelector((state) => state.filter);

  const [openSections, setOpenSections] = useState({
    category: false,
    group: false,
  });
  const [openCategorySections, setOpencategorySections] = useState({
  });

  const handleClick = (section) => {
    // Toggle the open state of the clicked section
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleClickCatgory = (section) => {
    // Toggle the open state of the clicked section
    setOpencategorySections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const handleCheckboxChange = (groupValue) => {
    dispatch(
      filterSliceAction.setSelectedGroups(
        selectedGroups.includes(groupValue)
          ? selectedGroups.filter((value) => value !== groupValue)
          : [...selectedGroups, groupValue]
      )
    );
  };

  const handleCategoryChange = (categoryId, termId) => {
    const exists = selectedCategory.some(
      (item) => item.category_id === categoryId && item.term_id.includes(termId)
    );
    let data = [];
    if (exists) {
      data = selectedCategory.filter(
        (item) =>
          !(item.category_id === categoryId && item.term_id === termId)
      );
      data = selectedCategory.map((item) => {
        if (item.category_id === categoryId) {
          // If item contains the termId, filter it out
          const updatedTermIds = item.term_id.filter((id) => id !== termId);

          // If no termId is left for this category, remove the entire item
          if (updatedTermIds.length === 0) {
            return null; // This item will be removed from the final array
          }
          return { ...item, term_id: updatedTermIds }; // Otherwise, update the term_id
        }
        return item; // Keep other items unchanged
      }).filter(item => item !== null);
      if (!data) {
        data = [];
      }
    } else {
      const categoryExists = selectedCategory.some(
        (item) => item.category_id === categoryId
      );

      if (categoryExists) {
        data = selectedCategory.map((item) => {
          if (item.category_id === categoryId) {
            return { ...item, term_id: [...item.term_id, termId] };
          }
          return item;
        });
      } else {
        data = [...selectedCategory, { category_id: categoryId, term_id: [termId] }];
      }
    }
    dispatch(filterSliceAction.setSelectedCategory(data));
  };

  const toggleArchive = () => {
    dispatch(filterSliceAction.setIsArchive(!isArchive));
  };

  return (
    <List className='p-0' sx={{ height: '70vh', overflowY: 'auto' }}>
      <ListItem disablePadding sx={{ display: 'block', pl: 0, mt: 1 }}>
        <ListItemButton className='justify-content-between' onClick={() => handleClick('category')}>
          <Typography className='fs-12-700' variant="h6" component="span">
            Category
          </Typography>
          {openSections.category ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSections.category} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {categories.map((category, index) => (
              <>
                <ListItem disablePadding sx={{ display: 'block', pl: 2, mt: 1 }}>
                  <ListItemButton className='justify-content-between' onClick={() => handleClickCatgory(category?.id)}>
                    <Typography className='fs-12' variant="h6" component="span">
                      {category?.label}
                    </Typography>
                    {openCategorySections[category?.id] ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse in={openCategorySections[category?.id]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {category?.options?.map((option, key) => (
                        <ListItemButton className='justify-content-between' key={key} sx={{ pl: 4 }} onClick={() => handleCategoryChange(option?.categoryId, option?.value)}>
                          <Typography className='fs-12' variant="h6" component="span">
                            {option?.label}
                          </Typography>
                          <Checkbox
                            checked={selectedCategory?.some(
                              (item) => item.category_id === option?.categoryId && item.term_id.includes(option?.value)
                            )}
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': option?.value }}
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                  {(categories?.length - 1) !== index && <Divider />}
                </ListItem>
              </>
            ))}
          </List>
        </Collapse>
      </ListItem>
      {type === 'people' && groups.length > 0 && (
        <>
          <Divider />
          <ListItem disablePadding sx={{ display: 'block', pl: 0 }}>
            <ListItemButton className='justify-content-between' onClick={() => handleClick('group')}>
              <Typography className='fs-12-700' variant="h6" component="span">
                Groups
              </Typography>
              {openSections.group ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openSections.group} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {groups.map((group, index) => (
                  <ListItemButton className='justify-content-between' key={index} sx={{ pl: 2 }} onClick={() => handleCheckboxChange(group?.value)}>
                    <Typography className='fs-12' variant="h6" component="span">
                      {group?.label}
                    </Typography>
                    <Checkbox
                      checked={selectedGroups.includes(group?.value)}
                      edge="start"
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': group?.value }}
                    />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </ListItem>
        </>
      )
      }
      {type !== 'people' && (
        <>
          <Divider />
          <ListItem disablePadding sx={{ display: 'block', pl: 0, mt: 1 }}>
            <ListItemButton className='justify-content-between' onClick={() => handleClick('include_archive')}>
              <Typography className='fs-12-700' variant="h6" component="span">
                Include Archive
              </Typography>
              {openSections.include_archive ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openSections.include_archive} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton className='justify-content-between' key={1} sx={{ pl: 2 }} onClick={() => toggleArchive()}>
                  <Typography className='fs-12' variant="h6" component="span">
                    Yes
                  </Typography>
                  <Checkbox
                    checked={isArchive}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'yes' }}
                  />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
        </>
      )
      }
    </List>
  )
}

export default Filter