import React, { useEffect, useState, useCallback, Suspense, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Card, Carousel, Image } from "react-bootstrap";
import {
    TextField,
    Typography,
    Box,
    IconButton,
    Breadcrumbs,
    Link,
    Divider,
    Stack,
    Paper,
    Button,
    Popover,
    Grid,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    ListItemIcon,
    CardMedia,
    CardContent
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { pageAction } from "../../redux/slices/page/page";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { faArrowLeft, faEye, faGears, faLock, faPen, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOMServer from 'react-dom/server';
import UpdatePostCreateModal from "../../components/common/dashboard/UpdatePostCreateModal";
import UploadMediaModel from "../../components/common/media/uploadMediaModel";
import AddMedia from "../../components/common/media/addMedia";
import FileSaver from "file-saver";
import { useDropzone } from "react-dropzone";
import {
    faFilePdf,
    faFile,
    faFileExcel,
    faXmark,
    faFloppyDisk,
    faStar
} from "@fortawesome/free-solid-svg-icons";
import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';
import { Toast } from "../../utils/toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import ActionMenu from "../../components/ui/ActionMenu";
import { styled } from "@mui/material/styles";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const PreviewPage = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        open,
        adminAccessible,
        getAllTypesContent
    } = useSelector((state) => state?.drawerMenu);

    const [loading, setLoading] = useState(false)
    const [userMenuLists, setUserMenuLists] = useState({});

    const [inksData, setLinksData] = useState([])
    const [pageTitle, setPageTitle] = useState()
    const [typesData, setTypesData] = useState({})
    const [peopleData, setPeopleData] = useState({})
    const [feedData, setFeedData] = useState({})
    const [searchQuery, setSearchQuery] = useState(""); // Add state for search input
    const [openUpdateBox, setOpenUpdateBox] = useState(false)

    const [mediaDataFor, setMediaDataFor] = useState("image")

    const [folderList, setFolderList] = useState()
    const [myMediaFolderList, setMyFolderList] = useState([])
    const [systemMediaFolderList, setSystemFolderList] = useState([])
    const [selectedMediaTypeForImage, setSelectedMediaTypeForImage] = useState("")
    const [selectedMediaTypeForDoc, setSelectedMediaTypeForDoc] = useState("")

    const [selectedMediaFileDetailsForImage, setSelectedMediaFileDetailsForImage] = useState()
    const [selectedMediaFileForImage, setSelectedMediaFileForImage] = useState("")

    const [selectedMediaFolderIDForImage, setSelectedMediaFolderIDForImage] = useState("")
    const [selectedMediaFolderIDFordoc, setSelectedMediaFolderIDForDoc] = useState("")

    const [selectedMediaFileDetailsForDoc, setSelectedMediaFileDetailsForDoc] = useState()
    const [selectedMediaFileForDoc, setSelectedMediaFileForDoc] = useState("")

    const [pageForMedia, setPageForMedia] = useState(0);
    const [rowsPerPageForMedia, setRowsPerPageForMedia] = useState(18)
    const [openMediaUpload, setOpenMediaUpload] = useState(false)

    const [mediaList, setMediaList] = useState([])
    const [mediaListResponse, setMediaListResponse] = useState()

    const [openMediaModelFor, setOpenMediaModelFor] = useState("")

    const [selectedMediaFileForUpdate, setSelectedMediaFileForUpdate] = useState()

    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");

    const [openFileUploadModelMedia, setOpenFileUploadModelMedia] = useState(false)

    const [mediaTitle, setMediaTitle] = useState("")
    const [mediaAltText, setMediaAltText] = useState("")
    const [mediaDescription, setMediaDescription] = useState("")
    const [mediaDirectUrl, setMediaDirectUrl] = useState("")
    const [isSystemTypeMedia, setIsSystemTypeMedia] = useState(false)
    const [responseForMedia, setResponseForMedia] = useState()
    const [valueForMediaTab, setValueForMediaTab] = useState(0);

    const [selectedMedia, setSelectedMedia] = useState("")
    const [permissionsForMedia, setPermissionsForMedia] = useState([]);
    const [parentFolderId, setParentFolderId] = useState(0)
    const [isParentFolderSystemType, setIsParentFolderSystemType] = useState(false)

    const [isEditMedia, setIsEditMedia] = useState(false)
    const [isRenameMedia, setIsRenameMedia] = useState(false)
    const [selectedFileMedia, setSelectedFileMedia] = useState(null);

    const [openRnameMediaModel, setOpenRenameModel] = useState(false)
    const [isImageMedia, setIsImageMedia] = useState(false);

    const [isAdvancePermissionForMedia, setIsAdvancePermissionForMedia] = useState(false);

    const [isDataFetchingForFeed, setIsDataFetchingForFeed] = useState(false);
    const [pageFeed, setPageFeed] = useState(1);
    const [totalPagesFeed, setTotalPagesFeed] = useState(null);

    const [selectedMediaFileDetailsForImages, setSelectedMediaFileDetailsForImages] = useState([])
    const [selectedMediaFileForImages, setSelectedMediaFileForImages] = useState([])

    const [isFeedDataForUpdatePost, setIsFeedDataForUpdatePost] = useState(false)

    const [anchorElForCreateContent, setAnchorElForCreateContent] = useState(null);
    const [anchorElForAvatar, setAnchorElForAvatar] = useState(null);

    const [anchorElMenuForPost, setAnchorElForMenuForPost] = useState(null);
    const [menuItemsForPostList, SetMenuItemsForPostList] = useState([]);

    const [singlePostData, setSinglePostData] = useState()

    const [openImageModel, setOpenImageModel] = useState()
    const [openUserImageModel, setOpenUserImageModel] = useState()

    const [selectedImageIndex, setSelectedImageIndex] = useState(0); // Add this state to track the selected image index
    const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
    const [deletePostId, setDeletePostID] = useState()

    const [menuPostAction, setMenuPostaction] = useState({
        isEdit: false,
        isDelete: false,
        typeName: "",
        id: ""
    })

    const openMenuForPost = Boolean(anchorElMenuForPost);

    const { html, css, js, style, menu, link, youtube, types, people, feed } = useSelector((state) => state?.page);

    const columns_atttributes_headers = [
        {
            key: 1,
            disablePadding: true,
            id: "name",
            label: "Name",
            minWidth: 250,
            className: "other-color",
        },
        {
            key: 2,
            id: "view",
            disablePadding: true,
            label: "View",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 3,
            id: "deny",
            disablePadding: true,
            label: "Deny",
            minWidth: 50,
            className: "other-color",
        },
    ];

    const columns_advace_atttributes_headers = [
        {
            key: 1,
            disablePadding: true,
            id: "name",
            label: "Name",
            minWidth: 250,
            className: "other-color",
        },
        {
            key: 2,
            id: "view",
            disablePadding: true,
            label: "View",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 3,
            id: "deny",
            disablePadding: true,
            label: "Deny",
            minWidth: 50,
            className: "other-color",
        },

        {
            key: 5,
            id: "edit",
            disablePadding: true,
            label: "Edit",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 6,
            id: "delete",
            disablePadding: true,
            label: "Delete",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 7,
            id: "redacted",
            disablePadding: true,
            label: "Redacted",
            minWidth: 50,
            className: "other-color",
        },
    ];

    const media_Pagination_options = [{ label: "18", value: 18 },
    { label: "36", value: 36 },
    { label: "54", value: 54 },
    { label: "108", value: 108 }]

    const total_mediaFiles = parseInt(mediaListResponse?.meta?.total)

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");

    const openMenuForCreateContent = Boolean(anchorElForCreateContent)
    const openMenuForAvatar = Boolean(anchorElForAvatar);

    const idCreateContent = openMenuForCreateContent ? "simple-popover-2" : undefined
    const idAvatar = openMenuForAvatar ? "simple-popover-2" : undefined;
    const idFeedActions = openMenuForPost ? "simple-popover-2" : undefined;
    const maxVisibleCategory = 1

    const formatDateToReadable = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();

        // Determine the suffix for the day
        const suffix =
            day === 1 || day === 21 || day === 31
                ? "st"
                : day === 2 || day === 22
                    ? "nd"
                    : day === 3 || day === 23
                        ? "rd"
                        : "th";

        const month = date.toLocaleString("default", { month: "long" }); // e.g., January
        const year = date.getFullYear();

        return `${day}${suffix} ${month} ${year}`;
    };

    const InView = ({ children }) => {
        const [hasBeenInView, setHasBeenInView] = useState(false);
        const ref = useRef();

        useEffect(() => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        setHasBeenInView(true); // Set to true once in view
                        observer.disconnect();  // Stop observing once it's in view
                    }
                },
                { threshold: 0.1 } // Trigger when at least 10% of the element is in view
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            return () => {
                if (ref.current) {
                    observer.unobserve(ref.current);
                }
            };
        }, []);

        return <div ref={ref}>{hasBeenInView ? children : null}</div>;
    };

    function PreviewLink({ linkData }) {
        console.log("linkData:::", linkData)
        const [displayData, setDisplayData] = useState(linkData);

        const fetchLinkPreview = async (urls) => {
            try {
                const userToken = localStorage.getItem("accessToken");
                const response = await api.post(`user/generate-link-preview`, { urls }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                if (response?.success == true) {
                    setDisplayData(response?.response);
                } else if (response?.success == false) {
                    Toast.warning(response?.message);
                }
            } catch (err) {
                console.log("error while fetching generate link preview api::", err);
            }
        }

        const generateLinkPreview = async () => {
            const urls = [];

            Object.entries(displayData).forEach(([link, data]) => {
                if (!data?.success) {
                    urls.push(data?.link);
                }
            });

            if (urls?.length > 0) {
                await fetchLinkPreview(urls);
            }
        }

        useEffect(() => {
            generateLinkPreview();
        }, []);

        return (
            <>
                {Object.entries(displayData).map(([link, data], index) => (
                    data?.success && (
                        <a href={data?.link} key={index} className='text-decoration-none' target='_blank'>
                            <Card className='mt-4 card-preview'>
                                {data?.image && (
                                    <CardMedia
                                        sx={{
                                            width: 100
                                        }}
                                        image={
                                            data?.image ?? ''
                                        }
                                    />
                                )}
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5" className='link-title'>
                                            {data?.title ? (data?.title.length > 40 ? `${data?.title.substring(0, 40)}...` : data?.title) : (data?.link.length > 59 ? `${data?.link.substring(0, 59)}...` : data?.link)}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            component="div"
                                            sx={{ color: 'text.secondary' }}
                                            className='link-url'
                                        >
                                            {data?.link.length > 59 ? `${data?.link.substring(0, 59)}...` : data?.link}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>
                        </a>
                    )
                ))}
            </>
        )
    }

    const handleOpenUploadMedia = (dataFor) => {
        setMediaDataFor(dataFor)
        fetchMediaFolders(dataFor)
    }

    const handleCloseForUploadMedia = () => {
        setOpenMediaUpload(false)
        setFolderList()
        setMyFolderList([])
        setSystemFolderList([])
        setMediaDataFor("")
    }

    const handleClickForCreateContent = (event) => {
        console.log("event::", event)
        if (anchorElForCreateContent == null) {
            setAnchorElForCreateContent(event?.currentTarget);
        } else {
            setAnchorElForCreateContent(null);
        }
    };

    const handleCloseForCreateContent = () => {
        setAnchorElForCreateContent(null);
    };

    const handleClickForAvatar = (event) => {
        handleCloseForCreateContent();
        if (anchorElForAvatar == null) {
            setAnchorElForAvatar(event.currentTarget);
        } else {
            setAnchorElForAvatar(null);
        }
    };

    const handleCloseForAvatar = () => {
        setAnchorElForAvatar(null);
    };

    const handleClickForDetails = () => {
        if (loggedUser?.user_type == "admin") {
            localStorage.setItem("last-redirect-page", `/`)
            navigate("/");
        } else {
            localStorage.setItem("last-redirect-page-user", `/dashboard`)
            navigate("/dashboard");
        }
    };

    const handleClickForMenuPost = (event, isEdit, isDelete, typeName, id, typeSlug, typeID) => {
        console.log("menu row data::", isEdit)

        const menuItemsArr = [
            {
                label: 'View',
                icon: <FontAwesomeIcon icon={faEye} />,
                onClick: () => navigate(`/user-type/view/${typeName}/${id}`)
            },
            ...(isEdit === true && typeName !== "Update"
                ? [
                    {
                        label: 'Edit',
                        icon: <FontAwesomeIcon icon={faPen} />,
                        onClick: () => navigate(`/user-type/create-content/edit/${id}`),
                        showDivider: true,
                        className: "edit-item", // Custom class for the menu item
                        iconClassName: "edit-icon", // Custom class for the icon
                        labelClassName: "edit-label",
                    },
                ]
                : []),
            ...(isDelete === true
                ? [
                    {
                        label: 'Delete',
                        icon: <FontAwesomeIcon icon={faTrash} />,
                        onClick: () => handleDeletePost(id),
                        showDivider: true,
                        className: "action-delete-item", // Custom class for the menu item
                        iconClassName: "action-delete-icon", // Custom class for the icon
                        labelClassName: "action-delete-label",
                    },
                ]
                : []),];
        SetMenuItemsForPostList(menuItemsArr)
        setAnchorElForMenuForPost(event.currentTarget);
        setDeletePostID(id)
        localStorage.setItem("type-selected-name-user", typeName);
        localStorage.setItem("type-selected-id-user", typeID);
        localStorage.setItem("type-selected-slug-user", typeSlug);

        // setSinglePostData(row);
    };

    const handleCloseForMenuPost = () => {
        setAnchorElForMenuForPost(null);
        setSinglePostData();
        SetMenuItemsForPostList([])
        setDeletePostID()
        // Remove aria-expanded and aria-controls from all buttons with the class 'edit-post-icon-btn'
        const iframe = document.querySelector("iframe"); // Adjust the selector to match your iframe
        const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Access iframe's document
        const editPostButtons = iframeDoc.querySelectorAll(".edit-post-icon-btn");

        editPostButtons.forEach((button) => {
            button.removeAttribute("aria-expanded");
            button.removeAttribute("aria-controls");
        });
    }

    const handleCloseDeleteAlertModel = () => {
        setOpenDeleteAlert(false);
        setSinglePostData()
        setAnchorElForMenuForPost(null)
        setDeletePostID()
    };

    const handleCloseForImgeModel = () => {
        setOpenImageModel(false)
        setSinglePostData()
        setSelectedImageIndex(0)
    }

    const handleCloseForUserImageModel = () => {
        setOpenUserImageModel(false)
        setSinglePostData()
    }

    const handleDeletePost = (data) => {
        setSinglePostData(data)
        setAnchorElForMenuForPost(null)

        setOpenDeleteAlert(true)
    }

    const onLogout = async () => {
        setLoading(true);
        localStorage.removeItem("selected-folder-item")
        localStorage.removeItem("selected-folder-type")

        const result = await logoutHelper(dispatch, navigate, userToken);
        if (result == true) {
            setLoading(false);
        }
    };

    const getMimeType = (extension) => {
        const mimeTypes = {
            'png': 'image/png',
            'jpg': 'image/jpeg',
            'jpeg': 'image/jpeg',
            'pdf': 'application/pdf',
            'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'mp4': 'video/mp4',
            'mp3': 'audio/mpeg'
        };
        return mimeTypes[extension] || 'application/octet-stream';
    };

    const downloadFileForImage = async () => {
        var blob = new Blob([selectedMediaFileDetailsForImage?.file_path], {
            type: getMimeType(selectedMediaFileDetailsForImage?.type)
        });
        FileSaver.saveAs(blob, selectedMediaFileDetailsForImage?.title);
    };

    const downloadFileFordoc = async () => {
        var blob = new Blob([selectedMediaFileDetailsForDoc?.file_path], {
            type: getMimeType(selectedMediaFileDetailsForDoc?.type)
        });
        FileSaver.saveAs(blob, selectedMediaFileDetailsForDoc?.title);
    };

    const handleCloseMediaModelAddMedia = () => {
        setOpenFileUploadModelMedia(true)
        let folder_id;
        if (mediaDataFor === "image") {
            folder_id = selectedMediaFolderIDForImage
            if (selectedMediaTypeForImage === "system") {
                setIsSystemTypeMedia(true)
                setIsParentFolderSystemType(true)
            } else {
                setIsSystemTypeMedia(false)
                setIsParentFolderSystemType(true)
            }
        }
        else if (mediaDataFor === "doc") {
            folder_id = selectedMediaFolderIDFordoc
            if (selectedMediaTypeForDoc === "system") {
                setIsSystemTypeMedia(true)
                setIsParentFolderSystemType(true)
            } else {
                setIsSystemTypeMedia(false)
                setIsParentFolderSystemType(true)
            }
        }
        setParentFolderId(folder_id)
    }

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles?.length > 0) {
            const file = acceptedFiles[0];
            console.log("file::", file)
            // Create a URL for the file using URL.createObjectURL
            const fileUrl = URL.createObjectURL(file);
            console.log("fileUrl:::", fileUrl)
            // Set the selected file with both file object and its URL
            setSelectedFileMedia({
                file,
                url: fileUrl,
                type: file?.type, // Get the MIME type from the dropped file
                name: file?.name // Use the file's name for display
            });
        }
    }, []);

    const acceptedFilesForUpload = () => {
        if (mediaDataFor === "image") {
            return {
                "image/*": [], // Accept all image types
            }
        } else if (mediaDataFor === "doc") {
            return {
                "application/pdf": [], // Accept PDFs
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [], // DOCX
                "application/vnd.openxmlformats-officedocument.presentationml.presentation": [], // PPTX
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // XLSX
            }
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptedFilesForUpload,
        multiple: false,
    });

    const renderPreview = () => {
        if (!selectedFileMedia) return null;

        // Handle image files
        if (selectedFileMedia?.type?.startsWith('image/')) {
            return (
                <div className="image-preview">
                    <img
                        src={selectedFileMedia?.url}
                        alt="Uploaded"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Maintain aspect ratio
                        }}
                    />
                </div>
            );
        }

        // Handle PDF files
        if (selectedFileMedia?.type === 'application/pdf') {
            // return <p>PDF file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFilePdf
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        // Handle DOCX files
        if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            // return <p>DOCX file selected: {selectedFile.name}</p>;
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        // Handle PPTX files
        if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFile
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        if (selectedFileMedia?.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return (
                <Card
                    key={"pdf"}
                    variant="outlined"
                    sx={{
                        p: 2,
                        mb: 2,
                        cursor: "pointer",
                    }}
                    className="pdf-doc"
                >
                    <Box
                        height={150}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FontAwesomeIcon
                            size="3x"
                            icon={
                                faFileExcel
                            }
                            color="#660C60"
                        />
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            paddingLeft: 1,
                        }}
                    >
                        {selectedFileMedia?.name}
                    </Box>
                </Card>
            );
        }

        // Handle MP4 files
        if (selectedFileMedia?.type === 'video/mp4') {
            return (
                <Box className="w-100 h-100">
                    <Typography>{selectedFileMedia?.name}</Typography>
                    <ReactPlayer
                        url={selectedFileMedia?.url}
                        className="react-player"
                        playing={true}
                        width="100%"
                        height="100%"
                        controls={true}
                    />
                </Box>
            );
        }

        // Handle MP3 files
        if (selectedFileMedia?.type === 'audio/mpeg') {
            return (
                <Box className="w-100 h-100 audio-file">
                    <Typography>{selectedFileMedia?.name}</Typography>
                    <ReactAudioPlayer
                        src={selectedFileMedia?.url}
                        controls
                    />
                </Box>
            );
        }

        return (
            <Card
                key={"pdf"}
                variant="outlined"
                sx={{
                    p: 2,
                    mb: 2,
                    cursor: "pointer",
                }}
                className="pdf-doc"
            >
                <Box
                    height={150}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <FontAwesomeIcon
                        size="3x"
                        icon={
                            faFilePdf
                        }
                        color="#660C60"
                    />
                </Box>
                <Divider />
                <Box
                    sx={{
                        paddingLeft: 1,
                    }}
                >
                    {selectedFileMedia?.name}
                </Box>
            </Card>
        );
    };

    const validateMedia = () => {
        let isValid = true;

        if (mediaTitle === "") {
            Toast.error("Please enter a title for media");
            isValid = false;
        }
        if (mediaAltText === "") {
            Toast.error("Please enter a alt text for media");
            isValid = false;
        }
        if (mediaDescription === "") {
            Toast.error("Please enter a description for media");
            isValid = false;
        }
        // if (permissionsForMedia?.length < 1) {
        //   Toast.error("Please select at least one user/group for access permission");
        //   isValid = false;
        // }
        if (selectedFileMedia === null) {
            Toast.error("Please upload a media file");
            isValid = false;
        }
        return isValid;
    }

    const fetchMediaDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/file/edit/${selectedMedia}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                const responseData = response?.response
                setLoading(false);
                setMediaTitle(responseData?.title)
                setMediaAltText(responseData?.alt_text)
                setMediaDescription(responseData?.description)
                const permissions = responseData?.permission;
                setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
                setResponseForMedia(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view === 0 ? false : true,
                        deny: data?.can_deny === 0 ? false : true,
                        edit: data?.can_edit === 0 ? false : true,
                        delete: data?.can_delete === 0 ? false : true,
                        redacted: data?.can_resource === 0 ? false : true,
                        type: data?.type,
                    };
                });
                const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
                const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
                setMediaDirectUrl(responseData?.file_path)
                setPermissionsForMedia(finalPermissions)
                setIsAdvancePermissionForMedia(
                    responseData?.advance_permission === 0 ? false : true
                );
                setSelectedFileMedia({
                    url: responseData?.file_path,
                    type: mimeType,
                    name: responseData?.title || "Uploaded File"
                });
                setOpenFileUploadModelMedia(true)
                Toast.success(response?.message);
            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchMediaDetailsForRename = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/file/edit/${selectedMedia}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                const responseData = response?.response
                setLoading(false);
                setMediaTitle(responseData?.title)
                setMediaAltText(responseData?.alt_text)
                setMediaDescription(responseData?.description)
                const permissions = responseData?.permission;
                setIsSystemTypeMedia(responseData?.is_system === 0 ? false : true)
                setResponseForMedia(responseData)
                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view === 0 ? false : true,
                        deny: data?.can_deny === 0 ? false : true,
                        edit: data?.can_edit === 0 ? false : true,
                        delete: data?.can_delete === 0 ? false : true,
                        redacted: data?.can_resource === 0 ? false : true,
                        type: data?.type,
                    };
                });
                const fileExtension = responseData?.file_path?.split('.').pop().toLowerCase();
                const mimeType = getMimeType(fileExtension);  // You can create a helper function to get mime type from file extension
                setMediaDirectUrl(responseData?.file_path)
                setPermissionsForMedia(finalPermissions)
                setIsAdvancePermissionForMedia(
                    responseData?.advance_permission === 0 ? false : true
                );
                setSelectedFileMedia({
                    url: responseData?.file_path,
                    type: mimeType,
                    name: responseData?.title || "Uploaded File"
                });
                setOpenRenameModel(true)
                Toast.success(response?.message);
            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in ctreate news api::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 422) {
                setLoading(false);
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const storeMedia = async () => {
        const validate = await validateMedia()

        if (validate) {
            const formData = new FormData();
            let permissionData;
            formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
            formData.append("title", mediaTitle);
            formData.append("alt_text", mediaAltText);
            formData.append("description", mediaDescription);
            formData.append("advance_permission", 0);

            if (isAdvancePermissionForMedia === true) {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }

            formData.append("folder_id", parentFolderId)

            if (isEditMedia === true) {
                formData.append("direct_url", responseForMedia?.file_path)
            }

            formData.append("permission", JSON?.stringify(permissionData));

            if (
                selectedFileMedia?.file instanceof Blob ||
                selectedFileMedia?.file instanceof File
            ) {
                formData.append("file", selectedFileMedia?.file);
            }

            if (isEditMedia === true) {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/update/${selectedMedia}`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        let media_id;
                        if (mediaDataFor === "image") {
                            setSelectedMediaFileForImage(response?.response?.id)
                            media_id = response?.response?.id;
                            setSelectedMediaFileDetailsForImage(response?.response)
                            // setSelectedFileMedia(response?.response?.id)
                        } else if (mediaDataFor === "doc") {
                            setSelectedMediaFileForDoc(response?.response?.id)
                            media_id = response?.response?.id;
                            setSelectedMediaFileDetailsForDoc(response?.response)
                            // setSelectedFileMedia(response?.response?.id)
                        }

                        await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id)
                        Toast.success(response?.message);
                        handleCloseForMediaModel()

                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            } else {
                try {
                    setLoading(true);
                    const response = await api.post(
                        `user/media/file/store`,
                        formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    if (response?.success === true) {
                        setLoading(false);
                        console.log("line 741")
                        Toast?.success(response?.message);
                        let media_id;
                        if (mediaDataFor === "image") {
                            console.log("2282", response?.response?.id)
                            setSelectedMediaFileForImage(response?.response?.id)
                            media_id = response?.response?.id
                            setSelectedMediaFileDetailsForImage(response?.response)
                        } else if (mediaDataFor === "doc") {
                            setSelectedMediaFileForDoc(response?.response?.id)
                            media_id = response?.response?.id
                            setSelectedMediaFileDetailsForDoc(response?.response)
                        }
                        console.log("line 754")
                        fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id)
                        handleCloseForMediaModel()

                    } else {
                        setLoading(false);
                        Toast.error(response?.message);
                    }
                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            }

        }
    }

    const fetchMediaFolders = async (dataFor) => {
        try {
            setLoading(true);
            const response = await api.get(
                `user/media/browse/folders`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                setFolderList(response?.response)
                setMyFolderList(response?.response?.my_media_folder)
                setSystemFolderList(response?.response?.system_media_folder)
                let media_id;
                if (dataFor == "image") {
                    setSelectedMediaTypeForImage(selectedMediaTypeForImage == "" ? "my-media" : selectedMediaTypeForImage)
                    media_id = selectedMediaFileForImage
                    setSelectedMediaFolderIDForImage(selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage)
                } else if (dataFor == "doc") {
                    setSelectedMediaTypeForDoc(selectedMediaTypeForDoc == "" ? "my-media" : selectedMediaTypeForDoc)
                    media_id = selectedMediaFileForDoc
                    setSelectedMediaFolderIDForDoc(selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc)
                }
                fetchMediaFiles(pageForMedia, rowsPerPageForMedia, dataFor, media_id)
                setOpenMediaUpload(true)
                setLoading(false)
            } else if (response?.success == false) {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/categories`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/categories`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchMediaFiles = async (pageNo, perPage, dataFor, mediaFile) => {
        let type;
        if (dataFor === "image") {
            if (selectedMediaTypeForImage === "system") {
                type = "system"
            } else {
                type = "user"
            }
        } else if (dataFor === "doc") {
            if (selectedMediaTypeForDoc === "system") {
                type = "system"
            } else {
                type = "user"
            }
        }
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/browse/files?page=${pageNo}`,
                {
                    folder_id: dataFor === "image" ? selectedMediaFolderIDForImage : selectedMediaFolderIDFordoc,
                    type: type,
                    per_page: perPage,
                    file_type: dataFor
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {

                setMediaListResponse(response?.response)
                setMediaList(response?.response?.data)
                if (dataFor === "image" && mediaFile !== "") {
                    console.log("line 3928", mediaFile)
                    setSelectedMediaFileForImage(mediaFile)
                    setSelectedMediaFolderIDForImage(selectedMediaFolderIDForImage)
                } else if (dataFor === "doc" && selectedMediaFileForDoc !== "") {
                    setSelectedMediaFileForDoc(selectedMediaFileForDoc)
                    setSelectedMediaFolderIDForDoc(selectedMediaFolderIDFordoc)
                }
                setLoading(false)
            } else if (response?.success === false) {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/categories`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/categories`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const handleChangePageForMedia = (event, newPage) => {
        if (newPage > pageForMedia) {
            setPageForMedia(newPage);
            let media_id;
            if (mediaDataFor === "image") {
                media_id = selectedMediaFileForImage
            } else if (mediaDataFor === "doc") {
                media_id = selectedMediaFileForDoc
            }
            fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id)
        } else if (newPage < pageForMedia) {
            setPageForMedia(newPage);
            let media_id;
            if (mediaDataFor === "image") {
                media_id = selectedMediaFileForImage
            } else if (mediaDataFor === "doc") {
                media_id = selectedMediaFileForDoc
            }
            fetchMediaFiles(newPage + 1, rowsPerPageForMedia, mediaDataFor, media_id)
        }
    };

    const handleChangeForMedia = (event, newValue) => {
        setValueForMediaTab(newValue);
    };

    const deleteMedia = async (id) => {
        let mediID;
        if (mediaDataFor === "image") {
            mediID = selectedMediaFileForImage
        } else if (mediaDataFor === "doc") {
            mediID = selectedMediaFileForDoc
        }
        try {
            setLoading(true);
            const response = await api.post(
                `user/media/file/delete/${mediID}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success === true) {
                setLoading(false);

                Toast.success(response?.message);
                let media_id;
                if (mediaDataFor === "image") {
                    setSelectedMediaFileForImage("")
                    media_id = "";
                    setSelectedMediaFileDetailsForImage()
                } else if (mediaDataFor === "doc") {
                    setSelectedMediaFileForDoc("")
                    media_id = "";
                    setSelectedMediaFileDetailsForDoc()
                }

                await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id)
                handleCloseForMediaModel()

            } else {
                setLoading(false);
                Toast.error(response?.message);
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/categories`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/categories`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const renameMedia = async () => {
        const validate = await validateMedia()

        if (validate) {
            const formData = new FormData();
            let permissionData;
            formData.append("is_system", isSystemTypeMedia === true ? 1 : 0);
            formData.append("title", mediaTitle);
            formData.append("alt_text", mediaAltText);
            formData.append("description", mediaDescription);
            formData.append("advance_permission", 0);

            if (isAdvancePermissionForMedia === true) {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissionsForMedia?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: 0,
                        can_deny: 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_resource: 0,
                        label: data?.label,
                    };
                });
            }

            formData.append("folder_id", parentFolderId)

            formData.append("direct_url", responseForMedia?.file_path)

            formData.append("permission", JSON?.stringify(permissionData));

            if (
                selectedFileMedia?.file instanceof Blob ||
                selectedFileMedia?.file instanceof File
            ) {
                formData.append("file", selectedFileMedia?.file);
            }
            try {
                setLoading(true);
                const response = await api.post(
                    `user/media/file/update/${selectedMedia}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Bearer ${userToken}`,
                        },
                    }
                );
                if (response?.success === true) {
                    setLoading(false);
                    let media_id;
                    let folderID;

                    if (mediaDataFor === "image") {
                        setSelectedMediaFileForImage(response?.response?.id)
                        media_id = response?.response?.id;
                        folderID = selectedMediaFolderIDForImage == "" ? 0 : selectedMediaFolderIDForImage
                        setSelectedMediaFileDetailsForImage(response?.response)
                    } else if (mediaDataFor === "doc") {
                        setSelectedMediaFileForDoc(response?.response?.id)
                        media_id = response?.response?.id;
                        setSelectedMediaFileDetailsForDoc(response?.response)
                        folderID = selectedMediaFolderIDFordoc == "" ? 0 : selectedMediaFolderIDFordoc

                    }

                    await fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, media_id, folderID)
                    Toast.success(response?.message);
                    handleCloseForMediaModel()
                } else {
                    setLoading(false);
                    Toast.error(response?.message);
                }
            } catch (err) {
                console.log("error in ctreate news api::", err);
                setLoading(false);

                if (err?.response?.status === 401) {
                    setLoading(false);
                    logoutHelper(dispatch, navigate, userToken);
                }
                if (err?.response?.status === 422) {
                    setLoading(false);
                    const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                }
                if (err?.response?.status === 429) {
                    Toast.error("Too many attemps wait for 2 min.")
                }
            }
        }
    }

    const handleChangeRowsPerPageForMedia = (event) => {
        setRowsPerPageForMedia(event.target.value);
        setPageForMedia(0);
        let media_id;
        if (mediaDataFor === "image") {
            media_id = selectedMediaFileForImage
        } else if (mediaDataFor === "doc") {
            media_id = selectedMediaFileForDoc
        }
        fetchMediaFiles(0, event.target.value, mediaDataFor, media_id)
    };

    const handleCloseForMediaModel = () => {
        setOpenFileUploadModelMedia(false)
        setMediaTitle("")
        setMediaAltText("")
        setMediaDescription("")
        setMediaDirectUrl("")
        setIsSystemTypeMedia(false)
        setSelectedFileMedia(null)
        setValueForMediaTab(0)
        setSelectedMedia("")
        setResponseForMedia()
        setIsEditMedia(false)
        setIsRenameMedia(false)
        setOpenRenameModel(false)
        setOpenMediaModelFor("")
    }

    const fetchUserMenuList = async (menuArray) => {
        setLoading(true);

        const menuData = {}; // Object to store data by menu type

        try {
            // Use Promise.all to fetch data for all menu types concurrently
            const responses = await Promise.all(
                menuArray.map(async (menuItem) => {
                    const response = await api.post(
                        `user/admin-menu`,
                        { menu_id: menuItem.type },
                        {
                            headers: {
                                Authorization: `Bearer ${userToken}`,
                            },
                        }
                    );
                    // Save the fetched data by menu type and include the position
                    menuData[menuItem.type] = {
                        position: menuItem.position,
                        items: response?.success ? response?.response : [],
                    };
                    return menuItem.type; // Return the type for debugging
                })
            );
            // Save data by menu type
            setUserMenuLists(menuData);
            setLoading(false);
        } catch (err) {
            console.error("Error fetching menu details:", err);
            setLoading(false);
        }
    };

    const fetchTemplate = async () => {
        setLoading(true)
        try {
            const response = await api.get(`${loggedUser?.user_type === "admin" ? "admin" : "user"}/page/view-page/${slug} `, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const htmlData = response?.response?.editor_html
                const cssData = response?.response?.editor_css
                const jsData = response?.response?.editor_js
                const componentData = response?.response?.editor_components
                const styleData = response?.response?.editor_styles
                const menuData = response?.response?.menu ? JSON.parse(response?.response?.menu) : {};
                const linkdata = response?.response?.link ? JSON.parse(response?.response?.link) : {};

                setPageTitle(response?.response?.title)

                dispatch(pageAction?.setTemplateData({ htmlData, cssData, jsData, componentData, styleData }))
                dispatch(pageAction?.setMenuID(menuData))
                dispatch(pageAction?.setLinkData(linkdata))

                // Parse "other" field for YouTube data
                const othersData = JSON.parse(response?.response?.other || "{}");
                if (othersData?.youtube?.length > 0 || othersData?.types?.length > 0 || othersData?.people?.length > 0 || othersData?.feed?.length > 0) {
                    const youtubeData = othersData.youtube.map((data) => ({
                        title: data?.title,
                        url: data?.url,
                    }));
                    const typesData = othersData?.types?.map((data) => ({
                        typeID: data?.typeID,
                        categoryID: data?.categoryID,
                        layout: data?.layout,
                        max: data?.max,
                        title: data?.title
                    }))
                    const peopleData = othersData?.people?.map((data) => ({
                        title: data?.title,
                        groups: data?.groups,
                        users: data?.users,
                        sort: data?.sort,
                        viewtype: data?.viewtype,
                        showpeople: data?.showpeople,
                        showphoto: data?.showphoto,
                        showemail: data?.showemail,
                        showjobtitle: data?.showjobtitle,
                        showmobile: data?.showmobile
                    }))
                    const feedData = othersData?.feed?.map((data) => ({
                        title: data?.title,
                        feedTypes: data?.feedTypes,
                        categories: data?.categories,
                        showUpdateBox: data?.showUpdateBox,
                        id: data?.id
                    }))
                    dispatch(pageAction?.setYoutubeData(youtubeData));
                    dispatch(pageAction?.setTypesData(typesData))
                    dispatch(pageAction?.setPeopleData(peopleData))
                    dispatch(pageAction?.setFeedData(feedData))
                } else {
                    dispatch(pageAction?.setYoutubeData([]));
                }
                setLoading(false)
            }
        } catch (err) {
            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchLinkData = async (linkIds) => {
        setLoading(true);
        const newLinkData = {};
        try {
            for (const linkId of linkIds) {
                const response = await api.get(`user/user-link-list/?label_id=${linkId}`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                if (response?.success === true) {
                    newLinkData[linkId] = response.response.data; // Store data by ID
                } else if (response?.success === false) {
                    Toast.warning(`Failed to fetch data for linkId ${linkId}: ${response.message}`);
                    localStorage.setItem("last-redirect-page", `/links`);
                }
            }
            setLinksData(newLinkData); // Update state with ID-wise data
        } catch (err) {
            console.error("Error in fetch links list API:", err);
            if (err?.response?.status === 401) {
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/links`);
            }
            if (err?.response?.status === 403) {
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/links`);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attempts. Please wait for 2 minutes.");
            }
        } finally {
            setLoading(false);
        }
    };

    const fetchTypesData = async (types) => {
        const fetchedData = {}; // Object to store results by typeID
        setLoading(true);

        try {
            // Use Promise.all to fetch data concurrently
            await Promise.all(
                types.map(async (typeItem) => {
                    const { typeID, categoryID: catID, max } = typeItem;
                    try {
                        const response = await api.post(
                            `user/post/post-list/${typeID}`,
                            { category_id: catID, per_page: max },
                            {
                                headers: {
                                    Authorization: `Bearer ${userToken}`,
                                },
                            }
                        );

                        // Save the data by typeID
                        fetchedData[typeID] = {
                            layout: typeItem?.layout,
                            title: typeItem?.title,
                            data: response?.data
                        };

                    } catch (err) {
                        console.error(`Error fetching data for typeID ${typeID}:`, err);
                    }
                })
            );

            // Update the state with fetched data
            setTypesData(fetchedData);
        } catch (err) {
            console.error("Error fetching types data:", err);
        } finally {
            setLoading(false);
        }
    };

    const fetchPeopleData = async (people, search) => {
        const fetchedData = {}; // Object to store results by typeID
        setLoading(true);

        try {
            // Use Promise.all to fetch data concurrently
            await Promise.all(
                people.map(async (typeItem, index) => {
                    const { groups, users, sort, title, viewtype, showpeople, showphoto, showemail, showjobtitle, showmobile } = typeItem;
                    try {
                        const response = await api.post(
                            `admin/page/component/user-list`,
                            { group: groups, search: search, user_id: users, order: sort },
                            {
                                headers: {
                                    Authorization: `Bearer ${userToken}`,
                                },
                            }
                        );
                        // Save the data by typeID
                        fetchedData[index] = {
                            title: title,
                            viewtype: viewtype,
                            showpeople: showpeople,
                            showphoto: showphoto,
                            showemail: showemail,
                            showjobtitle: showjobtitle,
                            showmobile: showmobile,
                            data: response?.response?.data
                        };
                    } catch (err) {
                        console.error(`Error fetching data for typeID :`, err);
                    }
                })
            );

            // Update the state with fetched data
            setPeopleData(fetchedData);
        } catch (err) {
            console.error("Error fetching types data:", err);
        } finally {
            setLoading(false);
        }
    };

    const fetchFeedData = async (page) => {
        setLoading(true);

        try {
            const newFetchedData = {}; // Temporary object to store newly fetched data

            await Promise.all(
                feed.map(async (item, index) => {
                    const { title, feedTypes, categories, showUpdateBox } = item;

                    try {
                        const response = await api.post(
                            `admin/page/component/feed-list`,
                            { category_id: categories, type_id: feedTypes, page: page },
                            {
                                headers: {
                                    Authorization: `Bearer ${userToken}`,
                                },
                            }
                        );

                        // Existing and fetched data
                        const existingData = feedData[index]?.data || []; // Get existing data if present
                        const newData = response?.response?.data || []; // Newly fetched data

                        // Merge data based on isFeedDataForUpdatePost
                        const mergedData = isFeedDataForUpdatePost
                            ? [
                                ...newData.filter(
                                    (newItem) => !existingData.some((existingItem) => existingItem.id === newItem.id)
                                ),
                                ...existingData,
                            ] // Add new data at the top
                            : [
                                ...existingData,
                                ...newData.filter(
                                    (newItem) => !existingData.some((existingItem) => existingItem.id === newItem.id)
                                ),
                            ]; // Add new data at the bottom

                        // Add merged data to the newFetchedData object
                        newFetchedData[index] = {
                            title: title,
                            feedTypes: feedTypes,
                            categories: categories,
                            showUpdateBox: showUpdateBox,
                            data: mergedData, // Updated order
                        };
                        // console.log("newFetchedData:::", newFetchedData)
                        setTotalPagesFeed(response?.response?.meta?.last_page ?? 1);
                    } catch (err) {
                        console.error(`Error fetching data for typeID :`, err);
                    }
                })
            );

            // Update the state with merged data
            setFeedData((prevFeedData) => ({
                ...prevFeedData,
                ...newFetchedData,
            }));
        } catch (err) {
            console.error("Error fetching types data:", err);
        } finally {
            setIsDataFetchingForFeed(false); // Reset fetching state after the API call
            setLoading(false);
        }
    };

    const fetchFeedDataAfterDelete = async (page) => {
        setLoading(true);

        try {
            const newFetchedData = {}; // Temporary object to store newly fetched data

            await Promise.all(
                feed.map(async (item, index) => {
                    const { title, feedTypes, categories, showUpdateBox } = item;

                    try {
                        const response = await api.post(
                            `admin/page/component/feed-list`,
                            { category_id: categories, type_id: feedTypes, page: page },
                            {
                                headers: {
                                    Authorization: `Bearer ${userToken}`,
                                },
                            }
                        );

                        // Directly use the newly fetched data
                        const newData = response?.response?.data || [];

                        // Add fetched data to newFetchedData
                        newFetchedData[index] = {
                            title,
                            feedTypes,
                            categories,
                            showUpdateBox,
                            data: newData, // Directly replacing with new data
                        };

                        // Set total pages for feed
                        setTotalPagesFeed(response?.response?.meta?.last_page ?? 1);
                    } catch (err) {
                        console.error(`Error fetching data for typeID :`, err);
                    }
                })
            );

            // Update the feedData state with the newFetchedData
            setFeedData((prevFeedData) => ({
                ...prevFeedData,
                ...newFetchedData,
            }));
        } catch (err) {
            console.error("Error fetching types data:", err);
        } finally {
            setIsDataFetchingForFeed(false); // Reset fetching state after the API call
            setLoading(false);
        }
    };

    const deleteTypePostAPI = async (id) => {
        try {

            setLoading(true);
            const response = await api.delete(`user/post/delete/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                console.log("line 1874::")
                // handleCloseDeleteAlertModel()
                // const updatedFeedData = {
                //     ...feedData, // Copy the original feedData object
                //     0: {
                //         ...feedData[0], // Copy the first object
                //         data: feedData[0]?.data?.filter((data) => data?.id !== id), // Filter out the deleted post
                //     },
                // };

                // setFeedData(updatedFeedData);
                // console.log("updatedPosts::", updatedFeedData)
                Toast.success(response?.message);
                setLoading(false);
                handleCloseDeleteAlertModel()
                fetchFeedDataAfterDelete(pageFeed)

            } else if (response?.success == false) {

                Toast.warning(response?.message);
                setLoading(false);
            }
        } catch (err) {
            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
            setLoading(false);
        }
    };

    const generateMenuItems = (menuItems) => {
        return menuItems
            .map((item) => {
                const { id, link, title, icon, icon_colour, sub_menu } = item;
                const iconData = icon?.icon_value;

                return `
                <li class="MuiListItem-root menu-item-list-root" key="${id}">
                    <div class="MuiButtonBase-root menu-item-button-base-root MuiListItemButton-root MuiListItemButton-gutters">
                        <a href="${link}" class="custom-menu-link">
                            <div class="MuiListItemIcon-root menu-list-item-icon-root" style="color: ${icon_colour}">
                                <svg 
                                    aria-hidden="true" 
                                    focusable="false" 
                                    data-prefix="${iconData?.prefix}" 
                                    data-icon="${iconData?.iconName}" 
                                    class="svg-inline--fa fa-${iconData?.iconName} custom-menu-icon" 
                                    role="img" xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 ${iconData?.icon[0]} ${iconData?.icon[1]}">
                                    <path fill="currentColor" d="${iconData?.icon[4]}"></path>
                                </svg>
                            </div>
                            <div class="MuiListItemText-root menu-name">
                                <span>${title}</span>
                            </div>
                        </a>
                        ${sub_menu?.length
                        ? `<div class="submenu-toggle-wrapper" data-id="${id}">
                                    <svg 
                                        aria-hidden="true" 
                                        focusable="false" 
                                        data-prefix="fas" 
                                        data-icon="chevron-down" 
                                        class="svg-inline--fa fa-chevron-down menu-icon toggle-icon" 
                                        role="img" xmlns="http://www.w3.org/2000/svg" 
                                        viewBox="0 0 512 512">
                                        <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                                    </svg>
                                </div>`
                        : ""
                    }
                    </div>
                    ${sub_menu?.length
                        ? `<ul class="sub-menu hidden" id="submenu-${id}">
                                ${generateMenuItems(sub_menu)}
                            </ul>`
                        : ""
                    }
                </li>`;
            })
            .join("");
    };

    const generateLinkBlocks = (linksData) => {
        return linksData?.map((data) => {
            const { background, colour, icon, name, link } = data;
            const iconData = icon?.icon_value;

            return `
            <div 
                class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-4 link-list-item-grid css-11l5t4l-MuiGrid-root">
                 <a href="${link}" target="_blank">
                <div 
                    class="py-3 link-icon-box MuiBox-root css-15bwb4e" 
                    style="background-color: ${background}; color: ${colour}">
                    <div class="icon-content MuiBox-root css-0">
                        <div class="icon-box MuiBox-root css-0">
                            <svg 
                                aria-hidden="true" 
                                focusable="false" 
                                data-prefix="${iconData?.prefix}" 
                                data-icon="${iconData?.iconName}" 
                                class="svg-inline--fa fa-${iconData?.iconName} icon-value" 
                                role="img" 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 ${iconData?.icon[0]} ${iconData?.icon[1]}">
                                <path fill="currentColor" d="${iconData?.icon[4]}"></path>
                            </svg>
                        </div>
                        <div class="link-label-text MuiBox-root css-0">
                            <p 
                                class="MuiTypography-root link-block-link-name MuiTypography-body1 link-label-box css-ahj2mt-MuiTypography-root">
                                ${name}
                            </p>
                        </div>
                    </div>
                </div>
                </a>
            </div>
        `;
        }).join("");
    };

    const generateYoutubeBlocks = ({ youtubeUrl, title }) => {
        let embedUrl = '';
        try {
            if (youtubeUrl) {
                const url = new URL(youtubeUrl);
                embedUrl = `https://www.youtube.com/embed/${url.searchParams.get('v')}`;
            }
        } catch (error) {
            console.error("Invalid YouTube URL:", youtubeUrl);
        }

        // Create the container div
        const container = document.createElement('div');
        container.style.textAlign = 'center';
        container.style.padding = '5px';
        container.style.height = "100%"

        // Create and append the title
        const titleElement = document.createElement('h3');
        titleElement.textContent = title || 'YouTube Video';
        container.appendChild(titleElement);

        // Create and append the iframe
        if (embedUrl) {
            const iframe = document.createElement('iframe');
            iframe.style.width = '80%';
            iframe.style.height = '80%';
            iframe.src = embedUrl;
            iframe.title = 'YouTube video player';
            iframe.frameBorder = '0';
            iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
            iframe.allowFullscreen = true;
            container.appendChild(iframe);
        } else {
            const errorText = document.createElement('p');
            errorText.textContent = 'Please provide a valid YouTube URL.';
            container.appendChild(errorText);
        }

        return container;
    };

    const generateEmbedBlocks = (attributes) => {
        const { title, embedCode } = attributes; // Destructure title and embedCode from attributes

        return `
        <div class="embed-block-preview">
            <h1>${title || "Default Title"}</h1>
            <div>${embedCode || "Enter embed code here"}</div>
        </div>
    `;
    };

    const generateTypesBlock = (typeData) => {
        return (
            <div className="mt-2 row row-main">
                {typeData?.title !== "" && (
                    <h3 className="text-center">{typeData?.title}</h3>
                )}
                {typeData?.data?.length > 0 ? (
                    <>
                        {typeData?.layout === "cards" ? (
                            <>
                                {typeData?.data?.map((post) => (
                                    <div className="col-lg-3 col-md-6 col-sm-12" key={post?.id || post?.title}>
                                        <div className="MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root news-card-wrapper css-1rz4ayn-MuiPaper-root-MuiCard-root">
                                            <img
                                                className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img cursor-pointer type-post-img css-pp913-MuiCardMedia-root"
                                                src={post?.image}
                                                alt={post?.title}
                                            />
                                            <div className="MuiCardContent-root card-content-post css-46bh2p-MuiCardContent-root">
                                                <div className="gap-10 h-100 gaps-for-stack vstack">
                                                    <div className="gap-10 news-date justify-content-between stack-for-stack hstack">
                                                        <div className="d-flex align-items-center gap-2 div-for-avatar">
                                                            <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root">
                                                                {post?.created_by?.charAt(0)}
                                                            </div>
                                                            <span className="avatar-span-className">{post?.created_by}</span>
                                                        </div>
                                                    </div>
                                                    <div className="main-content-data-box MuiBox-root css-0">
                                                        <div className="d-flex flex-wrap gap-3 stack-for-data-title hstack">
                                                            <h4
                                                                className="news-title dark-text fw-400 cursor-pointer text-break"
                                                                style={{ height: "28px" }}
                                                            >
                                                                {post?.title}
                                                            </h4>
                                                        </div>
                                                        <p
                                                            className="news-para dark-text fw-400 opacity-50 text-break"
                                                            style={{ height: "46px" }}
                                                        >
                                                            {post?.content}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                    {typeData?.data?.map((_, index) => (
                                        <button
                                            type="button"
                                            data-bs-target="#carouselExampleCaptions"
                                            data-bs-slide-to={index}
                                            className={index === 0 ? "active" : ""}
                                            aria-current={index === 0 ? "true" : "false"}
                                            aria-label={`Slide ${index + 1}`}
                                            key={`indicator-${index}`}
                                        ></button>
                                    ))}
                                </div>
                                <div className="carousel-inner">
                                    {typeData?.data?.map((post, index) => (
                                        <div
                                            className={`carousel-item ${index === 0 ? "active" : ""}`}
                                            key={`carousel-item-${index}`}
                                        >
                                            <img src={post?.image} className="d-block w-100" alt={post?.title} />
                                            <div className="carousel-caption d-none d-md-block">
                                                <h5>{post?.title}</h5>
                                                <p>{post?.content}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide="prev"
                                >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#carouselExampleCaptions"
                                    data-bs-slide="next"
                                >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <h1>No data available</h1>
                )}
            </div>
        );
    }

    const generatePeopleBlock = (peopleData) => {
        return (
            <>
                {peopleData?.title !== null && (
                    <h1 className="text-center">{peopleData?.title}</h1>
                )}
                <div className="mt-2 row row-main p-2">
                    {peopleData?.showpeople === "YES" && (
                        <div className="row g-3">
                            <div className="col-auto">
                                <input type="text" className="form-control" placeholder="search.." value={searchQuery} // Bind to state
                                    onChange={(e) => setSearchQuery(e.target.value)} // Update state on change 
                                />
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary mb-3" id={`search-button-${peopleData?.title}`}>Search People</button>
                            </div>
                        </div>
                    )}
                    {peopleData?.data?.length > 0 ? (
                        <>
                            {peopleData?.viewtype === "cards" ? (
                                <>
                                    {peopleData?.data?.map((user) => (
                                        <>
                                            <div className="col-lg-6 col-md-6 col-sm-12" >
                                                <div className="card mb-3" style={{ maxWidth: "540px" }}>
                                                    <div className="row g-0">
                                                        {peopleData?.showphoto === "YES" && (
                                                            <div className="col-md-4">
                                                                <img src={user?.thumbnail} className="img-fluid rounded-start h-100" alt={user?.name} />
                                                            </div>
                                                        )}

                                                        <div className="col-md-8">
                                                            <div className="card-body">
                                                                <h5 className="card-title">Name: {user?.name} {user?.last_name}</h5>
                                                                {peopleData?.showemail === "YES" && (
                                                                    <p className="card-text">Email: {user?.email}</p>
                                                                )}
                                                                {peopleData?.showjobtitle === "YES" && (
                                                                    <p className="card-text">Job title: {user?.job_title}</p>
                                                                )}
                                                                {peopleData?.showmobile === "YES" && (
                                                                    <p className="card-text">Mobile: {user?.phone}</p>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <table className="table table-striped p-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Photo</th>
                                                <th scope="col">Job Title</th>
                                                <th scope="col">Mobile Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {peopleData?.data?.map((user) => (
                                                <>
                                                    <tr>
                                                        <th scope="row">{user?.name} {user?.last_name}</th>
                                                        {peopleData?.showemail === "YES" ? (
                                                            <td>{user?.email}</td>
                                                        ) : (
                                                            <td>-</td>
                                                        )}
                                                        {peopleData?.showphoto === "YES" ? (
                                                            <td> <img src={user?.thumbnail} className="img-fluid rounded-start" alt={user?.name} height="100px" width="100px" /></td>
                                                        ) : (
                                                            <td>-</td>
                                                        )}
                                                        {peopleData?.showjobtitle === "YES" ? (
                                                            <td>{user?.job_title}</td>
                                                        ) : (
                                                            <td>-</td>
                                                        )}
                                                        {peopleData?.showmobile === "YES" ? (
                                                            <td>{user?.phone}</td>
                                                        ) : (
                                                            <td>-</td>
                                                        )}

                                                    </tr>

                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <h2>No data available</h2>
                        </>
                    )}

                </div >
            </>
        )
    }

    const generateFeedBlock = (feedData) => {
        return (
            <div className="px-3 py-2">
                {feedData?.title && (
                    <h3 className="text-center">{feedData?.title}</h3>
                )}
                {feedData?.showUpdateBox === "YES" && (
                    // <div class="serach-box w-100 MuiBox-root css-0 mt-2">
                    //     <div class="input-group update-box-search-div">
                    //         <input placeholder="Post an update" aria-label="Recipient's username"
                    //             aria-describedby="basic-addon2" class="search-input update-box-search w-100 form-control" />
                    //         <button type="button"
                    //             class="search-btn-dash-2 btn btn-primary"><svg aria-hidden="true" focusable="false" data-prefix="fab"
                    //                 data-icon="telegram" class="svg-inline--fa fa-telegram " role="img" xmlns="http://www.w3.org/2000/svg"
                    //                 viewBox="0 0 496 512">
                    //                 <path fill="currentColor"
                    //                     d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z">
                    //                 </path>
                    //             </svg>
                    //         </button>
                    //     </div>
                    // </div>
                    <div class="update-type-post-card card">
                        <div class="MuiCardContent-root update-type-post-card-content css-46bh2p-MuiCardContent-root">
                            <div class="MuiBox-root css-0">
                                <div class="MuiBox-root css-0"><span style={{ fontSize: "20px" }}>Welcome to platform, <b>{loggedUser?.full_name} </b></span>
                                </div>
                                <div class="mt-3 d-flex justify-content-center align-items-center gap-2 MuiBox-root css-0 serach-box">
                                    <div class="MuiBox-root css-0">
                                        {loggedUser?.thumbnail !== "" ? (
                                            <div class="MuiAvatar-root MuiAvatar-circular update-avtar-creator css-1wlk0hk-MuiAvatar-root" aria-label="recipe">
                                                <img src={loggedUser?.thumbnail} class="MuiAvatar-img feed-update-post-user-img css-1pqm26d-MuiAvatar-img" />
                                            </div>
                                        ) : (
                                            <div class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator-post css-17o22dy-MuiAvatar-root" aria-label="recipe">
                                                {loggedUser?.full_name?.charAt(0)}
                                            </div>
                                        )}
                                    </div>
                                    <div class="w-100 MuiBox-root css-0 update-box-search-div">
                                        <input placeholder="Post an update" aria-label="Recipient's username" aria-describedby="basic-addon2" class="search-input w-100 form-control update-box-search" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {feedData?.data?.length > 0 ? (
                    <>
                        {feedData?.data?.map((feed, index) => (
                            <>
                                {feed?.type?.name === "Update" ? (
                                    <>
                                        {/* <div data-post-id={feed?.id} className="mt-4 card feed-card">
                                            <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                                <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                                    {feed?.profile !== "" ? (
                                                        <>
                                                            <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                                aria-label="recipe">
                                                                <img src={feed?.profile} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                                aria-label="recipe">{feed?.created_by?.charAt(0)}
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content"><span
                                                    className="MuiTypography-body2  MuiCardHeader-title">
                                                    <div className="MuiBox-root feed-top-header css-69i1ev"><span><b>{feed?.created_by}</b> posted a <b>{feed?.type?.name}</b></span><span
                                                        className="MuiTypography-caption">{feed?.published_date}</span></div>
                                                </span></div>
                                            </div>
                                            
                                            {feed?.multi_image?.length > 0 && (
                                                feed?.multi_image?.length === 1 ? (
                                                    <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" role="img"
                                                        style={{
                                                            backgroundImage: `url(${feed?.multi_image[0]?.file_url})`,
                                                        }}>
                                                    </div>
                                                ) : (
                                                    <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-spacing-md-3 update-post-grid-container p-3 css-1xgjpwf-MuiGrid-root">
                                                        {feed?.multi_image.map((file, index) => (
                                                            <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-4 MuiGrid-grid-md-3 css-1vm8tgf-MuiGrid-root update-post-grid-item">
                                                                <div className="upload-img-wrapper" style={{ height: "162px" }}>
                                                                    <img src={file?.file_url} alt={file?.alt_text || `Image ${index + 1}`} className="" style={{ height: "100%", width: "100%" }} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )
                                            )}
                                            <div class="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                                <div class="MuiBox-root css-0">
                                                    <h3
                                                        class="MuiTypography-h3 content-title-update text-break">
                                                        <span>{feed?.content}</span></h3>
                                                </div>
                                            </div>
                                            <div className="MuiCardActions-root MuiCardActions-spacing bg-white card-action-2 css-c7tfsh-MuiCardActions-root"></div>
                                        </div> */}
                                        <div data-post-id={feed?.id} className="mt-4 card feed-card" isedit={feed?.is_edit === 1 ? "true" : "false"}
                                            isdelete={feed?.is_delete === 1 ? "true" : "false"} typeName={feed?.type?.name} typeID={feed?.type?.id} typeSlug={feed?.type?.slug}>
                                            <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                                <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                                    {feed?.created_by?.thumbnail !== "" ? (
                                                        <>
                                                            <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                                aria-label="recipe">
                                                                <img src={feed?.created_by?.thumbnail} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                                aria-label="recipe">{feed?.created_by?.name?.charAt(0)}
                                                            </div>
                                                        </>
                                                    )}

                                                </div>
                                                <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                                    <span className="MuiTypography-body2  MuiCardHeader-title">
                                                        <div className="MuiBox-root feed-top-header css-69i1ev">
                                                            <span className="post-posted-label"><b>{feed?.created_by?.name}</b> posted a <b>{feed?.type?.name}</b></span>
                                                            <div class="MuiBox-root css-0">
                                                                <span
                                                                    class=" MuiTypography-caption post-date-label css-1sn4lm3-MuiTypography-root">{formatDateToReadable(feed?.published_date_time)}
                                                                </span>
                                                                <button
                                                                    class="MuiIconButton-root MuiIconButton-sizeMedium edit-post-icon-btn css-78trlr-MuiButtonBase-root-MuiIconButton-root"
                                                                    tabindex="0" type="button" aria-label="more" data-post-id={feed?.id} aria-haspopup="true"><svg
                                                                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                                        focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreVertIcon">
                                                                        <path
                                                                            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2">
                                                                        </path>
                                                                    </svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="MuiCardContent-root content-div-body-2 css-46bh2p-MuiCardContent-root">
                                                <div className="MuiBox-root css-0">
                                                    <h3
                                                        className="MuiTypography-h3 content-title-update text-break">
                                                        <span>{feed?.content}</span>
                                                    </h3>
                                                    {feed?.meta_data && Object.keys(feed?.meta_data).length > 0 && <InView><PreviewLink key={index} linkData={feed?.meta_data} /></InView>}
                                                </div>
                                                {feed?.multi_image?.length > 0 && (
                                                    feed?.multi_image?.length === 1 ? (
                                                        <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" data-post={JSON.stringify(feed)} role="img"
                                                            style={{
                                                                backgroundImage: `url(${feed?.multi_image[0]?.file_url})`,
                                                            }}>
                                                        </div>
                                                    ) : (
                                                        <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 MuiGrid-spacing-md-3 mt-1 update-multi-grid-container css-1xgjpwf-MuiGrid-root" >
                                                            {feed?.multi_image.map((file, index) => (
                                                                <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-6 MuiGrid-grid-sm-4 MuiGrid-grid-md-3 css-1vm8tgf-MuiGrid-root update-post-grid-item"
                                                                    data-post={JSON.stringify(feed)} // Attach the data-post attribute
                                                                >
                                                                    <div className="upload-img-wrapper" style={{ height: "162px" }}>
                                                                        <img src={file?.file_url} alt={file?.alt_text || `Image ${index + 1}`} className="" style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "3px" }} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : feed?.type?.name === "Insight" ? (
                                    <>
                                        <div data-post-id={feed?.id} className="mt-4 card feed-card" isedit={feed?.is_edit === 1 ? "true" : "false"}
                                            isdelete={feed?.is_delete === 1 ? "true" : "false"} typeName={feed?.type?.name} typeID={feed?.type?.id} typeSlug={feed?.type?.slug}>
                                            <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                                <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                                    {feed?.created_by?.thumbnail !== "" ? (
                                                        <>
                                                            <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                                aria-label="recipe">
                                                                <img src={feed?.created_by?.thumbnail} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                                aria-label="recipe">{feed?.created_by?.name?.charAt(0)}
                                                            </div>
                                                        </>
                                                    )}

                                                </div>
                                                <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                                    <span className="MuiTypography-body2  MuiCardHeader-title">
                                                        <div className="MuiBox-root feed-top-header css-69i1ev">
                                                            <span className="post-posted-label"><b>{feed?.created_by?.name}</b> posted a <b>{feed?.type?.name}</b></span>
                                                            <div class="MuiBox-root css-0"><span
                                                                class=" MuiTypography-caption post-date-label css-1sn4lm3-MuiTypography-root">{formatDateToReadable(feed?.published_date_time)}
                                                            </span>
                                                                {/* <button
                                                                    class="MuiIconButton-root MuiIconButton-sizeMedium edit-post-icon-btn css-78trlr-MuiButtonBase-root-MuiIconButton-root"
                                                                    tabindex="0" type="button" aria-label="more" data-post-id={feed?.id} aria-haspopup="true"><svg
                                                                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                                        focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreVertIcon">
                                                                        <path
                                                                            d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2">
                                                                        </path>
                                                                        </svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
                                                                    </button> */}
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <div class="MuiCardContent-root content-div-body-2 css-46bh2p-MuiCardContent-root">
                                                <div class="d-flex gap-3 align-items-center MuiBox-root css-0">
                                                    <div class="insight-img-box MuiBox-root css-0">
                                                        <img
                                                            src={feed?.celebrate_user?.thumbnail}
                                                            alt={feed?.celebrate_user?.name} class="cursor-pointer user-img-insight rounded-circle" data-post={JSON.stringify(feed)} style={{ width: "100%", height: "100%" }} /></div>
                                                    <div class="MuiBox-root css-0">
                                                        <p
                                                            class=" MuiTypography-body1 insight-content-name text-uppercase css-ahj2mt-MuiTypography-root">
                                                            {feed?.content}</p>
                                                        <p
                                                            class="MuiTypography-body1 insight-content-user-name text-capitalize mt-1 css-ahj2mt-MuiTypography-root">
                                                            {feed?.celebrate_user?.name} {feed?.celebrate_user?.last_name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    // <div data-post-id={feed?.id} className="mt-4 card feed-card">
                                    //     <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                    //         <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                    //             {feed?.profile !== "" ? (
                                    //                 <>
                                    //                     <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                    //                         aria-label="recipe">
                                    //                         <img src={feed?.profile} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                    //                     </div>
                                    //                 </>
                                    //             ) : (
                                    //                 <>
                                    //                     <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                    //                         aria-label="recipe">{feed?.created_by?.charAt(0)}
                                    //                     </div>
                                    //                 </>
                                    //             )}

                                    //         </div>
                                    //         <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content"><span
                                    //             className="MuiTypography-body2  MuiCardHeader-title ">
                                    //             <div className="MuiBox-root feed-top-header css-69i1ev"><span><b>{feed?.created_by}</b> posted a <b>{feed?.type?.name}</b></span><span
                                    //                 className=" MuiTypography-caption">{feed?.published_date}</span></div>
                                    //         </span></div>
                                    //     </div>
                                    //     <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" role="img"
                                    //         style={{
                                    //             backgroundImage: `url(${feed?.image})`,
                                    //         }}>
                                    //     </div>
                                    //     <div className="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                    //         <div className="main-content-div-1">
                                    //             <div className="main-div-posted">
                                    //                 <div className="icon-bd-div"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                                    //                     className="svg-inline--fa fa-clock " role="img" xmlns="http://www.w3.org/2000/svg"
                                    //                     viewBox="0 0 512 512">
                                    //                     <path fill="currentColor"
                                    //                         d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                    //                     </path>
                                    //                 </svg></div>
                                    //                 <p className="MuiTypography-body1 fs-14 opacity-50"> Posted {feed?.published_date}</p>
                                    //             </div>
                                    //         </div>
                                    //         <div className="MuiBox-root css-0">
                                    //             <div
                                    //                 className="MuiTypography-h5 MuiTypography-gutterBottom content-title text-break">
                                    //                 {feed?.title}</div>
                                    //             <p
                                    //                 className="MuiTypography-body2 fs-14 content-desc text-break">
                                    //                 {feed?.content}
                                    //             </p>
                                    //         </div>
                                    //         <button type="button" className="btn primary-btn mt-4 btn btn-primary view-article-btn">View Article<svg aria-hidden="true"
                                    //             focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right "
                                    //             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    //             <path fill="currentColor"
                                    //                 d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z">
                                    //             </path>
                                    //         </svg></button>
                                    //     </div>
                                    //     <div className="MuiCardActions-root MuiCardActions-spacing bg-white card-action-2 css-c7tfsh-MuiCardActions-root"></div>
                                    // </div>
                                    <div data-post-id={feed?.id} className="mt-4 card feed-card" isedit={feed?.is_edit === 1 ? "true" : "false"}
                                        isdelete={feed?.is_delete === 1 ? "true" : "false"} typeName={feed?.type?.name} typeID={feed?.type?.id} typeSlug={feed?.type?.slug}>
                                        <div className="MuiCardHeader-root card-header-content css-185gdzj-MuiCardHeader-root">
                                            <div className="MuiCardHeader-avatar feed-card-avatar-header css-1ssile9-MuiCardHeader-avatar">
                                                {feed?.created_by?.thumbnail !== "" ? (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">
                                                            <img src={feed?.created_by?.thumbnail} className="MuiAvatar-img css-1pqm26d-MuiAvatar-img" />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault author-avtar-creator css-17o22dy-MuiAvatar-root"
                                                            aria-label="recipe">{feed?.created_by?.name?.charAt(0)}
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                            <div className="MuiCardHeader-content feed-card-header css-1qbkelo-MuiCardHeader-content">
                                                <span className="MuiTypography-body2  MuiCardHeader-title">
                                                    <div className="MuiBox-root feed-top-header css-69i1ev">
                                                        <span className="post-posted-label"><b>{feed?.created_by?.name}</b> posted a <b>{feed?.type?.name}</b></span>
                                                        <div class="MuiBox-root css-0"><span
                                                            class=" MuiTypography-caption post-date-label css-1sn4lm3-MuiTypography-root">{formatDateToReadable(feed?.published_date_time)}</span><button
                                                                class="MuiIconButton-root MuiIconButton-sizeMedium edit-post-icon-btn css-78trlr-MuiButtonBase-root-MuiIconButton-root"
                                                                tabindex="0" type="button" aria-label="more" data-post-id={feed?.id} aria-haspopup="true"><svg
                                                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                                                                    focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MoreVertIcon">
                                                                    <path
                                                                        d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2">
                                                                    </path>
                                                                </svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                        {/* <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root feed-card-img" role="img"
                                        style={{
                                            backgroundImage: `url(${feed?.image})`,
                                        }}>
                                    </div>
                                    <div className="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                        <div className="main-content-div-1">
                                            <div className="main-div-posted">
                                                <div className="icon-bd-div">
                                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                                                        className="svg-inline--fa fa-clock " role="img" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512">
                                                        <path fill="currentColor"
                                                            d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <p className="MuiTypography-body1 fs-14 opacity-50"> Posted {feed?.published_date}</p>
                                            </div>
                                        </div>
                                        <div className="MuiBox-root css-0">
                                            <div
                                                className="MuiTypography-h5 MuiTypography-gutterBottom content-title text-break">
                                                {feed?.title}</div>
                                            <p
                                                className="MuiTypography-body2 fs-14 content-desc text-break">
                                                {feed?.content}
                                            </p>
                                        </div>
                                        <button type="button" className="btn primary-btn mt-4 btn btn-primary">View Article
                                            <svg aria-hidden="true"
                                                focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right "
                                                role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path fill="currentColor"
                                                    d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div> */}

                                        <div class="d-flex card-content-box MuiBox-root css-0">
                                            <div className="MuiCardMedia-root cursor-pointer css-4yb5c6-MuiCardMedia-root post-img" role="img"
                                                style={{
                                                    backgroundImage: `url(${feed?.image})`,
                                                }}>
                                            </div>
                                            <div class="MuiCardContent-root content-div-body css-46bh2p-MuiCardContent-root">
                                                <div class="main-content-div-1">
                                                    <div class="main-div-posted"><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock"
                                                        class="svg-inline--fa fa-clock posted-date-icon" role="img" xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 512 512">
                                                        <path fill="currentColor"
                                                            d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z">
                                                        </path>
                                                    </svg>
                                                        <p
                                                            class="MuiTypography-root MuiTypography-body1 posted-date-text opacity-50  css-ahj2mt-MuiTypography-root">
                                                            {formatDateToReadable(feed?.published_date_time)}</p>
                                                    </div>
                                                    {feed?.category?.length > 0 && (
                                                        <div class="main-div-workspace">
                                                            <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                                data-icon="briefcase" class="svg-inline--fa fa-briefcase posted-date-icon" role="img"
                                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                <path fill="currentColor"
                                                                    d="M184 48l144 0c4.4 0 8 3.6 8 8l0 40L176 96l0-40c0-4.4 3.6-8 8-8zm-56 8l0 40L64 96C28.7 96 0 124.7 0 160l0 96 192 0 128 0 192 0 0-96c0-35.3-28.7-64-64-64l-64 0 0-40c0-30.9-25.1-56-56-56L184 0c-30.9 0-56 25.1-56 56zM512 288l-192 0 0 32c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-32L0 288 0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-128z">
                                                                </path>
                                                            </svg>
                                                            {feed?.category
                                                                ?.slice(0, maxVisibleCategory)
                                                                .map((cate, index) => (
                                                                    <p
                                                                        class="MuiTypography-root MuiTypography-body1 posted-date-text opacity-50 css-ahj2mt-MuiTypography-root">
                                                                        {cate}
                                                                    </p>
                                                                ))}
                                                            {feed?.category?.length > maxVisibleCategory && (
                                                                <div class="MuiChip-root MuiChip-filled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-filledDefault categoty-label-chip cursor-pointer css-gavykb-MuiChip-root"
                                                                    data-mui-internal-clone-element="true">
                                                                    <span
                                                                        class="MuiChip-label MuiChip-labelMedium css-6od3lo-MuiChip-label">+{feed?.category.length - maxVisibleCategory
                                                                        } others
                                                                    </span>
                                                                </div>
                                                            )}

                                                        </div>
                                                    )}

                                                </div>
                                                <div class="post-content-data MuiBox-root css-0">
                                                    <div
                                                        class="MuiTypography-h5 MuiTypography-gutterBottom content-title text-break css-h93ljk-MuiTypography-root">
                                                        {feed?.title}</div>
                                                    <p
                                                        class=" MuiTypography-body2 fs-14 content-desc text-break css-r40f8v-MuiTypography-root">
                                                        {feed?.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ))}
                    </>
                ) : (
                    <h1 className="mt-2 text-center">No Feed Available</h1>
                )}
            </div>
        )
    }

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    const handleScrollForFeed = async () => {
        const scrollableHeight = document.documentElement.scrollHeight;
        const scrollPosition = window.innerHeight + window.scrollY;
        const remainingScrollThreshold = scrollableHeight * 0.3;

        if (
            scrollableHeight - scrollPosition < remainingScrollThreshold &&
            !isDataFetchingForFeed &&
            pageFeed < totalPagesFeed
        ) {
            console.log("inside handle scroll if condition");
            setIsDataFetchingForFeed(true);
            const nextPageNum = pageFeed + 1;
            setPageFeed(nextPageNum);
            await fetchFeedData(nextPageNum);
        }
    };

    const submenuToggleScript = `
    document.addEventListener("DOMContentLoaded", () => {
        document.querySelectorAll(".submenu-toggle-wrapper").forEach((toggleWrapper) => {
            toggleWrapper.addEventListener("click", (e) => {
                const menuId = toggleWrapper.getAttribute("data-id");
                const submenu = document.getElementById("submenu-" + menuId);
                const toggleIcon = toggleWrapper.querySelector(".toggle-icon");

                if (submenu) {
                    submenu.classList.toggle("hidden");

                    // Toggle the SVG icon
                    if (submenu.classList.contains("hidden")) {
                        toggleIcon.setAttribute("data-icon", "chevron-down");
                        toggleIcon.innerHTML = '<path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>';
                    } else {
                        toggleIcon.setAttribute("data-icon", "chevron-up");
                        toggleIcon.innerHTML = '<path fill="currentColor" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"></path>';
                    }
                }
            });
        });
    });
`   ;

    const buttonClickScript = `
        document.addEventListener("DOMContentLoaded", () => {
            const buttons = document.querySelectorAll("[id^='search-button-']");
            buttons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                const parent = button.closest(".custom-people-block");
                const input = parent?.querySelector("input[type='text']");
                const searchValue = input ? input.value.trim() : "";

                // if (!searchValue) {
                //   alert("Please enter a search value!");
                //   return;
                // }

            const data = {
                action: "fetchPeopleData",
                key: "unique_key", // Add a unique key
                searchValue,
                people
            };

                // Send message to the parent window
                window.parent.postMessage(data, "*");
            });
            });
        });
    `;

    const createContentBTNScript = `
    document.addEventListener("DOMContentLoaded", () => {
        const createContentButton = document.querySelector(".create-content-btn-2");
        if (createContentButton) {
            // Example variables for attribute values
            const openMenuForCreateContent = false; // Replace with your dynamic logic
            const idCreateContent = "simple-popover-2"; // Replace with your dynamic ID

            // Set the attributes
            createContentButton.setAttribute("aria-controls",  "account-create-content-2");
            createContentButton.setAttribute("aria-expanded", openMenuForCreateContent ? "true" : undefined);
            createContentButton.setAttribute("aria-describedby","simple-popover-2");

            // Add click event listener
            createContentButton.addEventListener("click", (e) => {
                e.preventDefault();
                console.log("event if create button click::",e)
                // Toggle state example
                const isExpanded = createContentButton.getAttribute("aria-expanded") === "true";
                createContentButton.setAttribute("aria-expanded", !isExpanded ? "true" : "false");

                // Custom logic for handling button click
                const data = {
                    action: "createContent",
                    key: "unique_key_createContent", // Different keys for different scenarios
                };

                console.log("Create Content button clicked!", data);

                // Optionally send data to the parent window
                window.parent.postMessage(data, "*");
            });
        }
    });
    `;

    const settingBTNScript = `
    document.addEventListener("DOMContentLoaded", () => {
        const createContentButton = document.querySelector(".setting-btn-2");
        if (createContentButton) {
            // Example variables for attribute values
            const openMenuForAvatar = false; // Replace with your dynamic logic
            const idCreateContent = "simple-popover-2"; // Replace with your dynamic ID

            // Set the attributes
            createContentButton.setAttribute("aria-controls",  "account-create-content-2");
            createContentButton.setAttribute("aria-expanded", openMenuForAvatar ? "true" : undefined);
            createContentButton.setAttribute("aria-describedby","simple-popover-2");

            // Add click event listener
            createContentButton.addEventListener("click", (e) => {
                e.preventDefault();
                console.log("event if create button click::",e)
                // Toggle state example
                const isExpanded = createContentButton.getAttribute("aria-expanded") === "true";
                createContentButton.setAttribute("aria-expanded", !isExpanded ? "true" : "false");

                // Custom logic for handling button click
                const data = {
                    action: "settingBTN",
                    key: "unique_key_settingbtn", // Different keys for different scenarios
                };

                console.log("Create Content button clicked!", data);

                // Optionally send data to the parent window
                window.parent.postMessage(data, "*");
            });
        }
    });
    `;

    const viewAtricleBTNScript = `
    document.addEventListener("DOMContentLoaded", () => {
        const buttons = document.querySelectorAll(".view-article-btn");
        buttons.forEach(button => {
            button.addEventListener("click", (e) => {
                e.preventDefault();
                const feedCard = button.closest(".feed-card");
                const feedId = feedCard?.getAttribute("data-post-id");
                const feedTypeName = feedCard?.querySelector(".feed-top-header b:nth-child(2)")?.textContent;

                const data = {
                                action: "redirectAtriclePage",
                                key: "unique_key_redirectAtriclePage", // Different keys for different scenarios
                                feedId,
                                feedTypeName // Adjust this based on your API structure
                            };
                 window.parent.postMessage(data, "*");
                
            });
        });
    });
    `;

    // const viewAtricleImgDivScript = `
    // document.addEventListener("DOMContentLoaded", () => {
    //     const buttons = document.querySelectorAll(".feed-card-img");
    //     buttons.forEach(button => {
    //         button.addEventListener("click", (e) => {
    //             e.preventDefault();
    //             const feedCard = button.closest(".feed-card");
    //             const feedId = feedCard?.getAttribute("data-post-id");
    //             const feedTypeName = feedCard?.querySelector(".feed-top-header b:nth-child(2)")?.textContent;

    //             const data = {
    //                             action: "redirectAtriclePage",
    //                             key: "unique_key_redirectAtriclePage", // Different keys for different scenarios
    //                             feedId,
    //                             feedTypeName // Adjust this based on your API structure
    //                         };
    //              window.parent.postMessage(data, "*");

    //         });
    //     });
    // });
    // `;

    const viewImgSliderScript = `
    document.addEventListener("DOMContentLoaded", () => {
        const buttons = document.querySelectorAll(".feed-card-img");
        buttons.forEach(button => {
            button.addEventListener("click", (e) => {
                e.preventDefault();
                const feedCard = button.closest(".feed-card");
                const feedData = button.getAttribute("data-post"); // Retrieve the data-post attribute

                if (feedData) {
                    const feed = JSON.parse(feedData); // Parse the JSON string
                    const data = {
                        action: "imgslider",
                        key: "unique_key_imgslider", // Different keys for different scenarios
                        feed,
                        };
                    window.parent.postMessage(data, "*");
                } else {
                    console.error("Feed data is missing or invalid.");
                }
            });
        });
    });
    `;

    const viewImgSliderScript2 = `
    document.addEventListener("DOMContentLoaded", () => {
        const buttons = document.querySelectorAll(".update-post-grid-item");
        buttons.forEach(button => {
            button.addEventListener("click", (e) => {
                e.preventDefault();
                const feedCard = button.closest(".feed-card");
                const feedData = button.getAttribute("data-post"); // Retrieve the data-post attribute

                if (feedData) {
                    const feed = JSON.parse(feedData); // Parse the JSON string
                    const data = {
                        action: "imgslider",
                        key: "unique_key_imgslider", // Different keys for different scenarios
                        feed,
                    };
                    window.parent.postMessage(data, "*");
                } else {
                    console.error("Feed data is missing or invalid.");
                }
            });
        });
    });
    `;

    const viewImgSliderScript3 = `
    document.addEventListener("DOMContentLoaded", () => {
        const buttons = document.querySelectorAll(".user-img-insight");
        buttons.forEach(button => {
            button.addEventListener("click", (e) => {
                e.preventDefault();
                const feedCard = button.closest(".feed-card");
                const feedData = button.getAttribute("data-post"); // Retrieve the data-post attribute

                if (feedData) {
                    const feed = JSON.parse(feedData); // Parse the JSON string
                    const data = {
                        action: "imgslider2",
                        key: "unique_key_imgslider2", // Different keys for different scenarios
                        feed,
                    };
                    window.parent.postMessage(data, "*");
                } else {
                    console.error("Feed data is missing or invalid.");
                }
            });
        });
    });
    `;

    // const feedActionBTNScript = `
    // document.addEventListener("DOMContentLoaded", () => {
    //     const createContentButton = document.querySelector(".edit-post-icon-btn");
    //     if (createContentButton) {
    //         // Example variables for attribute values
    //         const openMenuForPost = false; // Replace with your dynamic logic
    //         // const idCreateContent = "simple-popover-2"; // Replace with your dynamic ID

    //         // Set the attributes
    //         createContentButton.setAttribute("aria-controls",  "long-menu");
    //         createContentButton.setAttribute("aria-label",  "more");

    //         createContentButton.setAttribute("aria-expanded", openMenuForPost ? "true" : undefined);
    //          createContentButton.setAttribute("aria-haspopup", "true");

    //         // Add click event listener
    //         createContentButton.addEventListener("click", (e) => {
    //             e.preventDefault();
    //             console.log("event if create button click::",e)
    //             // Toggle state example
    //             const isExpanded = createContentButton.getAttribute("aria-expanded") === "true";
    //             createContentButton.setAttribute("aria-expanded", !isExpanded ? "true" : "false");
    //             const feedCard = e.target.closest(".feed-card");
    //             const isEdit = feedCard.getAttribute("isedit") === "true";
    //             const isDelete = feedCard.getAttribute("isdelete") === "true";
    //             const typeName = feedCard.getAttribute("typeName");
    //             const id = feedCard.getAttribute("data-post-id");

    //             // Custom logic for handling button click
    //             const data = {
    //                 action: "feedActionBTN",
    //                 key: "unique_key_feedActionBTN", 
    //                 isEdit,
    //                 isDelete,
    //                 typeName,
    //                 id
    //             };

    //             console.log("Create Content button clicked!", data);

    //             // Optionally send data to the parent window
    //             window.parent.postMessage(data, "*");
    //         });
    //     }
    // });
    // `;

    // const feedActionBTNScript = `
    // document.addEventListener("DOMContentLoaded", () => {
    //     // Select all buttons with the class name 'edit-post-icon-btn'
    //     const createContentButtons = document.querySelectorAll(".edit-post-icon-btn");

    //     createContentButtons.forEach((createContentButton) => {
    //         // Add click event listener to each button
    //         createContentButton.addEventListener("click", (e) => {
    //             e.preventDefault();
    //             console.log("Event when create button is clicked:", e);

    //             // Toggle state example
    //             const isExpanded = createContentButton.getAttribute("aria-expanded") === "true";
    //             createContentButton.setAttribute("aria-expanded", !isExpanded && "true");
    //             createContentButton.setAttribute("aria-controls", "long-menu");
    //             createContentButton.setAttribute("aria-label", "more");
    //             createContentButton.setAttribute("aria-haspopup", "true");

    //             // Get attributes from the closest feed-card parent
    //             const feedCard = e.target.closest(".feed-card");
    //             if (feedCard) {
    //                 const isEdit = feedCard.getAttribute("isedit") === "true";
    //                 const isDelete = feedCard.getAttribute("isdelete") === "true";
    //                 const typeName = feedCard.getAttribute("typeName");
    //                 const id = feedCard.getAttribute("data-post-id");

    //                 // Custom logic for handling button click
    //                 const data = {
    //                     action: "feedActionBTN",
    //                     key: "unique_key_feedActionBTN", 
    //                     isEdit,
    //                     isDelete,
    //                     typeName,
    //                     id,
    //                 };

    //                 console.log("Create Content button clicked!", data);

    //                 // Optionally send data to the parent window
    //                 window.parent.postMessage(data, "*");
    //             } else {
    //                 console.warn("Parent feed-card not found for this button.");
    //             }
    //         });
    //     });
    // });
    // `;

    const feedActionBTNScript = `
        document.addEventListener("DOMContentLoaded", () => {
    // Select all buttons with the class name 'edit-post-icon-btn'
    const createContentButtons = document.querySelectorAll(".edit-post-icon-btn");

    createContentButtons.forEach((createContentButton) => {
        // Add click event listener to each button
        createContentButton.addEventListener("click", (e) => {
            e.preventDefault();

            // Get the button that triggered the event
            const clickedButton = e.currentTarget;

            // Toggle aria-expanded attribute
            const isExpanded = clickedButton.getAttribute("aria-expanded") === "true";
            clickedButton.setAttribute("aria-expanded", isExpanded ? "false" : "true");
            clickedButton.setAttribute("aria-controls", "long-menu");
            clickedButton.setAttribute("aria-label", "more");
            clickedButton.setAttribute("aria-haspopup", "true");

            // Get attributes from the closest feed-card parent
            const feedCard = clickedButton.closest(".feed-card");
            if (feedCard) {
                const isEdit = feedCard.getAttribute("isedit") === "true";
                const isDelete = feedCard.getAttribute("isdelete") === "true";
                const typeName = feedCard.getAttribute("typeName");
                const id = feedCard.getAttribute("data-post-id");
                const slug = feedCard.getAttribute("typeSlug")
                const typeID = feedCard.getAttribute("typeID")
                // Send the data for the clicked button
                const data = {
                    action: "feedActionBTN",
                    key: "unique_key_feedActionBTN",
                    isEdit,
                    isDelete,
                    typeName,
                    id,
                    slug,
                    typeID
                };

                console.log("Create Content button clicked!", data);

                // Optionally send data to the parent window
                window.parent.postMessage(data, "*");
            } else {
                console.warn("Parent feed-card not found for this button.");
            }
        });
    });
});

    `;

    let isFetching = false; // Prevent concurrent fetches
    let isFetchingText = false

    const textFieldScript = `
    document.addEventListener("DOMContentLoaded", () => {
        const inputFields = document.querySelectorAll("input[type='text']");

        let debounceTimeout; // Timeout for debouncing API calls
        let isFetchingText = false; // Flag to prevent concurrent API calls

        inputFields.forEach((input) => {
            input.addEventListener("input", () => {
                const searchValue = input.value.trim();

                // Clear previous debounce timeout
                clearTimeout(debounceTimeout);

                // Debounce the API call
                debounceTimeout = setTimeout(() => {
                    if (isFetchingText) return; // Prevent concurrent fetches

                    // Condition for triggering API: Empty or more than 2 characters
                    if (searchValue === "" || searchValue.length > 2) {
                        isFetchingText = true;

                        try {
                            console.log("Triggering action for search value:", searchValue);

                            // Send message to parent window
                            const data = {
                                action: "fetchPeopleData",
                                key: searchValue === "" ? "unique_key_empty" : "unique_key_search", // Different keys for different scenarios
                                searchValue,
                                people: people // Adjust this based on your API structure
                            };
                            window.parent.postMessage(data, "*");

                        } catch (err) {
                            console.error("Error triggering action for search value:", err);
                        } finally {
                            isFetchingText = false; // Reset the fetching flag
                        }
                    }
                }, 300); // Adjust debounce time as needed
            });
        });
    });
    `;

    const inputFocusScript = `
    document.addEventListener("DOMContentLoaded", () => {
        const inputElements = document.querySelectorAll(".update-box-search-div");
        inputElements.forEach(input => {
            input.addEventListener('click', (e) => {
                const parent = input.closest(".serach-box");
                if (parent) {
                    const data = {
                        action: "updateBoxFocus",
                        key: "unique_key_update_box", // Add a unique key if needed
                        isFocused: true
                    };
                    // Send message to the parent window
                    window.parent.postMessage(data, "*");
                }
            });
        });
    });
    `;

    const updateHTMLContent = (html, menuItems, linksData, peopleData, feedData) => {
        console.log("feedData::", feedData)
        // Parse the HTML to manipulate it
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");

        // Process custom-menu-block
        const menuBlocks = doc.querySelectorAll(".custom-menu-block");
        menuBlocks.forEach((menuBlock) => {
            const menuType = menuBlock.getAttribute("menuType");
            const menuPosition = menuBlock.getAttribute("menuPosition");
            const menuData = menuItems[menuType];
            if (menuData) {
                const menuItemsHTML = generateMenuItems(menuData.items);
                const positionClass = menuPosition === "vertical" ? "MuiList-root-vertical" : "MuiList-root-horizontal";
                const menuItemsBlock = menuBlock.querySelector("ul.menu-items");
                if (menuItemsBlock) {
                    menuItemsBlock.className = `${positionClass} menu-items`;
                    menuItemsBlock.innerHTML = menuItemsHTML;
                }
            }
        });

        // Process custom-link-block
        const linkBlocks = doc.querySelectorAll(".custom-link-block");
        linkBlocks.forEach((block) => {
            const selectLinks = block.getAttribute("selectLinks");
            if (selectLinks) {
                const linkId = parseInt(selectLinks, 10);
                const filteredLinks = linksData[linkId] ? [linksData[linkId]] : [];
                const linksBlockHTML = generateLinkBlocks(filteredLinks[0]);
                block.innerHTML = `
                <div className="cust-cms-link-block">
                    <div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 link-list-container css-mhc70k-MuiGrid-root mt-4">
                        ${linksBlockHTML || "links"}
                    </div>
                </div>
            `;
            }
        });

        // Process custom-youtube-block
        const youtubeBlocks = doc.querySelectorAll(".custom-youtube-block");
        youtubeBlocks.forEach((block) => {
            const youtubeUrl = block.getAttribute("youtubeUrl");
            const youtubeTitle = block.getAttribute("youtubeTitle");

            // Generate YouTube block as a Node
            const youtubeContent = generateYoutubeBlocks({ youtubeUrl, title: youtubeTitle });

            // Clear the block's content and append the generated Node
            block.innerHTML = ''; // Clear existing content
            block.appendChild(youtubeContent); // Append the Node
        });

        // Process custom-text-block
        const textBlocks = doc.querySelectorAll(".custom-text-block");
        textBlocks.forEach((textBlock) => {
            const title = textBlock.getAttribute("title");
            const blockTitle = textBlock.querySelector(".block-title");
            if (blockTitle && title) {
                blockTitle.textContent = title; // Update block-title content with title attribute
            }
        });

        // Process custom-img-block
        const imgBlocks = doc.querySelectorAll(".custom-img-block");
        imgBlocks.forEach((imgBlock) => {
            const imgTitle = imgBlock.getAttribute("imgTitle") || "";
            const imgUrl = imgBlock.getAttribute("imgUrl") || "";
            const imgsrc = imgBlock.getAttribute("src") || "";
            const imgHoverOverText = imgBlock.getAttribute("imgHoverOverText") || "";
            const openInNewTab = imgBlock.getAttribute("openInNewTab") || "";

            // Determine link behavior
            const targetAttribute = openInNewTab === "YES" ? 'target="_blank"' : "";

            // Construct the updated HTML structure
            const newHTML = `
                <div class="custom-img-block-div">
                    ${imgTitle ? `<h2>${imgTitle}</h2>` : ""} <!-- Show imgTitle if it exists -->
                    <div class="hover-container">
                        ${imgUrl
                    ? `<a href="${imgUrl}" ${targetAttribute}>
                                    <img src="${imgsrc}" alt="${imgTitle}" />
                                </a>`
                    : `<img src="${imgsrc}" alt="${imgTitle}" />`
                }
                        ${imgHoverOverText ? `<span class="hover-text">${imgHoverOverText}</span>` : ""}
                    </div>
                </div>
             `;

            // Replace the content of the current block
            imgBlock.innerHTML = newHTML;
        });

        // Process custom-embed-block
        const embedBlocks = doc.querySelectorAll(".custom-embed-block");
        embedBlocks.forEach((embedBlock) => {
            const title = embedBlock.getAttribute("title");
            const embedCode = embedBlock.getAttribute("code");
            // console.log("embedCode::", embedCode)

            const embedBlockHTML = generateEmbedBlocks({ title, embedCode });
            embedBlock.innerHTML = embedBlockHTML;
        });

        // Process custom-type-block
        const typeBlocks = doc.querySelectorAll(".custom-type-block");
        typeBlocks.forEach((block) => {
            const typeId = block.getAttribute("type");
            if (typeId && typesData[typeId]) {
                // Generate the block's HTML using generateTypesBlock
                const typeHTML = generateTypesBlock(typesData[typeId]);
                // Replace the block's inner HTML with the generated content
                block.innerHTML = ReactDOMServer.renderToString(typeHTML);
            } else {
                block.innerHTML = `<h2>No data available for type: ${typeId}</h2>`;
            }
        });

        // Process custom-people-block
        const peopleBlocks = doc.querySelectorAll(".custom-people-block");
        peopleBlocks.forEach((block, index) => {
            const title = block.getAttribute("title"); // Default to 'block-0', 'block-1', etc. if title is null
            const groups = block.getAttribute("groups")?.split(',').map(Number) || [];
            const users = block.getAttribute("users")?.split(',').map(Number) || [];
            const sort = block.getAttribute("sort");
            const viewtype = block.getAttribute("viewtype");
            const showpeople = block.getAttribute("showpeople");
            const showphoto = block.getAttribute("showphoto");
            const showemail = block.getAttribute("showemail");
            const showjobtitle = block.getAttribute("showjobtitle");
            const showmobile = block.getAttribute("showmobile");

            // Use `peopleData` to fetch corresponding data
            const peopleBlockData = peopleData[index] || {
                title,
                groups,
                users,
                sort,
                viewtype,
                showpeople,
                showphoto,
                showemail,
                showjobtitle,
                showmobile,
                data: [], // Fallback to an empty array
            };
            // console.log("peopleBlockData::", peopleBlockData)
            // Generate the updated HTML using `generatePeopleBlock`
            const peopleBlockHTML = ReactDOMServer.renderToString(generatePeopleBlock(peopleBlockData));

            // Replace the block's inner HTML with the generated content
            block.innerHTML = peopleBlockHTML;

            // Attach event listeners right after the inner HTML is set
            // const searchButton = block.querySelector(`#search-button`);
            // console.log("searchButton::", searchButton);

            // if (searchButton) {
            //     console.log("searchButton1::")
            //     searchButton.addEventListener('click', function () {
            //         console.log("searchButton2::")
            //         alert("Search button clicked");
            //         // Add your custom fetch or other logic here
            //     });
            // }
        });

        //process custom-feed-block
        const feedBlocks = doc.querySelectorAll(".custom-feed-block")
        feedBlocks.forEach((block, index) => {
            const title = block.getAttribute("title"); // Default to 'block-0', 'block-1', etc. if title is null
            const feedTypes = block.getAttribute("feedType")?.split(',').map(Number) || [];
            const categories = block.getAttribute("category")?.split(',').map(Number) || [];
            const showUpdateBox = block.getAttribute("showUpdateBox");


            const feedBlockData = feedData[index] || {
                title,
                feedTypes,
                categories,
                showUpdateBox,
                data: [], // Fallback to an empty array
            };

            // Generate the updated HTML using `generatePeopleBlock`
            const feedBlockHTML = ReactDOMServer.renderToString(generateFeedBlock(feedBlockData));

            // Replace the block's inner HTML with the generated content
            block.innerHTML = feedBlockHTML;

        })

        return doc.body.innerHTML;
    };

    const updatedHTML = updateHTMLContent(html, userMenuLists, inksData, peopleData, feedData);

    const previewContent = `
        <html>
          <head>
           <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css"
            integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous" />
           <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.2/rollups/aes.js"></script>
           <script src="https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js" crossorigin></script>

           <script src="https://cdn.jsdelivr.net/npm/react-dom/umd/react-dom.production.min.js" crossorigin></script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.bundle.min.js" crossorigin></script>
           <script src="https://cdn.jsdelivr.net/npm/react-bootstrap@next/dist/react-bootstrap.min.js" crossorigin></script>

           <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.9.2/dist/umd/popper.min.js" integrity="sha384-IQsoLXl5PILFhosVNubq5LC7Qb9DXgDA9i+tQ8Zj3iwWAwPtgFTxbJ8NT4GN1R8p" crossorigin="anonymous"></script>
           <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.min.js" integrity="sha384-cVKIPhGWiC2Al4u+LWgxfKTRIcfu0JTxR+EQDz/bgldoEyl4H0zUF0QKbrJ0EcQF" crossorigin="anonymous"></script>
            <style>
                body{
                    padding-right: 0 !important;
                    overflow: scroll !important;
                }
                .custom-menu {
                    padding: 16px;
                    background-color: #f9f9f9;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    max-width: 320px;
                }
                .custom-menu-list {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                }
                .custom-menu-item {
                    margin-bottom: 8px;
                }
                .custom-menu-link {
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: #333;
                    font-size: 16px;
                }
                .custom-menu-icon {
                    font-size: 18px;
                    color: #660C60;
                    height: 18px;
                }
                .custom-menu-name {
                    font-weight: 400;
                }
                * {
                    font-family: "Inter" !important;
                }
                [data-gjs-type="cell"] {
                    position: relative !important;
                }
                td {
                    position: relative;
                }
                // .MuiPaper-root {
                //     width: auto;
                //     transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                //     overflow-x: hidden;
                //     height: 100vh;
                //     padding-top: 125px;
                //     padding-bottom: 30px;
                //     padding: 20px;
                //     background-color: #fff;
                //     color: rgba(0, 0, 0, 0.87);
                //     box-shadow: none;
                //     overflow-y: auto;
                //     display: flex;
                //     flex-direction: column;
                //     position: absolute;
                //     top: 0;
                //     outline: 0;
                //     left: 0;
                //     border: 1px solid rgba(0, 0, 0, 0.12);
                //     white-space: nowrap;
                // }
                // .MuiList-root {
                //     list-style: none;
                //     margin: 0;
                //     padding: 0;
                //     position: relative;
                //     display: flex;
                //     flex-direction: column;
                //     gap: 10px;
                //     width: 100%;
                //     padding-top: 0 !important;
                //     padding-bottom: 0 !important;
                //     white-space: nowrap;
                // }
                .MuiList-root-vertical {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    width: 100%;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    white-space: nowrap;
                }
                .MuiList-root-horizontal {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    width: 100%;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    white-space: nowrap;
                }
                // .MuiListItem-root {
                //     -webkit-box-pack: start;
                //     justify-content: flex-start;
                //     -webkit-box-align: center;
                //     align-items: center;
                //     position: relative;
                //     text-decoration: none;
                //     width: 100%;
                //     box-sizing: border-box;
                //     text-align: left;
                //     display: block;
                //     padding-top: 8px;
                //     padding-bottom: 8px;
                //     padding-left: 16px;
                //     padding-right: 16px;
                // }
                .menu-item-list-root{
                    -webkit-box-pack: start;
                    justify-content: flex-start;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    text-decoration: none;
                    width: 100%;
                    box-sizing: border-box;
                    text-align: left;
                    display: block;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-left: 16px;
                    padding-right: 16px;
                }
                // .MuiButtonBase-root {
                //     -webkit-tap-highlight-color: transparent;
                //     background-color: transparent;
                //     outline: 0;
                //     border: 0;
                //     margin: 0;
                //     border-radius: 0;
                //     padding: 0;
                //     cursor: pointer;
                //     user-select: none;
                //     vertical-align: middle;
                //     -webkit-appearance: none;
                //     -webkit-text-decoration: none;
                //     color: inherit;
                //     display: flex;
                //     -webkit-box-flex: 1;
                //     align-items: center;
                //     position: relative;
                //     text-decoration: none;
                //     min-width: 0;
                //     box-sizing: border-box;
                //     text-align: left;
                //     padding-top: 8px;
                //     padding-bottom: 8px;
                //     transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                //     min-height: 48px;
                //     -webkit-box-pack: initial;
                //     justify-content: space-between;
                //     align-items: center;
                //     padding-left: 20px;
                //     padding-right: 20px;
                // }
                .menu-item-button-base-root{
                     -webkit-tap-highlight-color: transparent;
                    background-color: transparent;
                    outline: 0;
                    border: 0;
                    margin: 0;
                    border-radius: 0;
                    padding: 0;
                    cursor: pointer;
                    user-select: none;
                    vertical-align: middle;
                    -webkit-appearance: none;
                    -webkit-text-decoration: none;
                    color: inherit;
                    display: flex;
                    -webkit-box-flex: 1;
                    align-items: center;
                    position: relative;
                    text-decoration: none;
                    min-width: 0;
                    box-sizing: border-box;
                    text-align: left;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    min-height: 48px;
                    -webkit-box-pack: initial;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                // .MuiListItemIcon-root {
                //     color: rgba(0, 0, 0, 0.54);
                //     flex-shrink: 0;
                //     display: inline-flex;
                //     min-width: 0;
                //     margin-right: 16px;
                //     -webkit-box-pack: center;
                //     justify-content: center;
                // }
                .menu-list-item-icon-root{
                    color: rgba(0, 0, 0, 0.54);
                    flex-shrink: 0;
                    display: inline-flex;
                    min-width: 0;
                    margin-right: 16px;
                    -webkit-box-pack: center;
                    justify-content: center;
                }
                // .MuiListItemIcon-root svg {
                //     overflow: visible;
                //     box-sizing: content-box;
                //     font-size: 15px;
                //     color: #959595;
                //     font-weight: 400;
                //     line-height: 15px;
                // }
                .menu-list-item-icon-root svg{
                     overflow: visible;
                    box-sizing: content-box;
                    font-size: 15px;
                    color: #959595;
                    font-weight: 400;
                    line-height: 15px;
                }
                .svg-inline--fa {
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    overflow: visible;
                    vertical-align: -.125em;
                }
                .menu-icon {
                    font-size: 15px;
                    color: #959595;
                    font-weight: 400;
                    line-height: 15px;
                }
                // .MuiListItemText-root {
                //     flex: 1 1 auto;
                //     min-width: 0;
                //     margin-top: 4px;
                //     margin-bottom: 4px;
                //     opacity: 1;
                // }
                .menu-name{
                    flex: 1 1 auto;
                    min-width: 0;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    opacity: 1;
                }
                // .MuiTypography-root {
                //     font-size: 15px;
                //     font-weight: 400;
                //     line-height: 15px;
                //     opacity: 50%;
                // }
                .link-block-link-name{
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 15px;
                    opacity: 50%;
                }
                .menu-name span {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 15px;
                    opacity: 50%;
                }
                .selected-menu-item .menu-name span {
                    color: white !important;
                    opacity: inherit;
                }
                .selected-menu-item {
                    background-color: #660C60 !important;
                    border-radius: 6px;
                    color: white !important;
                }
                .selected-menu-item .menu-icon {
                    color: white !important;
                }
                .vertical-menu {
                    display: flex;
                    flex-direction: column;
                }
                .horizontal-menu {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                }
                .horizontal-item {
                    display: inline-block;
                }
                .list-item-box {
                    height: 540px !important;
                    overflow-y: auto;
                }
                .menu-divider{
                    margin: 0;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    border-width: 0;
                    border-style: solid;
                    border-color: rgba(0, 0, 0, 0.12);
                    border-bottom-width: thin;
                }
                .content-btn {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 1rem!important;
                    margin-top: 1rem!important;
                    width: 100%!important;
                }
                .menu-create-content-box{
                    width: 100%;
                }
                .create-content-btn-2 {
                    background-color: #660C60 !important;
                    color: white !important;
                    border-color: white !important;
                    border-radius: 3px !important;
                    text-transform: capitalize !important;
                    box-shadow: none !important;
                    padding: 10px 15px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    font-family: "Inter";
                    width: 100%;
                }
                .menu-user-info-div{
                    margin-bottom: 1rem !important;
                    margin-top: 1rem !important;
                    display: flex !important;
                    justify-content: space-between !important;
                    align-items: center !important;
                }
                .menu-user-name-avatar-box{
                    display: flex !important;
                    align-items: center !important;
                    gap: .5rem !important;
                }
                .author-avtar {
                    height: 32px !important;
                    width: 32px !important;
                    position: relative;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    line-height: 1;
                    border-radius: 50%;
                    overflow: hidden;
                    user-select: none;
                    color: #fff;
                    background-color: #bdbdbd;
                    font-size: 14px;
                }
                .logged-user-name {
                    font-size: 14px !important;
                    font-weight: 500 !important;
                    line-height: 18px !important;
                    margin-bottom: 0 !important;
                    color: #333333 !important;
                    opacity: unset !important;
                }
                .setting-btn-2 {
                    width: auto !important;
                    background-color: #fff !important;
                    color: #959595 !important;
                    border-color: white !important;
                    border-radius: 3px !important;
                    text-transform: capitalize !important;
                    box-shadow: none !important;
                    padding: 10px 15px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    font-family: "Inter";
                }
                .setting-icon {
                    color: #959595 !important;
                }
                .hidden {
                    display: none !important;
                }
                .mt-4 {
                    margin-top: 1.5rem !important;
                }
                .link-list-container {
                    box-sizing: border-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-flex-wrap: wrap;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    width: calc(100% + 16px);
                    margin-left: -16px;
                }
                .link-list-item-grid {
                    padding-left: 16px;
                    padding-top: 16px;
                    box-sizing: border-box;
                    margin: 0;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -ms-flex-preferred-size: 100%;
                    flex-basis: 100%;
                    -webkit-box-flex: 0;
                    -ms-flex-positive: 0;
                    flex-grow: 0;
                    max-width: 100%;

                    @media(min-width: 1536px) {
                        -ms-flex-preferred-size: 33.333333%;
                        flex-basis: 33.333333%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 33.333333%;
                    }

                    @media(min-width: 1200px) {
                        -ms-flex-preferred-size: 33.333333%;
                        flex-basis: 33.333333%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 33.333333%;
                    }

                    @media(min-width: 900px) {
                        -ms-flex-preferred-size: 33.333333%;
                        flex-basis: 33.333333%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 33.333333%;
                    }

                    @media(min-width: 600px) {
                        -ms-flex-preferred-size: 50%;
                        flex-basis: 50%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 50%;
                    }
                }
                .link-icon-box {
                    cursor: pointer;
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    gap: 12px;
                    border-radius: 6px;
                    padding: 15px;
                    padding-top: 1rem !important;
                    padding-bottom: 1rem !important;
                }
                .icon-content {
                    display: flex;
                    gap: 12px;
                    overflow: hidden;
                }
                .icon-box {
                    display: block;
                    box-sizing: border-box;
                }
                .icon-value {
                    overflow: visible;
                    box-sizing: content-box;
                    display: inline-block;
                    height: 1em;
                    vertical-align: -.125em;
                }
                .link-label-text {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    height: 22px;
                }
                .link-label-box {
                    margin: 0;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                }
                .block-title {
                    text-align: center;
                    font-size: 1.5em;
                    /* Adjust font size if needed */
                    margin: 10px 0;
                    /* Optional spacing */
                }
                .block-content {
                    padding: 10px !important;
                }
                .custom-img-block-div {
                    position: relative;
                    display: inline-block;
                    height: 100%;
                    width: 100%;
                }
                .hover-container {
                    position: relative;
                    height: 100%;
                }
                .hover-container a img {
                    height: 100%;
                }
                .hover-container img {
                    height: 100%;
                    width: 100%;
                }
                .hover-text {
                    display: none;
                    position: absolute;
                    bottom: 100%;
                    /* Position above the image */
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: rgba(0, 0, 0, 0.8);
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 5px;
                    font-size: 12px;
                    white-space: nowrap;
                    z-index: 10;
                    opacity: 0;
                    transition: opacity 0.3s;
                }
                .hover-container:hover .hover-text {
                    display: block;
                    opacity: 1;
                }
                .news-card-wrapper {
                    padding: 0 !important;
                    background-color: #fff;
                    color: rgba(0, 0, 0, 0.87);
                    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    border-radius: 4px;
                    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                    overflow: hidden;
                    max-width: 420px;
                    height: "100%";
                }
                .news-card-wrapper img {
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 100%;
                    object-fit: cover;
                    height: 140px;
                    cursor: pointer;
                }
                .card-content-post {
                    padding: 16px
                }
                .gaps-for-stack {
                    gap: 10px;
                    height: 100% !important;
                    display: flex;
                    flex-direction: column;
                }
                .stack-for-stack {
                    gap: 10px;
                    justify-content: space-between !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-self: stretch;
                }
                .div-for-avatar {
                    gap: .5rem !important;
                    align-items: center !important;
                    display: flex !important;
                }
                .author-avtar-creator {
                    position: relative;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    font-size: 1.25rem;
                    line-height: 1;
                    border-radius: 50%;
                    overflow: hidden;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    color: #fff;
                    background-color: #bdbdbd;
                    height: auto !important;
                    width: 32px !important;
                    min-height: 32px;
                    font-size: 14px !important;
                }
                .avatar-span-class {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16.94px;
                    letter-spacing: 0px;
                    color: #000000;
                }
                .main-content-data-box {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
                .stack-for-data-title {
                    gap: 1rem !important;
                    flex-wrap: wrap !important;
                    display: flex !important;
                    flex-direction: row;
                    align-items: center;
                    align-self: stretch;
                }
                .news-title {
                    font-size: 20px;
                    line-height: 28px;
                    letter-spacing: 0px;
                    overflow: hidden;
                    cursor: pointer;
                    font-weight: 400;
                    color: #333333;
                    word-wrap: break-word !important;
                    word-break: break-word !important;
                }
                .news-para {
                    font-size: 14px;
                    line-height: 23px;
                    letter-spacing: 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    font-weight: 400;
                    color: #333333;
                    word-wrap: break-word !important;
                    word-break: break-word !important;
                    opacity: .5 !important;
                }
                .actions-div {
                    justify-content: space-between !important;
                    flex-wrap: wrap !important;
                    display: flex !important;
                    flex-direction: row;
                    align-items: center;
                    align-self: stretch;
                }
                .btn-view-article {
                    cursor: pointer;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    position: relative;
                    box-sizing: border-box;
                    -webkit-tap-highlight-color: transparent;
                    outline: 0;
                    margin: 0;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    vertical-align: middle;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    font-weight: 500;
                    line-height: 1.75;
                    letter-spacing: 0.02857em;
                    min-width: 64px;
                    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    background: #F9F9F9 !important;
                    color: #660C60 !important;
                    border: none !important;
                    text-transform: math-auto !important;
                    border-radius: 3px !important;
                    font-size: 14px !important;
                    padding: 10px !important;
                    --bs-bg-opacity: 1;
                }
                .btn-view-article svg {
                    overflow: visible;
                    box-sizing: content-box;
                    font-size: 12px !important;
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    overflow: visible;
                    vertical-align: -0.125em;
                }
                .btn-view-article span {
                    overflow: hidden;
                    pointer-events: none;
                    position: absolute;
                    z-index: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-radius: inherit;
                }
                .card-action-bottom {
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    border-top: 1px solid #EEEEEE;
                    padding: 15px;
                }
                .card-action-bottom div {
                    gap: .5rem !important;
                    margin-top: auto !important;
                    flex-wrap: wrap !important;
                    display: flex !important;
                }
                .custom-people-block {
                    padding: 10px;
                }
                .col-lg-3 {
                    height: 100%;
                }
                .col-md-6 {
                    height: 100%;
                }
                .col-sm-12 {
                    height: 100%;
                }
                .feed-card {
                    border: 1px solid #eeeeee !important;
                    border-radius: 6px;
                    box-shadow: none;
                    background-color: #ffffff !important;
                    height: 100% !important;
                    width: 100% !important;
                    color: #212529 !important;
                }
                .card-header-content {
                    padding: 10px !important;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #EEEEEE !important;
                }
                .feed-card-avatar-header {
                    display: flex;
                    flex: 0 0 auto;
                    margin-right: 16px;
                }
                .author-avtar-creator {
                    height: auto !important;
                    width: 32px !important;
                    min-height: 32px;
                    font-size: 14px !important;
                    position: relative;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    line-height: 1;
                    border-radius: 50%;
                    overflow: hidden;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    color: #fff;
                    background-color: #bdbdbd;
                }
                .feed-card-header {
                    flex: 1 1 auto;
                }
                .MuiTypography-body2 {
                    margin: 0;
                    font-weight: 400;
                    font-size: 0.875rem;
                    line-height: 1.43;
                    letter-spacing: 0.01071em;
                    display: block;
                }
                .feed-top-header {
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                }
                .MuiTypography-caption {
                    margin: 0;
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 1.66;
                    letter-spacing: 0.03333em;
                }
                .feed-card-img {
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    -webkit-background-position: center;
                    background-position: center;
                    height: 222px;
                    cursor: pointer;
                }
                .content-div-body {
                    padding: 15px 15px 15px 15px !important;
                    width: 70% !important;
                    background-color: #F9F9F9 !important;
                }
                .main-content-div-1 {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 40px;
                    margin-bottom: 20px;
                }
                .main-div-posted {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                }
                .posted-date-icon {
                    font-size: 12px;
                    font-weight: 400;
                    color: #660C60;
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    overflow: visible;
                    vertical-align: -.125em;
                }
                .posted-date-text {
                    font-size: 12px !important;
                    font-weight: 500 !important;
                    color: #000000;
                    opacity: 50% !important;
                    margin-top: auto !important;
                    margin-bottom: auto !important;
                }
                .main-div-workspace {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                }
                .categoty-label-chip {
                    height: auto !important;
                    background-color: #F9F9F9 !important;
                    border-radius: 0 !important;
                    font-weight: 400 !important;
                    max-width: 100%;
                    font-size: 0.8125rem;
                    display: inline-flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    color: rgba(0, 0, 0, 0.87);
                    white-space: nowrap;
                    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    cursor: pointer;
                    outline: 0;
                    text-decoration: none;
                    border: 0;
                    padding: 0;
                    vertical-align: middle;
                    box-sizing: border-box;
                }
                .categoty-label-chip span {
                    font-weight: 500 !important;
                    font-size: 9px !important;
                    line-height: 10px !important;
                    color: #959595;
                    /* opacity: 65%; */
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 3px !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                .post-content-data {
                    margin-top: 96px;
                }
                .icon-bd-div {
                    border: 1px solid #eeeeee;
                    height: 35px;
                    width: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: #f9f9f9;
                }
                .icon-bd-div svg {
                    color: #660c60 !important;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    line-height: 12px !important;
                    overflow: visible;
                    box-sizing: content-box;
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    overflow: visible;
                    vertical-align: -.125em;
                }
                .fs-14 {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    line-height: 24px !important;
                    font-family: "Inter" !important;
                    color: #333333 !important;
                    margin: 0 !important;
                }
                .opacity-50 {
                    opacity: .5 !important;
                }
                .content-title {
                    font-weight: 600 !important;
                    font-size: 16px !important;
                    line-height: 20px !important;
                    color: #000000 !important;
                    margin: 0;
                    letter-spacing: 0em;
                    margin-bottom: 0.35em;
                    word-wrap: break-word !important;
                    word-break: break-word !important;
                }
                .content-desc {
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    line-height: 24px !important;
                    font-family: "Inter" !important;
                    color: #333333 !important;
                }
                .primary-btn {
                    background-color: #660c60 !important;
                    color: #ffffff !important;
                    border: 1px solid #660c60;
                    border-radius: 3px !important;
                    text-transform: capitalize !important;
                    box-shadow: none !important;
                    padding: 10px 15px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 15px;
                    letter-spacing: 0px;
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    justify-content: center;
                    font-family: "Inter";
                }
                .svg-inline--fa {
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    overflow: visible;
                    vertical-align: -.125em;
                }
                .author-avtar-creator img {
                    height: 32px;
                    width: 32px;
                    text-align: center;
                    object-fit: cover;
                    color: transparent;
                    text-indent: 10000px;
                }
                .content-title-update {
                    font-size: 16px !important;
                    font-weight: 600 !important;
                    text-align: start;
                    color: #333333 !important;
                    line-height: 20px !important;
                    word-wrap: break-word !important;
                    word-break: break-word !important;
                }
                .search-input {
                    border: 1px solid #eeeeee;
                    border-radius: 100px !important;
                    background-color: #f9f9f9;
                    height: auto;
                    padding: 10px 54px 10px 15px;
                }
                .serach-box .search-btn-dash-2 {
                    position: absolute;
                    margin: 5px !important;
                    bottom: 0px;
                    right: 0px;
                    background-color: #660c60 !important;
                    border-color: #f9f9f9;
                    width: 38px !important;
                    height: 38px;
                    border-radius: 100px !important;
                    position: absolute !important;
                    right: 0;
                    margin: 0px 4px 0px 4px;
                    top: -2px;
                }
                .p-3{
                    padding: 1rem!important;
                }
                .update-post-grid-container{
                    width: calc(100% + 16px);
                    margin-left: -16px;
                    box-sizing: border-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -ms-flex-direction: row;
                    flex-direction: row;
                }
                @media(min-width: 600px) {
                    .update-post-grid-item{
                        -ms-flex-preferred-size: 33.333333%;
                        flex-basis: 33.333333%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 33.333333%;
                    }
                }
                @media(min-width: 900px) {
                    .update-post-grid-container{
                        width: calc(100% + 24px);
                        margin-left: -24px;
                    }
                    .update-post-grid-item{
                        padding-left: 15px;
                        padding-top: 15px;
                        -ms-flex-preferred-size: 25%;
                        flex-basis: 25%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 25%;
                    }
                }
                @media(min-width: 1200px) {
                    .update-post-grid-item{
                        -ms-flex-preferred-size: 25%;
                        flex-basis: 25%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 25%;
                    }
                }
                @media(min-width: 1536px) {
                    .update-post-grid-item{
                        -ms-flex-preferred-size: 25%;
                        flex-basis: 25%;
                        -webkit-box-flex: 0;
                        -ms-flex-positive: 0;
                        flex-grow: 0;
                        max-width: 25%;
                    }
                }
                .update-type-post-card {
                    height: 154px;
                    padding: 30px;
                    margin: 0 0 46px 0;
                    border: 1px solid #eeeeee;
                    border-radius: 6px;
                    box-shadow: none;
                    background-color: #ffffff;
                }
                .update-type-post-card-content{
                    padding: 0 !important;
                }
                .update-avtar-creator {
                    height: 40px !important;
                    width: 40px !important;
                    position: relative;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    font-size: 1.25rem;
                    line-height: 1;
                    border-radius: 50%;
                    overflow: hidden;
                    user-select: none;
                    color: #fff;
                    background-color: #bdbdbd;
                }
                .search-input {
                    border: 1px solid #eeeeee;
                    border-radius: 6px !important;
                    background-color: #f9f9f9;
                    height: auto;
                    padding: 10px 54px 10px 15px;
                    display: block;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    color: var(--bs-body-color);
                    appearance: none;
                    background-clip: padding-box;
                    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .post-date-label {
                    font-size: 12px !important;
                    font-weight: 500 !important;
                    color: #959595 !important;
                }
                .edit-post-icon-btn {
                    display: inline-flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    position: relative;
                    box-sizing: border-box;
                    -webkit-tap-highlight-color: transparent;
                    background-color: transparent;
                    outline: 0;
                    border: 0;
                    margin: 0;
                    cursor: pointer;
                    user-select: none;
                    vertical-align: middle;
                    -moz-appearance: none;
                    -webkit-appearance: none;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    text-align: center;
                    border-radius: 50%;
                    overflow: visible;
                    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    color: #333333 !important;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    padding: 0 8px 0 8px !important;
                }
                .edit-post-icon-btn svg{
                    user-select: none;
                    width: 1em;
                    height: 1em;
                    display: inline-block;
                    fill: currentColor;
                    -ms-flex-negative: 0;
                    flex-shrink: 0;
                    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    font-size: 1.5rem;
                }
                .content-div-body-2 {
                    padding: 15px 15px 15px 15px !important;
                    width: 100% !important;
                    background-color: #fff !important;
                }
                .update-content-img{
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 222px;
                }
                .author-avtar-creator-post {
                    height: 24px !important;
                    width: 24px !important;
                    font-size: 14px !important;
                    position: relative;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    flex-shrink: 0;
                    line-height: 1;
                    border-radius: 50%;
                    overflow: hidden;
                    user-select: none;
                    color: #fff;
                    background-color: #bdbdbd;
                }
                .feed-update-post-user-img{
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    object-fit: cover;
                    color: transparent;
                    text-indent: 10000px;
                }
                .feed-card-header-avatar{
                    display: flex;
                    flex: 0 0 auto;
                    margin-right: 16px;
                }
                .feed-card-header-content{
                    flex: 1 1 auto;
                }
                .feed-card-box-1{
                    display: flex;
                    -webkit-box-pack: justify;
                    justify-content: space-between;
                    -webkit-box-align: center;
                    align-items: center;
                }
                .feed-card-box-2{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .post-posted-label{
                    font-size: 12px !important;
                }
                .update-multi-grid-container{
                    box-sizing: border-box;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                }
                @media(min-width: 900px){
                    .update-multi-grid-container{
                        width: calc(100% + 24px) !important;
                        margin-left: -24px !important;
                    }
                }
                    @media(min-width: 0px){
                    .update-multi-grid-container{
                        width: calc(100% + 16px) !important;
                        margin-left: -16px !important;
                    }
                }
                .card-content-box {
                    padding: 20px;
                }
                .post-img{
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 222px;
                    width: 30% !important;
                }
                .insight-img-box {
                    width: 80px;
                    height: 80px;
                }
                .insight-content-name {
                    font-size: 12px !important;
                    font-weight: 700 !important;
                    line-height: 14.52px !important;
                    color: #660C60 !important;
                }
                .insight-content-user-name {
                    font-size: 16px !important;
                    font-weight: 600 !important;
                    line-height: 20px !important;
                    color: #333333 !important;
                }
                .create-content-popup-2{
                    position: fixed;
                    z-index: 1300;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    left: 0;
                }
                .create-content-popup-2 .MuiBackdrop-root{
                    opacity: 1;
                    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
                    position: fixed;                   
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    background-color: transparent;
                    -webkit-tap-highlight-color: transparent;
                    z-index: -1;
                }
                .create-content-popup-2 .MuiPaper-root{
                    opacity: 1;
                    transform: none;
                    transition: opacity 371ms cubic-bezier(0.4, 0, 0.2, 1), transform 247ms cubic-bezier(0.4, 0, 0.2, 1);
                    top: 0 !important;
                    left: 30px;
                    transform-origin: 0px 471.778px;
                    background-color: #fff;
                    color: rgba(0, 0, 0, 0.87);
                    border-radius: 4px;
                    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
                    position: absolute;
                    overflow-y: auto;
                    overflow-x: hidden;
                    min-width: 16px;
                    min-height: 16px;
                    max-width: calc(100% - 32px);
                    max-height: calc(100% - 32px);
                    outline: 0;
                }
                .setting-popup-2{
                    position: fixed;
                    z-index: 1300;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    left: 0;
                }
                .setting-popup-2 .MuiBackdrop-root{
                    opacity: 1;
                    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
                    position: fixed;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    background-color: transparent;
                    -webkit-tap-highlight-color: transparent;
                    z-index: -1;
                }
                .setting-popup-2 .MuiPaper-root{
                    opacity: 1;
                    transform: none;
                    transition: opacity 371ms cubic-bezier(0.4, 0, 0.2, 1), transform 247ms cubic-bezier(0.4, 0, 0.2, 1);
                    top: 492px !important;
                    left: 30px;
                    transform-origin: 0px 471.778px;
                    background-color: #fff;
                    color: rgba(0, 0, 0, 0.87);
                    border-radius: 4px;
                    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
                    position: absolute;
                    overflow-y: auto;
                    overflow-x: hidden;
                    min-width: 16px;
                    min-height: 16px;
                    max-width: calc(100% - 32px);
                    max-height: calc(100% - 32px);
                    outline: 0;
                }
                .main-app-icon-div-2{
                    height: auto;
                    width: 263px;
                    padding: .5rem!important;
                }
                .main-grid{
                    box-sizing: border-box;
                    display: flex;
                    -webkit-flex-wrap: wrap;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    width: 100% !important;
                    margin-left: 0 !important;
                    margin-top: 0 !important;
                }
                .type-stack{
                    display: flex;
                    flex: 1 1 auto;
                    flex-direction: column;
                    align-self: stretch;
                    width: 100%!important;
                    gap: .25rem!important;
                }
                .list-item-menu-2{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
                .list-item-setting-menu-2{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    position: relative;
                    padding-top: 8px;
                    padding-bottom: 8px;
                }
                .type-list-item{
                    -webkit-box-pack: start;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    position: relative;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    width: 100%;
                    box-sizing: border-box;
                    text-align: left;
                    display: block;
                }
                .type-list-item-settings{
                    display: flex;
                    -webkit-box-pack: start;
                    justify-content: flex-start;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    text-decoration: none;
                    width: 100%;
                    box-sizing: border-box;
                    text-align: left;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-left: 16px;
                    padding-right: 16px;
                    height: 37px;
                }
                .item-div{
                    -webkit-tap-highlight-color: transparent;
                    background-color: transparent;
                    outline: 0;
                    border: 0;
                    margin: 0;
                    border-radius: 0;
                    padding: 0;
                    cursor: pointer;
                    user-select: none;
                    vertical-align: middle;
                    -webkit-appearance: none;
                    color: inherit;
                    display: flex;
                    -webkit-box-flex: 1;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    min-width: 0;
                    box-sizing: border-box;
                    text-align: left;
                    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    min-height: 48px;
                    -webkit-box-pack: initial;
                    -ms-flex-pack: initial;
                    justify-content: initial;
                }
                .type-list-item-icon{
                    color: rgba(0, 0, 0, 0.54);
                    flex-shrink: 0;
                    display: inline-flex;
                    min-width: 0;
                    margin-right: 16px;
                    margin-left: 32px;
                    -webkit-box-pack: center;
                    justify-content: center;
                }
                .type-list-item-icon-settings{
                    min-width: 36px;
                    color: rgba(0, 0, 0, 0.54);
                    flex-shrink: 0;
                    display: inline-flex;
                }
                .type-list-item-icon svg {
                    overflow: visible;
                    box-sizing: content-box;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    color: #660C60 !important;
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    vertical-align: -.125em;
                }
                .type-list-item-icon-settings svg{
                    overflow: visible;
                    box-sizing: content-box;
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    color: #660C60 !important;
                    display: var(--fa-display, inline-block);
                    height: 1em;
                    vertical-align: -.125em;
                }
                .type-list-item-text{
                    flex: 1 1 auto;
                    min-width: 0;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    color: #959595 !important;
                }
                .type-list-item-text-settings{
                    flex: 1 1 auto;
                    min-width: 0;
                    margin-top: 4px;
                    margin-bottom: 4px;
                    font-size: 14px !important;
                    font-weight: 400 !important;
                    color: #959595 !important;
                }
                .type-list-item-text span{
                    margin: 0;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                    display: block;
                }
                .type-list-item-text-settings span{
                    margin: 0;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                    display: block;
                }
                .action-menu{
                    position: fixed !important;
                    z-index: 1300 !important;
                    right: 0 !important;
                    bottom: 0 !important;
                    top: 0 !important;
                    left: 0 !important;
                }
                .action-menu .MuiBackdrop-root{
                    opacity: 1;
                    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
                    position: fixed;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    justify-content: center;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    left: 0;
                    background-color: transparent;
                    -webkit-tap-highlight-color: transparent;
                    z-index: -1;
                }
                .action-menu .MuiPaper-root{
                    min-width: 120px !important;
                    border-radius: 6px !important;
                    border: 1px solid #eeeeee;
                    box-shadow: none !important;
                    padding: 5px;
                    background-color: #ffffff;
                    color: rgba(0, 0, 0, 0.87);
                    position: absolute;
                    overflow-y: auto;
                    overflow-x: hidden;
                    min-height: 16px;
                    max-width: calc(100% - 32px);
                    outline: 0;
                    max-height: calc(100% - 96px);
                }
                .action-menu ul {
                    padding: 0 0 0 0 !important;
                    list-style: none;
                    margin: 0;
                    position: relative;
                    outline: 0;
                }
                .action-menu-item {
                    padding: 10px 15px !important;
                    gap: 10px;
                    -webkit-tap-highlight-color: transparent;
                    background-color: transparent;
                    outline: 0;
                    border: 0;
                    margin: 0;
                    border-radius: 0;
                    padding: 0;
                    cursor: pointer;
                    user-select: none;
                    vertical-align: middle;
                    -webkit-appearance: none;
                    -webkit-text-decoration: none;
                    color: inherit;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                    display: flex;
                    -webkit-box-pack: start;
                    justify-content: flex-start;
                    -webkit-box-align: center;
                    align-items: center;
                    position: relative;
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    box-sizing: border-box;
                    white-space: nowrap;
                }
                .menu-item-icon {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 12px;
                    color: #660c60;
                }
                .menu-item-label {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 16.94px;
                    color: rgba(149, 149, 149, 0.5843137255);
                }
                .action-delete-item, .action-delete-icon, .action-delete-label {
                    color: #e1593b !important;
                }
             ${css}
            </style>
            <script>
            ${js}
            ${submenuToggleScript}  
             const people = ${JSON.stringify(people)};
            ${buttonClickScript}
            ${textFieldScript}
            ${inputFocusScript}
            ${viewAtricleBTNScript}
            ${viewImgSliderScript}
            ${createContentBTNScript}
            ${settingBTNScript}
            ${feedActionBTNScript}
            ${viewImgSliderScript2}
            ${viewImgSliderScript3}
            </script>
          </head>
          <body>
          ${updatedHTML}
          </body>
        </html>
    `;

    useEffect(() => {
        const apiCall = async () => {
            await fetchTemplate()
        }
        apiCall()

        const handleMessage = (event) => {
            // console.log("Event received:", event);
            if (event.data?.action === "updateBoxFocus" && event.data?.key === "unique_key_update_box") {
                console.log("line 4543")
                setOpenUpdateBox((prevState) => !prevState); // Toggle the state
                setIsFeedDataForUpdatePost(true)
            }
            else if (event.data?.action === "redirectAtriclePage" && event.data?.key === "unique_key_redirectAtriclePage") {
                const url = `/user-type/view/${event?.data?.feedTypeName}/${event?.data?.feedId}`;
                window.open(url, "_blank"); // Open the URL in a new tab
            }
            else if (event.data.action === "createContent" && event.data.key === "unique_key_createContent") {
                console.log("line 3548", event)
                // Access the iframe
                const iframe = document.querySelector("iframe"); // Adjust the selector to match your iframe
                if (iframe) {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Access iframe's document
                    const createContentButton = iframeDoc.querySelector(".create-content-btn-2"); // Query the button within the iframe

                    if (createContentButton) {
                        console.log("createContentButton:::", createContentButton);
                        // Simulate a click on the button or handle the logic
                        handleClickForCreateContent({ currentTarget: createContentButton });
                    } else {
                        console.warn("Create Content button not found inside the iframe.");
                    }
                } else {
                    console.warn("Iframe not found.");
                }
            }
            else if (event.data.action === "settingBTN" && event.data.key === "unique_key_settingbtn") {
                console.log("line 3894", event)
                // handleClickForCreateContent(event)
                // Manually find the button element
                // Access the iframe
                const iframe = document.querySelector("iframe"); // Adjust the selector to match your iframe
                if (iframe) {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Access iframe's document
                    const createContentButton = iframeDoc.querySelector(".setting-btn-2"); // Query the button within the iframe

                    if (createContentButton) {
                        console.log("createContentButton:::", createContentButton);
                        // Simulate a click on the button or handle the logic
                        handleClickForAvatar({ currentTarget: createContentButton });
                    } else {
                        console.warn("Create Content button not found inside the iframe.");
                    }
                } else {
                    console.warn("Iframe not found.");
                }
            }
            else if (event.data.action === "feedActionBTN" && event.data.key === "unique_key_feedActionBTN") {
                console.log("line 4854", event)
                // handleClickForCreateContent(event)
                // Manually find the button element
                // Access the iframe
                const iframe = document.querySelector("iframe"); // Adjust the selector to match your iframe
                let createContentButton;
                if (iframe) {
                    const iframeDoc = iframe.contentDocument || iframe.contentWindow.document; // Access iframe's document
                    createContentButton = iframeDoc.querySelector(`.edit-post-icon-btn[data-post-id="${event?.data?.id}"]`);

                    if (createContentButton) {
                        console.log("createContentButton:::", createContentButton);
                        // Simulate a click on the button or handle the logic
                        // handleClickForAvatar({ currentTarget: createContentButton });
                        // handleClickForMenuPost({ currentTarget: createContentButton }, event?.data?.isEdit, event?.data?.isDelete, event?.data?.typeName, event?.data?.id)
                    } else {
                        console.warn("Create Content button not found inside the iframe.");
                    }
                } else {
                    console.warn("Iframe not found.");
                }
                if (anchorElMenuForPost) {
                    handleCloseForMenuPost()
                } else {
                    handleClickForMenuPost({ currentTarget: createContentButton }, event?.data?.isEdit, event?.data?.isDelete, event?.data?.typeName, event?.data?.id, event?.data?.typeSlug, event?.data?.typeID)
                }
            }
            else if (event.data.action === "imgslider" && event.data.key === "unique_key_imgslider") {
                console.log("line 5223", event)
                setOpenImageModel(true)
                setSinglePostData(event?.data?.feed)
            }
            else if (event.data.action === "imgslider2" && event.data.key === "unique_key_imgslider2") {
                console.log("line 5287", event)
                setOpenUserImageModel(true)
                setSinglePostData(event?.data?.feed)
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage); // Cleanup on unmount
        };
    }, [])

    useEffect(() => {
        const handleMessage = async (event) => {
            if (event.data?.action === "fetchPeopleData" && event.data?.key === "unique_key") {
                const { searchValue, people } = event.data;
                // Update the input field value dynamically
                const iframe = document.querySelector("iframe"); // Adjust selector as needed
                const inputField = iframe?.contentDocument?.querySelector("input[type='text']");

                if (inputField) {
                    inputField.value = searchValue; // Set the input field value
                }
                setSearchQuery(searchValue)
                // Prevent concurrent fetches
                if (isFetching) return;
                isFetching = true;

                try {
                    const data = await fetchPeopleData(people, searchValue); // Call your fetch function
                    console.log("Fetched data:", data);
                } catch (error) {
                    console.error("Error fetching people data:", error);
                } finally {
                    isFetching = false; // Reset the fetching flag
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage); // Cleanup on unmount
        };
    }, [isFetching]);

    useEffect(() => {
        const handleMessage = async (event) => {

            if (event.data?.action === "fetchPeopleData" && event.data?.key === "unique_key_empty") {
                const { searchValue, people } = event.data;

                // Update the input field value dynamically
                const iframe = document.querySelector("iframe"); // Adjust selector as needed
                const inputField = iframe?.contentDocument?.querySelector("input[type='text']");

                if (inputField) {
                    inputField.value = searchValue; // Set the input field value
                }
                setSearchQuery(searchValue)
                // Prevent concurrent fetches
                if (isFetchingText) return;
                isFetchingText = true;

                try {
                    const data = await fetchPeopleData(people, searchValue); // Call your fetch function
                    console.log("Fetched data:", data);
                } catch (error) {
                    console.error("Error fetching people data:", error);
                } finally {
                    isFetchingText = false; // Reset the fetching flag
                }
            }
            if (event.data?.action === "fetchPeopleData" && event.data?.key === "unique_key_search") {
                const { searchValue, people } = event.data;
                // Update the input field value dynamically
                const iframe = document.querySelector("iframe"); // Adjust selector as needed
                const inputField = iframe?.contentDocument?.querySelector("input[type='text']");

                if (inputField) {
                    inputField.value = searchValue; // Set the input field value
                }
                setSearchQuery(searchValue)
                // Prevent concurrent fetches
                if (isFetchingText) return;
                isFetchingText = true;

                try {
                    const data = await fetchPeopleData(people, searchValue); // Call your fetch function
                    console.log("Fetched data:", data);
                } catch (error) {
                    console.error("Error fetching people data:", error);
                } finally {
                    isFetchingText = false; // Reset the fetching flag
                }
            }
        };

        window.addEventListener("message", handleMessage);

        return () => {
            window.removeEventListener("message", handleMessage); // Cleanup on unmount
        };
    }, [isFetchingText]);

    useEffect(() => {
        // Trigger API call when the searchValue becomes empty
        const handleEmptySearch = async () => {
            if (searchQuery === "" && !isFetching) {
                isFetching = true;
                try {
                    const data = await fetchPeopleData([], ""); // Replace with appropriate logic for empty search
                    console.log("Fetched data for empty search:", data);
                } catch (error) {
                    console.error("Error fetching data for empty search:", error);
                } finally {
                    isFetching = false; // Reset the fetching flag
                }
            }
        };

        handleEmptySearch();
    }, [searchQuery]);

    useEffect(() => {
        if (menu?.length > 0) {
            fetchUserMenuList(menu)
        }
    }, [menu])

    useEffect(() => {
        if (types?.length > 0) {
            fetchTypesData(types)
        }
    }, [types])

    useEffect(() => {
        if (people?.length > 0) {
            fetchPeopleData(people)
        }
    }, [people])

    useEffect(() => {
        if (feed?.length > 0) {
            fetchFeedData(pageFeed)
        }
    }, [feed])

    useEffect(() => {
        if (link?.selectLink?.length > 0) {
            fetchLinkData(link?.selectLink)
        }
    }, [link])

    useEffect(() => {
        const handleSubmenuToggle = (e) => {
            const menuId = e.target.getAttribute("data-id");
            const submenu = document.getElementById(`submenu-${menuId}`);
            if (submenu) {
                submenu.classList.toggle("hidden");
                e.target.textContent = submenu.classList.contains("hidden") ? "▼" : "▲";
            }
        };

        document.querySelectorAll(".submenu-toggle").forEach((toggle) => {
            toggle.addEventListener("click", handleSubmenuToggle);
        });

        return () => {
            document.querySelectorAll(".submenu-toggle").forEach((toggle) => {
                toggle.removeEventListener("click", handleSubmenuToggle);
            });
        };
    }, [updatedHTML]);

    useEffect(() => {
        if (selectedMediaFolderIDForImage !== "" && selectedMediaTypeForImage !== "") {
            fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForImage)
        }
    }, [selectedMediaFolderIDForImage, selectedMediaTypeForImage])

    useEffect(() => {
        if (selectedMediaFolderIDFordoc !== "" && selectedMediaTypeForDoc !== "") {
            fetchMediaFiles(pageForMedia, rowsPerPageForMedia, mediaDataFor, selectedMediaFileForDoc)
        }
    }, [selectedMediaFolderIDFordoc, selectedMediaTypeForDoc])

    useEffect(() => {
        if (selectedMedia !== "" && isEditMedia) {
            fetchMediaDetails()
        }
    }, [selectedMedia])

    useEffect(() => {
        if (selectedMedia !== "" && isRenameMedia) {
            fetchMediaDetailsForRename()
        }
    }, [selectedMedia])

    useEffect(() => {
        console.log("inside useEffect scroll");

        const debouncedHandleScroll = debounce(handleScrollForFeed, 200);

        window.addEventListener("scroll", debouncedHandleScroll);
        return () =>
            window.removeEventListener("scroll", debouncedHandleScroll);
    }, [isDataFetchingForFeed, pageFeed, totalPagesFeed]);

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <div className="main-div-2-types">
                        <Box className="">
                            <Stack spacing={2}>
                                <Breadcrumbs
                                    separator={<NavigateNextIcon fontSize="small" />}
                                    aria-label="breadcrumb"
                                >
                                    <Link
                                        underline="hover"
                                        key="1"
                                        color="inherit"
                                        href="/"
                                        component="h3"
                                        className="cursor-pointer"
                                    >
                                        {loggedUser?.user_type === "admin" ? "Admin" : "User"}
                                    </Link>
                                    <Typography key="3"
                                        component="h3"
                                        color="inherit"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            localStorage.setItem("last-redirect-page", `/pages`);
                                            dispatch(pageAction?.clearAll())
                                            if (loggedUser?.user_type === "admin" && adminAccessible === false) {
                                                localStorage.setItem("last-redirect-page", `/pages`);
                                                dispatch(pageAction?.clearAll())
                                                navigate("/pages");
                                            } else if (loggedUser?.user_type === "admin" && adminAccessible === true) {
                                                localStorage.setItem("last-redirect-page-admin-user", `/user/pages`);
                                                dispatch(pageAction?.clearAll())
                                                navigate("/user/pages")
                                            } else {
                                                localStorage.setItem("last-redirect-page-user", `/user/pages`);
                                                dispatch(pageAction?.clearAll())
                                                navigate("/user/pages")
                                            }
                                        }}>
                                        Pages
                                    </Typography>
                                    <Typography key="3"
                                        component="h3"
                                        color="inherit"
                                        className="cursor-pointer"
                                    >
                                        {pageTitle}
                                    </Typography>
                                </Breadcrumbs>
                            </Stack>
                        </Box>
                        <div className="d-flex flex-wrap gap-1">
                            <Button
                                className="btn primary-btn"
                                onClick={() => {
                                    dispatch(pageAction?.clearAll())
                                    window?.history?.back()
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                                Back
                            </Button>
                        </div>
                    </div>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <div style={{ margin: "0 auto", width: "auto", height: "800px" }}>

                        <iframe
                            title="Preview"
                            style={{
                                width: '100%', height: '100%', border: 'none', overflow: 'hidden',
                            }}
                            srcDoc={previewContent}
                        ></iframe>

                    </div>
                    {openUpdateBox && (
                        <Suspense fallback={<Loader isLoading={true} />}>
                            <UpdatePostCreateModal
                                openPostPopup={openUpdateBox}
                                setOpenPostPopup={setOpenUpdateBox}
                                loggedUser={loggedUser}
                                adminAccessible={adminAccessible}
                                fetchAllTypePostReset={fetchFeedData}
                                handleOpenUploadMedia={handleOpenUploadMedia}
                                setOpenMediaModelFor={setOpenMediaModelFor}
                                setSelectedMediaFileForUpdate={setSelectedMediaFileForUpdate}
                                selectedMediaFileForUpdate={selectedMediaFileForUpdate}
                                showAdvancedOption={false}
                                isMultiImage={true}
                                selectedMediaFileDetailsForImages={selectedMediaFileDetailsForImages}
                                selectedMediaFileForImages={selectedMediaFileForImages}
                                setSelectedMediaFileForImages={setSelectedMediaFileForImages}
                                setSelectedMediaFileDetailsForImages={setSelectedMediaFileDetailsForImages}
                            />
                        </Suspense>
                    )}

                    <UploadMediaModel
                        openMediaUpload={openMediaUpload}
                        handleCloseForUploadMedia={handleCloseForUploadMedia}
                        selectedMediaTypeForImage={selectedMediaTypeForImage}
                        selectedMediaTypeForDoc={selectedMediaTypeForDoc}
                        setSelectedMediaTypeForImage={setSelectedMediaTypeForImage}
                        setSelectedMediaTypeForDoc={setSelectedMediaTypeForDoc}
                        setSelectedMediaFolderIDForImage={setSelectedMediaFolderIDForImage}
                        setSelectedMediaFolderIDForDoc={setSelectedMediaFolderIDForDoc}
                        systemMediaFolderList={systemMediaFolderList}
                        selectedMediaFolderIDForImage={selectedMediaFolderIDForImage}
                        selectedMediaFolderIDFordoc={selectedMediaFolderIDFordoc}
                        myMediaFolderList={myMediaFolderList}
                        downloadFileForImage={downloadFileForImage}
                        downloadFileFordoc={downloadFileFordoc}
                        selectedMediaFileDetailsForImage={selectedMediaFileDetailsForImage}
                        setSelectedMediaFileDetailsForImage={setSelectedMediaFileDetailsForImage}
                        selectedMediaFileForImage={selectedMediaFileForImage}
                        setSelectedMediaFileForImage={setSelectedMediaFileForImage}
                        selectedMediaFileDetailsForDoc={selectedMediaFileDetailsForDoc}
                        setSelectedMediaFileDetailsForDoc={setSelectedMediaFileDetailsForDoc}
                        selectedMediaFileForDoc={selectedMediaFileForDoc}
                        setSelectedMediaFileForDoc={setSelectedMediaFileForDoc}
                        mediaList={mediaList}
                        total_mediaFiles={total_mediaFiles}
                        pageForMedia={pageForMedia}
                        setPageForMedia={setPageForMedia}
                        handleChangePageForMedia={handleChangePageForMedia}
                        rowsPerPageForMedia={rowsPerPageForMedia}
                        handleChangeRowsPerPageForMedia={handleChangeRowsPerPageForMedia}
                        media_Pagination_options={media_Pagination_options}
                        mediaDataFor={mediaDataFor}
                        handleCloseMediaModelAddMedia={handleCloseMediaModelAddMedia}
                        setSelectedFileMedia={setSelectedFileMedia}
                        selectedFileMedia={selectedFileMedia}
                        isEditMedia={isEditMedia}
                        setIsEditMedia={setIsEditMedia}
                        setSelectedMedia={setSelectedMedia}
                        selectedMedia={selectedMedia}
                        deleteMedia={deleteMedia}
                        setOpenRenameModel={setOpenRenameModel}
                        setIsRenameMedia={setIsRenameMedia}
                        setSelectedMediaFileForUpdate={setSelectedMediaFileForUpdate}
                        selectedMediaFileForUpdate={selectedMediaFileForUpdate}
                        setIsImageMedia={setIsImageMedia}
                        isMultiImage={true}
                        setSelectedMediaFileDetailsForImages={setSelectedMediaFileDetailsForImages}
                        selectedMediaFileDetailsForImages={selectedMediaFileDetailsForImages}
                        setSelectedMediaFileForImages={setSelectedMediaFileForImages}
                        selectedMediaFileForImages={selectedMediaFileForImages}
                    />

                    <AddMedia
                        openFileUploadModel={openFileUploadModelMedia}
                        isEdit={isEditMedia}
                        handleCloseForMediaModel={handleCloseForMediaModel}
                        getRootProps={getRootProps}
                        selectedFile={selectedFileMedia}
                        getInputProps={getInputProps}
                        renderPreview={renderPreview}
                        valueForMediaTab={valueForMediaTab}
                        handleChangeForMedia={handleChangeForMedia}
                        mediaTitle={mediaTitle}
                        setMediaTitle={setMediaTitle}
                        mediaAltText={mediaAltText}
                        setMediaAltText={setMediaAltText}
                        mediaDescription={mediaDescription}
                        setMediaDescription={setMediaDescription}
                        mediaDirectUrl={mediaDirectUrl}
                        setMediaDirectUrl={setMediaDirectUrl}
                        loggedUser={loggedUser}
                        isSystemTypeMedia={isSystemTypeMedia}
                        isParentFolderSystemType={isParentFolderSystemType}
                        permissionsForMedia={permissionsForMedia}
                        setPermissionsForMedia={setPermissionsForMedia}
                        isAdvancePermissionForMedia={isAdvancePermissionForMedia}
                        setIsAdvancePermissionForMedia={setIsAdvancePermissionForMedia}
                        columns_atttributes_headers={columns_atttributes_headers}
                        columns_advace_atttributes_headers={columns_advace_atttributes_headers}
                        responseForMedia={responseForMedia}
                        storeMedia={storeMedia}
                        showSystemMediaCheckbox={false}
                    />

                    <Dialog
                        aria-labelledby="customized-dialog-title"
                        open={openRnameMediaModel}
                        maxWidth="sm"
                        fullWidth={true}
                        className="media-dialog-2"
                    >
                        <DialogTitle
                            sx={{ m: 0, p: 2 }}
                            id="customized-dialog-title"
                            className="dialog-title"
                        >
                            Rename Media
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseForMediaModel}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                            className="alert-close-btn"
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent dividers className="media-preview">
                            <Box className=" main-app-icon-div-4 d-flex flex-wrap">
                                <Box className="media-info-div-2">
                                    <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                                        <Typography className="fs-14">Title</Typography>
                                        <TextField
                                            className="input-field mt-2"
                                            fullWidth
                                            id="outlined-basic short summary"
                                            variant="outlined"
                                            placeholder={`write media title`}
                                            value={mediaTitle}
                                            onChange={(event) => {
                                                setMediaTitle(event?.target?.value)
                                            }}
                                        />

                                    </Paper>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                            <Button
                                className="btn delete-btn-model"
                                onClick={handleCloseForMediaModel}
                            >
                                <FontAwesomeIcon icon={faXmark} />
                                Cancel
                            </Button>
                            <Button
                                className="btn primary-btn"
                                onClick={renameMedia}
                            >
                                <FontAwesomeIcon icon={faFloppyDisk} />
                                Save Changes
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container >
                <Popover
                    id={idCreateContent}
                    open={openMenuForCreateContent}
                    anchorEl={anchorElForCreateContent}
                    onClose={handleCloseForCreateContent}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    className={` ${openMenuForCreateContent ? "create-content-popup-2" : "d-none"}`}
                >
                    <Box className="p-2 main-app-icon-div-2">
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid
                                container
                                columns={{ xs: 4, sm: 8, md: 12 }}
                                className="main-grid"
                            >
                                <Stack direction="vertical" gap={1} className="w-100 type-stack">
                                    <List className="list-item-menu-2" >
                                        {getAllTypesContent?.map((type, index) => (
                                            <ListItem
                                                key={type?.slug}
                                                disablePadding
                                                sx={{ display: "block" }}
                                                className="type-list-item"
                                            >
                                                <ListItemButton
                                                    sx={{
                                                        minHeight: 48,
                                                        justifyContent: "initial",
                                                        px: 2.5,
                                                        "&:hover": {
                                                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                                                        },
                                                    }}
                                                    onClick={() => {
                                                        dispatch(drawerMenuAction.clickDrawerItem(type?.slug));
                                                        dispatch(drawerMenuAction.handleNameCollapsable(false));
                                                        dispatch(drawerMenuAction.handleActiveType(true));
                                                        dispatch(drawerMenuAction.handleTypesCollapsable(true));
                                                        dispatch(drawerMenuAction.handleUsersCollapsable(false));

                                                        if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                                                            handleCloseForCreateContent();
                                                            localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                                                            localStorage.setItem("type-selected-name", type?.name);
                                                            localStorage.setItem("type-selected-id", type?.id);
                                                            localStorage.setItem("type-selected-slug", type?.slug);
                                                            navigate(`/type/add/${type?.slug}`);
                                                        }
                                                        else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
                                                            handleCloseForCreateContent();
                                                            localStorage.setItem("last-redirect-page-admin-user", `/create-content`)
                                                            localStorage.setItem("type-selected-name-user", type?.name);
                                                            localStorage.setItem("type-selected-id-user", type?.id);
                                                            localStorage.setItem("type-selected-slug-user", type?.slug);
                                                            navigate(`/create-content`);
                                                        }
                                                        else {
                                                            handleCloseForCreateContent();
                                                            localStorage.setItem("last-redirect-page-user", `/create-content`)
                                                            localStorage.setItem("type-selected-name-user", type?.name);
                                                            localStorage.setItem("type-selected-id-user", type?.id);
                                                            localStorage.setItem("type-selected-slug-user", type?.slug);
                                                            navigate(`/create-content`);
                                                        }
                                                    }}
                                                    className={"item-div"}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: 0,
                                                            mr: 2,
                                                            ml: 4,
                                                            justifyContent: "center",
                                                        }}
                                                        className="type-list-item-icon"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={type?.icon !== null ? type?.icon : faStar}
                                                            className="fs-12-660C60 type-list-item-icon"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={type?.name}
                                                        className="fs-14-muted type-list-item-text"
                                                        sx={{
                                                            color: type?.colour,
                                                        }}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Stack>
                            </Grid>
                        </Box>
                    </Box>
                </Popover>

                <Popover
                    anchorEl={anchorElForAvatar}
                    id={idAvatar}
                    open={openMenuForAvatar}
                    onClose={handleCloseForAvatar}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    className={`${openMenuForAvatar ? "setting-popup-2" : "d-none"}`}
                    onClick={handleCloseForAvatar}
                >

                    <List className="list-item-setting-menu-2">
                        <ListItem onClick={handleClickForDetails} className="type-list-item-settings cursor-pointer">
                            <ListItemIcon style={{ minWidth: "36px" }} className="type-list-item-icon-settings">
                                <FontAwesomeIcon
                                    icon={faUser}
                                    className="fs-12-660C60 type-list-item-icon-settings"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={"My Details"}
                                className="type-list-item-text-settings fs-14-muted"
                            />
                        </ListItem>
                        <ListItem onClick={handleClickForDetails} className="type-list-item-settings cursor-pointer">
                            <ListItemIcon style={{ minWidth: "36px" }} className="type-list-item-icon-settings">
                                <FontAwesomeIcon
                                    icon={faGears}
                                    className="fs-12-660C60 type-list-item-icon-settings"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Top Drawer"}
                                className="type-list-item-text-settings fs-14-muted"
                            />
                        </ListItem>
                        <ListItem onClick={onLogout} className="type-list-item-settings cursor-pointer">
                            <ListItemIcon style={{ minWidth: "36px" }} className="type-list-item-icon-settings">
                                <FontAwesomeIcon
                                    icon={faLock}
                                    className="fs-12-660C60 type-list-item-icon-settings"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Log Out"}
                                className="type-list-item-text-settings fs-14-muted"
                            />
                        </ListItem>
                    </List>
                </Popover>

                {openMenuForPost &&
                    <ActionMenu
                        anchorEl={anchorElMenuForPost}
                        open={openMenuForPost}
                        onClose={handleCloseForMenuPost}
                        menuItems={menuItemsForPostList}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        className={`${openMenuForPost ? "action-menu" : "d-none"}`}
                    />
                }

                {/* <Popover
                    anchorEl={anchorElMenuForPost}
                    id={idFeedActions}
                    open={openMenuForPost}
                    onClose={handleCloseForAvatar}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    className={`${openMenuForPost ? "setting-popup-2" : "d-none"}`}
                    onClick={handleCloseForMenuPost}
                >

                    <List className="list-item-setting-menu-2">
                        <ListItem onClick={handleClickForDetails} className="type-list-item-settings cursor-pointer">
                            <ListItemIcon style={{ minWidth: "36px" }} className="type-list-item-icon-settings">
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="fs-12-660C60 type-list-item-icon-settings"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={"View"}
                                className="type-list-item-text-settings fs-14-muted"
                            />
                        </ListItem>
                        <ListItem onClick={handleClickForDetails} className="type-list-item-settings cursor-pointer">
                            <ListItemIcon style={{ minWidth: "36px" }} className="type-list-item-icon-settings">
                                <FontAwesomeIcon
                                    icon={faPen}
                                    className="fs-12-660C60 type-list-item-icon-settings"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Edit"}
                                className="type-list-item-text-settings fs-14-muted"
                            />
                        </ListItem>
                        <ListItem onClick={onLogout} className="type-list-item-settings cursor-pointer">
                            <ListItemIcon style={{ minWidth: "36px" }} className="type-list-item-icon-settings">
                                <FontAwesomeIcon
                                    icon={faTrash}
                                    className="fs-12-660C60 type-list-item-icon-settings"
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Delete"}
                                className="type-list-item-text-settings fs-14-muted"
                            />
                        </ListItem>
                    </List>
                </Popover> */}

                <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openDeleteAlert}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle
                        sx={{ m: 0, p: 2 }}
                        id="customized-dialog-title"
                        className="dialog-title"
                    >
                        Are you sure?
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDeleteAlertModel}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        className="alert-close-btn"
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers className="">
                        {" "}
                        Are you sure want to delete post?
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                        <Button
                            className="btn delete-btn-model"
                            onClick={handleCloseDeleteAlertModel}
                        >
                            <FontAwesomeIcon icon={faXmark} />
                            Cancel
                        </Button>
                        <Button
                            className="btn primary-btn"
                            onClick={() => deleteTypePostAPI(deletePostId)}
                        >
                            <FontAwesomeIcon icon={faFloppyDisk} />
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <BootstrapDialog
                    onClose={handleCloseForImgeModel}
                    aria-labelledby="customized-dialog-title"
                    open={openImageModel}
                    fullWidth
                    maxWidth={"lg"}
                >
                    <DialogContent dividers className="no-border-img-carousel">
                        <Carousel data-bs-theme="dark" activeIndex={selectedImageIndex} onSelect={(selectedIndex) => setSelectedImageIndex(selectedIndex)}>
                            {singlePostData?.multi_image.map((file, index) => (
                                <Carousel.Item key={index}>
                                    <Image
                                        className="w-100 h-100"
                                        src={file?.file_url}
                                        alt={file?.alt_text || `Image ${index + 1}`}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </DialogContent>
                </BootstrapDialog>

                <BootstrapDialog
                    onClose={handleCloseForUserImageModel}
                    aria-labelledby="customized-dialog-title"
                    open={openUserImageModel}
                    fullWidth
                    maxWidth={"xl"}
                >
                    <DialogContent dividers className="no-border-img-carousel">
                        <Image
                            className="w-100 h-100"
                            src={singlePostData?.celebrate_user?.thumbnail}
                            alt={singlePostData?.celebrate_user?.name}
                        />
                    </DialogContent>
                </BootstrapDialog>

                <Loader isLoading={loading} />
                <Toaster />
            </div >
        </>
    )
}

export default PreviewPage