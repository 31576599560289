import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";

import { useNavigate } from "react-router";
import { UserMenu } from "../common/userMenu";
import { AdminMenu } from "../common/adminMenu";
import { useLocation } from "react-router-dom";
import api from "../common/commonFunctionForApi";
import { useSelector } from "react-redux";
import { Toast } from "../../utils/toast";
import { faEye, faGear, faPen, faPlus, faStar, faUser, faGears, faLock } from "@fortawesome/free-solid-svg-icons";
import { Stack, Image as BootstrapImage, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Avatar, Typography, Popover, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, IconButton } from "@mui/material";
import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../common/loader";

const drawerWidth = 320;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerMenu = (props) => {
  let {
    open,
    dispatch,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    usersCollapsable,
    activeType,
    drawerVariant,
    fetchNewType
  } = props;
  const location = useLocation();

  const selectedTypeSlug = localStorage.getItem("type-selected-slug");
  const [loading, setLoading] = useState(false);

  const [openOperations, setOpenOperations] = useState(false);
  const [openNamingClassification, setOpenNamingClassification] = useState(
    selectedItem === "naming-types" ||
      selectedItem === "naming-categories" ||
      selectedItem === "naming-attributes" ||
      selectedItem === "naming-rules" ||
      selectedItem === "naming" ||
      location.pathname === "/types" ||
      location.pathname === "/types/add-new" ||
      location.pathname.startsWith("/types/edit") ||
      location.pathname === "/attribute" ||
      location.pathname === "/attribute/add-new" ||
      location.pathname.startsWith("/attribute/edit") ||
      location.pathname === "/types" ||
      location.pathname === "/attribute" ||
      location.pathname === "/add/categories" ||
      location.pathname === "/categories" ||
      location.pathname.startsWith("/categories/edit")
      ? true
      : false
  );
  const [openTypes, setOpenTypes] = useState(
    location.pathname.startsWith("/type/") ||
      location.pathname.startsWith("/type/add") ||
      location.pathname.startsWith("/type/edit") ||
      location.pathname.startsWith("/type/view") ||
      location.pathname.startsWith(`/type/edit/${selectedTypeSlug}`)
      ? true
      : false
  );
  const [openUsers, setOpenUsers] = useState(
    location.pathname === "/settings" ||
      location.pathname === "/profile-fields" ||
      location.pathname === "/users" ||
      location.pathname === "/groups" ||
      location.pathname === "/add/groups" ||
      location.pathname.startsWith("/group/edit/") ||
      location.pathname === "/add/users" ||
      location.pathname.startsWith("/user/edit/")
      ? true
      : false
  );

  const { menuFor,
    adminAccessible, getAllTypesContent } = useSelector((state) => state?.drawerMenu);
  const [getAllTypes, setGetAllTypes] = useState([]);

  const [getAllTypesForUsers, setGetAllTypesForUsers] = useState([]);

  const [expandedMenuForUser, setExpandedMenuForUser] = useState({});
  const [expandedMenuForAdmin, setExpandedMenuForAdmin] = useState({});

  const [anchorElForCreateContent, setAnchorElForCreateContent] = useState(null);
  const [anchorElForAvatar, setAnchorElForAvatar] = React.useState(null);

  const [drawerVarient, setDrawerVarient] = useState("permanent");

  const [showMenuFor, setshowMenuFor] = useState("")

  const [userMenuLists, setUserMenuLists] = useState([])
  const [selectedView, setSelectedView] = React.useState("")

  const navigate = useNavigate();

  const userToken = localStorage.getItem("accessToken");

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  const openMenuForCreateContent = Boolean(anchorElForCreateContent)
  const openMenuForAvatar = Boolean(anchorElForAvatar);

  const idCreateContent = openMenuForCreateContent ? "simple-popover" : undefined
  const idAvatar = openMenuForAvatar ? "simple-popover" : undefined;


  const handleClickForOperationsMenu = () => {
    setOpenOperations(!openOperations);
  };

  const handleClickForNamingClassificationMenu = () => {
    let updateOpen = nameCollapsable == false ? true : false;
    setOpenNamingClassification(updateOpen);
    dispatch(drawerMenuAction.handleTypesCollapsable(false));
    setOpenOperations(false);
    dispatch(drawerMenuAction.handleUsersCollapsable(false));
    setOpenUsers(false);
    dispatch(drawerMenuAction.handleNameCollapsable(updateOpen));
  };

  const handleClickForUsersMenu = () => {
    let updateOpen = usersCollapsable == false ? true : false;
    setOpenUsers(updateOpen);

    dispatch(drawerMenuAction.handleUsersCollapsable(updateOpen));
  };

  const handleClickForTypesMenu = () => {
    let updateOpen = typeCollapsable == false ? true : false;
    setOpenTypes(updateOpen);
    dispatch(drawerMenuAction.handleNameCollapsable(false));
    setOpenNamingClassification(false);
    dispatch(drawerMenuAction.handleUsersCollapsable(false));
    setOpenUsers(false);
    dispatch(drawerMenuAction.handleTypesCollapsable(updateOpen));
  };

  const handleClickForCreateContent = (event) => {
    console.log("event for drawer::", event)
    if (anchorElForCreateContent == null) {
      setAnchorElForCreateContent(event.currentTarget);
    } else {
      setAnchorElForCreateContent(null);
    }
  };

  const handleCloseForCreateContent = () => {
    setAnchorElForCreateContent(null);
  };

  const handleChange = (data) => {
    if (data == true) {
      localStorage.setItem("admin-access-view", true)
      setSelectedView("view")
      dispatch(drawerMenuAction.handleSelectMenu("user"))
      dispatch(drawerMenuAction.handleAdminAccessible(true))
      navigate(
        localStorage?.getItem("last-redirect-page-admin-user")
          ? localStorage?.getItem("last-redirect-page-admin-user")
          : "/dashboard"
      );
    } else {
      localStorage.setItem("admin-access-view", false)
      setSelectedView("edit")
      dispatch(drawerMenuAction.handleSelectMenu("admin"))
      dispatch(drawerMenuAction.handleAdminAccessible(false))
      navigate(
        localStorage?.getItem("last-redirect-page")
          ? localStorage?.getItem("last-redirect-page")
          : "/"
      );
    }
  };

  const handleClickForAvatar = (event) => {
    handleCloseForCreateContent();
    if (anchorElForAvatar == null) {
      setAnchorElForAvatar(event.currentTarget);
    } else {
      setAnchorElForAvatar(null);
    }
  };

  const handleCloseForAvatar = () => {
    setAnchorElForAvatar(null);
  };

  const handleClickForDetails = () => {
    if (loggedUser?.user_type == "admin") {
      localStorage.setItem("last-redirect-page", `/`)
      navigate("/");
    } else {
      localStorage.setItem("last-redirect-page-user", `/dashboard`)
      navigate("/dashboard");
    }
  };

  const onLogout = async () => {
    setLoading(true);
    localStorage.removeItem("selected-folder-item")
    localStorage.removeItem("selected-folder-type")

    const result = await logoutHelper(dispatch, navigate, userToken);
    if (result == true) {
      setLoading(false);
    }
  };

  const updateIcon = () => {
    if (window.innerWidth <= 768) {
      dispatch(drawerMenuAction.handleDrawerForSmallScreen(false));
      dispatch(drawerMenuAction.handleDrawerVariant("persistent"));
      setDrawerVarient("persistent");
    } else if (window.innerWidth <= 1024) {
      dispatch(drawerMenuAction.handleDrawerForSmallScreen(false));
    } else {
      dispatch(drawerMenuAction.handleDrawerVariant("permanent"));
      setDrawerVarient("permanent");
    }
  };

  const fetchAllTypes = async (type, order) => {
    try {
      const response = await api.get(`admin/type/master/list/menu`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllTypes(response?.data);
    } catch (err) {
      console.log("err`or in fetch news api::", err);
    }
  };

  const fetchAllTypesForCeateContnt = async (type, order) => {

    try {
      const response = await api.get(`user/type/list/menu`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log("response in fetchAllTypesForCeateContnt::", response)
      dispatch(drawerMenuAction.setGetAllTypesContent(response?.data))
    } catch (err) {
      console.log("err`or in fetch news api::", err);
    }
  };

  const fetchAllTypesForUser = async () => {
    try {
      const response = await api.get(`user/type/list/menu`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setGetAllTypesForUsers(response?.data);
    } catch (err) {
      console.log("err`or in fetch news api::", err);

    }
  };

  const fetchUserMenuList = async () => {
    try {
      const response = await api.get(
        "user/user-menu",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        console.log("notification action updated");
        const flattenedArray = [...new Map(response?.response?.flat().map(item => [item.id, item])).values()];
        console.log("flattenedArray:::", flattenedArray)
        setUserMenuLists(flattenedArray)
      } else {
        console.log("error while updating seen action::", response?.error);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err updating seen action api::", err);
    }
  }

  const fetchAdminMenuList = async () => {
    try {
      const response = await api.get(
        "user/admin-menu",
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success) {
        console.log("notification action updated");
        const flattenedArray = [...new Map(response?.response?.flat().map(item => [item.id, item])).values()];
        console.log("flattenedArray:::", flattenedArray)
        setUserMenuLists(flattenedArray)
      } else {
        console.log("error while updating seen action::", response?.error);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err updating seen action api::", err);
    }
  }

  useEffect(() => {
    updateIcon();
    const adminAccessView = localStorage.getItem("admin-access-view")

    if (adminAccessView === "true") {
      setSelectedView("view");
      dispatch(drawerMenuAction.handleSelectMenu("user"))
      dispatch(drawerMenuAction.handleAdminAccessible(true))
    } else {
      setSelectedView("edit");
      dispatch(drawerMenuAction.handleSelectMenu("admin"))
      dispatch(drawerMenuAction.handleAdminAccessible(false))
    }
    window.addEventListener("resize", updateIcon);
    return () => {
      window.removeEventListener("resize", updateIcon);
    };


  }, []);

  useEffect(() => {
    if (loggedUser?.user_type === "admin" && adminAccessible == false) {
      fetchAllTypes();
      setshowMenuFor("admin")
      fetchAdminMenuList()
      fetchAllTypesForCeateContnt()
    } else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
      fetchAllTypesForUser();
      fetchUserMenuList()
      setshowMenuFor("user")
      fetchAllTypesForCeateContnt()

    } else if (loggedUser?.user_type === "user") {
      fetchAllTypesForUser();
      fetchUserMenuList()
      setshowMenuFor("user")
      fetchAllTypesForCeateContnt()

    }
  }, [adminAccessible])

  useEffect(() => {
    if (fetchNewType == true) {
      fetchAllTypes();
      fetchAllTypesForCeateContnt()
      if (loggedUser?.user_type === "user" || loggedUser?.user_type === "admin" && adminAccessible == true) {
        fetchAllTypesForUser()
      }
      dispatch(drawerMenuAction?.handleFetchNewType(false))
    }
  }, [fetchNewType])

  useEffect(() => {
    const adminAccessView = localStorage.getItem("admin-access-view")
    if (loggedUser?.type === "user") {
      fetchUserMenuList()
    } else if (loggedUser?.type === "admin" && adminAccessView === true) {
      fetchUserMenuList()
    }
  }, [loggedUser])

  return (
    <>
      <Drawer
        variant={drawerVariant}
        open={open}
        className={`drawer ${open == false && "short-drawer-width"} `}
      >
        {showMenuFor === "admin" ? (
          <AdminMenu
            selectedItem={selectedItem}
            open={open}
            openOperation={openOperations}
            openNamingClassification={openNamingClassification}
            handleClickForOperationsMenu={handleClickForOperationsMenu}
            handleClickForNamingClassificationMenu={
              handleClickForNamingClassificationMenu
            }
            dispatch={dispatch}
            navigate={navigate}
            location={location}
            getAllTypes={getAllTypes}
            setOpenNamingClassification={setOpenNamingClassification}
            nameCollapsable={nameCollapsable}
            typeCollapsable={typeCollapsable}
            handleClickForTypesMenu={handleClickForTypesMenu}
            openTypes={openTypes}
            setOpenTypes={setOpenTypes}
            activeType={activeType}
            handleClickForUsersMenu={handleClickForUsersMenu}
            openUsers={openUsers}
            setOpenUsers={setOpenUsers}
            userMenuLists={userMenuLists}
            setExpandedMenuForAdmin={setExpandedMenuForAdmin}
            expandedMenuForAdmin={expandedMenuForAdmin}
          />
        ) : showMenuFor === "user" ? (
          <UserMenu
            selectedItem={selectedItem}
            open={open}
            openOperation={openOperations}
            handleClickForOperationsMenu={handleClickForOperationsMenu}
            dispatch={dispatch}
            navigate={navigate}
            location={location}
            adminAccessible={adminAccessible}
            getAllTypes={getAllTypes}
            getAllTypesForUsers={getAllTypesForUsers}
            loggedUser={loggedUser}
            userMenuLists={userMenuLists}
            setExpandedMenuForUser={setExpandedMenuForUser}
            expandedMenuForUser={expandedMenuForUser}
          />
        ) : (
          ""
        )}

        <Divider style={{ background: "#EEEEEE" }} />
        <Box className={`${open === false ? "mt-3 mb-3 content-btn-open-false w-100" : "mt-3 mb-3 content-btn w-100"} `}>
          <Box className={` ${open === false ? "d-flex justify-content-center w-100" : "w-100"}  `}>
            <Button
              className={` ${open === false ? "create-content-btn-open-false" : "create-content-btn"}  ${(loggedUser?.user_type === "user") ? "w-100" : ""}`}
              aria-controls={openMenuForCreateContent ? "account-create-content" : undefined}
              aria-expanded={openMenuForCreateContent ? "true" : undefined}
              aria-describedby={idCreateContent}
              onClick={handleClickForCreateContent}
            >
              Create Content
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <FontAwesomeIcon
              icon={faPlus}
              aria-controls={openMenuForCreateContent ? "account-create-content" : undefined}
              aria-expanded={openMenuForCreateContent ? "true" : undefined}
              aria-describedby={idCreateContent}
              onClick={handleClickForCreateContent}
              className={`${open === false ? "create-content-btn-icon-open-false cursor-pointer" : "create-content-btn-icon"}`} />
          </Box>
          {loggedUser?.user_type === "admin" && (
            <Box>
              <Box className="main-switch-div">
                <Box className="switch-div-2">
                  <Box
                    className={`${selectedView === "edit" ? "icon-div-selected cursor-pointer" : "icon-div-2 cursor-pointer"} "cusror-pointer"`}
                    // className={`icon-div-2 cursor-pointer cusror-pointer`}

                    onClick={() => handleChange(false)}
                  >
                    <FontAwesomeIcon icon={faPen} className="icon-switch" />
                  </Box>
                  <Box
                    className={`${selectedView === "view" ? "icon-div-selected cursor-pointer" : "icon-div-2 cursor-pointer"} "cursor-pointer"`}
                    // className={`icon-div-2 cursor-pointer cursor-pointer`}

                    onClick={() => handleChange(true)}
                  >
                    <FontAwesomeIcon icon={faEye} className="icon-switch" />
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        <Divider style={{ background: "#EEEEEE" }} />
        <Box className={` ${open === false ? "mt-3 mb-3" : "d-flex justify-content-between align-items-center mt-3 mb-3"} `}>
          <Box>
            <Box className={` ${open === false ? "d-none" : "d-flex gap-2 align-items-center"} `}>
              <Avatar
                src={loggedUser?.thumbnail}
                className="author-avtar"
                sx={{ fontSize: "14px" }}
              >
                {loggedUser?.name?.charAt(0)}
              </Avatar>
              <Typography className="logged-user-name">
                {loggedUser?.name}
              </Typography>
            </Box>
          </Box>
          <Box className={`${open === false && "d-flex align-items-center justify-content-center"}`}>
            <Button
              className={` ${open === false ? "setting-btn-open-false" : "setting-btn"} `}
              aria-controls={openMenuForAvatar ? "account-menu" : undefined}
              aria-expanded={openMenuForAvatar ? "true" : undefined}
              aria-describedby={idAvatar}
              onClick={handleClickForAvatar}
            >
              <FontAwesomeIcon icon={faGear} className={` ${open === false ? "setting-icon" : "setting-icon"}`} />

            </Button>
          </Box>

        </Box>
      </Drawer >

      <Popover
        id={idCreateContent}
        open={openMenuForCreateContent}
        anchorEl={anchorElForCreateContent}
        onClose={handleCloseForCreateContent}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="create-content-popup"
      >
        <Box className="p-2 main-app-icon-div-2">
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              className="main-grid"
            >
              <Stack direction="vertical" gap={1} className="w-100">
                <List className="list-item-menu-2" >
                  {getAllTypesContent?.map((type, index) => (
                    <ListItem
                      key={type?.slug}
                      disablePadding
                      sx={{ display: "block" }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                          "&:hover": {
                            backgroundColor: "#F9F9F9", // Change this to your desired hover background color

                          },
                        }}
                        onClick={() => {
                          dispatch(drawerMenuAction.clickDrawerItem(type?.slug));
                          dispatch(drawerMenuAction.handleNameCollapsable(false));
                          dispatch(drawerMenuAction.handleActiveType(true));
                          dispatch(drawerMenuAction.handleTypesCollapsable(true));
                          dispatch(drawerMenuAction.handleUsersCollapsable(false));

                          if (loggedUser?.user_type == "admin" && adminAccessible == false) {
                            handleCloseForCreateContent();
                            localStorage.setItem("last-redirect-page", `/type/add/${type?.slug}`)
                            localStorage.setItem("type-selected-name", type?.name);
                            localStorage.setItem("type-selected-id", type?.id);
                            localStorage.setItem("type-selected-slug", type?.slug);
                            navigate(`/type/add/${type?.slug}`);
                          }
                          else if (loggedUser?.user_type == "admin" && adminAccessible == true) {
                            handleCloseForCreateContent();
                            localStorage.setItem("last-redirect-page-admin-user", `/create-content`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            navigate(`/create-content`);
                          }
                          else {
                            handleCloseForCreateContent();
                            localStorage.setItem("last-redirect-page-user", `/create-content`)
                            localStorage.setItem("type-selected-name-user", type?.name);
                            localStorage.setItem("type-selected-id-user", type?.id);
                            localStorage.setItem("type-selected-slug-user", type?.slug);
                            navigate(`/create-content`);
                          }
                        }}
                        className={"item-div"}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 4,
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={type?.icon !== null ? type?.icon : faStar}
                            className="fs-12-660C60"
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={type?.name}
                          className="fs-14-muted"
                          sx={{
                            color: type?.colour,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Box>
        </Box>
      </Popover>

      <Popover
        anchorEl={anchorElForAvatar}
        id={idAvatar}
        open={openMenuForAvatar}
        onClose={handleCloseForAvatar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      // onClick={handleCloseForAvatar}
      >
        <List>
          <ListItem onClick={handleClickForDetails} className="menu-items-user cursor-pointer">
            <ListItemIcon style={{ minWidth: "36px" }}>
              <FontAwesomeIcon
                icon={faUser}
                className="fs-12-660C60"
              />
            </ListItemIcon>
            <ListItemText
              primary={"My Details"}
              className="menu-name fs-14-muted"
            />
          </ListItem>
          <ListItem onClick={handleClickForDetails} className="menu-items-user cursor-pointer">
            <ListItemIcon style={{ minWidth: "36px" }}>
              <FontAwesomeIcon
                icon={faGears}
                className="fs-12-660C60"
              />
            </ListItemIcon>
            <ListItemText
              primary={"Top Drawer"}
              className="menu-name fs-14-muted"
            />
          </ListItem>
          <ListItem onClick={onLogout} className="menu-items-user cursor-pointer">
            <ListItemIcon style={{ minWidth: "36px" }}>
              <FontAwesomeIcon
                icon={faLock}
                className="fs-12-660C60"
              />
            </ListItemIcon>
            <ListItemText
              primary={"Log Out"}
              className="menu-name fs-14-muted"
            />
          </ListItem>
        </List>
      </Popover>

      <Loader isLoading={loading} />
    </>
  );
};

export default DrawerMenu;
