import React from "react";
import ReactDOM from "react-dom/client";
import ReactDOMServer from "react-dom/server";

export const RenderEmbedBlock = ({ title, embedCode }) => {
    return (
        <div>
            <h1>{title || "Default Title"}</h1>
            <div dangerouslySetInnerHTML={{ __html: embedCode || "Enter embed code here" }} />
        </div>
    );
};

const EmbedBlock = (editor) => {

    const embedblock = ReactDOMServer.renderToStaticMarkup(
        <RenderEmbedBlock />
    )

    editor.Traits.addType("textarea", {
        createInput({ trait }) {
            // Create a Vue instance or plain textarea element
            const textarea = document.createElement("textarea");
            textarea.style.width = "100%";
            textarea.style.minHeight = "100px";
            textarea.placeholder = trait?.get("placeholder") || "Enter text here...";

            // Get initial value from the target component's attributes
            const initialValue = this.target?.get(trait?.get("name")) || "";
            textarea.value = initialValue;

            // Update component attribute when textarea value changes
            textarea.addEventListener("input", (e) => {
                const value = e.target.value;
                this.target?.set(trait?.get("name"), value);
            });

            // Return the created textarea element
            return textarea;
        },

        onUpdate({ component }) {
            // Sync the textarea value when the component attributes update
            const textarea = this.el; // Reference to the textarea element
            const value = component?.getAttributes()[this?.trait?.get("name")] || "";
            textarea.value = value;
        },
    });

    editor.BlockManager.add("cust-embed-block", {
        label: "Custom Embed Block",
        category: "Components",
        content: {
            type: "custom-embed-block-component", // Custom type for React
            components: embedblock,
            className: "embed-blk-class",
            tagName: "div", // Wrap the menu in a div
        },
        media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>',
    });

    editor.DomComponents.addType("custom-embed-block-component", {
        isComponent: (el) => el?.tagName === "DIV" && el?.dataset?.type === "custom-embed-block-component",
        model: {
            defaults: {
                tagName: "div",
                droppable: false, // Prevent other components from being dropped inside
                copyable: true, // Enable copy
                resizable: {
                    // Add resizable options
                    tl: 0, // Top-left
                    tr: 0, // Top-right
                    bl: 0, // Bottom-left
                    br: 1, // Bottom-right (enabled)
                    tc: 0, // Top-center
                    bc: 1, // Bottom-center
                    cl: 0, // Center-left
                    cr: 1, // Center-right
                    keyWidth: 'width',
                    keyHeight: 'height',
                },
                attributes: {
                    title: "",
                    code: "", // Ensure this is initialized
                },
                traits: [
                    {
                        type: "text",
                        label: "Title",
                        name: "title",
                        placeholder: "Enter title",
                    },
                    {
                        type: "textarea",
                        label: "Embed Code",
                        name: "code",
                        placeholder: "Enter embed code",

                    },
                ],
                classes: ["custom-embed-block"], // Add the class here
            },
            init() {

                // Sync initial values with traits
                this.get("traits").forEach((trait) => {
                    const traitName = trait.get("name");
                    console.log("traitName::", traitName)
                });

                this.on("change:code", this.handleEmbedCode);
                this.on("change:title", this.handleTitle)
                this.on("change:attributes", () => {
                    this.handleEmbedCode(this.getAttributes().code)
                    this.handleTitle(this.getAttributes().title)
                });

            },
            handleTitle() {
                const code = this.getAttributes().code;
                const title = this.getAttributes().title;
                console.log("changed att data::", this.get("traits").map((trait) => trait.get("value")))

                const menuRoot = this?.view?.el;

                // Check if menuRoot exists
                if (!menuRoot) {
                    console.error("menuRoot is not available for rendering the menu.");
                    return;
                }

                const root = ReactDOM?.createRoot(menuRoot);

                root.render(
                    <RenderEmbedBlock title={title} embedCode={code} />
                );
            },
            handleEmbedCode() {
                const code = this.getAttributes().code;
                const title = this.getAttributes().title;

                const menuRoot = this?.view?.el;

                // Check if menuRoot exists
                if (!menuRoot) {
                    console.error("menuRoot is not available for rendering the menu.");
                    return;
                }

                const root = ReactDOM?.createRoot(menuRoot);

                root.render(
                    <RenderEmbedBlock title={title} embedCode={code} />
                );
            },
            removed() {
                console.log('Local hook: model.removed');
                if (this.reactRoot) {
                    this.reactRoot.unmount();
                }
            },
        },
        view: {
            onRender() {
                const menuRoot = this?.el;

                const iframe = document.querySelector("iframe");
                if (iframe) {
                    const iframeDocument =
                        iframe.contentDocument || iframe.contentWindow.document;

                    // Inject CSS dynamically into the iframe
                    const style = iframeDocument.createElement("style");

                    style.innerHTML = `
                                        //global style
                            *{
                                font-family: "Inter" !important;
                                //   background-color: #fff;
                            }
                            [data-gjs-type="cell"] {
                                position: relative !important;
                            }
                            .mt-4 {
                                margin-top: 1.5rem !important;
                            }
                            //type component
                            .news-card-wrapper {
                                padding: 0 !important;
                                background-color: #fff;
                                color: rgba(0, 0, 0, 0.87);
                                transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                border-radius: 4px;
                                box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
                                overflow: hidden;
                                max-width: 420px;
                                height: "100%";
                            }
                            .news-card-wrapper img {
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                width: 100%;
                                object-fit: cover;
                                height: 140px;
                                cursor: pointer;
                            }
                            .card-content-post {
                                padding: 16px
                            }
                            .gaps-for-stack {
                                gap: 10px;
                                height: 100% !important;
                                display: flex;
                                flex-direction: column;
                            }
                            .stack-for-stack {
                                gap: 10px;
                                justify-content: space-between !important;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                align-self: stretch;
                            }
                            .div-for-avatar {
                                gap: .5rem !important;
                                align-items: center !important;
                                display: flex !important;
                            }
                            .author-avtar-creator {
                                position: relative;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                font-size: 1.25rem;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                                height: auto !important;
                                width: 32px !important;
                                min-height: 32px;
                                font-size: 14px !important;
                            }
                            .avatar-span-class {
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 16.94px;
                                letter-spacing: 0px;
                                color: #000000;
                            }
                            .main-content-data-box {
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                            }
                            .stack-for-data-title {
                                gap: 1rem !important;
                                flex-wrap: wrap !important;
                                display: flex !important;
                                flex-direction: row;
                                align-items: center;
                                align-self: stretch;
                            }
                            .news-title {
                                font-size: 20px;
                                line-height: 28px;
                                letter-spacing: 0px;
                                overflow: hidden;
                                cursor: pointer;
                                font-weight: 400;
                                color: #333333;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                            }
                            .news-para {
                                font-size: 14px;
                                line-height: 23px;
                                letter-spacing: 0px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                font-weight: 400;
                                color: #333333;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                                opacity: .5 !important;
                            }
                            .actions-div {
                                justify-content: space-between !important;
                                flex-wrap: wrap !important;
                                display: flex !important;
                                flex-direction: row;
                                align-items: center;
                                align-self: stretch;
                            }
                            .btn-view-article {
                                cursor: pointer;
                                display: -ms-inline-flexbox;
                                display: inline-flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                position: relative;
                                box-sizing: border-box;
                                -webkit-tap-highlight-color: transparent;
                                outline: 0;
                                margin: 0;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                vertical-align: middle;
                                -webkit-text-decoration: none;
                                text-decoration: none;
                                font-weight: 500;
                                line-height: 1.75;
                                letter-spacing: 0.02857em;
                                min-width: 64px;
                                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                background: #F9F9F9 !important;
                                color: #660C60 !important;
                                border: none !important;
                                text-transform: math-auto !important;
                                border-radius: 3px !important;
                                font-size: 14px !important;
                                padding: 10px !important;
                                --bs-bg-opacity: 1;
                            }
                            .btn-view-article svg {
                                overflow: visible;
                                box-sizing: content-box;
                                font-size: 12px !important;
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -0.125em;
                            }
                            .btn-view-article span {
                                overflow: hidden;
                                pointer-events: none;
                                position: absolute;
                                z-index: 0;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                border-radius: inherit;
                            }
                            .card-action-bottom {
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                border-top: 1px solid #EEEEEE;
                                padding: 15px;
                            }
                            .card-action-bottom div {
                                gap: .5rem !important;
                                margin-top: auto !important;
                                flex-wrap: wrap !important;
                                display: flex !important;
                            }
                            .col-lg-3 {
                                height: 100%;
                            }
                            .col-md-6 {
                                height: 100%;
                            }
                            .col-sm-12 {
                                height: 100%;
                            }
                            //people block
                            .custom-people-block {
                                padding: 10px;
                            }
                            //feed block
                            .custom-feed-block {
                                padding: 10px 15px 10px 15px;
                            }
                            .feed-card {
                                border: 1px solid #eeeeee !important;
                                border-radius: 6px;
                                box-shadow: none;
                                background-color: #ffffff !important;
                                height: 100% !important;
                                width: 100% !important;
                                color: #212529 !important;
                            }
                            .card-header-content {
                                padding: 10px !important;
                                display: flex;
                                align-items: center;
                                border-bottom: 1px solid #EEEEEE !important;
                            }
                            .feed-card-avatar-header {
                                display: flex;
                                flex: 0 0 auto;
                                margin-right: 16px;
                            }
                            .author-avtar-creator {
                                height: auto !important;
                                width: 32px !important;
                                min-height: 32px;
                                font-size: 14px !important;
                                position: relative;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                            }
                            .feed-card-header {
                                flex: 1 1 auto;
                            }
                            .MuiTypography-body2 {
                                margin: 0;
                                font-weight: 400;
                                font-size: 0.875rem;
                                line-height: 1.43;
                                letter-spacing: 0.01071em;
                                display: block;
                            }
                            .feed-top-header {
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                            }
                            .MuiTypography-caption {
                                margin: 0;
                                font-weight: 400;
                                font-size: 0.75rem;
                                line-height: 1.66;
                                letter-spacing: 0.03333em;
                            }
                            .feed-card-img {
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                -webkit-background-position: center;
                                background-position: center;
                                height: 222px;
                                cursor: pointer;
                            }
                            .content-div-body {
                                padding: 15px 15px 15px 15px !important;
                                width: 70% !important;
                                background-color: #F9F9F9 !important;
                            }
                            .main-content-div-1 {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 40px;
                                margin-bottom: 20px;
                            }
                            .main-div-posted {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 15px;
                            }
                            .posted-date-icon {
                                font-size: 12px;
                                font-weight: 400;
                                color: #660C60;
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .posted-date-text {
                                font-size: 12px !important;
                                font-weight: 500 !important;
                                color: #000000;
                                opacity: 50% !important;
                                margin-top: auto !important;
                                margin-bottom: auto !important;
                            }
                            .main-div-workspace {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 15px;
                            }
                            .categoty-label-chip {
                                height: auto !important;
                                background-color: #F9F9F9 !important;
                                border-radius: 0 !important;
                                font-weight: 400 !important;
                                max-width: 100%;
                                font-size: 0.8125rem;
                                display: inline-flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                                color: rgba(0, 0, 0, 0.87);
                                white-space: nowrap;
                                transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                cursor: pointer;
                                outline: 0;
                                text-decoration: none;
                                border: 0;
                                padding: 0;
                                vertical-align: middle;
                                box-sizing: border-box;
                            }
                            .categoty-label-chip span {
                                font-weight: 500 !important;
                                font-size: 9px !important;
                                line-height: 10px !important;
                                color: #959595;
                                /* opacity: 65%; */
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 3px !important;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .post-content-data {
                                margin-top: 96px;
                            }
                            .icon-bd-div {
                                border: 1px solid #eeeeee;
                                height: 35px;
                                width: 35px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                background-color: #f9f9f9;
                            }
                            .icon-bd-div svg {
                                color: #660c60 !important;
                                font-size: 12px !important;
                                font-weight: 400 !important;
                                line-height: 12px !important;
                                overflow: visible;
                                box-sizing: content-box;
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .fs-14 {
                                font-size: 14px !important;
                                font-weight: 400 !important;
                                line-height: 24px !important;
                                font-family: "Inter" !important;
                                color: #333333 !important;
                                margin: 0 !important;
                            }
                            .opacity-50 {
                                opacity: .5 !important;
                            }
                            .content-title {
                                font-weight: 600 !important;
                                font-size: 16px !important;
                                line-height: 20px !important;
                                color: #000000 !important;
                                margin: 0;
                                letter-spacing: 0em;
                                margin-bottom: 0.35em;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                            }
                            .content-desc {
                                font-size: 14px !important;
                                font-weight: 400 !important;
                                line-height: 24px !important;
                                font-family: "Inter" !important;
                                color: #333333 !important;
                            }
                            .primary-btn {
                                background-color: #660c60 !important;
                                color: #ffffff !important;
                                border: 1px solid #660c60;
                                border-radius: 3px !important;
                                text-transform: capitalize !important;
                                box-shadow: none !important;
                                padding: 10px 15px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 15px;
                                letter-spacing: 0px;
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                justify-content: center;
                                font-family: "Inter";
                            }
                            .svg-inline--fa {
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .author-avtar-creator img {
                                height: 32px;
                                width: 32px;
                                text-align: center;
                                object-fit: cover;
                                color: transparent;
                                text-indent: 10000px;
                            }
                            .content-title-update {
                                font-size: 16px !important;
                                font-weight: 600 !important;
                                text-align: start;
                                color: #333333 !important;
                                line-height: 20px !important;
                                word-wrap: break-word !important;
                                word-break: break-word !important;
                            }
                            .search-input {
                                border: 1px solid #eeeeee;
                                border-radius: 100px !important;
                                background-color: #f9f9f9;
                                height: auto;
                                padding: 10px 54px 10px 15px;
                            }
                            .serach-box .search-btn-dash-2 {
                                position: absolute;
                                margin: 5px !important;
                                bottom: 0px;
                                right: 0px;
                                background-color: #660c60 !important;
                                border-color: #f9f9f9;
                                width: 38px !important;
                                height: 38px;
                                border-radius: 100px !important;
                                position: absolute !important;
                                right: 0;
                                margin: 0px 4px 0px 4px;
                                top: -2px;
                            }
                            .p-3{
                                padding: 1rem!important;
                            }
                            .update-post-grid-container{
                                width: calc(100% + 16px);
                                margin-left: -16px;
                                box-sizing: border-box;
                                display: -ms-flexbox;
                                display: flex;
                                -ms-flex-wrap: wrap;
                                flex-wrap: wrap;
                                -ms-flex-direction: row;
                                flex-direction: row;
                            }
                             @media(min-width: 600px) {
                                .update-post-grid-item{
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                            }
                            @media(min-width: 900px) {
                                .update-post-grid-container{
                                    width: calc(100% + 24px);
                                    margin-left: -24px;
                                }
                                .update-post-grid-item{
                                    padding-left: 15px;
                                    padding-top: 15px;
                                    -ms-flex-preferred-size: 25%;
                                    flex-basis: 25%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 25%;
                                }
                            }
                            @media(min-width: 1200px) {
                                .update-post-grid-item{
                                    -ms-flex-preferred-size: 25%;
                                    flex-basis: 25%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 25%;
                                }
                            }
                            @media(min-width: 1536px) {
                                .update-post-grid-item{
                                    -ms-flex-preferred-size: 25%;
                                    flex-basis: 25%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 25%;
                                }
                            }
                            .update-type-post-card {
                                height: 154px;
                                padding: 30px;
                                margin: 0 0 46px 0;
                                border: 1px solid #eeeeee;
                                border-radius: 6px;
                                box-shadow: none;
                                background-color: #ffffff;
                            }
                            .update-type-post-card-content{
                                padding: 0 !important;
                            }
                            .update-avtar-creator {
                                height: 40px !important;
                                width: 40px !important;
                                position: relative;
                                display: flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                font-size: 1.25rem;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                            }
                            .search-input {
                                border: 1px solid #eeeeee;
                                border-radius: 6px !important;
                                background-color: #f9f9f9;
                                height: auto;
                                padding: 10px 54px 10px 15px;
                                display: block;
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: var(--bs-body-color);
                                appearance: none;
                                background-clip: padding-box;
                                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                            }
                           .post-date-label {
                                font-size: 12px !important;
                                font-weight: 500 !important;
                                color: #959595 !important;
                            }
                            .edit-post-icon-btn {
                                display: inline-flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                                position: relative;
                                box-sizing: border-box;
                                -webkit-tap-highlight-color: transparent;
                                background-color: transparent;
                                outline: 0;
                                border: 0;
                                margin: 0;
                                cursor: pointer;
                                user-select: none;
                                vertical-align: middle;
                                -moz-appearance: none;
                                -webkit-appearance: none;
                                -webkit-text-decoration: none;
                                text-decoration: none;
                                text-align: center;
                                border-radius: 50%;
                                overflow: visible;
                                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                color: #333333 !important;
                                font-size: 12px !important;
                                font-weight: 400 !important;
                                padding: 0 8px 0 8px !important;
                            }
                            .edit-post-icon-btn svg{
                                user-select: none;
                                width: 1em;
                                height: 1em;
                                display: inline-block;
                                fill: currentColor;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                font-size: 1.5rem;
                            }
                            .content-div-body-2 {
                                padding: 15px 15px 15px 15px !important;
                                width: 100% !important;
                                background-color: #fff !important;
                            }
                            .update-content-img{
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                height: 222px;
                            }
                            .author-avtar-creator-post {
                                height: 24px !important;
                                width: 24px !important;
                                font-size: 14px !important;
                                position: relative;
                                display: flex;
                                -webkit-box-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                justify-content: center;
                                flex-shrink: 0;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                            }
                            .feed-update-post-user-img{
                                width: 100%;
                                height: 100%;
                                text-align: center;
                                object-fit: cover;
                                color: transparent;
                                text-indent: 10000px;
                            }
                            .feed-card-header-avatar{
                                display: flex;
                                flex: 0 0 auto;
                                margin-right: 16px;
                            }
                            .feed-card-header-content{
                                flex: 1 1 auto;
                            }
                            .feed-card-box-1{
                                display: flex;
                                -webkit-box-pack: justify;
                                justify-content: space-between;
                                -webkit-box-align: center;
                                align-items: center;
                            }
                            .feed-card-box-2{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            .post-posted-label{
                                font-size: 12px !important;
                            }
                            .update-multi-grid-container{
                                box-sizing: border-box;
                                display: flex;
                                flex-wrap: wrap;
                                flex-direction: row;
                            }
                            @media(min-width: 900px){
                                .update-multi-grid-container{
                                    width: calc(100% + 24px) !important;
                                    margin-left: -24px !important;
                                }
                            }
                             @media(min-width: 0px){
                                .update-multi-grid-container{
                                    width: calc(100% + 16px) !important;
                                    margin-left: -16px !important;
                                }
                            }
                            .card-content-box {
                                padding: 20px;
                            }
                            .post-img{
                                display: block;
                                background-size: cover;
                                background-repeat: no-repeat;
                                background-position: center;
                                height: 222px;
                                width: 30% !important;
                            }
                            .insight-img-box {
                                width: 80px;
                                height: 80px;
                            }
                            .insight-content-name {
                                font-size: 12px !important;
                                font-weight: 700 !important;
                                line-height: 14.52px !important;
                                color: #660C60 !important;
                            }
                            .insight-content-user-name {
                                font-size: 16px !important;
                                font-weight: 600 !important;
                                line-height: 20px !important;
                                color: #333333 !important;
                            }
                            //menu block
                            .custom-menu {
                                padding: 16px;
                                background-color: #f9f9f9;
                                border: 1px solid #ddd;
                                border-radius: 8px;
                                max-width: 320px;
                            }
                            .custom-menu-list {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                            }
                            .custom-menu-item {
                                margin-bottom: 8px;
                            }
                            .custom-menu-link {
                                text-decoration: none;
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                color: #333;
                                font-size: 16px;
                            }
                            .custom-menu-icon {
                                font-size: 18px;
                                color: #660C60;
                                height: 18px;
                            }
                            .custom-menu-name {
                                font-weight: 400;
                            }
                            .MuiPaper-root {
                                width: auto;
                                transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
                                overflow-x: hidden;
                                height: 100vh;
                                padding-top: 125px;
                                padding-bottom: 30px;
                                padding: 20px;
                                background-color: #fff;
                                color: rgba(0, 0, 0, 0.87);
                                box-shadow: none;
                                overflow-y: auto;
                                display: flex;
                                flex-direction: column;
                                position: absolute;
                                top: 0;
                                outline: 0;
                                left: 0;
                                border: 1px solid rgba(0, 0, 0, 0.12);
                                white-space: nowrap;
                            }
                            .MuiList-root {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                width: 100%;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                white-space: nowrap;
                            }
                            .MuiList-root-vertical {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                gap: 0;
                                width: 100%;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                white-space: nowrap;
                            }
                            .MuiList-root-horizontal {
                                list-style: none;
                                margin: 0;
                                padding: 0;
                                position: relative;
                                display: flex;
                                flex-direction: row;
                                gap: 10px;
                                width: 100%;
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                white-space: nowrap;
                            }
                            .MuiListItem-root {
                                -webkit-box-pack: start;
                                justify-content: flex-start;
                                -webkit-box-align: center;
                                align-items: center;
                                position: relative;
                                text-decoration: none;
                                width: 100%;
                                box-sizing: border-box;
                                text-align: left;
                                display: block;
                                padding-top: 8px;
                                padding-bottom: 8px;
                                padding-left: 16px;
                                padding-right: 16px;
                            }
                            .MuiButtonBase-root {
                                -webkit-tap-highlight-color: transparent;
                                background-color: transparent;
                                outline: 0;
                                border: 0;
                                margin: 0;
                                border-radius: 0;
                                padding: 0;
                                cursor: pointer;
                                user-select: none;
                                vertical-align: middle;
                                -webkit-text-decoration: none;
                                color: inherit;
                                display: flex;
                                -webkit-box-flex: 1;
                                align-items: center;
                                position: relative;
                                text-decoration: none;
                                min-width: 0;
                                box-sizing: border-box;
                                text-align: left;
                                padding-top: 8px;
                                padding-bottom: 8px;
                                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                                min-height: 48px;
                                -webkit-box-pack: initial;
                                justify-content: space-between;
                                align-items: center;
                                padding-left: 20px;
                                padding-right: 20px;
                            }
                            .MuiListItemIcon-root {
                                color: rgba(0, 0, 0, 0.54);
                                flex-shrink: 0;
                                display: inline-flex;
                                min-width: 0;
                                margin-right: 16px;
                                -webkit-box-pack: center;
                                justify-content: center;
                            }
                            .MuiListItemIcon-root svg {
                                overflow: visible;
                                box-sizing: content-box;
                                font-size: 15px;
                                color: #959595;
                                font-weight: 400;
                                line-height: 15px;
                            }
                            .svg-inline--fa {
                                display: var(--fa-display, inline-block);
                                height: 1em;
                                overflow: visible;
                                vertical-align: -.125em;
                            }
                            .menu-icon {
                                font-size: 15px;
                                color: #959595;
                                font-weight: 400;
                                line-height: 15px;
                            }
                            .MuiListItemText-root {
                                flex: 1 1 auto;
                                min-width: 0;
                                margin-top: 4px;
                                margin-bottom: 4px;
                                opacity: 1;
                            }
                            .MuiTypography-root {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 15px;
                                opacity: 50%;
                            }
                            .menu-name span {
                                font-size: 15px;
                                font-weight: 400;
                                line-height: 15px;
                                opacity: 50%;
                            }
                            .selected-menu-item .menu-name span {
                                color: white !important;
                                opacity: inherit;
                            }
                            .selected-menu-item {
                                background-color: #660C60 !important;
                                border-radius: 6px;
                                color: white !important;
                            }
                            .selected-menu-item .menu-icon {
                                color: white !important;
                            }
                            .vertical-menu {
                                display: flex;
                                flex-direction: column;
                            }
                            .horizontal-menu {
                                display: flex;
                                flex-direction: row;
                                gap: 20px;
                            }
                            .horizontal-item {
                                display: inline-block;
                            }
                            .list-item-box {
                                height: 540px !important;
                                overflow-y: auto;
                            }
                            .menu-divider{
                                margin: 0;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                border-width: 0;
                                border-style: solid;
                                border-color: rgba(0, 0, 0, 0.12);
                                border-bottom-width: thin;
                            }
                            .content-btn {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 1rem!important;
                                margin-top: 1rem!important;
                                width: 100%!important;
                            }
                            .menu-create-content-box{
                                width: 100%;
                            }
                            .create-content-btn {
                                background-color: #660C60 !important;
                                color: white !important;
                                border-color: white !important;
                                border-radius: 3px !important;
                                text-transform: capitalize !important;
                                box-shadow: none !important;
                                padding: 10px 15px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 15px;
                                letter-spacing: 0px;
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                justify-content: center;
                                font-family: "Inter";
                                width: 100%;
                            }
                            .menu-user-info-div{
                                margin-bottom: 1rem !important;
                                margin-top: 1rem !important;
                                display: flex !important;
                                justify-content: space-between !important;
                                align-items: center !important;
                            }
                            .menu-user-name-avatar-box{
                                display: flex !important;
                                align-items: center !important;
                                gap: .5rem !important;
                            }
                            .author-avtar {
                                height: 32px !important;
                                width: 32px !important;
                                position: relative;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                justify-content: center;
                                -ms-flex-negative: 0;
                                flex-shrink: 0;
                                line-height: 1;
                                border-radius: 50%;
                                overflow: hidden;
                                user-select: none;
                                color: #fff;
                                background-color: #bdbdbd;
                                font-size: 14px;
                            }
                            .logged-user-name {
                                font-size: 14px !important;
                                font-weight: 500 !important;
                                line-height: 18px !important;
                                margin-bottom: 0 !important;
                                color: #333333 !important;
                                opacity: unset !important;
                            }
                            .setting-btn {
                                width: auto !important;
                                background-color: #fff !important;
                                color: #959595 !important;
                                border-color: white !important;
                                border-radius: 3px !important;
                                text-transform: capitalize !important;
                                box-shadow: none !important;
                                padding: 10px 15px;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 15px;
                                letter-spacing: 0px;
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                justify-content: center;
                                font-family: "Inter";
                            }
                            .setting-icon {
                                color: #959595 !important;
                            }
                            //link block
                            .link-list-container {
                                box-sizing: border-box;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-box-flex-wrap: wrap;
                                -ms-flex-wrap: wrap;
                                flex-wrap: wrap;
                                -ms-flex-direction: row;
                                flex-direction: row;
                                width: calc(100% + 16px);
                                margin-left: -16px;
                            }

                            .link-list-item-grid {
                                padding-left: 16px;
                                padding-top: 16px;
                                box-sizing: border-box;
                                margin: 0;
                                -ms-flex-direction: row;
                                flex-direction: row;
                                -ms-flex-preferred-size: 100%;
                                flex-basis: 100%;
                                -webkit-box-flex: 0;
                                -ms-flex-positive: 0;
                                flex-grow: 0;
                                max-width: 100%;

                                @media(min-width: 1536px) {
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                                @media(min-width: 1200px) {
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                                @media(min-width: 900px) {
                                    -ms-flex-preferred-size: 33.333333%;
                                    flex-basis: 33.333333%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 33.333333%;
                                }
                                @media(min-width: 600px) {
                                    -ms-flex-preferred-size: 50%;
                                    flex-basis: 50%;
                                    -webkit-box-flex: 0;
                                    -ms-flex-positive: 0;
                                    flex-grow: 0;
                                    max-width: 50%;
                                }
                            }
                            .link-icon-box {
                                cursor: pointer;
                                position: relative;
                                overflow: hidden;
                                display: flex;
                                gap: 12px;
                                border-radius: 6px;
                                padding: 15px;
                                padding-top: 1rem !important;
                                padding-bottom: 1rem !important;
                            }
                            .icon-content {
                                display: flex;
                                gap: 12px;
                                overflow: hidden;
                            }
                            .icon-box {
                                display: block;
                                box-sizing: border-box;
                            }
                            .icon-value {
                                overflow: visible;
                                box-sizing: content-box;
                                display: inline-block;
                                height: 1em;
                                vertical-align: -.125em;
                            }
                            .link-label-text {
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                height: 22px;
                            }
                            .link-label-box {
                                margin: 0;
                                font-weight: 400;
                                font-size: 1rem;
                                line-height: 1.5;
                                letter-spacing: 0.00938em;
                            }
                    
                        `;
                    iframeDocument.head.appendChild(style);
                }

                const attributes = this.model.getAttributes();
                const { title, code } = attributes
                const root = ReactDOM.createRoot(menuRoot);
                root.render(
                    <RenderEmbedBlock title={title} embedCode={code} />
                );
            },
        },
    });
}

export default EmbedBlock